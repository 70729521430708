@import "../../../../mixins.scss";

.newSettingWrapper {
    min-height: 100vh;

    // &.h-100 {
    //     min-height: 100vh;
    // }

    @include themed() {
        background: $bg-dark;
    }

    .pageTitle {
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 99;
        background: $white;
        height: 70px;
        width: 100%;
        padding: 24px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);

        @include themed {
            background: $bg-dark;
            box-shadow: 0 8px 6px -6px black;
        }

        a {
            display: flex;
            justify-content: center;
            margin-right: 12px;
            color: $primary;

            svg {
                position: relative;
                transition: all 0.5s ease;
                left: 0px;
            }

            &:hover {
                cursor: pointer;
                color: $primary-red;

                svg {
                    left: -4px;
                }
            }
        }

        .MuiSelect-select {
            min-width: 220px;
        }
    }

    .innerSection {
        padding-top: 90px;
        padding-left: 24px;
        padding-right: 24px;
        min-height: 100vh;
        padding-bottom: 24px;

        .MenuBox {
            border: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.10);
            border-radius: 16px;
            padding: 16px;
            min-height: 390px;

            @include themed {
                background: $bg-grey;
            }

            h4 {
                font-family: 'Montserrat';
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
                margin-bottom: 20px;
            }

            .menulistBox {
                span {
                    position: relative;
                    display: block;
                    padding-bottom: 128px;

                    $button-icons: general,
                        organisation,
                        userControl,
                        customisation,
                        portalSetup,
                        dataAdmin,
                        developerSpace;
                    $k: 0;

                    @each $icons in $button-icons {
                        $bacRightP: $k * 260;
                        $k: $k +1;
                        // padding: 12px 12px 12px 72px;

                        &.#{$icons} {
                            &:before {
                                @include position(absolute, $top: 0, $left: calc(50% - 130px));
                                content: "";
                                height: 111px;
                                width: 260px;
                                background: url("../../../../../public/images/settingModulesicons.svg") no-repeat -#{$bacRightP}px 0px/1820px;

                            }

                            // &:focus,
                            // &:hover {
                            //     color: $primary;
                            //     font-weight: 700;
                            //     transform: scale(1.2);
                            //     transition: all 0.4s ease;
                            // }
                        }
                    }
                }

                .menulist {
                    padding: 0;
                    margin: 0;

                    li {
                        list-style: none;
                        font-family: 'Montserrat';
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 32px;
                        margin-bottom: 4px;
                        position: relative;
                        padding-left: 4px;
                        padding-right: 4px;

                        &::after {
                            content: '';
                            background: url("./../../../../../public/images/rightArrowIcon.svg");
                            width: 6px;
                            height: 10px;
                            @include position(absolute, $top: 11px, $right: 4px);

                            @include themed {
                                background: url("./../../../../../public/images/rightArrowIconWhite.svg");
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:hover {

                            background: #c5d8ed;
                            border-radius: 6px;
                        }

                        .listItem {
                            text-decoration: none;
                            color: rgba(0, 0, 0, 0.7);
                            position: relative;
                            display: inline-block;
                            width: 100%;

                            &:hover {
                                color: $primary !important;
                            }

                            @include themed() {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

}

.settingMenuWrapper {
    padding: 88px 12px 95px 24px;
    min-height: 100vh;

    @include themed {
        background: $bg-dark;
    }

    .pageHeader {
        background: $white;
        // @include box-shadow(0px 2px 12px rgba($black, 0.1));
        @include position(fixed, $top: 0px, $left: 0px);
        margin: 0px;
        height: 70px;
        width: 100%;
        z-index: 991;

        @include themed {
            background: $bg-dark;
        }

        .pageTitle {
            display: flex;
            align-items: center;

            .backStep {
                margin-right: 8px;
                color: $primary;
                display: flex;
                align-items: center;
                justify-content: center;


                &:hover {
                    color: $primary-red;

                    svg {
                        left: -4px;
                    }
                }

                svg {
                    position: relative;
                    transition: all 0.5s ease;
                    left: 0px;
                    height: 26px;
                    width: 30px;
                }
            }

            .detailIcon {
                background: $primary-orange;
                @include border-radius(50px);
                color: $white;
                @include fontSize(20px);
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                margin-right: 16px;
                height: 40px;
                width: 40px;
            }

            h3 {
                margin-bottom: 0px;
                @include fontSize(24px);
                line-height: 29px;

                @include themed {
                    color: $text-grey;
                }
            }

            p {
                margin-bottom: 0px;
                margin-top: 6px;
                margin-bottom: 2px;
                color: rgba($black, 0.5);
                @include fontSize(11px);

                @include themed {
                    color: $text-grey;
                }
            }
        }
    }

    .rightSection {
        // padding-top: 67px;
        overflow-y: auto;
        height: calc(100vh - 145px);

        &.h-100 {
            min-height: 91vh;
        }

        .detailSection {
            // background: $white;
            border: 1px solid #E5E7FE;
            @include border-radius(12px);
            padding-bottom: 12px;
            padding-top: 8px;

            @include themed {
                border: 1px solid $bg-grey;
            }

            .moduleHeading {
                display: flex;
                // justify-content: space-between;
                background: #e4f1ff;
                @include border-radius(12px 12px 0px 0px);
                padding: 14px 12px;
                margin-bottom: 0;

                @include themed {
                    background: $black;
                    color: $text-grey;
                }

                li {
                    list-style-type: none;
                    font-family: 'Montserrat';
                    font-weight: 600;
                    @include fontSize(12px);
                    line-height: 15px;
                    flex-basis: 0;
                    flex-grow: 1;

                    a {
                        color: $primary;
                    }

                    @include themed {
                        color: $text-grey;
                    }
                }
            }

            .module_list {
                display: flex;
                align-items: center;
                padding: 14px 12px;
                margin-bottom: 0;

                @include themed {
                    background: #171b1e;
                    color: $text-grey;
                    border-radius: 0 0 12px 12px;
                }

                img {
                    margin-right: 5px;
                }


                li {
                    list-style-type: none;
                    font-family: 'Montserrat';
                    font-weight: 500;
                    @include fontSize(14px);
                    line-height: 17px;
                    flex-basis: 0;
                    flex-grow: 1;

                    a {
                        text-decoration: none !important;
                        cursor: pointer;
                    }

                    @include themed {
                        background: #171b1e;
                        color: $text-grey;
                    }

                    .moduleLink {
                        text-decoration: none;
                        color: $black;

                        @include themed {
                            color: $text-grey;
                        }
                    }

                    .module_sublist {
                        margin-top: 10px;
                        margin-left: 20px;

                        li {
                            .Sublist_module {
                                @include fontSize(12px);
                                color: $black;
                                padding: 5px 0px;
                                display: inline-block;
                                color: $primary;
                            }
                        }
                    }
                }
            }

            hr {
                margin-bottom: 0px;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: none;
                border-left: none;
                border-right: none;

                @include themed {
                    border-top: 1px solid $bg-grey;
                }
            }

            .profile_section {
                text-align: center;

                .profile_inner_section {
                    border: 1px dashed rgba($black, 0.1);
                    padding: 8px;
                    @include border-radius(8px);

                    img {
                        height: 92px;
                    }
                }
            }
        }
    }

    .settingListdetailBox {
        box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.12);
        border-radius: 20px;

        // min-height: 700px;
        @include themed() {
            background: $bg-grey;
        }

        .settingListBoxheader {
            background: #EEF6FE;
            border-radius: 16px 16px 0px 0px;
            padding: 14px 24px;

            h5 {
                color: $black;
                margin-bottom: 0;
                font-family: 'Montserrat';
                font-weight: 600;
                @include fontSize(12px);
                line-height: 15px;
            }
        }

        .detailTable {
            padding: 24px;

            @include themed() {
                color: $white;
            }

            h6 {
                @include fontSize(14px);
                font-weight: 700;
                line-height: 17px;
            }

            p {
                @include fontSize(14px);
                font-weight: 500;
                line-height: 17px;

                .lastupdated_userprofile {
                    height: 38px;
                    width: 38px;
                    margin-right: 20px;
                    border-radius: 30px;
                }
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style: none;
                    @include fontSize(14px);
                    font-weight: 500;
                    line-height: 17px;
                    padding-bottom: 12px;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }

            .last_modified_p {
                @include fontSize(14px);
                font-weight: 500;
                line-height: 17px;
                position: relative;
                padding-left: 60px;

                &::before {
                    position: absolute;
                    content: '';
                    background: red url('./../../../../../public/images/last_modified_profile.svg') no-repeat 0 0/38px;
                    height: 38px;
                    width: 38px;
                    top: -8px;
                    left: 0;
                    @include border-radius(30px);
                }
            }
        }

        hr {
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .pageTitle {
        display: flex;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            margin-right: 12px;
            color: $primary;

            svg {
                position: relative;
                transition: all 0.5s ease;
                left: 0px;
            }

            &:hover {
                cursor: pointer;
                color: $primary-red;

                svg {
                    left: -4px;
                }
            }
        }

        .MuiSelect-select {
            min-width: 220px;
        }
    }

    .user_profile_section {
        padding: 48px 20px;
        background: $primary;
        border-radius: 20px;

        .user_profile_photo {
            width: 135px;
            height: 135px;
            border-radius: 50%;
        }

        h5 {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 6px;
            color: $white;
        }

        p {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $white;
            font-family: 'Montserrat';
        }

        .user_personal_detail {
            h6 {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 12px;
                color: $white;
            }

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                font-family: 'Montserrat';
                color: $white;
            }

            span {
                position: relative;
                display: block;
                width: 30px;
                height: 30px;
                left: calc(50% - 15px);
                margin-bottom: 8px;

                $button-icons: country,
                    timeFormat,
                    currency,
                    GSTNumber,
                    website;
                $k: 0;

                @each $icons in $button-icons {
                    $bacRightP: $k * 30;
                    $k: $k +1;

                    &.#{$icons} {
                        &:before {
                            position: absolute;
                            content: "";
                            height: 30px;
                            width: 30px;
                            left: 0;
                            background: url("./../../../../../public/images/user_personal_details_icons.svg") no-repeat 0 0 /36px;
                            background-position: -#{$bacRightP}px 0px;
                            background-size: 150px;
                            z-index: 2;

                        }
                    }
                }
            }
        }
    }

    .password_section {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 36px 20px 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        @include themed {
            background: $bg-grey;
        }

        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 9px;

            @include themed {
                color: $white;
            }

            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                font-family: 'Montserrat';
                display: block;
                padding-top: 9px;

                @include themed {
                    color: $white;
                }
            }
        }

        .chng_pwd_link {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-decoration: none;
            color: $primary;
            font-family: 'Montserrat';

            img {
                padding-right: 4px;
            }
        }
    }

    .signature_section {
        background: #F9FBFF;
        border-radius: 16px;
        padding: 32px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        @include themed {
            background: $bg-grey;
        }

        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 9px;

            @include themed {
                color: $white;
            }

            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                font-family: 'Montserrat';
                display: block;
                padding-top: 9px;

                @include themed {
                    color: $white;
                }
            }
        }

        .upload {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-decoration: none;
            color: $primary;
            font-family: 'Montserrat';

            img {
                padding-right: 4px;
            }
        }
    }

    .address_details_section {
        border: 1px solid rgba(0, 0, 0, 0.1);
        filter: drop-shadow(-3px 10px 14px rgba(0, 0, 0, 0.03));
        border-radius: 20px;
        padding: 50px 20px !important;


        @include themed {
            background: $bg-grey;
        }

        h5 {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 9px;

            @include themed {
                color: $white;
            }
        }

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            font-family: 'Montserrat';
            color: rgba(0, 0, 0, 0.3);

            @include themed {
                color: $white;
            }
        }

        .contact_details_innerBox {
            position: relative;

            h6 {
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                margin-bottom: 8px;
                color: $primary;
                font-family: "Montserrat";

                // @include themed {
                //     color: $white;
                // }
            }

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                font-family: 'Montserrat';
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 95%;

                @include themed {
                    color: $text-light;
                }
            }

            h5 {
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                font-family: 'Montserrat';

                @include themed {
                    color: $text-light;
                }
            }

            span {
                position: relative;
                display: inline-block;
                width: 36px;
                height: 36px;
                // $button-icons: emailicon, mbNumber, phNumber, buildingName, areaName, state, city, Zipcode, country;
                // $k: 0;

                // @each $icons in $button-icons {
                //     $bacRightP: $k * 36;
                //     $k: $k +1;

                //     &.#{$icons} {
                //         &:before {
                //             position: absolute;
                //             content: "";
                //             height: 36px;
                //             width: 36px;
                //             background: url("./../../../../../public/images/contact_details_icon.svg");
                //             background-position: -#{$bacRightP}px 0px;
                //             background-size: 396px;
                //             z-index: 2;

                //         }
                //     }
                // }
            }

        }
    }

    .themeBox {
        border: 1px solid $primary-light;
        @include border-radius(16px);
        padding-top: 8px;
        padding-bottom: 24px;
        margin-top: 24px;

        @include themed() {
            border-color: $bg-grey;
            background: $bg-grey;
        }

        &.darkTheme {
            background: $black;
            border-color: $black;

            @include themed() {
                border-color: $bg-grey;
            }

            h5 {
                color: $text-light;
            }

            hr {
                border-color: $bg-grey;
            }

            .MuiRadio-root {
                svg {
                    color: $text-grey;
                }

                &.Mui-checked {
                    svg {
                        color: $primary-color;
                    }
                }
            }
        }

        .MuiRadio-root {
            svg {
                color: $text-grey;
                @include fontSize(32px);
            }

            &.Mui-checked {
                svg {
                    color: $primary-color;
                }
            }
        }

        h5 {
            @include fontSize(16px);
            font-weight: 600;
            display: flex;
            align-items: center;

            @include themed() {
                color: $text-light;
            }

            svg {
                @include fontSize(28px);
                margin-left: 16px;
                margin-right: 12px;
                color: $text-grey;
            }
        }

        hr {
            border: none;
            border-top: 1px solid $primary-light;
            margin-top: 8px;
            margin-bottom: 24px;

            @include themed() {
                border-color: $bg-grey;
            }
        }

        img {
            width: 100%;
            max-width: 290px;
            padding: 0px 24px;
        }
    }

    p {
        @include themed() {
            color: $white;
        }
    }


    //Organisanation Details sections
    .logosection {
        background: $blue-light;
        border-radius: 16px;
        // padding: 16px 20px;
        text-align: center;
        // min-height: 190px;
        // margin-bottom: 24px;

        @include themed {
            background: $bg-grey;
        }

        &.order_section {
            order: 3;

            @media(max-width: 1200px) {
                order: 2;
            }
        }

        .detail_list{
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            gap: 24px;
            li{
                width: calc(50% - 12px);
                padding-right: 8px;
                list-style: none;
                text-align: left;
                p {
                    color: $primary;
                    font-family: 'Montserrat';
                    font-weight: 400;
                    @include fontSize(10px);
                    line-height: 12px;
                    margin-bottom: 8px;
                }
    
                h6 {
                    font-family: 'Montserrat';
                    font-weight: 700;
                    @include fontSize(12px);
                    line-height: 14px;
                    color: $black;
                    text-align: left;
                    word-break: break-all;
                    margin-bottom: 0;
    
                    @include themed {
                        color: $text-light;
                    }
                }
            }
        }

        .detail_list_v2{
            display: flex;
            flex-direction: column;
            padding-left: 0;
            gap: 24px;
            li{
                width: calc(50% - 12px);
                padding-right: 8px;
                list-style: none;
                text-align: left;
                p {
                    color: $primary;
                    font-family: 'Montserrat';
                    font-weight: 400;
                    @include fontSize(10px);
                    line-height: 12px;
                    margin-bottom: 8px;
                }
    
                h6 {
                    font-family: 'Montserrat';
                    font-weight: 700;
                    @include fontSize(12px);
                    line-height: 14px;
                    color: $black;
                    text-align: left;
                    word-break: break-all;
                    margin-bottom: 0;
    
                    @include themed {
                        color: $text-light;
                    }
                }
            }
        }

        h6 {
            font-family: 'Montserrat';
            font-weight: 500;
            @include fontSize(10px);
            line-height: 12px;
            text-align: center;
            margin-bottom: 10px;

            @include themed {
                color: $text-light;
            }
        }

        .companylogo {
            height: 70px;
            margin-bottom: 10px;
            border: 1px dashed rgba($black, 0.1);
            @include border-radius(8px);
            overflow: hidden;
            padding: 8px;
        }

        .upload_img {
            cursor: pointer;

            &:hover {
                background-color: transparent;
            }

            img {
                position: relative;
                right: 6px;
                top: 10px;
            }
        }

        h5 {
            font-family: 'Montserrat';
            font-weight: 700;
            @include fontSize(12px);
            line-height: 12px;
            text-align: center;
            margin-bottom: 0;

            @include themed {
                color: $text-light;
            }
        }

        .Companydetail {
            display: flex;
            justify-content: space-between;
            @media(max-width:1450px){
                flex-direction: column;
                align-items: flex-start;
            }

            p {
                color: $primary;
                font-family: 'Montserrat';
                font-weight: 400;
                @include fontSize(10px);
                line-height: 12px;
                margin-bottom: 8px;
                @media(max-width:1450px){
                    text-align: left;
                 }
            }

            h6 {
                font-family: 'Montserrat';
                font-weight: 700;
                @include fontSize(12px);
                line-height: 14px;
                color: $black;
                margin-bottom: 10px;
                word-break: break-all;

                @include themed {
                    color: $text-light;
                }
                @media(max-width:1450px){
                   text-align: left;
                }
            }

            h5 {
                font-family: 'Montserrat';
                font-weight: 400;
                @include fontSize(12px);
                line-height: 14px;
                color: $black;
                margin-bottom: 10px;
                text-align: left;

                @include themed {
                    color: $text-light;
                }
            }
        }
    }

    // personal Details sections
    .personal_logosection {
        background: #F9FBFF;
        border-radius: 16px;
        padding: 20px 17px;
        text-align: center;
        min-height: 190px;
        margin-bottom: 24px;

        @include themed {
            background: $bg-grey;
        }

        h6 {
            font-family: 'Montserrat';
            font-weight: 500;
            @include fontSize(10px);
            line-height: 12px;
            text-align: center;
            margin-bottom: 16px;

            @include themed {
                color: $text-light;
            }
        }

        .company_logo {
            height: 92px;
            margin-bottom: 20px;
            border: 1px solid transparent;
            border-radius: 100%;
            width: 93px;
            overflow: hidden;
        }

        .upload_img {
            cursor: pointer;

            &:hover {
                background-color: transparent;
            }

            img {
                position: relative;
                right: 6px;
                top: 10px;
            }
        }

        h5 {
            font-family: 'Montserrat';
            font-weight: 700;
            @include fontSize(12px);
            line-height: 15px;
            text-align: center;
            margin-bottom: 6px;

            @include themed {
                color: $text-light;
            }
        }

        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            margin-bottom: 0;

            @include themed {
                color: $text-light;
            }
        }

        .Companydetail {
            display: flex;
            justify-content: space-between;

            p {
                color: $primary;
                font-family: 'Montserrat';
                font-weight: 400;
                @include fontSize(10px);
                line-height: 12px;
                margin-bottom: 8px;
                text-align: left;
            }

            h4 {
                color: $primary;
                font-family: 'Montserrat';
                font-weight: 400;
                @include fontSize(10px);
                line-height: 12px;
                margin-bottom: 8px;
                text-align: right;
            }

            h6 {
                font-family: 'Montserrat';
                font-weight: 700;
                @include fontSize(12px);
                line-height: 14px;
                color: $black;
                margin-bottom: 10px;

                @include themed {
                    color: $text-light;
                }
            }

            // h5 {
            //     font-family: 'Montserrat';
            //     font-weight: 700;
            //     @include fontSize(12px);
            //     line-height: 14px;
            //     color: $black !important;
            //     margin-bottom: 10px;

            //     @include themed {
            //         color: $text-light;
            //     }
            // }
        }
    }

    .right_section {
        border: 1px solid rgba(0, 0, 0, 0.1);
        @include border-radius(20px);
        padding: 20px;
        margin-bottom: 24px;

        @include themed {
            background: $bg-grey;
        }

        h5 {
            font-family: 'Montserrat';
            font-weight: 600;
            @include fontSize(16px);
            line-height: 20px;
            margin-bottom: 20px;

            span {
                display: block;
                padding-top: 10px;
                font-family: 'Montserrat';
                font-weight: 400;
                @include fontSize(12px);
                line-height: 14px;
            }

            @include themed {
                color: $text-light;
            }
        }

        p {
            color: $primary;
            font-family: 'Montserrat';
            font-weight: 400;
            @include fontSize(10px);
            line-height: 12px;
            margin-bottom: 8px;
        }

        h6 {
            font-family: 'Montserrat';
            font-weight: 700;
            @include fontSize(12px);
            line-height: 14px;
            color: $black;
            margin-bottom: 10px;

            @include themed {
                color: $text-light;
            }

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 95%;
        }
    }

    .address_section {
        background: #F9FBFF;
        @include border-radius(20px);
        padding: 20px;
        margin-bottom: 24px;

        @include themed {
            background: $bg-grey;
        }

        h5 {
            font-family: 'Montserrat';
            font-weight: 600;
            @include fontSize(16px);
            line-height: 20px;
            margin-bottom: 20px;

            span {
                display: block;
                padding-top: 10px;
                font-family: 'Montserrat';
                font-weight: 400;
                @include fontSize(12px);
                line-height: 14px;
            }

            @include themed {
                color: $text-light;
            }
        }

        p {
            color: $primary;
            font-family: 'Montserrat';
            font-weight: 400;
            @include fontSize(10px);
            line-height: 12px;
            margin-bottom: 8px;
        }

        h6 {
            font-family: 'Montserrat';
            font-weight: 700;
            @include fontSize(12px);
            line-height: 14px;
            color: $black;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 95%;

            @include themed {
                color: $text-light;
            }
        }
    }

    .footerSection {
        background: $white;
        @include box-shadow(0px -4px 12px rgba($black, 0.1));
        padding: 4px;
        @include position(fixed, $bottom: 0px, $left: 0px);
        margin: 0px;
        width: 100%;
        z-index: 991;
        text-align: right;

        @include themed {
            background: $bg-grey;
            // box-shadow: 0px -9px 12px rgba(0, 0, 0, 0.3);
        }

        button {
            @include fontSize(14px);
            margin-left: 12px;
            padding: 8px 30px;
        }
    }
}