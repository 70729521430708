

    .Mdrawer {
        margin-top: 50px;
        font-family:Montserrat !important;
        overflow: hidden;
        input[type='radio'] {
            background: red;
        }
        span.error {
            bottom: -24px;
            text-transform: capitalize;
        }
        span,p{
            font-family: Montserrat;
            font-size: .875rem;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            

        }
        p{
            color: #2160aa;
            font-family: Montserrat;
            font-size: .6875rem;
            font-weight: 500;
            line-height: 13px;
            margin-bottom: 0;
        }
    }
  