@import "../../../../mixins.scss";

.wellness_wrapper {
    .main_heading {
        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(28px);
            font-weight: 600;
            line-height: 34px;
            margin-bottom: 5px;
        }

        p {
            font-family: "Roboto", sans-serif;
            @include fontSize(14px);
            font-weight: 300;
            line-height: 16px;
            margin-bottom: 0px;
            opacity: 0.5;
        }
    }

    iframe {
        width: 300px;
        @include border-radius(12px);
        height: 220px;
    }
}