@import "../../../../mixins.scss";

.main-container {
  .header {
    padding: 20px;
    box-shadow: 0px 4px 11px 0px #0000001a;

    h3 {
      font-family: Montserrat;
      font-size: 20px !important;
      font-weight: 600;
      line-height: 24.38px;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }
  .record-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border: 1px solid #0000001A;
    padding: 12px;
    .leftSide {
      display: flex;
      gap: 40px;
      .record-section {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;

        .value {
          font-weight: 700;
        }
      }
    }
   .right-side{
    display: flex;
    align-items: center;
   span{
    font-family: Montserrat;
font-size: 12px;
font-weight: 500;
line-height: 14.63px;
text-align: right;
color: $primary;
display: inline-block;
margin-left: 5px;

   }
   }
  }
  .done-btn{

padding: 10px 40px 10px 40px;
gap: 10px;
border-radius: 10px;
background: $primary;
color: #fff;
font-family: Montserrat;
position: relative;
z-index: 500000;



  }
  
}

.flex {
  display: flex;
}
