@import "../../mixins.scss";

.keyboardWrapper {
  .MuiBackdrop-root {
    background: rgba($grey-dark, 0.2);
    backdrop-filter: blur(4px);
  }
  .keyboardShortcuts {
    background: rgba($grey-dark, 0.9);
    @include box-shadow(0px 6px 16px rgba($grey-dark, 0.4));
    @include border-radius(16px);
    color: #fff;
    @include abs-position(50%, null, null, 50%);
    transform: translate(-50%, -50%);
    padding: 16px;
    height: 360px;
    width: 820px;
    z-index: 999;
    // @include themed() {
    //   background: rgba($bg-dark, 0.9);
    // }
    h4 {
      font-weight: 500;
      margin-bottom: 8px;
    }
    .shortcutKeys {
      height: 290px;
      overflow-y: auto;
    }
    p {
      border-bottom: 1px solid rgba(#fff, 0.2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
    }
    kbd {
      background: rgba($grey-dark, 0.9);
      @include border-radius(4px);
      text-shadow: 0px 3px 6px rgba(#000, 0.2);
      @include fontSize(12px);
      font-weight: 500;
      padding: 2px 8px;
    }
  }
}
