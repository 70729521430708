@import "../../../../mixins.scss";

.totalClients {
  background: rgba($primary, 0.1);
  @include border-radius(20px);
  position: relative;
  min-height: 140px;

  @include themed() {
    background: rgba($primary, 0.4);
  }

  h3 {
    @include fontSize(28px);
    color: $primary;
    margin-bottom: 4px;
    font-family: "Montserrat";
  }

  p {
    font-weight: 300;
    @include fontSize(14px);
    font-family: "Montserrat";

    @include themed() {
      color: $text-light;
    }
  }

  .charts {
    position: absolute;
    top: -7px;
    right: 0px;
  }

  svg {
    transform: scale(1.2);
    transition: all 0.4s ease;

    &:hover {
      transform: scale(2);
    }

    rect {
      fill: none;
    }
  }
}

.activePolicies {
  background: $white;
  border: 1px solid #ffe1a7;
  @include border-radius(20px);
  min-height: 140px;

  h3 {
    @include fontSize(28px);
    color: #d8940f;
    margin-bottom: 4px;
    margin-top: 6px;
    font-family: "Montserrat";
  }

  p {
    font-weight: 300;
    margin-bottom: 6px;
    @include fontSize(14px);
    font-family: "Montserrat";
  }

  ul {
    margin-top: 12px;
    margin-bottom: 12px;

    li {
      @include fontSize(12px);
      text-align: center;
      list-style-type: none;
      position: relative;
      text-align: right;
      padding-right: 12px;
      margin-bottom: 6px;
      font-family: "Montserrat";

      &:before {
        content: "";
        @include position(absolute, $right: 0px, $top: 4px);
        height: 6px;
        width: 6px;
      }

      $box-colors: ("health", #2160aa), ("term", #576cef), ("ci", #e45c8b),
        ("pa", #f3b03d), ("trvl", #4caf50), ("stp", #ffc107);
      $k: 0;

      @each $color in $box-colors {
        $k: $k + 1;

        &.#{nth($color,1)} {
          &:before {
            background: nth($color, 2);
          }
        }
      }
    }
  }
}

.noofLives {
  background: $primary;
  @include border-radius(20px);
  min-height: 140px;

  .totalLives {
    display: flex;
    justify-content: center;
    margin-top: 8px;

    h3 {
      color: $white;
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 24px;
      font-family: "Montserrat";
    }

    img {
      height: 60px;
      margin-right: 16px;
      margin-left: 8px;
    }
  }

  h5 {
    color: $white;
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 20px;
    font-family: "Montserrat";
  }

  p {
    color: $black;
    @include fontSize(14px);
    font-weight: 500;
    font-family: "Montserrat";
  }
}

.alertMessage_broker {
  background: $white;
  border: 1px solid rgba($primary-orange, 0.5);
  @include border-radius(12px);
  @include box-shadow(0px 4px 12px rgba($primary-orange, 0.2));
  padding: 16px 12px 12px 12px;
  position: relative;

  // @include themed(){
  //     background: rgba($primary-orange, 0.3);
  //     border-color: rgba($white, 0.5);
  // }

  h5 {
    margin-bottom: 8px;
    @include fontSize(14px);
    font-weight: 600;
    font-family: "Montserrat";
    display: flex;
    align-items: center;
    gap: 8px;

    @include themed() {
      color: $bg-dark;
    }

    svg {
      color: $primary-orange;
    }
  }

  p {
    @include fontSize(13px);
    color: rgba($black, 0.5);
    margin-bottom: 4px;
    font-family: "Montserrat";

    @include themed() {
      color: rgba($white, 0.5);
    }
  }

  a {
    display: block;
    @include position(absolute, $top: 22px, $right: 16px);
    height: 18px;
    width: 18px;

    svg {
      color: $text-grey;
      position: relative;
      top: auto;
      left: auto;
    }

    &:hover {
      cursor: pointer;
    }
  }

  ul {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    padding-left: 10px;

    &::-webkit-scrollbar {
      width: 0px;
    }

    li {
      font-family: "Montserrat";
      // list-style-type: circle;
      @include fontSize(13px);
      color: rgba($black, 0.5);
      margin-bottom: 6px;
      position: relative;
      padding-left: 10px;

      &:before {
        content: "";
        @include position(absolute, $top: 7px, $left: 0);
        background: $primary;
        height: 5px;
        width: 5px;
        border-radius: 50%;
      }

      @media (max-width: 1450px) {
        display: flex;
        flex-direction: column;
        gap: 0;
      }
    }
  }
}

.policyRenewal {
  background: #def5e6;
  @include border-radius(20px);
  border: 1px solid $white;
  @include box-shadow(0px 4px 16px #def5e6);
  padding: 16px;
  position: relative;
  margin-bottom: 12px;

  @include themed() {
    background: rgba(#def5e6, 0.7);
    @include box-shadow(0px 4px 16px rgba($black, 0.9));
  }

  .thisMonth {
    background: $white;
    @include border-radius(20px);
    @include box-shadow(0px 4px 12px rgba(#2e814a, 0.3));
    @include position(absolute, $top: 12px, $right: 12px);
    @include fontSize(12px);
    padding: 6px 12px;
  }

  h3 {
    color: #2e814a;
    @include fontSize(28px);
    margin-bottom: 4px;
  }

  p {
    @include fontSize(14px);
  }

  ul {
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
    width: 100%;

    li {
      @include fontSize(10px);
      text-align: center;
      list-style-type: none;
      padding: 4px 8px;
      position: relative;

      &:before {
        content: "";
        @include position(absolute, $bottom: -6px, $left: 50%);
        transform: translateX(-50%);
        height: 4px;
        width: 24px;
      }

      span {
        font-weight: 600;
        display: block;
        margin-bottom: 4px;
      }

      $box-colors: ("health", #2160aa), ("term", #576cef), ("ci", #e45c8b),
        ("pa", #f3b03d);
      $k: 0;

      @each $color in $box-colors {
        $k: $k + 1;

        &.#{nth($color,1)} {
          &:before {
            background: nth($color, 2);
          }
        }
      }
    }
  }
}

.policytypeas {
  min-height: 400px;

  svg {
    g text {
      font-family: "Montserrat";
    }

    rect {
      fill: none;
    }

    .highcharts-exporting-group,
    .highcharts-title,
    .highcharts-credits {
      display: none;
    }
  }
}

.alertSummary {
  h3 {
    @include fontSize(18px);
    margin-bottom: 8px;
  }

  p {
    @include fontSize(12px);
    color: rgba($black, 0.5);
    margin-bottom: 0px;

    @include themed() {
      color: rgba($white, 0.7);
    }
  }

  ul {
    margin-top: 24px;

    li {
      list-style-type: none;
      border-bottom: 1px solid rgba($black, 0.1);
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      margin-bottom: 16px;

      @include themed() {
        border-color: rgba($white, 0.3);
      }

      p {
        @include fontSize(14px);
        color: $black;
        width: 100%;

        span {
          font-weight: 600;
          float: right;
        }
      }

      $box-colors: ("quoteicon", #def5e6), ("claimicon", #ffeee6),
        ("endoreseicon", $blue-light);
      $k: 0;

      @each $color in $box-colors {
        $bacRightP: $k * 40;
        $k: $k + 1;

        .#{nth($color,1)} {
          background: nth($color, 2);
          @include border-radius(8px);
          position: relative;
          margin-right: 12px;
          height: 40px;
          width: 40px;

          &:before {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            background: url("../../../../../public/images/alert_summary_icons.svg")
              no-repeat;
            background-position: -#{$bacRightP}px 0px;
            background-size: 120px;
            height: 40px;
            width: 40px;
          }
        }
      }
    }
  }
}

.loginTypeToggle {
  background: #fff;
  border: 1px solid rgba($black, 0.5);
  @include border-radius(20px !important);

  button {
    @include border-radius(20px !important);
    font-family: "Montserrat";
    border: none;
    color: $grey-dark;
    text-transform: capitalize;
    font-weight: 500;
    @include fontSize(12px);
    padding: 6px 12px;

    @include themed() {
      color: $text-grey;
    }

    &.MuiToggleButtonGroup-grouped {
      @include border-radius(8px);
    }

    &.Mui-selected {
      background-color: $primary-color;
      color: #fff;
    }
  }
}

.graphLib {
  text-align: center;

  .graphBox {
    padding: 24px;
    background: rgba(107, 98, 220, 0.1);
    border-radius: 20px;

    @media (max-width: 1300px) {
      padding: 24px 0;
    }

    .total_count {
      text-align: center;
      color: #8e8e93;
      font-weight: 600;
    }
  }
}

.dateRangePicker .rs-picker-has-value .rs-btn-default {
  padding-right: 50px;
}

.supportWrapper {
  p {
    font-family: "Montserrat";

    @include themed {
      color: $text-grey;
    }
  }
}
