@import "../../mixins.scss";
@import "./rsuite.css";

// @import "rsuite/dist/rsuite.css";
.dateRangePicker {
  border: 1px solid #ddd;
  border-radius: 6px;

  @include themed {
    background: $white;
    border-radius: 6px;
  }

  p {
    display: inline-block;
    font-family: "Montserrat";

  }

  .rs-picker-toggle-active {
    border-color: transparent !important;
    @include box-shadow(0px 0px 0px rgba(#fff, 0));
  }

  .rs-picker-toggle {
    background: transparent;
    border: none;

    &:focus-visible {
      outline: none;
    }

    .rs-picker-toggle-value {
      color: #000;
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(13px);
    }

    .rs-picker-toggle-caret {
      color: #000;
      top: 6px !important;
    }
  }
}

.rs-anim-fade {
  @include box-shadow(0px 4px 20px rgba($primary-color, 0.2));
  @include border-radius(12px);
  z-index: 9;

  .rs-picker-daterange-header {
    @include fontSize(13px);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
  }

  .rs-calendar {}

  .rs-calendar-month-dropdown {}

  .rs-picker-daterange-predefined {
    border: none;
  }

  .rs-calendar-table-cell-selected {
    .rs-calendar-table-cell-content {
      background: $primary-color;
    }
  }

  .rs-picker-toolbar {
    .rs-stack-item {
      input {
        font-family: "Montserrat" !important;
      }

      .rs-btn-link {
        color: $primary-color;
        @include fontSize(13px);
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;

        &:hover {
          background: rgba($primary-color, 0.1);
          text-decoration: none;
        }
      }

      .rs-btn-primary {
        background: $primary-color;
        @include box-shadow(0px 4px 12px rgba($primary-color, 0.3));

        &:hover {
          background: rgba($primary-color, 0.9);
        }
      }
    }
  }
}