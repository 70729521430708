@import '../../../../../mixins.scss';

.employee_table {
    .employee_innerpart {
        width: 100%;

        .heading {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $blue-light;
            background: #e4f1ff;
            @include border-radius(4px 4px 0px 0px);
            margin-bottom: 8px;
            width: 100%;

            @include themed {
                background: $bg-grey !important;
                border-bottom: 1px solid $bg-grey;
            }

            li {
                list-style-type: none;
                font-family: "Montserrat";
                font-weight: 600;
                @include fontSize(12px);
                line-height: 24px;
                margin-bottom: 0;
                height: 40px;
                color: $black;
                padding: 10px 12px;

                @include themed {
                    background: $bg-grey !important;
                    color: $text-grey;
                }

                &:first-child {
                    width: 70% !important;
                }

                &:last-child {
                    width: 30% !important;
                }
            }
        }

        .subheading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            border-bottom: 1px solid $blue-light;
            width: 100%;

            @include themed {
                border-bottom: 1px solid $bg-grey;
            }

            li {
                list-style-type: none;
                min-height: 50px;
                padding: 13px 12px;
                font-family: "Montserrat";
                font-weight: 500;
                @include fontSize(12px);
                line-height: 24px;
                margin-bottom: 0px;
                color: $black;

                @include themed {
                    color: $text-light !important;
                }

                &:first-child {
                    width: 70% !important;
                }

                &:last-child {
                    width: 30% !important;
                }
            }
        }
    }

    // &.tpa_table {
    //   .employee_innerpart {
    //     max-height: 200px;

    //     .heading,
    //     .subheading {
    //       li {
    //         flex: none;

    //         &:first-child {
    //           width: 70% !important;
    //         }

    //         &:last-child {
    //           width: 30% !important;
    //         }
    //       }
    //     }
    //   }
    // }
}