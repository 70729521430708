@import "../../../src/mixins.scss";


$theme:$primary ;
$themelight: #f0efff;

.filter_button {
  cursor: pointer;
  display: flex;
  justify-content: end;
  gap: 4px;

  > a {
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 500;
    color: $theme;
    text-decoration: none;
  }

  > img {
    height: 15px;
    width: 15px;
  }
}

.modal_filter {
  .filterModal {
    .label {
      margin-bottom: 0;
      color: $theme;
      font-weight: 500;
      font-size: 11px;
      line-height: 24px;
    }

    .form_fields {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;

      .chipp {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .neww {
          background: #e8eaff;
          padding: 0 10px;
          border-radius: 4px;

          .filter_selected {
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            background: #e8eaff;
            padding: 0;
            border-radius: 4px;
            position: relative;

            > span {
              padding: 0;
            }
          }

          .cross {
            margin-left: 10px;
            cursor: pointer;
            font-size: unset;
            color: #000;
          }
          .cross.icon {
            width: 13px;
            height: 13px;
            background: #ccc;
            border-radius: 50%;
            position: absolute;
            top: -3px;
            display: flex;
            right: -18px;
            align-items: center;
            justify-content: center;
            font-size: 7px;
          }
        }
      }

      .field_row {
        width: calc(100% - 24px);
      }

      .delete_icon {
        width: 24px;

        .dlt {
          display: block;
          color: #cf0707;
          cursor: pointer;
        }
      }
    }

    .footer {
      // box-shadow: 0px 10px 28px 0px #0000001A;
      padding: 24px 0 !important;
      display: flex;
      justify-content: center;
      gap: 12px;
    }
  }
}
