@import "../../../../../mixins.scss";

// Styling for the insurance box
.insurance_box {
  @include border-radius(0 0 20px 20px);
  background: $white;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;

  // Styling for the viewBox
  .viewBox {
    height: 225px;
    width: 100%;
    @include position(absolute, $left: 0, $bottom: 0);
    @include border-radius(0 0 20px 20px);

    &.health {
      box-shadow: 0px 16px 16px 0px rgba(88, 182, 189, 0.2);

      .stop-color-red {
        stop-color: #58b6bd;
      }

      .stop-color-yellow {
        stop-color: #6ce1c5;
      }
    }

    &.termins {
      box-shadow: 0px 16px 16px 0px rgba(255, 117, 86, 0.2);

      .stop-color-red {
        stop-color: #ff7556;
      }

      .stop-color-yellow {
        stop-color: #ffbc75;
      }
    }

    &.super {
      box-shadow: 0px 16px 16px 0px rgba(107, 98, 220, 0.2);

      .stop-color-red {
        stop-color: #9b68cd;
      }

      .stop-color-yellow {
        stop-color: #b795ef;
      }
    }
    &.criticalillness {
      box-shadow: 0px 16px 16px 0px #2160aa33;

      .stop-color-red {
        stop-color: #689dcd;
      }

      .stop-color-yellow {
        stop-color: #b1d5f5;
      }
    }
    &.personalAccdt {
      box-shadow: 0px 16px 16px 0px rgba(#eb99a2, 20%);

      .stop-color-red {
        stop-color: #cd6874;
      }

      .stop-color-yellow {
        stop-color: #eb99a2;
      }
    }
  }

  // Styling for the inner box
  .inner_box {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    align-items: center;

    // Styling for the image in the inner box
    img {
      height: 40px;
      margin-right: 20px;
    }

    // Styling for the heading in the inner box
    h5 {
      font-family: "Montserrat", sans-serif;
      @include fontSize(14px);
      font-weight: 600;
      line-height: 17px;
      margin-bottom: 0px;

      @include themed {
        color: $black;
      }

      // Styling for the span in the heading
      span {
        display: block;
        font-weight: 400;
      }
    }

    // Styling for the green variant of the inner box
    &.green {
      position: relative;

      // Styling for the after pseudo-element in the green variant
      &:after {
        content: "";
        background: #58b6bd;
        opacity: 10%;
        height: 75px;
        width: 135px;
        @include position(absolute, $left: 0, $top: 65px);
        @include border-radius(0 100px 0 0);
      }
    }

    // Styling for the orange variant of the inner box
    &.orange {
      position: relative;

      // Styling for the after pseudo-element in the orange variant
      &:after {
        content: "";
        background: #ff7556;
        opacity: 10%;
        height: 75px;
        width: 135px;
        @include position(absolute, $left: 0, $top: 65px);
        @include border-radius(0 100px 0 0);
      }
    }

    // Styling for the purple variant of the inner box
    &.purple {
      position: relative;

      // Styling for the after pseudo-element in the purple variant
      &:after {
        content: "";
        background: #9b68cd;
        opacity: 10%;
        height: 75px;
        width: 135px;
        @include position(absolute, $left: 0, $top: 65px);
        @include border-radius(0 100px 0 0);
      }
    }
  }

  // Styling for the policy box
  .policy_box {
    padding: 30px 35px 17px 35px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .com_box {
      width: 50%;
    }

    // Styling for the image in the policy box
    img {
      height: 57px;
      margin-right: 12px;
    }

    // Styling for the heading in the policy box
    h5 {
      font-family: "Montserrat", sans-serif;
      @include fontSize(12px);
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 0;
      color: $white;
      min-height: 60px;

      // Styling for the span in the heading
      span {
        display: block;
        font-weight: 600;
      }
    }
  }

  // Styling for the coverage section
  .coverage_section {
    padding: 0 35px;
    display: flex;
    justify-content: center;

    // Styling for the paragraph in the coverage section
    .coverage_p {
      font-family: "Montserrat", sans-serif;
      @include fontSize(12px);
      font-weight: 500;
      line-height: 14px;
      margin-bottom: 0;
      line-height: normal;

      @include themed {
        color: $black !important;
      }

      // Styling for the span in the paragraph
      span {
        font-weight: 600;
        @include themed {
          color: $black;
        }
      }
    }
  }

  // Styling for the module overlay
  .module_overlay {
    display: none;
  }

  // Styling for the hover state of the insurance box
  &:hover {
    .module_overlay {
      padding: 20px;
      background: $white;
      @include border-radius(0 0 20px 20px);
      position: absolute;
      top: 0;
      z-index: 9;
      transition: 0.4s;
      height: 100%;
      width: 100%;
      display: block;

      // Styling for the unordered list in the module overlay
      ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 0;
        align-items: center;
        row-gap: 20px;

        // Styling for the list items in the unordered list
        li {
          list-style-type: none;
          width: 50%;
          text-align: center;
          padding: 25px 0 0;
          position: relative;

          // Styling for the last list item
          &:last-child {
            margin-bottom: 0;
          }

          // Styling for the anchor tag in the list item
          a {
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            @include fontSize(12px);
            line-height: 20px;
            color: $primary;
            text-align: center;
            padding: 25px 0 0px;
          }
        }

        // Product icons
        $producticons: module_list1, module_list2, module_list3, module_list4,
          module_list5;
        $k: 0;

        // Styling for each product icon
        @each $icons in $producticons {
          $bacRightP: $k * 20;
          $k: $k + 1;

          .#{$icons} {
            &:before {
              content: "";
              @include position(absolute, $left: calc(50% - 10px), $top: 0);
              width: 20px;
              height: 20px;
              background: url("../../../../../../public/images/employeeV2_img.svg")
                no-repeat -#{$bacRightP}px
                0px/100px;
            }
          }
        }
      }
    }
  }
}
