@import "../../../../../mixins.scss";
 
.grouppolicy_wrapper {
    padding-top: 70px;
 
    @include themed {
        background: $bg-dark !important;
    }
 
    .pageHeader {
        background: $white;
        @include box-shadow(0px 2px 12px rgba($black, 0.1));
        @include position(fixed, $top: 0px, $left: 0px);
        margin: 0px;
        height: 70px;
        width: 100%;
        z-index: 991;
 
        @include themed {
            background: $bg-dark;
            box-shadow: 0 8px 6px -6px black;
        }
 
        .pageTitle {
            display: flex;
            align-items: center;
 
            .backStep {
                margin-right: 8px;
                color: $primary;
                display: flex;
                align-items: center;
                justify-content: center;
 
                &:hover {
                    color: $primary-red;
 
                    svg {
                        left: -4px;
                    }
                }
 
                svg {
                    position: relative;
                    transition: all 0.5s ease;
                    left: 0px;
                    height: 26px;
                    width: 30px;
                }
            }
 
            h3 {
                margin-bottom: 0px;
                @include fontSize(16px);
                line-height: 20px;
                text-transform: capitalize;
 
                @include themed {
                    color: $text-grey;
                }
            }
        }
    }
 
    .main_wrapper {
        padding: 24px 12px;
 
        .left_section {
            h5 {
                font-family: "Montserrat";
                font-weight: 400;
                @include fontSize(14px);
                line-height: 17px;
                margin-bottom: 24px;
 
                span {
                    font-weight: 600;
                    margin-right: 30px;
                }
            }
 
            .coverage_section {
                background: linear-gradient(180deg, rgba(88, 182, 189, 0.30) 0%, rgba(108, 225, 197, 0.30) 100%);
                @include border-radius(12px);
                padding: 12px 24px;
                margin-bottom: 24px;
 
                ul {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 0px;
                    gap: 8px;
 
                    li {
                        list-style-type: none;
                        font-family: "Montserrat";
                        font-weight: 400;
                        @include fontSize(12px);
                        line-height: 14px;
                        color: #30364D;
 
                        @include themed {
                            color: $white;
                        }
 
                        span {
                            font-weight: 600;
                            padding-top: 5px;
                            display: block;
                            color: $black;
                        }
                    }
                }
            }
 
            .policycover_section {
                margin-bottom: 24px;
 
                .scrollable_item {
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: calc(100vh - 360px);
                    &::-webkit-scrollbar {
                        width: 0px;
                    }
                }
 
                h5 {
                    font-family: "Montserrat";
                    font-weight: 600;
                    @include fontSize(14px);
                    line-height: 17px;
                    margin-bottom: 14px;
                    color: $primary;
                    width: calc(100% /4);
 
                    &:last-child {
                        text-align: right;
                    }
                }
 
                .policycover {
                    margin-bottom: 0px;
                    display: flex;
                    flex-wrap: wrap;
 
                    li {
                        list-style-type: none;
                        font-family: "Montserrat";
                        font-weight: 400;
                        @include fontSize(12px);
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;
                        width: calc(100% / 5);
                        padding-right: 12px;
 
                        &:last-child {
                            padding-right: 0;
 
                            div {
                                margin-left: auto;
                            }
                        }
 
                        @include themed {
                            color: $white;
                        }
 
                        span {
                            font-weight: 600;
                        }
 
                        img {
                            margin-right: 10px;
                            height: 30px;
                        }
 
                    }
                }
 
                .rider_detail {
                    li.rider_name {
                        color: #30364D;
                        font-weight: 600;
                        @include fontSize(14px);
                    }
 
                    li.amnt {
                        h6 {
                            color: #30364D;
                            font-weight: 400;
                            @include fontSize(12px);
 
                            p {
                                font-weight: 600;
                            }
                        }
                    }
                    li.desc {
                       max-width: 50%; 
                        h6 {
                            color: #30364D;
                            font-weight: 600;
                            @include fontSize(12px);
 
                            p {
                                font-weight: 400;
                            }
                        }
                    }
                }
 
                .category_name {
                    margin-bottom: 0px;
 
                    li {
                        list-style-type: none;
                        margin-bottom: 12px;
 
                        h5 {
                            font-family: "Montserrat";
                            font-weight: 600;
                            @include fontSize(14px);
                            line-height: 20px;
                            margin-bottom: 5px;
                            color: $black;
 
                            @include themed {
                                color: $white;
                            }
                        }
 
                        h6 {
                            font-family: "Montserrat";
                            font-weight: 400;
                            @include fontSize(12px);
                            line-height: 18px;
                            margin-bottom: 0px;
                            display: flex;
                            justify-content: space-between;
 
                            @include themed {
                                color: $white;
                            }
 
                            span {
                                font-weight: 600;
                            }
                        }
 
                        p {
                            font-family: "Montserrat";
                            font-weight: 400;
                            @include fontSize(12px);
                            line-height: 18px;
                            margin-bottom: 0px;
 
                            @include themed {
                                color: $white;
                            }
 
                            span {
                                font-weight: 600;
                            }
                        }
                    }
                }
 
            }
 
            .exclusion {
                overflow-y: auto;
                overflow-x: hidden;
                max-height: calc(100vh - 360px);
 
                &::-webkit-scrollbar {
                    width: 0px;
                }
 
                @include border-radius(0px 0px 12px 12px);
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #EDF1FE 100%);
                box-shadow: 0px 10px 12px 0px rgba(107, 98, 220, 0.08);
                border-right: 1px dashed rgba(107, 98, 220, 0.50);
                border-bottom: 1px dashed rgba(107, 98, 220, 0.50);
                border-left: 1px dashed rgba(107, 98, 220, 0.50);
                padding: 12px 16px;
 
                h5 {
                    font-family: "Montserrat";
                    font-weight: 600;
                    @include fontSize(14px);
                    line-height: 17px;
                    margin-bottom: 8px;
                }
 
                .exclusion_content {
                    margin-bottom: 0;
 
                    li {
                        list-style-type: none;
                        font-family: "Montserrat";
                        font-weight: 400;
                        @include fontSize(12px);
                        line-height: 18px;
                        margin-bottom: 8px;
                        padding-left: 22px;
                    }
 
                    h6 {
                        font-family: "Montserrat";
                        font-weight: 400;
                        @include fontSize(12px);
                        line-height: 18px;
                        margin-bottom: 8px;
                        padding-left: 22px;
                        position: relative;
 
                        &:before {
                            content: "";
                            background: url("../../../../../../public/images/group_policy_tick_icon.svg");
                            height: 12px;
                            width: 12px;
                            @include position(absolute, $left: 0px, $top: 0);
                        }
                    }
                }
            }
        }
 
        .right_section {
            h4 {
                font-family: "Montserrat";
                font-weight: 600;
                @include fontSize(16px);
                line-height: 20px;
                margin-bottom: 24px;
                text-align: center;
            }
 
            .table {
                overflow-x: auto;
 
                .hospital_table {
                    width: 100%;
                    min-width: 780px;
 
                    .heading {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid $blue-light;
                        background: #e4f1ff;
                        @include border-radius(4px 4px 0px 0px);
                        margin-bottom: 8px;
                        width: 100%;
 
                        @include themed {
                            background: $bg-grey !important;
                            border-bottom: 1px solid $bg-grey;
                        }
 
                        li {
                            list-style-type: none;
                            font-family: 'Montserrat';
                            font-weight: 600;
                            @include fontSize(12px);
                            line-height: 24px;
                            margin-bottom: 0;
                            width: 200px;
                            height: 40px;
                            color: $black;
                            padding: 10px 12px;
                            flex: 1;
 
                            &:first-child {
                                min-width: 600px;
                                width: 60%;
                            }
 
                            @include themed {
                                background: $bg-grey !important;
                                color: $text-grey;
                            }
 
                            &:child(2) {
                                min-width: 500px;
                                width: 40%;
                            }
 
                            @include themed {
                                background: $bg-grey !important;
                                color: $text-grey;
                            }
                        }
                    }
 
                    .subheading {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 8px;
                        border-bottom: 1px solid $blue-light;
                        width: 100%;
 
                        @include themed {
                            border-bottom: 1px solid $bg-grey;
                        }
 
                        &:last-child {
                            border-bottom: 0;
                        }
 
                        li {
                            list-style-type: none;
                            min-height: 50px;
                            padding: 13px 12px;
                            font-family: 'Montserrat';
                            font-weight: 500;
                            width: 200px;
                            @include fontSize(12px);
                            line-height: 24px;
                            margin-bottom: 0px;
                            color: $black;
                            flex: 1;
 
                            span {
                                display: block;
                                font-weight: 400;
                                padding-top: 8px;
                                color: #666666;
                            }
 
                            @include themed {
                                color: $text-light !important;
                            }
 
                            &:first-child {
                                min-width: 600px;
                                width: 60%;
 
                            }
 
                            &:child(2) {
                                min-width: 500px;
                                width: 40%;
                            }
 
                        }
 
                    }
                }
            }
 
        }
    }
}
 
.modalWrapper {
    .modalBig {
        .cover_heading {
            font-weight: 600;
            margin-bottom: 14px;
            color: $primary;
        }
 
        .category_name {
            margin-bottom: 0px;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: calc(100vh - 300px);
 
            &::-webkit-scrollbar {
                width: 0px;
            }
 
            li {
                list-style-type: none;
                margin-bottom: 12px;
 
                .feature_inerlist {
                    background: #f3f2ff91;
                    border-radius: 10px;
                    padding: 10px 10px 10px 72px;
                    min-height: 64px;
                    margin-bottom: 12px;
                    position: relative;
 
                    .medical {
                        position: absolute;
                        content: "";
                        height: 50px;
                        width: 50px;
                        top: 12px;
                        left: 12px;
                        background: url("../../../../../../public/images/policy_features_icon.svg") no-repeat -0px 0px / 400px;
                    }
 
                    h5 {
                        font-family: "Montserrat";
                        font-weight: 600;
                        @include fontSize(14px);
                        line-height: 20px;
                        margin-bottom: 5px;
                        color: $black;
 
                        @include themed {
                            color: $white;
                        }
                    }
 
                    h6 {
                        font-family: "Montserrat";
                        font-weight: 400;
                        @include fontSize(12px);
                        line-height: 18px;
                        margin-bottom: 0px;
                        display: flex;
                        justify-content: space-between;
 
                        @include themed {
                            color: $white;
                        }
 
                        span {
                            font-weight: 600;
                        }
                    }
 
                    p {
                        font-family: "Montserrat";
                        font-weight: 400;
                        @include fontSize(12px);
                        line-height: 18px;
                        margin-bottom: 0px;
 
                        @include themed {
                            color: $white;
                        }
 
                        span {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
 
        .exclusion {
            @include border-radius(0px 0px 12px 12px);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #EDF1FE 100%);
            box-shadow: 0px 10px 12px 0px rgba(107, 98, 220, 0.08);
            border-right: 1px dashed rgba(107, 98, 220, 0.50);
            border-bottom: 1px dashed rgba(107, 98, 220, 0.50);
            border-left: 1px dashed rgba(107, 98, 220, 0.50);
            padding: 12px 16px;
 
            overflow-x: hidden;
            overflow-y: auto;
            max-height: calc(100vh - 300px);
 
            &::-webkit-scrollbar {
                width: 0px;
            }
 
            h5 {
                font-family: "Montserrat";
                font-weight: 600;
                @include fontSize(14px);
                line-height: 17px;
                margin-bottom: 8px;
            }
 
            .exclusion_content {
                margin-bottom: 0;
 
                .exclusion_list {
                    list-style-type: none;
                    background: #fff3f3;
                    @include border-radius(10px);
                    padding: 10px;
                    min-height: 64px;
                    margin-bottom: 12px;
                    position: relative;
                    display: flex;
                    align-items: center;
 
                    &:last-child {
                        margin-bottom: 0;
                    }
 
                    span {
                        display: inline-block;
                        $button-icons: aids, pregnancy, ectopic, std, aids, pregnancy, ectopic1,                            std;
                        $k: 0;
                        @each $icons in $button-icons {
                            $bacRightP: $k * 50;
                            $k: $k + 1;
 
                            &.#{$icons} {
                                position: absolute;
                                content: "";
                                height: 50px;
                                width: 50px;
                                top: calc(50% - 25px);
                                left: 12px;
                                background: url("../../../../../../public/images/policy_exclusion_icon.svg") no-repeat -#{$bacRightP}px 0px/400px;
                            }
                        }
                    }
                    .exclusion_inerlist {
                        padding-left: 64px;
                        .exclusion_content {
                            text-align: left;
                            h5 {
                                font-family: "Montserrat";
                                @include fontSize(14px);
                                line-height: 24px;
                                font-weight: 600;
                                margin-bottom: 4px;
                            }
                            p {
                                font-family: "Montserrat";
                                @include fontSize(12px);
                                line-height: 16px;
                                font-weight: 400;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modalWrapper {
    .modalBig {
        .cover_heading {
            font-weight: 600;
            margin-bottom: 14px;
            color: $primary;
        }

        .category_name {
            margin-bottom: 0px;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: calc(100vh - 300px);

            &::-webkit-scrollbar {
                width: 0px;
            }

            li {
                list-style-type: none;
                margin-bottom: 12px;

                .feature_inerlist {
                    background: #f3f2ff91;
                    border-radius: 10px;
                    padding: 10px 10px 10px 72px;
                    min-height: 64px;
                    margin-bottom: 12px;
                    position: relative;

                    .medical {
                        position: absolute;
                        content: "";
                        height: 50px;
                        width: 50px;
                        top: calc(50% - 25px);
                        left: 12px;
                        background: url("../../../../../../public/images/policy_features_icon.svg") no-repeat -0px 0px / 400px;
                    }

                    h5 {
                        font-family: "Montserrat";
                        font-weight: 600;
                        @include fontSize(14px);
                        line-height: 20px;
                        margin-bottom: 5px;
                        color: $black;

                        @include themed {
                            color: $white;
                        }
                    }

                    h6 {
                        font-family: "Montserrat";
                        font-weight: 400;
                        @include fontSize(12px);
                        line-height: 18px;
                        margin-bottom: 0px;
                        display: flex;
                        justify-content: space-between;

                        @include themed {
                            color: $white;
                        }

                        span {
                            font-weight: 600;
                        }
                    }

                    p {
                        font-family: "Montserrat";
                        font-weight: 400;
                        @include fontSize(12px);
                        line-height: 18px;
                        margin-bottom: 0px;

                        @include themed {
                            color: $white;
                        }

                        span {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .exclusion {
            @include border-radius(0px 0px 12px 12px);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #EDF1FE 100%);
            box-shadow: 0px 10px 12px 0px rgba(107, 98, 220, 0.08);
            border-right: 1px dashed rgba(107, 98, 220, 0.50);
            border-bottom: 1px dashed rgba(107, 98, 220, 0.50);
            border-left: 1px dashed rgba(107, 98, 220, 0.50);
            padding: 12px 16px;

            overflow-x: hidden;
            overflow-y: auto;
            max-height: calc(100vh - 300px);

            &::-webkit-scrollbar {
                width: 0px;
            }

            h5 {
                font-family: "Montserrat";
                font-weight: 600;
                @include fontSize(14px);
                line-height: 17px;
                margin-bottom: 8px;
            }

            .exclusion_content {
                margin-bottom: 0;

                li {
                    list-style-type: none;
                    font-family: "Montserrat";
                    font-weight: 400;
                    @include fontSize(12px);
                    line-height: 18px;
                    margin-bottom: 8px;
                    padding-left: 22px;
                }

                h6 {
                    font-family: "Montserrat";
                    font-weight: 400;
                    @include fontSize(12px);
                    line-height: 18px;
                    margin-bottom: 8px;
                    padding-left: 22px;
                    position: relative;

                    &:before {
                        content: "";
                        background: url("../../../../../../public/images/group_policy_tick_icon.svg");
                        height: 12px;
                        width: 12px;
                        @include position(absolute, $left: 0px, $top: 0);
                    }
                }
            }
        }
    }
}