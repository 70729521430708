@import "../../../../mixins.scss";

.svg-pi-wrapper {
  position: relative;
  top: 16px;

  .dashCircle {
    position: absolute;
    top: 20px;
    left: 20px;
    background: url("../../../../../public/images/dash_circle.svg") no-repeat;
    background-size: 100px;
    background-position: 0px 0px;
    height: 140px;
    width: 140px;
  }

  .meterbox {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -56px;
    left: 18px;
    transform: rotate(-90deg);
    transform-origin: bottom;

    &:before {
      background-color: #555;
      border-radius: 20px;
      content: "";
      position: absolute;
      bottom: -9px;
      left: -1px;
      height: 10px;
      width: 10px;
      z-index: 2;
    }

    &:after {
      background-color: #fff;
      box-shadow: -8px 0px 16px rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      content: "";
      position: absolute;
      bottom: -14px;
      left: -6px;
      height: 20px;
      width: 20px;
    }

    .niddle {
      position: absolute;
      bottom: -4px;
      left: -68px;
      transform-origin: bottom;
      z-index: -1;

      img {
        height: 50px;
      }
    }
  }
}

.svg-pi-indicator {
  stroke: url(#gradient);
}

.svg-pi {
  transform: rotate(-199deg);
  /* Fix the orientation */
}

/* Animated spinner version */
.svg-pi-indicator--spinner {
  animation: spinner 0.75s linear infinite;
  transform-origin: center;
}

.svg-pi-label__progress {
  font-size: 1.5em;
  font-weight: bold;
}

.svg-pi-label__loading,
.svg-pi-label__progress {
  display: block;
}

/* Spinner animation */
@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}