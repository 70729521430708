@import "../../../../../mixins.scss";

.vehicleDetailBox {
    background: $blue-light;
    @include border-radius(16px);
    display: inline-flex;
    align-items: center;
    padding: 24px 48px 12px 12px;
    position: relative;
    margin: 12px 24px 24px 0px;
    width: 100%;

    &.active {
        outline: 2px solid $primary;
        offset: -2px;
    }

    .regno {
        background: $white url("../../../../../../public/images/ind.svg") no-repeat 0px 0px/24px;
        @include border-radius(4px);
        border: 1px dashed $black;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        @include fontSize(16px);
        font-weight: 600;
        padding: 4px 12px 4px 32px;
        @include position(absolute, $left: 16px, $top: -18px);
        height: 36px;
    }

    .noofDays {
        background: #FFE8C4;
        @include border-radius(40px);
        @include position(absolute, $right: 36px, $top: -12px);
        color: $primary-orange;
        display: inline-flex;
        align-items: center;
        @include fontSize(12px);
        padding: 4px 12px 4px 8px;

        svg {
            height: 14px;
        }
    }

    .myVehicle {
        display: flex;
        align-items: center;
        cursor: pointer;

        $button-icons: carIcon, bikeIcon;
        $k: 0;

        @each $icons in $button-icons {
            $bacRightP: $k * 86;
            $k: $k +1;

            .#{$icons} {
                background: url("../../../../../../public/images/vehicle-icons.svg") no-repeat -#{$bacRightP}px 0px /168px;
                margin-right: 12px;
                height: 60px;
                width: 80px;
            }
        }

        .vehicleInfo {
            h5 {
                font-weight: 600;
                margin-bottom: 6px;
            }

            p {
                color: rgba($text-dark, 0.5);
                @include fontSize(12px);
            }
        }
    }

    .renew {
        @include position(absolute, $right: -31px, $top: 35px);
        background: $white;
        @include border-radius(14px 14px 0px 0px);
        @include box-shadow(0px 4px 8px rgba($primary, 0.2));
        color: $primary;
        display: inline-flex;
        @include fontSize(12px);
        padding: 6px 12px;
        transform: rotate(90deg);
        width: 93px;
        z-index: 9;

        svg {
            color: $text-dark;
            height: 14px;
        }
    }
}