@import "./../../../../mixins.scss";

.assignbulkpolicy_table{
    th{
        background: $primary-light;
    }
    .parent_rows{
        td{
            padding: 12px;
            &:first-child{
                padding: 12px 0px 12px 0px;
                width: 34px;
            }
        }
    }
}

.sliding-panel-container .panel-content .scrollableArea {
    padding-top: 5px;
    height: calc(100vh - 90px);
    overflow-y: auto;
    overflow-x: hidden;
}