@import "../../../../mixins.scss";

.mydocument_wrapper {
    .main_heading {
        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(28px);
            font-weight: 600;
            line-height: 34px;
            margin-bottom: 5px;

            span {
                font-weight: 300;
            }
        }

        p {
            font-family: "Roboto", sans-serif;
            @include fontSize(14px);
            font-weight: 300;
            line-height: 16px;
            margin-bottom: 0px;
            opacity: 0.5;
        }
    }

    .kyc_doc {
        @include border-radius(0 0 12px 12px);
        border-right: 1px dashed rgba(107, 98, 220, 0.50);
        border-bottom: 1px dashed rgba(107, 98, 220, 0.50);
        border-left: 1px dashed rgba(107, 98, 220, 0.50);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #EDF1FE 100%);
        box-shadow: 0px 10px 12px 0px rgba(107, 98, 220, 0.08);
        margin-right: 24px;
        padding: 12px 16px;
        min-height: 90px;
        display: flex;
        align-items: center;

        img {
            margin-right: 12px;
        }

        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(12px);
            font-weight: 400;
            line-height: 14px;
            margin-bottom: 0;

            .MuiTypography-root {
                color: $primary;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    .upload_files {
        @include border-radius(0 0 12px 12px);
        border-right: 1px dashed rgba(107, 98, 220, 0.50);
        border-bottom: 1px dashed rgba(107, 98, 220, 0.50);
        border-left: 1px dashed rgba(107, 98, 220, 0.50);
        background: $white;
        box-shadow: 0px 10px 12px 0px rgba(107, 98, 220, 0.08);
        padding: 12px 16px;
        min-height: 90px;
        display: flex;
        align-items: center;

        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(12px);
            font-weight: 400;
            line-height: 14px;
            text-align: center;
            margin-bottom: 16px;
        }

        ul {
            display: flex;
            margin-bottom: 0;

            li {
                list-style-type: none;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .policy_doc {
        @include border-radius(20px);
        border: 1px dashed #ACD4FB;
        padding: 24px 16px;
        text-align: center;

        img {
            height: 200px;
            margin-bottom: 12px;
        }

        h6 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(14px);
            font-weight: 400;
            line-height: 17px;
            margin-bottom: 0px;

            .MuiTypography-root {
                color: $primary;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    .upload_doc {
        h6 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(18px);
            font-weight: 600;
            line-height: 22px;
            margin-bottom: 24px;

            span {
                font-weight: 300;
            }
        }
    }

    .uploaddoc_innersection {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 24px;
        padding-bottom: 12px;

        .img_upload_section {
            display: flex;
            align-items: center;
            width: 100%;

            img {
                margin-right: 12px;
            }

            h6 {
                font-family: "Montserrat", sans-serif;
                @include fontSize(14px);
                font-weight: 400;
                line-height: 17px;
                margin-bottom: 0;
            }

            .MuiLinearProgress-root {
                margin-left: 10px;
                width: 100%;
                @include border-radius(10px);
                background: #EEF2FE;

                .MuiLinearProgress-bar {
                    background: $primary;
                }
            }
        }

        .action-btn {
            display: flex;
            align-items: center;
            justify-content: end;
            width: 200px;

            h6 {
                font-family: "Montserrat", sans-serif;
                @include fontSize(12px);
                font-weight: 500;
                line-height: 14px;
                margin-bottom: 0;
                opacity: 0.5;
                color: $black;
                margin-right: 12px;
            }

            .upload_failed {
                color: #EC3F3F;
                opacity: 1;
            }

            .MuiTypography-root {
                top: -7px;
            }
        }
    }
}