@import "../../../../mixins.scss";

h2 {
  font-size: 36px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}

.signout_option {
  .MuiMenu-list {
    .MuiMenuItem-root {
      justify-content: end;
      @include fontSize(12px);
    }
  }
}

.dashboardWrapper {
  padding: 8px 12px;
  min-height: 100vh;
  padding-bottom: 0;

  @include themed() {
      background-color: $bg-dark;
    }

  .CustomModuleLink {
    color: $grey-dark;
    //border: 1px solid $text-light;
    @include border-radius(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    &:hover {
      cursor: pointer;
    }
    svg {
      height: 20px;
    }
    @include themed{
      background-color: #000;
      color: #fff;
    }
  }

  .quickLaunch {
    background: $primary;
    @include border-radius(8px);
    @include position(fixed, $right:16px, $bottom:16px);
    @include box-shadow(0px 4px 12px rgba($primary, 0.4));
    min-width: 20px;
    height: 38px;
    width: 38px;
    transition: all 0.3s ease;
    z-index: 991;
    &:hover {
      background: $primary;
      @include border-radius(20px);
    }
    svg {
      color: $white;
    }
  }
    .todolist {
      margin-bottom: 40px;
  
      &:active {
        text-decoration: none !important;
      }
  
      h4 {
        margin-bottom: 17px;
      }
  
      $product-insurance: addpolicy, familyprofile, riskscore, addcity;
      $k: 0;
  
      @each $icons in $product-insurance {
        $bacRightP: $k * 32;
        $k: $k +1;
  
        .#{$icons} {
          position: relative;
          font-size: 14px;
          font-family: "Montserrat", sans-serif;
          color: #000;
          display: flex;
          align-items: center;
          padding: 0px 4px 0px 44px;
          margin-bottom: 12px;
          min-height: 36px;
  
          @include themed() {
            color: $text-grey;
          }
  
          &:focus,
          &:visited,
          &:link,
          &:active {
            text-decoration: none;
          }
  
          &:hover {
            color: $primary;
            text-decoration: none;
  
            &:before {
              background-color: #c5d8ed;
            }
          }
  
          &:before {
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            background-color: #eeeeee;
            border-radius: 50px;
            content: "";
            height: 32px;
            width: 32px;
            background-image: url("../../../../../public/images/todo_icons.svg");
            background-position: -#{$bacRightP}px 0px;
            background-size: 128px;
          }
        }
      }
    }
}

.showMenuBtns .MuiMenu-paper {
  border-radius: 8px !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2) !important;
  margin-top: -44px;
  li {
    @include fontSize(14px);
  }
}
