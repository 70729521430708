@import '../../../../../mixins.scss';

.headerSection {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    width: 100%;
    margin: 0 !important;
    position: fixed;
    z-index: 99;
    background: $white;

    @include themed {
        background: $black;
        border-bottom: 1px solid #f6f6f6;
    }

    .pageTitle {
        display: flex;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            margin-right: 12px;
            color: $primary;

            svg {
                position: relative;
                transition: all 0.5s ease;
                left: 0px;
            }

            &:hover {
                cursor: pointer;
                color: $primary-red;

                svg {
                    left: -4px;
                }
            }
        }

        .MuiSelect-select {
            min-width: 220px;
        }
    }
}

.ScrollSection {
    padding: 100px 20px 120px 20px;
    z-index: 10;

    @include themed {
        background: $black;
    }

    .detailBox {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 25px;
        margin-bottom: 24px;

        @include themed {
            border: 1px solid $bg-grey;
        }

        h5 {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 12px;
            border-bottom: 1px solid $primary;
            width: 200px;

            @include themed {
                color: $text-light;
            }
        }

        .add_logo {
            background: #EFEFEF;
            border-radius: 100px;
            height: 96px;
            width: 96px;
            margin-left: 0 !important;

            &.greenBtn {
                &.addfile {
                    &:before {
                        position: absolute;
                        left: 35px;
                        top: 27px;
                    }

                    &:after {
                        position: absolute;
                        left: 35px;
                        top: 27px;
                    }
                }
            }

            span {
                position: relative;
                left: -9px;
                top: 49px;
            }
        }

        .switch_btn {
            width: 60px;
            height: 38px;

            .MuiSwitch-root {
                .MuiSwitch-switchBase {
                    padding: 0 13px 0 0;

                    &:hover {
                        background-color: transparent !important;
                    }

                    .MuiSwitch-thumb {
                        box-shadow: none !important;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: #FFFFFF !important;
                    }
                }

                .MuiSwitch-track {
                    background: $primary;
                    border-radius: 100px;
                    height: 24px;
                    min-width: 44px;
                    position: relative;
                    bottom: 5px;
                    opacity: 1;
                }
            }
        }

        &.employee_portal {
            padding: 10px 25px;
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;

            @include themed {
                color: $text-light;
            }

        }
    }
}

.footer_section {
    background: #FFFFFF;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.15);
    height: 92px;
    position: fixed;
    bottom: 0;
    padding: 20px !important;
    display: flex;
    z-index: 99;
    justify-content: end;
    @include themed{
        border: 1px solid $bg-grey;
    }

    .cancel-btn {
        font-size: 16px;
        line-height: 20px;
        border: 1px solid $primary;
        border-radius: 10px;
        color: $primary;
        margin-right: 24px;
        cursor: pointer;
        font-weight: 500;
        font-family: 'Montserrat';
        text-decoration: none !important;
        padding: 16px 59px;

    }

    .save_btn {
        background: $primary;
        border-radius: 10px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        height: 52px;
        color: #fff;
        margin-right: 0px !important;
        font-family: 'Montserrat';
        cursor: pointer;
        padding: 16px 21px !important;
        text-decoration: none;
        &:hover{
            background-color: $primary;
          }
    }
}