@import "../../../../mixins.scss";

.claimAssistant_wrapper {
  .main_heading {
    h5 {
      font-family: "Montserrat", sans-serif;
      @include fontSize(28px);
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 5px;

      span {
        font-weight: 300;
      }
    }

    p {
      font-family: "Roboto", sans-serif;
      @include fontSize(14px);
      font-weight: 300;
      line-height: 16px;
      margin-bottom: 0px;
      opacity: 0.5;

      @include themed {
        color: #ddd;
      }
    }
  }

  .raise_request {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    img {
      height: 120px;
      margin-right: 12px;
    }

    h5 {
      font-family: "Montserrat", sans-serif;
      @include fontSize(16px);
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 0;
      width: 69px;
      margin-right: 24px;
    }

    Link {
      font-family: "Montserrat", sans-serif;
      @include fontSize(14px);
      font-weight: 400;
      line-height: 17px;
    }

    .red_border {
      border: 1px solid #ff5b5b;
      color: #ff5b5b;
      padding: 10px 16px;
      @include border-radius(8px);
    }

    .purple_border {
      border: 1px solid $primary;
      color: $primary;
      padding: 10px 16px;
      @include border-radius(8px);
    }

    span {
      font-family: "Montserrat", sans-serif;
      @include fontSize(16px);
      font-weight: 400;
      line-height: 20px;
      margin: 0 12px;

      @include themed {
        color: #ddd;
      }
    }
  }

  .pending_request_section {
    h6 {
      font-family: "Montserrat", sans-serif;
      @include fontSize(16px);
      font-weight: 400;
      line-height: 16px;
      margin: 10px 0 0 0px;
    }
  }

  .claim_section {
    @include border-radius(12px);
    background: $white;
    box-shadow: 0px 4px 12px 0px rgba(231, 56, 76, 0.1);
    padding: 0px 24px 0 12px;

    // min-height: 157px;
    @include themed {
      background: #ddd;
    }

    &.red_border {
      position: relative;

      &:after {
        content: "";
        background: #e7384c;
        width: 4px;
        height: 100%;
        @include position(absolute, $left: 0, $top: 0);
        @include border-radius(100px 0px 0px 100px);
      }
    }

    &.blue_border {
      position: relative;

      &:after {
        content: "";
        background: #00519b;
        width: 4px;
        height: 100%;
        @include position(absolute, $left: 0, $top: 0);
        @include border-radius(100px 0px 0px 100px);
      }
    }

    .claim_innerSection {
      margin-bottom: 10px;
      padding-left: 12px;

      .cashlessClaim {
        display: flex;
        align-items: center;

        .img_section {
          position: relative;

          $product-colors: ("healthInsurance", #e7384c),
            ("carInsurance", #66ccdb), ("bikeInsurance", #066ac8),
            ("termInsurance", #2160aa), ("investmentPlan", #ffa412),
            ("travelInsurance", #66b27b);
          $k: 0;

          @each $color in $product-colors {
            $bacRightP: $k * 58;
            $k: $k + 1;

            &.#{nth($color,1)} {
              background: linear-gradient(
                90deg,
                rgba(129, 130, 230, 0.2) 0%,
                rgba(107, 98, 220, 0.2) 100%
              );

              &:before {
                @include position(absolute, $left: calc(50% - 25px), $top: 4px);
                content: "";
                height: 50px;
                width: 50px;
                background: url("../../../../../public/images/insurance_product_icons.svg")
                  no-repeat -#{$bacRightP}px
                  0px /340px;
                z-index: 2;
              }
            }
          }

          &.redbg {
            background: #ffe8ea;
          }

          &.bluebg {
            background: #dbeeff;
          }

          &.accepted {
            background: #e2ffcb;
          }

          &.pending {
            background: #fcecdf;
          }

          @include border-radius(12px);

          padding: 10px;
          width: 60px;
          height: 60px;
          margin-right: 25px;

          img {
            height: 40px;
          }
        }

        .request_heading {
          display: flex;
          align-items: flex-start;

          h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(16px);
            font-weight: 500;
            line-height: 20px;
            margin: 0 12px 0 0px;

            @include themed {
              color: $black;
            }
          }

          span {
            @include border-radius(20px);
            padding: 6px 16px;
            font-family: "Montserrat", sans-serif;
            @include fontSize(12px);
            font-weight: 500;
            line-height: 14px;

            &.inprogress {
              background: #e4e2ff;
              color: $primary;
            }

            &.accepted {
              background: #e2ffcb;
              color: #529f15;
            }

            &.rejected {
              color: $primary-red;
              background: #ffe3e3;
            }

            &.pending {
              color: #ff9b44;
              background: #fcecdf;
            }
          }

          .view_btn {
            text-transform: capitalize;
            color: $primary;
          }
        }
      }

      .hospitalised_person {
        display: flex;
        align-items: center;
        justify-content: end;

        h6 {
          font-family: "Montserrat", sans-serif;
          @include fontSize(12px);
          font-weight: 400;
          line-height: 14px;
          margin: 0px 10px 0px 0px;
        }
        .user_img {
          border-radius: 80px;
          list-style-type: none;
          cursor: pointer;
          position: relative;
          height: 30px;
          width: 30px;

          $button-icons: father, mother, husband, wife, brother, sister, son, daughter;
          $k: -1;

          @each $icon in $button-icons {
              $k: $k + 1;
              $bacRightP: $k * 30;

              &.#{$icon} {
                  background: $white url("../../../../../public/images/family-members.svg") no-repeat -#{$bacRightP}px 0px/270px;
              }
          }
      }
        // .user_img {
        //   border-radius: 80px;
        //   list-style-type: none;
        //   cursor: pointer;
        //   border: 1px solid rgba(0, 0, 0, 0.2);
        //   box-shadow: 0px 4px 12px rgba(107, 98, 220, 0.2);
        //   margin-left: -8px;
        //   position: relative;
        //   height: 30px;
        //   width: 30px;
        //   background: $white
        //     url("../../../../../public/images/family-members.svg") no-repeat
        //     210px 6px /630px;
        // }
        // $button-icons: father, mother, husband, wife, brother, sister, son,
        //   daughter;
        // $k: 0;

        // @each $icons in $button-icons {
        //   $bacRightP: $k * 70;
        //   $k: $k + 1;

        //   &.#{$icons} {
        //     background: $white
        //       url("../../../../../public/images/family-members.svg")
        //       no-repeat -#{$bacRightP}px
        //       6px /630px;
        //   }
        // }
      }
    }

    .policy_detail {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      h5 {
        width: 75px;
        font-family: "Montserrat", sans-serif;
        @include fontSize(12px);
        font-weight: 600;
        line-height: 14px;
        margin: 0px 12px 0 0;

        @include themed {
          color: $primary;
        }

        span {
          display: block;
        }
      }

      ul {
        display: flex;
        width: 98%;
        margin-bottom: 0;

        li {
          list-style-type: none;
          font-family: "Montserrat", sans-serif;
          @include fontSize(12px);
          font-weight: 400;
          line-height: 14px;
          // min-width: 155px;
          min-width: 21%;
          border-right: 1px solid #cccccc;
          align-self: center;

          span {
            display: block;
            padding-bottom: 8px;
            color: #808080;
          }

          &:nth-child(2) {
            padding: 0 15px;
          }

          &:nth-child(3) {
            padding: 0 15px;
            // min-width: 60%;
            min-width: 42%;
          }

          &:last-child {
            border-right: none;
            min-width: 14%;
          }

          &.attachicon {
            display: flex;
            justify-content: end;
            align-items: center;

            .MuiLink-root {
              color: $primary;
              display: flex;
              align-items: center;

              svg {
                height: 20px;
                width: 20px;
                transform: rotate(215deg);
                color: $black;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }

    .hospital_detail {
      padding: 8px 24px;
      background: rgba(231, 56, 76, 0.1);
      @include border-radius(0 0 0 12px);

      ul {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        li {
          list-style-type: none;
          font-family: "Montserrat", sans-serif;
          @include fontSize(12px);
          font-weight: 500;
          line-height: 14px;
          margin-right: 16px;

          span {
            color: #7f7677;
            font-weight: 400;
          }
        }
      }
    }

    .requested_date_section {
      padding: 0px 12px;
      background: #f8f8f8;
      max-width: 180px;
      width: 180px;
      // height: 157px;
      border-left: 1px solid #dfdfdf;
      border-right: 1px solid #dfdfdf;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h5 {
        font-family: "Montserrat", sans-serif;
        @include fontSize(12px);
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 4px;
        color: $black;

        @include themed {
          color: $black;
        }

        span {
          display: block;
          color: $black;
          opacity: 50%;
          padding-bottom: 4px;
        }
      }

      p {
        font-family: "Montserrat", sans-serif;
        @include fontSize(12px);
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 0px;
        color: $black;

        @include themed {
          color: $text-light;
        }
      }
    }

    .icon_list {
      max-width: 95px;
      width: 95px;
      padding: 6px;
      text-align: center;
    }
  }
}
