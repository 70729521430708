@import "../../../mixins.scss";

// accordion popup css
.scrollable_area {
    overflow-y: auto;
    overflow-x: hidden;
    // height: 80vh;
    height: calc(100vh - 180px);

    .popup-subheading {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background: $primary;
            height: 1px;
            width: 200px;
        }
    }

    .voluntry_accordion {
        margin: 12px;

        .accordion_section {
            background: $white;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
            @include border-radius(12px !important);
            margin-bottom: 16px !important;

            @include themed {
                background-color: $bg-grey;
            }

            &:before {
                background: transparent;
            }

            &.Mui-expanded {
                margin: 10px 0;
            }

            .MuiAccordionSummary-root {
                .MuiAccordionSummary-content {
                    margin: 12px 0 !important;
                    padding-left: 0 !important;

                    // Checkbox
                    .checkBox {
                        display: block;
                        margin-left: 10px;

                        input {
                            display: none;
                        }

                        label {
                            position: relative;
                            cursor: pointer;
                            display: inline-block;
                            font-weight: 400;
                            padding-left: 28px;
                            line-height: 16px;
                            font-size: 14px;
                            font-family: "Montserrat";

                            @include themed {
                                color: $white;
                            }

                            &:before {
                                content: '';
                                @include border-radius(4px);
                                background-color: transparent;
                                border: 1px solid $grey-light;
                                @include abs-position(20px, null, null, 0px);
                                height: 16px;
                                width: 16px;
                                cursor: pointer;
                            }
                        }

                        input:checked+label {
                            color: $primary;

                            &:before {
                                border: 1px solid $primary;
                            }
                        }

                        input:checked+label:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 23px;
                            left: 7px;
                            width: 3px;
                            height: 7px;
                            border: solid $primary;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }

                    .title {
                        font-family: "Roboto", sans-serif;
                        @include fontSize(14px);
                        font-weight: 500;
                        line-height: 24px;
                        margin-bottom: 0;
                        align-self: center;
                        color: $primary;
                        padding-left: 6px;

                        span {
                            font-family: 'Montserrat';
                            font-weight: 400;
                            @include fontSize(11px);
                            line-height: 20px;
                            color: $black;
                            opacity: 0.5;
                            display: block;
                            margin-top: 8px;

                            @include themed {
                                color: $text-light;
                            }
                        }
                    }

                    .Mui-expanded {
                        max-height: 78px;
                        min-height: 78px;

                        @extend .title;
                    }
                }


            }
        }
    }
}