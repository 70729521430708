@import "../../../../../mixins.scss";

.faq {
    margin-bottom: 8px;
    .MuiPaper-root {
        @include box-shadow(0px 2px 0px rgba($primary, 0.2));
        @include border-radius(12px!important);
    }
    .MuiAccordionSummary-root {
        &.Mui-expanded {
            min-height: 40px;
        }
    }
    .MuiAccordionSummary-content {
        &.Mui-expanded {
            margin: 12px 0px;
        }
        p {
            @include fontSize(14px);
            font-weight: 600;
            font-family: "Montserrat", sans-serif;
            color: $text-dark;
            letter-spacing: 0px;
        }
    }
    .MuiAccordionDetails-root {
        p {
            font-weight: 300;
            @include fontSize(14px);
            color: rgba($text-dark, 0.6);
        }
    }
}