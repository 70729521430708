@import "../../../../mixins.scss";


// Dashboard
.dashbaordWrapper {
    position: relative;
    min-height: calc(100vh - 90px);

    // z-index: 9;
    &:before {
        @include position(absolute, $left: -152px, $top: -76px);
        content: '';
        background: #EDF1FE;
        width: calc(66% + 152px);
        height: calc(100% + 80px);
        z-index: -1;

        @include themed {
            border-right: 1px solid #373535;
            // z-index: 8;
            background: none;
        }
    }

    .insurancePolicies {
        padding-left: 0px;

        .healthPolicy {
            background: linear-gradient(180deg, #ACD4FB 0%, rgba(255, 255, 255, 0) 100%);
            @include border-radius(16px);
            width: 300px;
            max-width: 300px;
            padding-top: 24px;
            text-align: center;

            @media (max-width: 1300px) {
                max-width: 240px;
            }

            img {
                height: 140px;
            }

            .policyDetail {
                background: $text-dark;
                @include border-radius(16px);
                @include box-shadow(0px 4px 16px rgba($text-dark, 0.3));
                color: $white;
                font-family: "Montserrat", sans-serif;
                padding: 16px 24px;
                text-align: left;
                position: relative;
                top: -4px;
                z-index: 9;
                height: 112px;

                p {
                    @include fontSize(16px);

                    span {
                        font-weight: 600;
                    }

                    &.highlight {
                        color: $primary-yellow;
                        font-weight: 600;
                    }

                    &.smText {
                        @include fontSize(12px);
                    }
                }

                h4 {
                    @include fontSize(20px);
                    margin: 4px 0px;
                }

                .covered {
                    background: rgba($white, 0.1);
                    @include fontSize(12px);
                    padding: 6px 12px;
                    @include border-radius(50px);
                    display: inline-block;
                }
            }
        }

        .termInsurance {
            background: lighten($primary-yellow, 15%);
            @include border-radius(16px);
            padding: 16px 24px;
            display: inline-flex;
            align-items: center;
            font-family: "Montserrat", sans-serif;
            color: $text-dark;
            margin-left: 24px;
            height: 112px;

            img {
                width: 60px;
                margin-right: 12px;
            }

            h4 {
                @include fontSize(20px);
            }

            p {
                @include fontSize(16px);

                span {
                    font-weight: 600;
                }

                &.highLight {
                    color: $white;
                    font-weight: 600;
                }

                &.smText {
                    @include fontSize(12px);
                }
            }
        }

        .riskCover {
            background: $white;
            @include border-radius(16px);
            @include box-shadow(0px 8px 10px rgba($primary, 0.1));
            padding: 16px;
            display: flex;
            align-items: center;
            margin-top: 24px;
            margin-left: 24px;
            margin-right: 12px;
            font-family: "Montserrat", sans-serif;
            height: 140px;

            img {
                width: 150px;
            }

            .percentage {
                color: $primary;
                @include fontSize(40px);
                margin: 0px 16px;

                span {
                    color: $text-dark;
                    @include fontSize(20px);
                }
            }

            h4 {
                @include fontSize(20px);
                font-weight: 400;
                margin-bottom: 4px;
            }

            p {
                @include fontSize(14px);
                color: rgba($black, 0.5);
            }
        }
    }

    .otherPlans {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0px;

        >div {
            margin-left: 6px;
            margin-right: 6px;
            cursor: default;
        }

        $button-icons: ci, pa, supertopup, travel;
        $k: 0;

        @each $icons in $button-icons {
            $bacRightP: $k * 50;
            $k: $k +1;

            .#{$icons} {
                border: 1px dashed rgba($primary, 0.5);
                @include border-radius(12px);
                color: $text-dark;
                padding: 16px 16px 16px 70px;
                position: relative;
                margin-bottom: 12px;
                width: 100%;

                @include themed {
                    color: #ddd;
                    background: #333;
                }

                &:hover {
                    background: $white;
                    @include box-shadow(0px 4px 8px rgba($primary, 0.2));

                    @include themed {
                        color: $text-dark;
                    }
                }

                h4 {
                    color: $primary;
                    margin-bottom: 4px;
                }

                p {
                    @include fontSize(14px);

                    span {
                        font-weight: 600;
                    }

                    @include themed {
                        color: #ddd;
                    }
                }

                &:before {
                    @include position(absolute, $left: 10px, $top: calc(50% - 25px));
                    content: "";
                    height: 50px;
                    width: 50px;
                    background: url("../../../../../public/images/otherPlans.svg") no-repeat -#{$bacRightP}px 0px /200px;
                    z-index: 2;
                    border: none;
                }
            }
        }
    }

    .thoughtofTheDay {
        display: flex;
        align-items: center;
        font-family: "Montserrat", sans-serif;
        margin: 24px 0px;
        padding-right: 24px;

        .icon {
            @include border-radius(16px 16px 0px 0px);
            background: linear-gradient(180deg, #2160aa 0%, #EDF1FE 86.46%);
            @include box-shadow(0px -11px 12px rgba($primary, 0.3));
            @include fontSize(12px);
            text-align: center;
            padding: 12px;
            margin-right: 40px;
            width: 100px;

            img {
                display: block;
                width: 28px;
                margin: 0px auto 12px;
            }
        }

        .thoughtText {
            @include fontSize(14px);
            color: $text-dark;
            border-top: 1px solid rgba($primary, 0.2);
            border-bottom: 1px solid rgba($primary, 0.2);
            padding: 16px 0px;
            width: 100%;

            @include themed {
                color: #ddd;
            }

            span {
                color: $primary;
                font-weight: 600;
                @include fontSize(24px);
            }

            .author {
                color: rgba($text-dark, 0.5);
                @include fontSize(14px);
                margin-top: 8px;

                @include themed {
                    color: rgba(#ddd, 0.5);
                }
            }
        }

    }

    .toDoList {
        margin-bottom: 32px;

        h4 {
            @include fontSize(20px);
            font-weight: 300;
            margin-bottom: 16px;

            span {
                font-weight: 600;
            }
        }

        ul {
            display: flex;

            li {
                list-style-type: none;
                @include fontSize(12px);
                padding-left: 6px;
                padding-right: 6px;

                .laddr_p {
                    @include themed {
                        color: $text-light;
                    }
                }

                &:first-child {
                    max-width: 94px;
                    padding-left: 0px;
                }

                &:nth-child(3) {
                    a {
                        padding-top: 85px;
                        height: 141px;

                        p {
                            min-width: 80px;
                        }
                    }
                }

                $product-colors: (
                    "addpolicy",
                    #CDE29F),
                    ("addMember", #FFA3A3),
                    ("riskScore", #CCC8FF
                );
            $k: 0;

            @each $color in $product-colors {
                $bacRightP: $k * 50;
                $k: $k +1;

                .#{nth($color,1)} {
                    @include border-radius(8px);
                    background: linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%);
                    border: 1px solid $white;
                    @include box-shadow(0px 8px 8px rgba($black, 0.1));
                    color: $text-dark;
                    text-decoration: none;
                    padding: 76px 8px 36px 8px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    width: 52px;
                    transition: all ease 0.3s;

                    &:hover {
                        color: darken(nth($color, 2), 20%);
                        @include box-shadow(0px 0px 8px rgba($black, 0.1));
                    }

                    &:before {
                        border: 1px solid $white;
                        @include border-radius(8px);
                        @include box-shadow(0px 4px 12px rgba($black, 0.1));
                        @include position(absolute, $left: -1px, $top: -1px);
                        content: "";
                        height: 40px;
                        width: 50px;
                        background: nth($color, 2) url("../../../../../public/images/toDoIcons.svg") no-repeat -#{$bacRightP}px 0px /150px;
                        z-index: 2;
                    }

                    p {
                        transform: rotate(90deg);
                        font-weight: 400;
                        min-width: 60px;
                    }
                }
            }

        }
    }
}

.yourRequests {
    margin-bottom: 32px;

    h4 {
        @include fontSize(20px);
        font-weight: 300;
        margin-bottom: 16px;

        span {
            font-weight: 600;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            list-style-type: none;
            margin-left: 12px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;

            &:first-child {
                margin: 0px;
            }

            p {
                max-width: 84px;
                margin: 0px 4px;
            }

            a {
                text-decoration: none;
                text-align: center;
                display: inline-block;
            }

            .addNewRequest {
                color: $primary;
                @include fontSize(12px);
                width: 80px;

                &:hover {
                    color: $text-dark;

                    svg {
                        @include border-radius(4px);
                    }
                }

                svg {
                    @include border-radius(8px);
                    border: 1px dashed #FF928E;
                    color: #FF928E;
                    padding: 8px;
                    margin-bottom: 8px;
                    transition: all ease 0.3s;
                    height: 40px;
                    width: 40px;
                }
            }

            $product-colors: (
                "endorsement",
                #2ddd2d),
                ("claim", #eb3737
            );
        $k: 0;

        @each $color in $product-colors {
            $bacRightP: $k * 40;
            $k: $k +1;

            .#{nth($color,1)} {
                @include border-radius(12px);
                background: linear-gradient(90deg, #EDF1FE 0%, rgba(237, 241, 254, 0) 100%);
                border: 1px dashed #ACD4FB;
                color: $text-dark;
                padding: 12px 8px 14px;
                @include fontSize(12px);
                transition: all ease 0.3s;
                min-width: 110px;

                &:hover {
                    // border: 1px solid #ACD4FB;
                    @include border-radius(20px);
                }

                .icon {
                    @include border-radius(8px);
                    @include box-shadow(0px 2px 8px rgba($black, 0.1));
                    margin: 0px auto 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    width: 40px;
                    background: $white;
                }

                span {
                    background: lighten(nth($color, 2), 38%);
                    @include border-radius(20px);
                    color: nth($color, 2);
                    @include fontSize(10px);
                    display: inline-block;
                    padding: 4px 8px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}


}

.buyInsurance {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 100%);
    @include box-shadow(0px 8px 12px rgba($primary, 0.1));
    @include border-radius(0px 0px 16px 16px);
    margin-right: 24px;

    .viewPolicy {
        color: $primary;
        text-decoration: none;
        @include fontSize(14px);
        padding: 8px 0px;
        display: inline-flex;
        align-items: center;

        .solidIcon {
            display: none;
        }

        &:hover {
            .solidIcon {
                display: inherit;
            }

            .outlineIcon {
                display: none;
            }
        }

        svg {
            height: 14px;
        }
    }

    .productList {
        display: flex;
        flex-wrap: wrap;

        li {
            @include border-radius(16px);
            list-style-type: none;
            padding: 12px 12px 12px 86px;
            position: relative;
            @include fontSize(14px);
            font-family: 'Montserrat';
            margin-top: 16px;
            margin-right: 3%;
            min-height: 74px;
            width: 30%;
            transition: all ease 0.3s;

            @media (max-width: 1200px) {
                width: 44%;
            }

            &:hover {
                @include fontSize(12px);

                // @include box-shadow(-8px 4px 12px -8px rgba($primary, 0.2));
                span {
                    @include fontSize(18px);
                }
            }

            span {
                font-weight: 600;
                display: block;
                margin-top: 10px;
                position: relative;
                transition: all ease 0.3s;
            }

            $product-colors: (
                "healthInsurance",
                #E7384C),
                ("carInsurance", #66CCDB),
                ("bikeInsurance", #066ac8),
                ("termInsurance", #2160aa),
                ("investmentPlan", #FFA412),
                ("travelInsurance", #66B27B
            );
        $k: 0;

        @each $color in $product-colors {
            $bacRightP: $k * 58;
            $k: $k +1;

            &.#{nth($color,1)} {
                background: linear-gradient(90deg, rgba(nth($color, 2), 0.2) 0%, rgba(237, 241, 254, 0) 100%);

                &:hover {
                    cursor: pointer;
                    background: $white;
                    // background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(nth($color, 2), 0.2) 100%);
                }

                &:before {
                    @include position(absolute, $left: 12px, $top: 12px);
                    content: "";
                    height: 50px;
                    width: 50px;
                    background: url("../../../../../public/images/insurance_product_icons.svg") no-repeat -#{$bacRightP}px 0px /340px;
                    z-index: 2;
                }
            }
        }
    }
}
}

.advertisementBox {
    border: 1px solid rgba($primary, 0.2);
    @include border-radius(12px);
    margin-bottom: 24px;
    // height: 180px;
    overflow: hidden;

    @include themed {
        background: #333;
    }

    @media (max-width: 1300px) {
        background-size: 160px !important;
    }

    h3 {
        @include fontSize(24px);
        font-weight: 300;
        margin-bottom: 8px;

        span {
            font-weight: 600;
        }
    }

    p {
        @include fontSize(14px);
        color: rgba($black, 0.5);

        @include themed {
            color: #ddd;
        }
    }

    a {
        @include border-radius(40px);
        @include fontSize(12px);
        color: $white;
        display: inline-flex;
        align-items: center;
        padding: 6px 12px;
        margin: 12px 0px;
        text-decoration: none;

        &:hover {
            @include box-shadow(0px 4px 12px rgba($black, 0.1));
        }
    }

    &.rv_box {

        h3,
        p {
            @include themed {
                color: $black;
            }
        }

    }
}
}