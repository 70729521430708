@import "../../../../mixins.scss";

.not_allowed {
  .enrollmentdrive_wrapper {
    .row {
      max-width: 1070px;
      margin: 0 auto;

      &:hover {
        outline: none !important;
      }
    }

    .enrollment_bg {
      background: #edf1fe;
      min-height: 100vh;
      position: relative;
      padding: 58px 66px 48px 66px;

      @media(max-width:768px) {
        // padding: 58px 66px 48px 87px;
        padding: 0;
        padding-right: 14px;
      }


      &:before {
        content: "";
        background: url("../../../../../public/images/enrollmentdrive_bg.svg") no-repeat top left/100%;
        @include position(absolute, $left: 0, $top: 0px);
        height: 100%;
        width: 100%;

      }

      .backBtn {
        position: relative;
        top: -50px;
        left: -120px;

        svg {
          cursor: pointer;
          position: relative;
          transition: all 0.5s ease;
          left: 0;
          margin-right: 20px;

          &:hover {
            left: -4px;
            color: #ec3f3f;
          }
        }

        span {
          font-family: "Montserrat";
          font-weight: 600;
          @include fontSize(34px);
          line-height: 41px;
        }
      }

      .enrollment_innersection {
        border: 2px solid $primary;
        @include border-radius(60px);
        background: $white;
        min-height: 740px;
        @include box-shadow(17px 17px 0px rgba($primary, 0.7));

        .inner_content {
          padding: 60px 0 0 0px;

          h5 {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(34px);
            line-height: 50px;
            margin: 20px 0 10px 0;
            font-family: Montserrat;
            font-size: 48px;
            font-weight: 600;
            line-height: 50px;
            text-align: center;

            @media(max-width:768px) {
              font-size: 35px;
            }

            span {
              font-weight: 600;
            }
          }

          p {
            font-family: "Roboto";
            font-weight: 300;
            @include fontSize(16px);
            line-height: 19px;
            margin-bottom: 20px;
            font-family: Roboto;
            width: 550px;
            height: 94px;
            margin: auto;
            padding: 0 10px;

            font-family: Roboto;
            font-size: 32px;
            font-weight: 300;
            line-height: 47px;
            text-align: center;

            @media(max-width:768px) {
              width: fit-content;
              font-size: 16px;
              line-height: 20px;
            }
          }

          .ctaBtn {
            .submitBtn {
              padding: 10px 94px;
            }
          }
        }

        .choose_policy {
          padding-top: 50px;

          h5 {
            font-family: "Montserrat";
            font-weight: 600;
            @include fontSize(34px);
            line-height: 41px;
            margin-bottom: 0px;
            text-align: center;
          }

          hr {
            color: rgba(0, 0, 0, 0.1);
            margin: 20px 0 20px !important;
          }

          .coverageAmt {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin: 0px;
            justify-content: center;

            li {
              list-style: none;
              min-width: 200px;

              .Checkbox {
                display: inline-block;
                position: relative;

                label {
                  font-family: "Montserrat";
                  display: inline-block;
                  text-align: center;
                  color: $black;
                  cursor: pointer;
                  position: relative;
                  padding: 40px 10px 10px;
                  @include border-radius(12px);
                  border: 1px solid $primary-light;
                  width: 209px;
                  height: 216px;

                  @include themed {
                    background: $bg-grey;
                    border-color: $bg-grey;
                  }

                  &:after {
                    position: absolute;
                    content: "";
                    height: 20px;
                    width: 20px;
                    border: 1px solid $primary;
                    @include border-radius(50%);
                    left: 10px;
                    top: 7px;
                  }

                  p {
                    // position: absolute;
                    // bottom: 12px;
                    left: 0;
                    width: 100%;
                    @include fontSize(14px);
                    line-height: 17px;
                    font-weight: 500;

                    @include themed {
                      color: $text-light;
                    }

                    span {
                      display: block;
                      padding-top: 8px;
                      @include fontSize(12px);
                      line-height: 17px;
                      font-weight: 500;
                      color: rgba(0, 0, 0, 0.5);
                    }
                  }

                  $button-icons: super, health, critical, accident;
                  $k: 0;

                  @each $icons in $button-icons {
                    $bacRightP: $k * 105;
                    $k: $k + 1;

                    &.#{$icons} {
                      &:before {
                        position: absolute;
                        content: "";
                        height: 100px;
                        width: 105px;
                        top: 30px;
                        left: calc(50% - 52px);
                        background: url("../../../../../public/images/enrollment_producttype_icons.svg") no-repeat -#{$bacRightP}px 0px/420px;
                        z-index: 2;
                        border: none;
                      }
                    }
                  }
                }

                input:checked~label {
                  border: 1px solid $primary;
                  @include box-shadow(0px -4px 12px rgba($black, 0.1));
                  color: $black;

                  &:after {
                    position: absolute;
                    content: "";
                    height: 22px;
                    width: 22px;
                    background: url("../../../../../public/images/checkbox_tick_icon.svg") no-repeat center center/100%;
                    @include border-radius(50%);
                    left: 10px;
                    top: 7px;
                    border: none;
                  }
                }

                hr {
                  margin-bottom: 12px;
                  border-top: 1px solid rgba(0, 0, 0, 0.1);
                  border-bottom: none;
                  border-left: none;
                  border-right: none;
                }

                input {
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  opacity: 0;

                  &:hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }

          .ctaBtn {
            .submitBtn {
              padding: 10px 78px;
            }
          }
        }
      }
    }
  }
}