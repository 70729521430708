@import "mixins.scss";

// .modalSideOpen {
//     overflow-y: auto;
//     overflow-x: hidden;
//     backdrop-filter: blur(4px);
//     background: rgba(#000, 0.2);

//     .MuiBackdrop-root {
//         background: transparent;
//     }

//     .modalInner {
//         background: $white;
//         @include box-shadow(0px 4px 16px rgba(#000, 0.2));
//         padding: 24px;
//         @include position(absolute, $top: 0px, $right: 0px);
//         min-height: 100%;
//         width: 582px;
//         transition: all 0.6s ease;

//         &.show {
//             right: 0px;
//             transition: all 0.6s ease;
//             animation: rahul 0.6s ease;
//             animation-delay: 0.001s;

//             @include themed {
//                 background: $bg-grey;
//             }
//         }

//         @keyframes rahul {
//             0% {
//                 right: -700px;

//             }

//             100% {
//                 right: 0px;

//             }
//         }

//         hr {
//             border: none;
//             border-top: 1px solid $primary-light;

//             @include themed {
//                 border-color: #000;
//             }
//         }

//         h4 {
//             font-family: "Montserrat", sans-serif;
//             font-size: 20px;
//             line-height: 24px;
//             margin-bottom: 0;
//             font-weight: 600;
//         }

//         .scrollable_area {
//             overflow-y: auto;
//             overflow-x: hidden;
//             height: 80vh;

//             .MuiPaper-root {
//                 margin-bottom: 16px;
//                 border-radius: 4px !important;

//                 &:before {
//                     background-color: transparent;
//                 }

//                 @include themed {
//                     background-color: $bg-grey;
//                     // box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
//                 }

//                 .MuiButtonBase-root {
//                     min-height: 0px;
//                     padding: 0;

//                     .MuiAccordionSummary-content {
//                         .MuiTypography-root {
//                             font-family: "Montserrat", sans-serif;
//                             font-size: 16px;
//                             line-height: 20px;

//                             @include themed {
//                                 color: $text-light;
//                             }
//                         }

//                     }

//                     .MuiAccordionSummary-content.Mui-expanded {
//                         margin: 10px 0 0 0;

//                         .MuiTypography-root {
//                             font-family: "Montserrat", sans-serif;
//                             font-size: 16px;
//                             line-height: 20px;
//                             margin-bottom: 0;
//                             font-weight: 400;
//                             color: $sky-blue;
//                         }
//                     }
//                 }

//                 .MuiAccordionDetails-root {
//                     padding-left: 0;
//                     padding-right: 0;

//                     .MuiTypography-root {
//                         .MuiGrid2-root.MuiGrid2-container {
//                             .pl {
//                                 padding-left: 0 !important;
//                             }

//                             .pr {
//                                 padding-right: 0 !important;
//                             }

//                         }
//                     }
//                 }
//             }

//             .browsebtn {
//                 font-family: "Montserrat";
//                 font-weight: 500;
//                 font-size: 14px;
//                 line-height: 24px;
//                 background: $primary;
//                 padding: 16px 14px;
//                 border-radius: 0 10px 10px 0;
//                 text-transform: capitalize;
//                 color: #fff;
//                 text-align: center;
//                 display: inline-block;

//                 &:hover {
//                     background: $primary;
//                     color: #fff;
//                 }
//             }

//         }

//     }
// }

.slidingPanel {
  @include themed() {
    background: $bg-dark;
  }

  .select_feature {
    padding: 0px 0px 0px 12px;

    hr {
      border: none;
      border-top: 1px solid $primary-light;

      @include themed {
        border-color: $text-light;
      }
    }

    h4 {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(18px);
      line-height: 24px;
      color: $black;

      @include themed {
        color: $text-light;
      }
    }
  }

  .popup-subheading {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      background: $primary;
      height: 1px;
      width: 200px;
    }
  }

  .scrollable_area {
    overflow-y: auto;
    overflow-x: hidden;
    // height: 80vh;
    height: calc(100vh - 180px);

    .popup-subheading {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background: $primary;
        height: 1px;
        width: 200px;
      }
    }

    .MuiPaper-root {
      margin-bottom: 16px;
      @include border-radius(4px);

      &:before {
        background-color: transparent;
      }

      @include themed {
        background-color: $bg-grey;
        // box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      }

      .MuiButtonBase-root {
        min-height: 0px;
        padding: 0;

        .MuiAccordionSummary-content {
          padding-left: 12px;

          .MuiTypography-root {
            font-family: "Montserrat", sans-serif;
            @include fontSize(16px);
            line-height: 20px;

            @include themed {
              color: $text-light;
            }
          }
        }

        .MuiAccordionSummary-content.Mui-expanded {
          margin: 10px 0 0 0;

          .MuiTypography-root {
            font-family: "Montserrat", sans-serif;
            @include fontSize(16px);
            line-height: 20px;
            margin-bottom: 0;
            font-weight: 400;
            color: $sky-blue;
          }
        }
      }

      .MuiAccordionDetails-root {
        padding-left: 0;
        padding-right: 0;

        .MuiTypography-root {
          .MuiGrid2-root.MuiGrid2-container {
            .pl {
              padding-left: 0 !important;
            }

            .pr {
              padding-right: 0 !important;
            }
          }
        }
      }
    }

    .browse_btn {
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(14px);
      line-height: 24px;
      background: $primary;
      padding: 16px 14px;
      border-radius: 0 10px 10px 0;
      text-transform: capitalize;
      color: #fff;
      text-align: center;
      display: inline-block;

      &:hover {
        background: $primary;
        color: $white;
      }
    }

    .selector {
      display: flex;
      height: 38px;
      max-width: 360px;
      // align-items: center;
      // justify-content: center;
      font-family: "Montserrat";
      @include fontSize(14px);
      line-height: 17px;
      color: $black;
      position: relative;

      &:after {
        @include border-radius(10px);
        position: absolute;
        left: 0px;
        top: 0px;
        content: "";
        border: 1px solid #000;
        width: calc(100% - 4px);
        height: calc(100% - 2px);
        z-index: 0;
      }

      button {
        position: relative;
        z-index: 1;
        font-family: "Montserrat";
        font-size: 14px;
        text-transform: capitalize;
      }

      .selection {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 100%;
        width: 100%; // to flexbox this will keep all widths the same. flexbox is just funny like that.
        margin-left: -1px;
        background-clip: border-box;
        transform: scale3d(1, 1, 1); // weird hack to prevent pixel-y borders
        font-family: "Montserrat";
        @include fontSize(14px);
        line-height: 17px;
        background: rgba(172, 212, 251, 1);
        color: $black;
        transition: 0.15s ease-in-out background-color;

        @include themed() {
          background: $bg-grey;
        }

        &.selected {
          background: transparent !important;
          z-index: 2;
          @include fontSize(14px);
          line-height: 17px;
          color: $black;
          font-weight: 600;

          @include themed() {
            background: $white;
          }
        }

        &:hover {
          background: rgba(172, 212, 251, 1);

          @include themed() {
            background: rgba(0, 0, 0, 0.5);
          }
        }

        // add some borders!
        &:first-child {
          margin-left: 0;
          @include border-radius(10px 0 0 10px);
        }

        &:last-child {
          @include border-radius(10px 0 0 10px);
        }
      }
    }

    .selector.width-per-item {
      // width: auto;
      margin: 0px auto;

      .selection,
      button {
        //min-width: 185px;
        max-width: 100%;
        width: 190px;
        padding: 0px 10px;
        font-weight: 500;

        @include themed() {
          color: $text-grey !important;
        }
      }
    }

    .coverage_section {
      @include border-radius(12px);

      .mini_scroll {
        height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      &.premium_section {
        border: 1px solid $primary-light;
      }

      &.red {
        border: 1px solid #ffe4e4;

        .coverage_heading {
          background: #ffeded;

          h5 {
            color: #ec3f3f;
          }
        }
      }

      &.blue {
        border: 1px solid #e5e7fe;

        .coverage_heading {
          background: #E4F1FF;

          h5 {
            color: $primary;
          }
        }
      }

      &.yellow {
        border: 1px solid #fff2db;

        .coverage_heading {
          background: #fef8ed;

          h5 {
            color: #f5be4c;
          }
        }
      }

      &.green {
        border: 1px solid #cdffd2;

        .coverage_heading {
          background: #ecffee;

          h5 {
            color: #029d11;
          }
        }
      }

      &.red,
      &.blue,
      &.yellow,
      &.green {
        .coverage_heading {
          border-radius: 12px 12px 0px 0px;
          padding: 12px 16px;

          @include themed {
            background: $bg-grey;
          }

          h5 {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 18px;
            margin-bottom: 0;
          }
        }
      }

      .insurer_heading {
        @include border-radius(12px 12px 0 0);
        padding: 12px 16px;
        background: $blue-light;
        width: 100%;

        @include themed {
          background: $bg-grey;
        }

        h5 {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(14px);
          line-height: 18px;
          margin-bottom: 0;
          color: $primary;
        }
      }

      hr {
        border: none;
        border-top: 1px solid $primary-light !important;

        @include themed {
          border-color: $bg-grey !important;
        }
      }

      @include themed {
        border-color: $bg-grey;
      }
    }
  }

  .checkbox_feature {
    .checkBox {
      label {
        &:before {
          top: calc(50% - 8px);
        }
      }

      input {
        &:checked {
          & + label {
            color: $sky-blue;

            &::after {
              content: "";
              display: block;
              position: absolute;
              top: calc(50% - 5px);
              left: 7px;
              width: 3px;
              height: 7px;
              border: solid $white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }

            &::before {
              border: 1px solid $primary;
              background: $primary;
            }
          }
        }
      }
    }

    p {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(12px);
      line-height: 17px;
      color: $black;
      margin-bottom: 0;

      @include themed {
        color: $text-light;
      }

      span {
        font-family: "Montserrat";
        font-weight: 400;
        @include fontSize(11px);
        line-height: 14px;
        color: $black;
        opacity: 0.5;
        display: block;
        padding-top: 4px;

        @include themed {
          color: $text-light;
        }
      }
    }
  }

  .footer_sec {
    background: #ffffff;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.07);
    height: 92px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 20px 0 !important;
    display: flex;
    z-index: 99;
    justify-content: center;

    @include themed {
      background: $bg-dark;
    }

    .save_btn {
      background: $primary;
      @include fontSize(16px);
      @include border-radius(10px);
      height: 52px;
      color: $white;
      font-family: "Montserrat";
      cursor: pointer;
      padding: 16px 40px;
      text-decoration: none;
      margin-bottom: 0px;
     
      &:hover {
        background-color: $primary;
      }
    }
  }
}

.email_section {
  padding-left: 14px;

  .person_name {
    font-family: "Montserrat";
    font-weight: 500;
    @include fontSize(12px);
    line-height: 15px;
    color: $primary !important;
    margin-bottom: 0px;
    padding-left: 36px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      background: url("../public/images/rahul_initial.svg") 0px 0px/26px;
      height: 26px;
      width: 26px;
    }
  }

  .subjects {
    font-family: "Montserrat";
    font-weight: 600;
    @include fontSize(12px);
    line-height: 24px;
    color: $black;
    padding-left: 36px;
    margin-bottom: 0px;
  }

  .cc_bcc {
    font-family: "Montserrat";
    font-weight: 600;
    @include fontSize(12px);
    line-height: 16px;
    padding-left: 36px;
    color: $black;
    margin-bottom: 0px;

    span {
      font-weight: 400;
    }
  }

  .date_time {
    font-family: "Montserrat";
    font-weight: 500;
    @include fontSize(12px);
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    text-align: right;
  }

  .mail_common {
    font-family: "Montserrat";
    font-weight: 600;
    @include fontSize(12px);
    line-height: 15px;
    color: $black !important;
    margin-bottom: 0;
  }

  .attatchments_section {
    img {
      height: 12px;
      width: 12px;
      margin-left: 15px;
    }
  }

  .thanks {
    font-family: "Montserrat";
    font-weight: 600;
    @include fontSize(12px);

    line-height: 15px;
    color: $black !important;
    margin-bottom: 24px;
  }

  .mail_data {
    font-family: "Montserrat";
    font-weight: 500;
    @include fontSize(12px);
    line-height: 16px;
    color: $black !important;
    margin: 24px 0px;
  }
}

.labelTitle {
  position: relative;
  padding-bottom: 12px;

  &:before {
    //@include box-shadow(0 8px 6px -6px rgba($black, 0.2));
    @include position(absolute, $left: 0px, $bottom: 0px);
    content: "";
    height: 14px;
    width: 100%;
  }
}

.panel {
  overflow: hidden !important;
}

.footer_sec {
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.07);
  height: 92px;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 0 !important;
  display: flex;
  z-index: 99;
  justify-content: center;

  @include themed {
    background: $bg-dark;
  }

  .save_btn {
    background: $primary;
    @include fontSize(16px);
    @include border-radius(10px);
    height: 52px;
    color: $white;
    font-family: "Montserrat";
    cursor: pointer;
    padding: 16px 40px;
    text-decoration: none;
    margin-bottom: 0px;
    &.menrollment {
      width: calc(100% - 20px);
      margin:auto;
    }
    &:hover {
      background-color: $primary;
    }
  }
}
