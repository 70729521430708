@import "./../../../../mixins.scss";

.claimAssistant {
  .productDataTable {
    border: none !important;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;

    .MuiDataGrid-columnHeaders {
      background: #EEF6FE;
      font-weight: 600;
    }

    .MuiDataGrid-footerContainer {
      border: none !important;
    }
  }

  // .filterBar {
  //   .MuiButtonBase-root {
  //     display: none;
  //   }
  // }

  .dataList {
    // .MuiDataGrid-columnHeaders {
    //   padding-left: 10px;

    //   .MuiDataGrid-columnHeader {
    //     &:first-child {
    //       width: 0 !important;
    //       min-width: 0 !important;
    //     }

    //     .MuiDataGrid-columnHeaderDraggableContainer {
    //       .MuiDataGrid-columnHeaderTitleContainerContent {
    //         .MuiButtonBase-root {
    //           display: none;
    //         }
    //       }
    //     }

    //     .MuiDataGrid-columnSeparator {
    //       &:first-child {
    //         display: none;
    //       }
    //     }
    //   }
    // }

    .MuiDataGrid-row {
      // padding-left: 10px;
      // width: 100% !important;
      min-height: 44px !important;

      // .MuiDataGrid-cellCheckbox {
      //   display: none;
      // }
    }
  }

  // .MuiDataGrid-virtualScrollerRenderZone {
  //   width: 100vw;
  // }

  .status_dropdown {
    // max-width: 180px;
    @include border-radius(20px);
    display: flex;
    align-items: center;
    padding: 0 10px;
    // width: 180px;
    border: 1px dashed;

    svg {
      height: 16px;
      width: 24px;
    }

    .inputFieldd {
      position: relative;
      margin-bottom: 0;
      width: 100%;

      .MuiOutlinedInput-input {
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(12px);
        line-height: 15px;
        padding: 6px 20px 5px 1px;
      }

      label,
      input {
        font-family: "Montserrat";
        font-weight: 500;
        letter-spacing: 0px;
        @include fontSize(12px);

        @include themed() {
          color: $text-light;
        }
      }

      label {
        top: 2px;
      }

      input {
        height: 23px;
      }

      .MuiSelect-select {
        background: url("../../../../../public/images/down_arrow.svg") no-repeat right 5px center/10px !important;
        text-align: left;

        @include themed() {
          background: url("./images/down_arrow_white.svg") no-repeat right 6px center/10px !important;
          color: $text-light;
        }
      }

      .MuiInputLabel-shrink {
        color: $primary !important;
      }

      svg {
        display: none;
      }

      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $primary !important;
        }
      }

      fieldset {
        border: none !important;
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(12px);
        line-height: 15px;

        @include themed() {
          border-color: $text-grey;
        }
      }
    }
  }

  div#Pending div {
    color: #ff9b44;
    border-color: #ff9b44;
    background: #fcecdf;
  }

  div#Approved div {
    color: #219653;
    border-color: #219653;
    background: #d3fce5;
  }

  div#Expired div {
    color: $primary-red;
    border-color: $primary-red;
    background: #ffe3e3;
  }

  div#Rejected div {
    color: $primary-red;
    border-color: $primary-red;
    background: #ffe3e3;
  }

  div#Sent_To_Insurer div {
    color: #4293e4;
    border-color: #4293e4;
    background: #deeeff;
  }

  div#Documents_Pending div {
    color: #ac25ac;
    border-color: #ac25ac;
    background: #c4b9c3;
  }

  div#Request_Raised div {
    color: #05daf7;
    border-color: #05daf7;
    background: #cee6e7;
  }
}