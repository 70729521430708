@import "./mixins.scss";

.status_dropdown {
  min-width: 140px;
  @include border-radius(20px);
  display: flex;
  align-items: center;
  padding: 2px 10px;
  // width: 100%;
  border: 1px dashed;
  max-width: 250px;

  &.disabled_sd {
    .inputFieldd {
      .MuiSelect-select {
        background: none !important;

        @include themed {
          background: none !important;
        }
      }
    }
  }

  svg {
    height: 16px;
    width: 24px;
  }

  .inputFieldd {
    position: relative;
    margin-bottom: 0;
    width: 100%;

    text-align: center;

    .MuiOutlinedInput-input {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(12px);
      line-height: 15px;
      padding: 9px 20px 7px 1px;
    }

    label,
    input {
      font-family: "Montserrat";
      font-weight: 500;
      letter-spacing: 0px;
      @include fontSize(12px);

      @include themed() {
        color: $text-light;
      }
    }

    label {
      top: 0px;
      background: #fff;
      left: -30px;
    }

    input {
      height: 23px;
    }

    .MuiSelect-select {
      background: url("../public/images/down_arrow.svg") no-repeat right 5px
        center/10px !important;
      text-align: left;

      @include themed() {
        background: url("./images/down_arrow_white.svg") no-repeat right
          6px center/10px !important;
        color: $text-light;
      }
    }

    .MuiInputLabel-shrink {
      color: $primary !important;
    }

    svg {
      display: none;
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $primary !important;
      }
    }

    fieldset {
      border: none !important;
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(12px);
      line-height: 15px;

      @include themed() {
        border-color: $text-grey;
      }
    }
  }

  span {
    padding: 8px 0 6px 4px;
    font-size: 13px;
    font-family: "Montserrat";
    font-weight: 600;
  }
}

// Draft
div#_1 div,
div#_20 div,
div#_72 div {
  color: #7b7b7b;
  border-color: #7b7b7b;
  background: #d4d4d4;
  border-radius: 20px;
}

// Sent to Insurer
div#_2 div {
  color: #525ceb;
  border-color: #525ceb;
  background: #caceff;
  border-radius: 20px;
}

// Sent to Client
div#_3 div {
  color: #227d9f;
  border-color: #227d9f;
  background: #c4ebf9;
  border-radius: 20px;
}

// Approved
div#_4 div,
div#_75 div,
div#_71 div {
  color: #008032;
  border-color: #008032;
  background: #c0fbd7;
  border-radius: 20px;
}

// rejected
div#_5 div,
div#_26 div,
div#_76 div,
div#_51 div,
div#_58 div {
  color: #ff1d1d;
  border-color: #ff1d1d;
  background: #fcb1b1;
  border-radius: 20px;
}

// Converted
div#_6 div {
  color: #fb8b24;
  border-color: #fb8b24;
  background: #ffca99;
  border-radius: 20px;
}

// created
div#_17 div {
  color: #e26ee5;
  border-color: #e26ee5;
  background: #fed6ff;
  border-radius: 20px;
}

// Inforced
div#_21 div {
  color: #52d3d8;
  border-color: #52d3d8;
  background: #c6fdff;
  border-radius: 20px;
}

// Expired
div#_22,
div#_52,
div#_59,
div#_73 div {
  color: #fa7070;
  border-color: #fa7070;
  background: #fecaca;
  border-radius: 20px;
}

// Renewed
div#_23 div {
  color: #d61355;
  border-color: #d61355;
  background: #ffdce8;
  border-radius: 20px;
}

// void
div#_24 div,
div#_34 div {
  color: #f6b17a;
  border-color: #f6b17a;
  background: #ffe7d4;
  border-radius: 20px;
}

// Cancelled
div#_25 div, div#_67 div {
  color: #e64848;
  border-color: #e64848;
  background: #f9d2d2;
  border-radius: 20px;
}

// Grace
div#_29 div {
  color: #2d9596;
  border-color: #2d9596;
  background: #e0ffff;
  border-radius: 20px;
}

// New
div#_30 div {
  color: #0766ad;
  border-color: #0766ad;
  background: #d0e8fb;
  border-radius: 20px;
}

// Renewal
div#_31 div {
  color: #508d69;
  border-color: #508d69;
  background: #d5ffe6;
  border-radius: 20px;
}

// Resigned
div#_35 div {
  color: #860a35;
  border-color: #860a35;
  background: #ffdae7;
  border-radius: 20px;
}

// Terminated
div#_36 div {
  color: #cb6488;
  border-color: #cb6488;
  background: #ffe1ec;
  border-radius: 20px;
}

// Employed
div#_37 div {
  color: #4f6f52;
  border-color: #4f6f52;
  background: #e1ffe4;
  border-radius: 20px;
}

// Probation
div#_38 div {
  color: #339690;
  border-color: #339690;
  background: #dcfffd;
  border-radius: 20px;
}

// NA
div#_39 div,
div#_43 div {
  color: #7702a9;
  border-color: #7702a9;
  background: #c4aecd;
  border-radius: 20px;
}

// Enrolment Initated
div#_40 div {
  color: #e06469;
  border-color: #e06469;
  background: #ffd2d4;
  border-radius: 20px;
}

// Enrolled
div#_41 div,
div#_44 div {
  color: #fa7070;
  border-color: #fa7070;
  background: #ffe4dd;
  border-radius: 20px;
}

// Opt out, inprogress
div#_42 div,
div#_66 div,
div#_45 div {
  color: #1b4242;
  border-color: #1b4242;
  background: #d7ffff;
  border-radius: 20px;
}

// Pending
div#_46 div {
  color: #cd5c08;
  border-color: #cd5c08;
  background: #fce7d7;
  border-radius: 20px;
}

// Requested
div#_47 div,
div#_77 div,
div#_54 div,
div#_61 div {
  color: #447eea !important;
  border-color: #447eea !important;
  background: #d6e3fb;
  border-radius: 20px;
}

// Sent to TPA/Insurer
div#_48 div,
div#_55 div {
  color: #525ceb;
  border-color: #525ceb;
  background: #caceff;
  border-radius: 20px;
}

// Information required
div#_49 div,
div#_56 div {
  color: #7360df;
  border-color: #7360df;
  background: #dcd6ff;
  border-radius: 20px;
}

// Approved
div#_50,
div#_57 div {
  color: #008032;
  border-color: #008032;
  background: #c0fbd7;
  border-radius: 20px;
}

// Partial Approval
div#_53 div {
  color: #9900ff;
  border-color: #9900ff;
  background: #e9ccfc;
  border-radius: 20px;
}

// Enrolment Completed and Published
div#_60 div,
div#_62 div,div#_65 div {
  color: #00871b;
  border-color: #00871b;
  background: #c6e0b4;
  border-radius: 20px;
}

// Enrolled-Requested and Opt-Out Requested
div#_63 div,
div#_64 div {
  color: #447eea;
  border-color: #447eea;
  background: #d0e0ff;
  border-radius: 20px;
}

div#_59 div {
  color: #cc0000;
  border-color: #cc0000;
  background: #ffdddd;
  border-radius: 20px;
}
div#_68 div {
  color: #FDA403;
  border-color: #FDA403;
  background: #ffeac4;
  border-radius: 20px;
}
div#_69 div {
  color: #cf4c00;
  border-color: #cf4c00;
  background: #fcd6bd;
  border-radius: 20px;
}
div#_70 div {
  color: #280bba;
  border-color: #280bba;
  background: #e8e3ff;
  border-radius: 20px;
}
div#_74 div {
  color: #8ea9db;
  border-color: #8ea9db;
  background: #d9e1f2;
  border-radius: 20px;
}

//  Active
div#_13,
div#_15,
div#_7,
div#_9,
div#_11,
div#_18,
div#_27,
div#_32 {
  div {
    color: #1db300;
    border-color: #1db300;
    background: #dfffd9;
    border-radius: 20px;
  }
}

// inactive

div#_14,
div#_16,
div#_8,
div#_10,
div#_12,
div#_19,
div#_28,
div#_33 {
  div {
    color: #f3aa60;
    border-color: #f3aa60;
    background: #ffe8d1;
    border-radius: 20px;
  }
}
