@import "../../../../../mixins.scss";


.headerSection {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    width: 100%;
    margin: 0 !important;
    position: fixed;
    z-index: 99;
    background: $white;
    height: 77px;
    display: flex;
    align-items: center;

    @include themed {
        background: $black;
        border-bottom: 1px solid #333;
    }

    .pageTitle {
        font-family: 'Montserrat';
        font-weight: 600;
        @include fontSize(16px);
        line-height: 22px;

        a {
            display: flex;
            justify-content: center;
            margin-right: 12px;
            color: $primary;

            svg {
                position: relative;
                transition: all 0.5s ease;
                left: 0px;
            }

            &:hover {
                cursor: pointer;
                color: $primary-red;

                svg {
                    left: -4px;
                }
            }
        }

        .MuiSelect-select {
            min-width: 220px;
        }

        span {
            font-family: 'Montserrat';
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: right;
            color: $primary;
            margin-left: 12px;
        }
    }

    .right_pageTitle {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        text-align: right;
        color: rgba(0, 0, 0, 0.5);

        @include themed {
            color: $text-light;
        }
    }
}

.main_section {
    padding: 100px 16px 0px 20px;
    height: 100vh;

    @include themed {
        background: $black;
    }

    .status_dropdown {
        width: 100%;

        .inputFieldd {
            position: relative;
            margin-bottom: 0;

            .MuiOutlinedInput-input {
                font-family: 'Montserrat';
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                padding: 12px;

            }

            label,
            input {
                font-family: "Montserrat";
                font-weight: 500;
                letter-spacing: 0px;
                font-size: 14px;

                @include themed() {
                    color: $text-light;
                }
            }

            label {
                // transform: translate(14px, 18px) scale(1);
                top: 2px;
            }

            input {
                height: 23px;
            }

            // .MuiSelect-select {
            //     background: url("../../../../../../public/images/down_arrow.svg") no-repeat right 14px center/10px;
            //     text-align: left;

            //     @include themed() {
            //         background: $bg-grey url("../../../../../../public/images/down_arrow.svg") no-repeat right 14px center/10px;
            //         color: $text-light;

            //     }
            // }


            .MuiInputLabel-shrink {
                color: $primary !important;
            }

            svg {
                display: none;
            }

            .Mui-focused {

                .MuiOutlinedInput-notchedOutline {
                    border-color: $primary !important;

                }
            }

            fieldset {
                border: none !important;
                font-family: "Montserrat";
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;

                @include themed() {
                    border-color: $text-grey;
                }
            }


        }

        // .dropdown_arrow {

        //     $sort-icons: highest,
        //     norm,
        //     high,
        //     low,
        //     lowest;
        //     $k: 0;

        //     @each $icons in $sort-icons {
        //         $bacRightP: $k * 10;
        //         $k: $k +1;

        //         .#{$icons} {
        //             position: relative;
        //             top: -1px;
        //             padding: 7px 27px 7px 55px;
        //             transition: all 0.3s ease-in-out;

        //             &:before {
        //                 position: absolute;
        //                 left: 27px;
        //                 top: 9px;
        //                 content: "";
        //                 height: 20px;
        //                 width: 20px;
        //                 background-image: url("../../../../../../public/images/dropdown_arrow.svg");
        //                 background-position: -#{$bacRightP}px 0px;
        //                 background-size: 50px;
        //             }
        //         }
        //     }
        // }

    }

    .assinee_section {
        .asignee_tag {
            height: 33px;
            width: 33px;
            border-radius: 50%;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            padding: 4px 7px;
            color: #ffffff;
            cursor: pointer;

            &.orgBg {
                background: #F2994A;
                position: relative;
                left: 5px;
            }

            &.violetBg {
                background: $primary;
                position: relative;
                left: 2px;
            }

            &.grayBg {
                background: #E0E0E0;
                position: relative;
                left: -1px;
            }
        }
    }

    .left_section {
        background: #FFFFFF;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        padding: 16px;
        height: 80vh;

        @include themed {
            background: #333;
        }

        h3 {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 12px 16px 40px;
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 0;

            @include themed {
                color: $text-light;
            }
        }

        .subtask {
            h6 {
                font-family: 'Montserrat';
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                color: $primary;
                margin-bottom: 12px;
            }

            ul {
                li {
                    list-style-type: none;
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 12px;
                    display: flex;
                    justify-content: space-between;

                    @include themed {
                        color: $text-light;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .delete_icon {
                        background: url("../../../../../../public//images/delete_icons.svg") no-repeat;
                        height: 24px;
                        width: 24px;
                        cursor: pointer;
                    }
                }
            }

        }

        .attachments {
            h6 {
                font-family: 'Montserrat';
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                color: $primary;
                margin-bottom: 0;
            }
        }

        .img_section {
            position: relative;
            background: #fff;
            border-radius: 4px;
            width: calc(25% - 9px);
            overflow: visible;
            margin: 0 12px 30px 0;
            justify-content: center;
            display: flex;
            // opacity: 0;

            &:hover {
                box-shadow: 0 2px 15px #0003;

                .inner_section .bottom_img {
                    opacity: 1;
                }
            }

            .inner_section {
                position: relative;
                height: 160px;
                background: #fff;
                border-radius: 4px;

                .task_img {
                    z-index: 999;
                    cursor: pointer;
                }

                span {
                    display: block;
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 11px;
                    text-align: center;
                    margin-top: 8px;
                }

                .bottom_img {
                    display: flex;
                    justify-content: space-around;
                    margin-top: 10px;
                    opacity: 0;

                    // &:hover {
                    //     opacity: 1;
                    // }

                    img {
                        cursor: pointer;
                    }
                }
            }
        }

    }

    .right_section {
        background: #FFFFFF;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        padding: 20px;
        height: 80vh;

        @include themed {
            background: #333;
        }

        .scrolll_section {
            // max-height: 100vh;
            overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: none;
            min-height: 60vh;

            .date_section {
                h6 {
                    font-family: 'Montserrat';
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #000000;
                    margin-bottom: 8px;

                    @include themed {
                        color: $text-light;
                    }
                }

                p {
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                    margin-bottom: 4px;

                    @include themed {
                        color: $text-light;
                    }
                }
            }

            hr {
                border: 1px solid rgba(0, 0, 0, 0.1);
            }

            .task_title {
                h6 {
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: rgba(0, 0, 0, 0.5);
                    margin-bottom: 6px;

                    @include themed {
                        color: $text-light;
                    }
                }
            }

            .task_date {
                text-align: right !important;

                h6 {
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    color: rgba(0, 0, 0, 0.5);
                    margin-bottom: 6px;

                    @include themed {
                        color: $text-light;
                    }
                }
            }
        }

        .MuiInput-underline {
            width: 100%;

            &:before {
                border: none;
            }

            &:after {
                border: none;
            }

            &:hover {
                &:not(.Mui-disabled)::before {
                    border-bottom: none !important;
                }
            }

            .MuiInputBase-input {
                word-wrap: break-word !important;
            }
        }

        .comment_section {
            background: #FFFFFF;
            height: 50px;
            position: fixed;
            bottom: 26px;
            z-index: 99;
            width: 45%;

            hr {
                border: 1px solid rgba(0, 0, 0, 0.1);
            }

            &:active,
            &:focus {
                min-height: 90px;
            }
        }
    }
}

div#started div {
    color: #219653;
}

#started {
    background: #DDFFE2;
    @include themed{
        background: #DDFFE2 !important;
    }
}

div#inqueue div {
    color: #2F80ED;
}

#inqueue {
    background: #E8F1FD;
}

div#senttoinsurer div {
    color: #F2994A;
}

#senttoinsurer {
    background: #FFE8D8;
}

div#upcomingrenewal div {
    color: #EB5757;
}

#upcomingrenewal {
    background: #FFE1E1;
}

div#completed div {
    color: #FF1493;
}

#completed {
    background: #FFC0CB;
}

div#pendingwithinsurer div {
    color: #DDFFE2;
}

#pendingwithinsurer {
    background: #219653;
}

div#endorsementrequestsent div {
    color: #F27900;
}

#endorsementrequestsent {
    background: #FFBE7C;
}

div#claimrequestsent div {
    color: #FFCC00;
}

#claimrequestsent {
    background: #FFFFE0;
}

div#Highest div {
    color: #EB5757;
}

div#High div {
    color: #F27900;
}

div#Lowest div {
    color: #2F80ED;
}

div#Low div {
    color: #219653;
}

div#Normal div {
    color: #FFBE7C;
}