@import "../../../../mixins.scss";

.menus {
  position: relative;

  // margin-top: 13px;
  button {
    border: none;
    font-size: 10px;
    font-family: "Montserrat", sans-serif;
    line-height: 14px;
    color: rgba($color: #000000, $alpha: 0.5);
    background-color: transparent;
    text-align: left;
    position: relative;
    padding: 0px 0px 8px 62px;
    min-height: 60px;
    max-width: 138px;

    &:hover{
      z-index: 99;
    }

    @include themed() {
      color: $text-grey;
    }

    p {
      @include fontSize(12px);
      span {
        text-decoration: underline;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:before {
      content: "";
      position: absolute;
      border-radius: 16px;
      left: 0px;
      top: 0px;
      height: 50px;
      width: 50px;
    }

    $othericons: dashboard, policywallet, riskmap, userprofile, mydocument,
      claimassistant, referafriend, trendingquestions, community, support,
      wellness, doctoronline, repairvehicle, settings, employe, report,
      endorsement, quote, policies, clients, contacts, taskmanagement;
    $k: 0;

    @each $icons in $othericons {
      $bacRightP: $k * 50;
      $k: $k + 1;

      &.#{$icons} {
        &:before {
          background-image: url("../../../../../public/images/tablist_icons.svg");
          background-position: -#{$bacRightP}px 0px;
          background-size: 1100px;
          background-repeat: no-repeat;

          @include themed() {
            background-image: url("../../../../../public/images/tablist-active-icons.svg");
            opacity: 0.3;
          }
        }

        &.active {
          color: #333;

          @include themed() {
            color: $white;
          }

          &:before {
            background-color: #333;
            background-image: url("../../../../../public/images/tablist-active-icons.svg");
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
            opacity: 1;

            @include themed() {
              @include box-shadow(0px 0px 16px rgba($black, 0.4));
            }
          }
        }
      }
    }
  }

  .subMenu {
    background: linear-gradient(180deg, #fff 0%, #f0effc 12.95%, #f0effc 100%);
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 9px 7px;
    z-index: 99;
    height: 100%;
    width: calc(100% - 50px);
    z-index: 100;

    @include themed() {
      background: $black;
    }

    ul {
      margin: 0px;
      padding: 0px;

      li {
        margin-bottom: 14px;
        list-style-type: none;
        font-family: "Montserrat", sans-serif;
        @include fontSize(10px);
        font-weight: 600;
        

        &.product {
          border-bottom: 1px solid #333;
          padding-bottom: 6px;
          margin-bottom: 15px;
          font-weight: 700;
          font-size: 12px;

          a{
            font-weight: 700;
          }

          @include themed() {
            color: $text-light;
            border-color: $bg-grey;
          }
        }

        a {
          @include fontSize(10px);
          font-family: "Montserrat", sans-serif;
          font-weight: 500;
          line-height: 14px;
          display: inline-block;
          color: #000;
          padding: 6px 0px;

          @include themed() {
            color: $text-light;
          }

          &:hover {
            color: $primary;
          }

          &.active {
            font-weight: 600;
            color: $primary;
          }
        }
      }
    }
  }
}
