@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap");
@import "./mixins.scss";

html {
  scroll-behavior: auto;
}
body {
  background: #fff !important;
}
h1,
h2,
h3,
h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;

  @include themed() {
    color: $text-light;
  }
}

h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;

  @include themed() {
    color: $text-light;
  }
}

.testingforserver {
  color: #000000;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: $white;
  font-family: "Roboto", sans-serif;
  height: 100%;
  margin: 0px;
  padding: 0px;
  @include fontSize(16px);
  overflow-x: hidden;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;

  @include themed {
    background-color: $bg-grey;
  }
}

$othericons: lifeicon, healthicon, taxicon;
$k: 0;

@each $icons in $othericons {
  $bacRightP: $k * 60;
  $k: $k + 1;

  .#{$icons} {
    // background: url("../images/nav-dropdown-icons.png")
    //   no-repeat -#{$bacRightP}px
    //   0px/480px;
    height: 60px;
    width: 60px;
  }
}

.w-100 {
  width: 100% !important;
}

a {
  cursor: pointer;
}

link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// Buttons
.ctaBtn {
  .submitBtn {
    background: $primary;
    @include border-radius(10px);
    color: $white;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    @include fontSize(16px);
    text-transform: capitalize;
    padding: 10px 40px;
    box-shadow: none;
    position: relative;
    min-width: 130px;

    &.fixedbtn {
      position: fixed;
      right: 36%;
      bottom: 1.5%;
    }

    &:hover {
      background: lighten($color: $primary, $amount: 8%);
      color: $white;
    }
  }

  .mediumBtn {
    @extend .submitBtn;
    font-weight: 400;
    padding: 6px 18px;
  }

  .smBtn {
    @extend .submitBtn;
    @include fontSize(12px);
    font-weight: 400;
    padding: 4px 12px;
  }

  .rejectBtn {
    background: #eb5757;
    border-radius: 10px;
    color: $white;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    padding: 10px 40px;

    &:hover {
      background: lighten($color: #eb5757, $amount: 8%);
      color: $white;
    }
  }

  .borderBtn {
    background: $white;
    border-radius: 10px;
    color: $primary;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    @include fontSize(16px);
    outline: 1px solid $primary;
    outline-offset: -1px;
    text-transform: capitalize;
    padding: 10px 40px;
    min-width: 130px;

    &:hover {
      // background: lighten($color: $primary, $amount: 8%);
      background: #2160aa;
      outline: 1px solid transparent;
      color: $white;
    }

    &.smBtn {
      @extend .borderBtn;
      @include fontSize(12px);
      font-weight: 400;
      padding: 4px 12px;
    }
  }

  .cancelBtn {
    background: #fff;
    @include border-radius(10px);
    color: #252525;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    @include fontSize(16px);
    text-transform: capitalize;
    padding: 9px 39px;
    box-shadow: none;
    position: relative;
    min-width: 130px;
    outline: 1px solid $primary;
    outline-offset: -1px;

    &:hover {
      background: $primary;
      color: #ffffff;
    }
  }

  // loader
  .MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;
  }
}

.status-btn {
  font-family: "Montserrat";
  @include fontSize(12px);
  line-height: 15px;
  font-weight: 500;
  display: inline-block;
  @include border-radius(40px);
  padding: 4px 10px;
  color: $white;

  &.bg-red {
    background-color: #eb5757 !important;
  }

  &.bg-green {
    background-color: #219653 !important;
  }

  &.bg-blue {
    background-color: $primary !important;
  }

  &.bg-orange {
    background-color: #f2994a !important;
  }
}

.textBtn {
  font-family: "Montserrat" !important;
  font-size: 11px !important;
  line-height: 15px !important;
  font-weight: 500 !important;
  padding: 4px 0px 4px 28px;
  display: inline-block;
  margin-left: 28px !important;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    height: 24px;
    width: 24px;
    border-radius: 6px;
    z-index: 1;
  }
}

.text-md-center {
  @media (max-width: 992px) {
    text-align: center !important;
  }
}

.text-sm-center {
  @media (max-width: 768px) {
    text-align: center !important;
  }
}

.truncate_text {
  overflow: hidden;
  text-overflow: ellipsis;
}

// $box-colors: ("blueBtn", $primary), ("greenBtn", #219653), ("blackBtn", #000000),
//   ("redBtn", #eb5757), ("lightblueBtn", #acd4fb);
$box-colors: ("blueBtn", $primary), ("greenBtn", #219653), ("blackBtn", #000000),
  ("redBtn", #eb5757), ("redBtnlight", #f18989), ("lightblueBtn", #acd4fb),
  ("orangeBtn", #f2994a);
$k: 0;

@each $color in $box-colors {
  $k: $k + 1;

  .#{nth($color,1)} {
    @extend .textBtn;
    color: nth($color, 2) !important;

    &:after {
      background: nth($color, 2);
    }

    &:hover {
      &:after {
        box-shadow: 0px 8px 12px rgba(nth($color, 2), 0.2);
      }
    }

    $button-icons: docs, addfile, delete, upload, settings, download, mail,
      addperson, filters, message, edit, view, righttick, cross, reload,
      downloadbox, location, replace_icon, attatch;
    $k: 0;

    @each $icons in $button-icons {
      $bacRightP: $k * 24;
      $k: $k + 1;

      &.#{$icons} {
        &:before {
          @include position(absolute, $left: 0px, $top: 0px);
          content: "";
          height: 24px;
          width: 24px;
          background: url("../public/images/text_button_icons.svg");
          background-position: -#{$bacRightP}px 0px;
          background-size: 504px;
          z-index: 2;
        }

        &:focus,
        &:visited,
        &:link,
        &:active,
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.blackBtn {
  @include themed() {
    color: $text-light !important;
  }
}

.clearFilter {
  background: #f1f1f1 url("../public/images/cross_solid.svg") no-repeat right
    10px center/7px;
  @include border-radius(4px);
  color: $black !important;
  font-weight: 500;
  @include fontSize(11px);
  padding: 6px 22px 6px 12px;
}

.rounded_add_icons {
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 15px;
  font-weight: 500;
  padding: 4px 0px 4px 28px;
  display: inline-block;
  margin-left: 28px !important;
  position: relative;
  color: $primary !important;
  text-decoration: none !important;

  &:before {
    content: "";
    @include position(absolute, $left: 10px, $top: calc(50% - 8px));
    height: 16px;
    width: 16px;
    background: url("../public/images/round-add-icon.svg") no-repeat 0px 0px /16px;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.align-self-center {
  align-self: center;
}

.df {
  display: flex;
}

// Margins
@for $i from 0 to 40 {
  .ml-#{$i} {
    margin-left: 4px * $i !important;
  }

  .mr-#{$i} {
    margin-right: 4px * $i !important;
  }

  .mt-#{$i} {
    margin-top: 4px * $i !important;
  }

  .mb-#{$i} {
    margin-bottom: 4px * $i !important;
  }

  .mx-#{$i} {
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }

  .my-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
  }

  .m-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }
}

// Paddings
@for $i from 0 to 40 {
  .pl-#{$i} {
    padding-left: 4px * $i !important;
  }

  .pr-#{$i} {
    padding-right: 4px * $i !important;
  }

  .pt-#{$i} {
    padding-top: 4px * $i !important;
  }

  .pb-#{$i} {
    padding-bottom: 4px * $i !important;
  }

  .px-#{$i} {
    padding-left: 4px * $i !important;
    padding-right: 4px * $i !important;
  }

  .py-#{$i} {
    padding-top: 4px * $i !important;
    padding-bottom: 4px * $i !important;
  }

  .p-#{$i} {
    padding-top: 4px * $i !important;
    padding-bottom: 4px * $i !important;
    padding-left: 4px * $i !important;
    padding-right: 4px * $i !important;
  }
}

.mx-3 {
  margin-right: 16px !important;
  margin-left: 10px !important;
}

.d-none {
  display: none !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 600 !important;
}

.fontMontserrat {
  font-family: "Montserrat";
}

.font-sm {
  font-size: 14px !important;
}

.animate__animated {
  &.animate__delay-500ms {
    animation-delay: 0.5s;
  }

  &.animate__delay-1500ms {
    animation-delay: 1.5s;
  }

  &.animate__delay-2500ms {
    animation-delay: 2.5s;
  }

  &.animate__delay-3500ms {
    animation-delay: 3.5s;
  }

  &.animate__delay-4500ms {
    animation-delay: 4.5s;
  }
}

.rupeeIcon {
  position: relative;

  input {
    padding-left: 25px !important;
    background: $white;

    &:focus {
      background: transparent;
    }
  }

  &:before {
    background: url("../public/images/inr_icon.svg") no-repeat 0px 0px/9px;
    @include position(absolute, $left: 7px, $top: 14px);
    content: "";
    height: 16px;
    width: 16px;
  }

  @include themed {
    &:before {
      background: url("../public/images/inr_icon_white.svg") no-repeat 0px 0px/9px;
      @include position(absolute, $left: 7px, $top: 14px);
      content: "";
      height: 16px;
      width: 16px;
      z-index: 999;
    }
  }
}

.display-block {
  display: block;
}

.inputField {
  position: relative;

  &.color-input {
    input {
      width: 25px !important;
      border: none !important;
      border-radius: 8px;
      height: 22px;
      color: transparent !important;
    }

    input[type="password"] {
      padding: 12px 47px 12px 15px;
    }
  }

  &.iconInputField {
    // position: relative;
    &:before {
      position: absolute;
      content: "";
      background: url("./../public/images/accordion_edit_icon.svg") no-repeat;
      top: 18px;
      right: 14px;
      width: 15px;
      height: 15px;
      opacity: 0;
      z-index: 999;
    }

    &:hover {
      &::before {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  svg {
    display: none;
  }

  .passwordView {
    @include position(absolute, $right: 8px, $top: 9px);
    padding: 4px 0px;
    color: $grey-light;
    z-index: 9;

    &:hover {
      color: $bg-grey;
    }

    svg {
      display: inherit;
      height: 14px;
    }
  }

  .MuiAutocomplete-root {
    background: url("../public/images/down_arrow.svg") no-repeat right 14px
      center/10px;

    @include themed() {
      background: $bg-grey url("./images/down_arrow_white.svg") no-repeat right
        14px center/10px;
      @include border-radius(8px);
    }

    // .MuiFormControl-root{
    //   background: $bg-grey;
    //   @include border-radius(8px);
    // }
  }

  .MuiSelect-select {
    background: url("../public/images/down_arrow.svg") no-repeat right 14px
      center/10px;
    text-align: left;
    padding: 7.8px 14px;
    @include fontSize(14px);
    @include border-radius(8px);
    font-family: "Montserrat";

    @include themed() {
      background: $bg-grey url("./images/down_arrow_white.svg") no-repeat right
        14px center/10px;
      color: $text-light;
      @include border-radius(8px);
    }
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.1);
    @include border-radius(8px);
    border-width: 1px !important;
    font-family: "Montserrat";
    font-weight: 500;
  }

  &.uploadFile {
    fieldset {
      border-radius: 8px 0px 0px 8px;
    }
  }

  .error {
    color: $primary-red;
    font-size: 11px;
    position: absolute;
    right: 0px;
    bottom: -16px;
  }

  .multi_inputs {
    .MuiChip-root {
      padding: 4px;
      height: 20px;

      .MuiChip-label,
      .MuiChip-labelMedium,
      .css-6od3lo-MuiChip-label {
        height: 20px;
        font-size: 10px;
      }
    }

    // MuiChip-filled
  }
}

.MuiGrid2-container {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
}

.error_msg {
  color: $primary-red;
  font-size: 11px;
  position: relative;
  right: -21px;
  bottom: 1px;
  display: inline-block;
}

.no_records {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $blue-light !important;
}

.MuiMenu-paper {
  @include themed() {
    background-color: $text-grey;
  }

  .MuiMenu-list {
    max-height: 200px;
    overflow-y: auto;
    // max-width: 300px;
    // scrollbar-width: thin;

    .MuiMenuItem-root {
      @include fontSize(13px);
      line-height: 18px;
      justify-content: stretch !important;
      padding: 8px 12px;
      width: 100%;
    }
  }
}

.switch {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 52px;
  height: 24px;
  background: #d9d9d9;
  @include border-radius(30px);
  transition: background 0.09s ease-in-out;
  color: $white;
  outline: none;
  transform: translate(0%, 0%);
  min-width: 52px;

  &:hover {
    cursor: pointer;
  }

  &:checked {
    background: $primary;
  }

  &::after {
    content: "";
    width: 20px;
    height: 20px;
    background: $white;
    @include border-radius(50%);
    @include position(absolute, $left: 2px, $top: 2px);
    transition: left 0.2s ease-in-out;
  }

  &:checked::after {
    left: 30px;
  }
}

.MuiFormControl-root {
  width: 100%;
}

.datagridTable {
  border: none !important;

  thead th:first-child {
    border-radius: 16px 0px 0px 0px;
  }

  thead th:last-child {
    border-radius: 0px 16px 0px 0px;
  }
}

.productTabs {
  border: 1px solid rgba(0, 0, 0, 0.1);
  @include border-radius(100px);
  display: inline-block;
  padding: 0px 8px;

  @include themed {
    border: 1px solid $blue-light;
  }

  .defaultTab {
    background: transparent;
    @include border-radius(100px);
    color: rgba(0, 0, 0, 0.5);
    position: relative;
    font-family: "Montserrat", sans-serif;
    @include fontSize(13px);
    text-transform: capitalize;
    padding: 6px 25px;
    font-weight: 500;
    position: relative;
    top: -1px;
    margin-bottom: -2px;

    @include themed() {
      color: $text-grey;
    }

    @media (max-width: 1250px) {
      padding: 6px 15px;
      @include fontSize(11px);
    }

    &.active {
      background: $primary;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
      color: $white;
      padding: 10px 25px;

      @media (max-width: 1250px) {
        padding: 10px 15px;
      }

      &:before {
        background-image: none;
      }
    }
  }

  $product-insurance: health, car, bike, term, investment, travel;
  $k: 0;

  @each $icons in $product-insurance {
    $bacRightP: $k * 24;
    $k: $k + 1;

    .#{$icons} {
      padding: 7px 27px 7px 55px;
      transition: all 0.3s ease-in-out;

      &:before {
        @include position(absolute, $left: 27px, $top: 9px);
        content: "";
        height: 20px;
        width: 20px;
        background-image: url("../public/images/claim_product_grey_icons.svg");
        background-position: -#{$bacRightP}px 0px;
        background-size: 140px;
      }

      &.active {
        background: $primary;
        border: 0.5px solid $primary;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
        color: $white;
        transition: all 0.3s ease-in;

        &:before {
          background-image: url("../public/images/claim_product_white_icons.svg");
          background-position: -#{$bacRightP}px 0px;
        }
      }
    }
  }
}

.selector {
  display: flex;
  height: 38px;
  max-width: 440px;
  // align-items: center;
  // justify-content: center;
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 17px;
  color: $black;
  position: relative;

  &:after {
    @include border-radius(10px);
    @include position(absolute, $left: 0px, $top: 0px);
    content: "";
    border: 1px solid $black;
    width: calc(100% - 4px);
    height: calc(100% - 2px);
    z-index: 0;
  }

  button {
    position: relative;
    z-index: 1;
    font-family: "Montserrat";
    @include fontSize(14px);
    text-transform: capitalize;
  }

  .selection {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    width: 100%; // to flexbox this will keep all widths the same. flexbox is just funny like that.
    margin-left: -1px;
    background-clip: border-box;
    transform: scale3d(1, 1, 1); // weird hack to prevent pixel-y borders
    font-family: "Montserrat";
    @include fontSize(14px);
    line-height: 17px;
    background: rgba(172, 212, 251, 1);
    color: $black;
    transition: 0.15s ease-in-out background-color;

    @include themed() {
      background: $bg-grey;
    }

    &.selected {
      background: transparent !important;
      z-index: 2;
      @include fontSize(14px);
      line-height: 17px;
      color: $black;
      font-weight: 600;

      @include themed() {
        background: $white;
      }
    }

    &:hover {
      background: rgba(172, 212, 251, 1);

      @include themed() {
        background: rgba(0, 0, 0, 0.5);
      }
    }

    // add some borders!
    &:first-child {
      margin-left: -1;
      border-radius: 10px 0 0 10px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
}

.selector.width-per-item {
  // width: auto;
  margin: 0px auto;

  .selection,
  button {
    //min-width: 185px;
    max-width: 100%;
    width: 290px;
    padding: 0px 10px;
    font-weight: 500;

    @include themed() {
      color: $text-grey !important;
    }
  }
}

.text-left {
  text-align: left !important;
}

// Popup
.modalWrapper {
  overflow-y: auto;
  backdrop-filter: blur(4px);
  background: rgba($black, 0.2);

  .MuiBackdrop-root {
    background: transparent;
  }

  .modalInner {
    @include box-shadow(0px 4px 16px rgba($black, 0.2));
    position: relative;
    margin: 60px auto;
    width: 582px;
    @include border-radius(20px);
    background: $white;
    padding: 40px;

    &.currencypopup {
      margin: 0px auto;
      border-radius: 0px 0px 20px 20px;
      width: 659px;
      padding: 30px 53px;
    }

    &.deletepopup {
      width: 450px;
      padding: 32px 25px;
      position: relative;
      padding-top: 300px;

      &::before {
        @include position(absolute, $left: 0px, $top: 0px);
        content: "";
        background: url("./../public/images/deletePopup_bg.svg") no-repeat;
        width: 100%;
        height: 262px;
        border-radius: 18px;
      }

      .close-button {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        color: white;
        top: 12px;
        right: 12px;

        &:after,
        &:before {
          background: white;
        }
      }

      .img-prt {
        @include position(absolute, $right: calc(50% - 50px), $top: 100px);
        background: linear-gradient(
          229.3deg,
          $primary 3.47%,
          rgba(255, 255, 255, 0) 96.99%
        );
        filter: drop-shadow(-2px 2px 0px rgba(255, 255, 255, 0.3));
        border-radius: 27px;
        width: 100px;
        box-sizing: border-box;
        height: 100px;

        // &:before{
        //   @include position(absolute, $right:calc(50% - 25px), $top: calc(50% - 25px));
        //   content: '';
        //   background: url('./../public/images/deletepopup_deleteicon.svg')no-repeat;
        //   width: 50px;
        //   height: 50px;
        // }
        .img {
          @include position(
            absolute,
            $right: calc(50% - 25px),
            $top: calc(50% - 25px)
          );
          content: "";
          width: 50px;
          height: 50px;
        }
      }
    }

    @include themed() {
      background: $bg-grey;
    }

    &.md-width {
      width: 624px;
      max-width: 100%;
    }

    &.lg-width {
      width: 650px;
      max-width: 650px;
    }

    &.xl-width {
      width: 882px;
      max-width: 882px;
    }

    &.mob {
      margin: 0 auto;
      width: 100%;
      background: $white;
      padding: 20px;
      @include border-radius(0px);
      height: 100%;
    }

    .back-button {
      display: inline-block;
      position: relative;
      $size: 22px;
      height: $size;
      width: $size;
      // margin-right: 8px;

      &:before,
      &:after {
        background-color: $grey-dark;
        @include border-radius(8px);
        content: "";
        @include position(absolute, $left: 0px, $top: 7px);
        $width: 12px;
        $height: 2.5px;
        height: $height;
        width: $width;
        transform: rotate(-45deg);
        transition: all 0.5s ease;

        @include themed() {
          background: $text-grey;
        }
      }

      &:after {
        transform: rotate(-135deg);
        top: 14px;
      }

      &:hover {
        &:before,
        &:after {
          // background: $primary-orange;
          left: -4px;
        }
      }
    }

    .popup-heading {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(20px);
      line-height: 24px;
      text-align: center;
      margin-bottom: 20px;
    }

    .currencyData {
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(14px);
      line-height: 24px;
      margin-bottom: 20px;
      margin-top: 20px;
      color: $black;
      position: relative;
      text-align: left;
      padding-left: 56px;

      @include themed() {
        color: $text-light;
      }

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $top: 0px);
        height: 48px;
        width: 48px;
        background: url("../public/images/currencydata_icon.svg") 0px 0px/48px;
      }
    }

    .steps {
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0px;
      @include position(absolute, $right: 16px, $top: 23px);

      @include themed {
        color: $white;
      }
    }

    .radio_group {
      display: flex;

      .MuiFormGroup-root {
        flex-direction: row;
        // .MuiSvgIcon-root{
        //   color: $primary;
        // }
      }

      .MuiFormControlLabel-root {
        // width: 50px;
        .MuiRadio-root {
          color: $grey-light;

          &:hover {
            background-color: transparent;
          }
        }

        .MuiRadio-root.Mui-checked {
          color: $primary;
        }

        .MuiSvgIcon-root {
          font-size: 16px;
        }

        .MuiFormControlLabel-label {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          @include fontSize(14px);
          line-height: 24px;

          @include themed {
            color: $text-light;
          }
        }
      }
    }

    .add_tab {
      &.switchFields {
        span {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(12px);
          line-height: 15px;
          padding-right: 24px;

          @include themed {
            color: $text-light;
          }
        }
      }
    }

    .tab_noteheading {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 0;
    }
  }

  .modalBig {
    @extend .modalInner;
    width: 800px;
    padding: 20px 40px;

    .close-button {
      @include position(absolute, $right: 20px, $top: 27px);
    }
  }

  // .back-button {
  //   background: url("../public/images/back_arrow.svg") no-repeat 0px center/18px;
  //   position: absolute;
  //   top: 31px;
  //   left: 40px;
  //   height: 40px;
  //   width: 60px;
  //   cursor: pointer;

  //   &:hover {
  //     left: 35px;
  //     transition-duration: 1s;
  //   }
  // }

  .back {
    background: url("../public/images/left-arrow.svg") no-repeat 0px center/9px;
    @include position(absolute, $left: 20px, $top: 33px);
    height: 13px;
    width: 9px;
    cursor: pointer;
  }

  .close-button {
    background: transparent;
    color: $black;
    border-radius: 50px;
    @include position(absolute, $right: 40px, $top: 37px);
    $size: 30px;
    height: $size;
    width: $size;
    box-sizing: border-box;
    line-height: $size;
    z-index: 99;

    &:before,
    &:after {
      $width: 20px;
      $height: 1.5px;
      transform: rotate(-45deg);
      content: "";
      @include position(absolute, $right: 5px, $top: 14px);
      height: $height;
      width: $width;
      background-color: $black;
      transition: all 0.2s ease;

      @include themed() {
        background-color: $white;
      }
    }

    &:after {
      transform: rotate(-135deg);
    }

    &:hover {
      &:before {
        background-color: #de4b4b;
        transform: rotate(0deg);
      }

      &:after {
        background-color: #de4b4b;
        transform: rotate(-180deg);
      }
    }
  }

  .close {
    background: $white;
    color: $black;
    border-radius: 50px;
    position: absolute;
    right: 20px;
    top: 20px;
    $size: 30px;
    height: $size;
    width: $size;
    box-sizing: border-box;
    line-height: $size;
    z-index: 99;

    &:before,
    &:after {
      $width: 20px;
      $height: 1.5px;
      transform: rotate(-45deg);
      content: "";
      position: absolute;
      right: 7px;
      top: 14px;
      height: $height;
      width: $width;
      background-color: $black;
      transition: all 0.2s ease;
    }

    &:after {
      transform: rotate(-135deg);
    }

    &:hover {
      &:before {
        background-color: #de4b4b;
        transform: rotate(0deg);
      }

      &:after {
        background-color: #de4b4b;
        transform: rotate(-180deg);
      }
    }
  }

  .popup-heading {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;

    @include themed() {
      color: $text-light;
    }
  }

  .popup-main-heading {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding-top: 10px;
    margin-bottom: 24px;
    padding-left: 20px;
  }

  .greeting-text {
    font-family: "Montserrat";
    font-weight: 600;
    @include fontSize(16px);
    line-height: 24px;
    margin-top: 30px;
  }

  .subHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    @include fontSize(14px);
    line-height: 24px;
    margin-bottom: 10px;
  }

  .textarea {
    border-radius: 5px 0 0 5px;
  }

  .small-text {
    font-family: "Montserrat";
    font-weight: 400;
    @include fontSize(8px);
    line-height: 8px;
    text-align: center;
    margin: 20px 0px 0px;
  }

  .browsebtn {
    font-family: "Montserrat";
    font-weight: 500;
    @include fontSize(14px);
    line-height: 24px;
    background: $primary !important;
    padding: 7px 20px;
    border-radius: 0 10px 10px 0;
    text-transform: capitalize;
    color: $white;
    text-align: center;
    display: inline-block;

    &:hover {
      background: $primary;
      color: $white;
    }
  }

  .main-border-ncb {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    display: inline-block;
    height: 40px;
    margin: 10px 0 10px 0;

    .ncbSelect {
      color: $white;
      background-color: $primary;
      padding: 6px 20px !important;
      font-size: 15px;
      border-radius: 50px;
      text-align: center;
      position: relative;
    }

    .ncb {
      padding: 6px 20px !important;
      font-size: 15px;
      border-radius: 50px;
      text-align: center;
      position: relative;
      color: $black;
    }
  }

  .popup-subheading {
    font-family: "Montserrat";
    font-weight: 500;
    @include fontSize(14px);
    line-height: 24px;
    text-align: center;
    margin-bottom: 0px;
  }

  .border-div {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    display: inline-block;
    height: 43px;
  }

  .sm-link {
    font-family: "Montserrat";
    font-weight: 500;
    @include fontSize(11px);
    line-height: 20px;
    text-align: center;
    color: $primary;
    display: block;
    margin-bottom: 10px;

    &:hover {
      text-decoration: none;
    }
  }

  .md-link {
    @extend .sm-link;
    font-weight: 400;
    @include fontSize(16px);
    line-height: 22px;

    &:hover {
      text-decoration: none;
    }
  }

  .sm-font {
    font-family: "Montserrat";
    font-weight: 400;
    @include fontSize(11px);
    line-height: 20px;
    text-align: center;
    color: $black;
    margin-bottom: 10px;
  }

  .policy-sm {
    font-family: "Montserrat";
    font-weight: 400;
    @include fontSize(11px);
    line-height: 20px;
    text-align: center;
    color: $black;
    margin: 10px 0;

    Link {
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(11px);
      line-height: 20px;
      color: $primary;
      text-decoration: none !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .regno {
    background: url("../public/images/ind.svg") no-repeat 0px 0px/40px;
    border-radius: 6px 0 0 6px;

    input {
      background: transparent;
      border: 0;
      height: 45px;
      width: 100%;
      @include fontSize(24px);
      font-weight: 700;
      padding: 5px 10px 5px 50px;
      @include box-shadow(0px 0px 0px transparent);
      text-transform: uppercase;

      &:focus {
        outline: 0;
      }
    }
  }

  .memberDetails {
    display: flex;
    margin-top: 8px;
    margin-bottom: 20px;
    padding-left: 20px;

    .memberIcon {
      background: #eeeeee;
      border-radius: 100px;
      margin-right: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      height: 60px;
      width: 60px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .memberName {
      align-self: center;
      max-width: 100%;

      h6 {
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(14px);
        line-height: 17px;
        margin-bottom: 8px;

        span {
          font-family: "Montserrat";
          font-weight: 400;
          @include fontSize(12px);
          margin-bottom: 8px;
        }
      }

      a {
        color: $primary;
        margin-bottom: 8px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        @include fontSize(14px);
      }
    }

    .actionBtns {
      margin-left: auto;
      display: flex;
    }
  }
}

.browsebtn {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: $primary !important;
  padding: 16px 20px;
  border-radius: 0 10px 10px 0;
  text-transform: capitalize;
  color: $white;
  text-align: center;
  display: inline-block;

  &:hover {
    background: $primary;
    color: $white;
  }
}

.searchSection {
  .searchField {
    background: transparent;
    border: none;
    @include fontSize(16px);
    height: 40px;
    width: 100%;

    &:focus,
    &:focus-visible {
      outline: none;
    }

    @include themed() {
      color: $white;
    }
  }

  .searchProductType {
    min-height: 16px;
    // padding: 6px 0px 10px;

    button {
      border: none;
      padding: 2px 4px;
      text-transform: capitalize;
      font-weight: 400;
      margin-right: 12px;

      @include themed() {
        color: $text-grey;
      }

      &:hover {
        @include themed() {
          background: $bg-grey;
        }
      }

      &.Mui-selected {
        background: transparent;
        color: $primary;
      }
    }

    .MuiTabs-indicator {
      background: $primary;
    }
  }

  .resultTitle {
    border-top: 1px solid $text-light;
    border-bottom: 1px solid $text-light;
    font-weight: 500;
    padding: 8px 0px;
    margin-top: 16px;

    @include themed() {
      border-color: $grey-light;
      color: $grey-light;
    }
  }

  .allResults {
    margin-top: 0px;
    max-height: 360px;
    min-height: 160px;
    overflow-y: auto;
  }

  .searchResult {
    text-decoration: none;

    h5 {
      @include fontSize(16px);
      color: $grey-dark;

      @include themed() {
        color: $text-light;
      }
    }

    ul {
      display: flex;
      margin: 0px;
      padding: 0px 0px 0px 12px;

      li {
        list-style-type: square;
        @include fontSize(12px);
        color: $grey-light;
        padding-right: 24px;
      }
    }
  }
}

// Coming Soon
.comingsoon {
  // position: absolute;
  // left: 54.5%;
  // transform: translate(-50%, -50%);
  // margin-top: 50px;
  h1 {
    color: $primary;
    font-size: 40px;
  }

  span {
    transition: all 0.4s ease;

    &:before {
      content: "";
      animation: animate infinite 4s;
      transition: all 0.4s ease;
    }
  }

  // .load {
  //   background: #000;
  //   color: #000;
  //   height: 5px;
  // }
}

@keyframes animate {
  0% {
    content: "C";
  }

  10% {
    content: "Co";
  }

  20% {
    content: "Com";
  }

  30% {
    content: "Comi";
  }

  40% {
    content: "Comin";
  }

  50% {
    content: "Coming";
  }

  60% {
    content: "Coming S";
  }

  70% {
    content: "Coming So";
  }

  80% {
    content: "Coming Soo";
  }

  90% {
    content: "Coming Soon";
  }

  100% {
    content: "Coming Soon!";
  }
}

.MuiDataGrid-filterForm {
  .MuiDataGrid-filterFormDeleteIcon {
    width: 26px;
  }
}

.datagridTable {
  .MuiDataGrid-main {
    font-size: 12px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;

    .MuiDataGrid-columnHeaders {
      background: #eef6fe;
      //border-radius: 16px 16px 0px 0px;
      border: none;

      @include themed() {
        background: $bg-grey;
        color: $text-light;
      }
    }

    .MuiDataGrid-virtualScrollerRenderZone {
      @include themed() {
        background: $black;
        color: $text-light;
      }
    }

    .MuiDataGrid-iconSeparator {
      display: none;
    }

    .MuiDataGrid-row {
      &:nth-child(even) {
        background: #f8f8f8;

        @include themed() {
          background: $bg-grey;
          color: $text-light;
        }
      }

      &:hover {
        background: rgba(107, 98, 220, 0.15);
      }

      .MuiDataGrid-cell {
        border: none;
      }
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;

      @include themed() {
        color: $text-grey;
      }
    }

    .MuiCheckbox-root {
      padding: 2px;
      color: $primary-color;

      &.Mui-checked {
        svg {
          color: $primary-color;
        }
      }
    }
  }

  .MuiDataGrid-footerContainer {
    border: none !important;

    .MuiTablePagination-toolbar {
      @include themed() {
        color: $text-grey;
      }

      button {
        @include themed() {
          color: $text-grey;
        }
      }
    }
  }
}

.actiondelete {
  position: relative;
  display: inline-block;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    background: url("../public/images/delete_icons.svg") 0px 0px /12px;
    height: 12px;
    width: 12px;
  }
}

.actionedit {
  position: relative;
  display: inline-block;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    background: url("../public/images/edit_iconss.svg") 0px 0px /12px;
    height: 12px;
    width: 12px;
  }
}

.actionremove {
  position: relative;
  display: inline-block;
  height: 16px;
  width: 16px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url("../public/images/remove_icons.svg") 0px 0px /12px;
    height: 12px;
    width: 12px;
  }
}

.addtaskaction {
  position: relative;
  display: inline-block;
  width: 16px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -16px;
    background: url("../public/images/add_icon.svg") 0px 0px /16px;
    height: 16px;
    width: 16px;
  }
}

.themeToggle {
  background: rgba($primary, 0.15);
  @include border-radius(20px);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;

  @include themed() {
    background: $black;
  }

  svg {
    height: 18px;
    color: $primary;
  }
}

.checkbox_heading {
  font-size: 20px;
  line-height: 24px;
  font-family: "Montserrat";
  margin-bottom: 0;
  font-weight: 600px;

  @include themed {
    color: $white;
  }
}

// tick icon css
.tick_animation {
  position: relative;
  top: 40vh;

  .quote_success {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    font-weight: 500;
    font-family: "Montserrat";
    margin-bottom: 20px;
    color: $black;

    @include themed() {
      color: $white !important;
    }
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: green;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: green;
  stroke-miterlimit: 10;
  margin: 1% auto 3%;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $white;
  }
}

.address {
  align-self: center;

  p {
    font-size: 16px;
    line-height: 20px;
    font-family: "Montserrat";
    font-weight: 500;
    margin-bottom: 0;

    @include themed {
      color: #ddd;
    }
  }
}

.dataList {
  // Status
  $status-colors: ("Active", #219653), ("Pending", #ff9b44),
    ("Rejected", #eb5757), ("Inprogress", #4293e4), ("Expired", #eb5757);
  $k: 0;

  @each $color in $status-colors {
    $bacRightP: $k * 15;
    $k: $k + 1;

    .#{nth($color,1)} {
      border: 1px dashed nth($color, 2);
      @include border-radius(20px);
      color: nth($color, 2);
      @include fontSize(12px);
      padding: 3px 12px 3px 30px;
      position: relative;

      &:before {
        @include position(absolute, $left: 10px, $top: 4px);
        content: "";
        background: url("../public/images/status_icons.svg")
          no-repeat -#{$bacRightP}px
          0px/105px;
        height: 15px;
        width: 15px;
      }
    }
  }
}

.dashboardWrapper {
  .shortcutBtn {
    background: $primary-red url("../public/images/shortcut_icon.svg") no-repeat
      center center/20px;
    @include border-radius(8px);
    @include box-shadow(0px 6px 20px rgba($primary-red, 0.5));
    @include position(fixed, $bottom: 16px, $right: 64px);
    transition: all 0.3s ease;
    min-width: 20px;
    height: 38px;
    width: 38px;
    z-index: 99;

    &:hover {
      background: $primary-red url("../public/images/shortcut_icon.svg")
        no-repeat center center/20px;
      @include border-radius(20px);
    }
  }
}

// Sliding panel
.sliding-panel {
  .normal-text {
    font-weight: 400;
    font-size: 12px;
    font-family: "Montserrat";
  }

  .small-text {
    @extend .normal-text;
    font-size: 8px;
    text-align: center;
    margin: 20px 0px 0px;
  }

  .browsebtn {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    background: $primary !important;
    padding: 7.5px 25px;
    border-radius: 0 10px 10px 0;
    text-transform: capitalize;
    color: $white;
    text-align: center;
    display: inline-block;
    box-shadow: none;

    &:hover {
      background: $primary;
      color: $white;
    }
  }
}

.actiondelete {
  position: relative;
  display: inline-block;
  background: url("../public/images/delete_icons.svg") 0px 0px /20px;
  height: 20px;
  width: 20px;
  margin-right: 20px !important;
  // &:before {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   background: url("../public/images/delete_icons.svg") 0px 0px /12px;
  //   height: 12px;
  //   width: 12px;
  //   display: inline-block;
  // }
}

.actionedit {
  position: relative;
  display: inline-block;
  background: url("../public/images/edit_iconss.svg") 0px 0px /20px;
  height: 20px;
  width: 20px;

  // &:before {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   background: url("../public/images/edit_iconss.svg") 0px 0px /12px;
  //   height: 12px;
  //   width: 12px;
  //   display: inline-block;
  // }
}
.password-rules-list {
  list-style-type: disc; /* You can use 'circle', 'square', 'none', or 'decimal' for numbers */
  padding-left: 20px; /* Adds some indentation to the list */
  margin-top: 10px;
  line-height: 1.5; /* Improves readability by increasing space between lines */
}

.password-rules-list li {
  margin-bottom: 8px; /* Adds space between list items */
  font-size: 14px; /* Adjust the size of the text */
  color: #333; /* Choose a neutral text color for readability */
}

//common accordion

.common_accordion {
  // @keyframes fadeIn {
  //   0% {
  //     opacity: 0;
  //     transform: translate3d(0, -3%, 0)
  //   }

  //   to {
  //     opacity: 1;
  //     transform: translateZ(0)
  //   }
  // }

  .Accordion_section {
    background: $white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    @include border-radius(12px !important);
    margin-bottom: 10px;
    animation: fadeIn 1s both;

    @include themed {
      background-color: $bg-dark;
      border: 1px solid $bg-grey;
    }

    &:before {
      background: transparent;
    }

    &.Mui-expanded {
      margin: 10px 0;
    }

    .MuiAccordionSummary-root {
      .MuiAccordionSummary-content {
        margin: 12px 0;

        .title {
          font-family: "Roboto", sans-serif;
          @include fontSize(14px);
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 0;
          align-self: center;
          color: $primary;
        }
      }

      &.Mui-expanded {
        max-height: 48px;
        min-height: 48px;

        @extend .title;
      }
    }
  }
}

.uploadAttachmentInfo span {
  color: #219653;
  font-size: 0.75rem;
  line-height: 12px;
  font-weight: 500;
  padding-left: 4px;
  font-family: "Montserrat";
  width: 100% !important;
}

p.describe-text {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.custom-height {
  min-height: calc(100vh - 300px) !important;
}

.primary-text {
  color: $primary !important;
}

// .MuiPaper-root {
//   opacity: 1;
//   transform: none;
//   min-width: 174px;
//   transition: opacity 240ms cubic-bezier(0.4, 0, 0.2, 1),
//     transform 160ms cubic-bezier(0.4, 0, 0.2, 1);
//   //top: 266px !important;
//   //left: 1270px !important;
//   //transform-origin: 989.156px 0px;
//   box-sizing: border-box;
//   ul.MuiList-root {
//     width: 580px !important;
//     li {
//       background: yellow !important;
//       color: green !important;
//       word-break: break-all !important;
//       word-spacing: normal !important;
//       overflow-wrap: wrap !important;
//     }
//   }
// }

// .MuiPaper-root {
//   opacity: 1;
//   transform: none;
//   min-width: 174px;
//   transition: opacity 240ms cubic-bezier(0.4, 0, 0.2, 1),
//     transform 160ms cubic-bezier(0.4, 0, 0.2, 1);
//   // top: 266px !important;
//   //left: 1270px !important;
//   // transform-origin: 989.156px 0px;
//   box-sizing: border-box;

//   ul.MuiList-root {
//     width: 580px !important;

//     li {
//       word-break: break-all !important;
//       overflow-wrap: break-word !important; /* wrap is not valid; use break-word */
//       white-space: normal !important; /* Ensures text wraps */
//     }
//   }
// }

// .swal2-confirm {
//   background: #2160aa important;
// }
