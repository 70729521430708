// Color Variables
// $primary: #2160AA;
// $white: #ffffff;

// $primary-color: #2160AA;
// $primary-light: #e5e7fe;
// $blue-light: #E4F1FF;
// $grey-light: #828282;
// $grey-dark: #3a4855;
// $primary-red: #eb5757;
// $primary-orange: #fc6509;
// $text-light: #ddd;
// $text-grey: #999;
// $bg-grey: #333;
// $bg-dark: #171b1e;
// $black: #000;
// $sky-blue: #3569B6;
// $disable-red: rgba(#eb5757, 0.5);
// $primary-yellow: #FFA412;
// $text-dark:#30364D;
// $primary-green: #27AE60;

// // Mobile colors
// $m-dark:#2F2E3E;
// $m-grey:#46495E;
// $m-orange:#F68479;
// $m-pink:#CE5D9A;
// $m-cyan:#2FB3BC;
$primary: #2160aa;
$white: #ffffff;

$primary-color: #3569b6;
$primary-light: rgb(217, 220, 255);
$blue-light: #e4f1ff;
$blue-light-2: rgba(228, 241, 255, 0.75);
$grey-light: #828282;
$grey-dark: #3a4855;
$primary-red: #ec3f3f;
$primary-orange: #fc6509;
$text-light: #ddd;
$text-grey: #00000080;
$bg-grey: #333;
$bg-dark: #171b1e;
$black: #000;
$sky-blue: #3569b6;
$disable-red: rgba(#eb5757, 0.5);
$primary-yellow: #ffa412;
$text-dark: #30364d;
$primary-green: #029200;

// Mobile colors
$m-dark: #2f2e3e;
$m-grey: #46495e;
$m-orange: #f68479;
$m-pink: #ce5d9a;
$m-cyan: #2fb3bc;

/*--------------Mixins------------------*/
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin border-radius($radius: none) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin appearance() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  //font-size: $size;
  font-size: calculateRem($size);
}

@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin abs-position($top, $right, $bottom, $left) {
  // @include abs-position(10px, 10px, auto, auto);
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// Theme Color Change
$themes: (
  dark: (
    // card-bg: $bg--card--rahul,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}
