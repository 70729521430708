@import "../../../../../../mixins.scss";

.removePopupWrapper {
  background: rgba($black, 0.3);
  @include position(fixed, $top: 0px, $left: 0px);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 991;
  .innerWrapper {
    margin: 24px auto;
    transition: transform 0.3s ease-out;
    display: flex;
    align-items: center;
    min-height: calc(100% - 48px);
    max-width: 400px;

    .popupContent {
      @include box-shadow(0px 4px 12px rgba($black, 0.2));
      background: $white;
      @include border-radius(12px);
      padding: 32px 24px;
      width: 100%;
      opacity: 0;
      animation: fadeInDown 1s ease-in-out 0s forwards;
      // animation-delay: 0.3s;
      @keyframes fadeInDown {
        from {
          opacity: 0;
          -webkit-transform: translate3d(0, -20%, 0);
          transform: translate3d(0, -20%, 0);
        }

        to {
          opacity: 1;
          -webkit-transform: none;
          transform: none;
        }
      }

      h4 {
        @include fontSize(20px);
        margin-bottom: 24px;
      }
      p {
        @include fontSize(14px);
        line-height: 18px;
      }
      .cancelBtn {
        @include border-radius(8px);
        color: $primary-red;
        font-family: "Montserrat";
        padding: 12px 24px;
      }
      .binImg {
        height: 140px;
        width: 140px;
        position: relative;
        margin: 0px auto 24px;
        .lid {
          @include position(absolute, $right: 40px, $top: 22px);
          z-index: 9;
          animation: swing ease-in-out 2s infinite alternate;
          transform-origin: bottom right;
        }
        .bin {
          height: 140px;
        }
        @keyframes swing {
          0% {
            transform: rotate(8deg);
          }
          100% {
            transform: rotate(-8deg);
          }
        }
      }

      svg {
        width: 100px;
        display: block;
        margin: 20px auto 40px;
        .path {
          stroke-dasharray: 1000;
          stroke-dashoffset: 0;
          &.circle {
            -webkit-animation: dash 1s ease-in-out;
            animation: dash 1s ease-in-out;
          }
          &.check {
            stroke-dashoffset: -100;
            -webkit-animation: dash-check 1s 1s ease-in-out forwards;
            animation: dash-check 1s 1s ease-in-out forwards;
          }
        }
        @-webkit-keyframes dash {
          0% {
            stroke-dashoffset: 1000;
          }
          100% {
            stroke-dashoffset: 0;
          }
        }

        @keyframes dash {
          0% {
            stroke-dashoffset: 1000;
          }
          100% {
            stroke-dashoffset: 0;
          }
        }

        @-webkit-keyframes dash-check {
          0% {
            stroke-dashoffset: -100;
          }
          100% {
            stroke-dashoffset: 900;
          }
        }

        @keyframes dash-check {
          0% {
            stroke-dashoffset: -100;
          }
          100% {
            stroke-dashoffset: 900;
          }
        }
      }
    }
  }
}
