@import "../../../../../mixins.scss";

.confirmation_wrapper {
  .row {
    max-width: 1424px;
    margin: 0 auto;
  }

  .backBtn {
    svg {
      cursor: pointer;
      position: relative;
      transition: all 0.5s ease;
      left: 0;
      margin-right: 20px;

      &:hover {
        left: -4px;
        color: #ec3f3f;
      }
    }

    span {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(34px);
      line-height: 41px;
    }
  }

  .pay_section {
    @include border-radius(20px);
    background: $white;
    box-shadow: 0px 8px 20px 0px rgba(107, 98, 220, 0.2);
    padding: 24px;
    margin-bottom: 20px;
    @media (max-width: 599px) {
      padding: 20px 8px;
      margin-bottom: 82px;
      box-shadow: 0px 8px 20px 0px #2160aa33;
    }

    h5 {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(20px);
      line-height: 29px;
      margin-bottom: 0;
    }

    hr {
      color: $black;
      opacity: 10%;
      margin: 24px 0;
      @media (max-width: 599px) {
        margin: 12px 0;
      }
    }

    .pay_list {
      margin-bottom: 0;

      li {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        font-family: "Montserrat";
        font-weight: 400;
        @include fontSize(14px);
        line-height: 17px;
        margin-bottom: 16px;
        gap: 12px;

        .name {
          width: 60%;
          font-weight: 500;
          @include fontSize(14px);
          line-height: 20px;
          text-transform: capitalize;
          @media (max-width: 599px) {
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .price {
          width: 40%;
          font-weight: 500;
          @include fontSize(14px);
          line-height: 20px;
          @media (max-width: 599px) {
            font-size: 15px;
            font-weight: 500;
            color: #000;
          }
        }
      }

      .total_payment {
        background: #ebeaff;
        @include border-radius(10px);
        padding: 15px 14px;
        font-weight: 600;
        @include fontSize(14px);
        line-height: 17px;

        span {
          @include fontSize(16px);
          line-height: 20px;
        }
      }
    }

    .ctaBtn {
      .submitBtn {
        width: 100%;
      }
    }

    img {
      height: 243px;
    }
  }

  .confirmation_rightSection {
    background: #edf1fe;
    height: 100%;
    @include border-radius(20px);
    position: relative;
    padding: 103px 147px 124px 124px;

    &:before {
      content: "";
      background: url("../../../../../../public/images/enrollmentdrive_bg.svg")
        no-repeat;
      @include position(absolute, $left: 40px, $top: 30px);
      height: 100%;
      width: 100%;
    }

    .confirmation_innerSection {
      border: 2px solid $primary;
      @include border-radius(30px);
      background: $white;
      min-height: 450px;
      @include box-shadow(17px 17px 0px rgba($primary, 0.7));
      padding: 67px 92px;
      text-align: center;

      img {
        margin-bottom: 20px;
        max-width: 100%;
      }

      h5 {
        font-family: "Montserrat";
        @include fontSize(34px);
        line-height: 41px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
      }

      p {
        font-family: "Roboto";
        @include fontSize(16px);
        line-height: 18px;
        font-weight: 300;
        margin-bottom: 0px;
        text-align: center;
      }
    }
  }
}

.image_container {
  padding: 50px 12px 0 12px;
  gap: 20px;
  border-radius: 30px;
  background: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: left;
    color: #000;
    padding: 0;
    margin: 0;
  }
  p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 300;
    line-height: 16.41px;
    text-align: center;
    color: #000;
  }
}
