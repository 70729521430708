@import "../../../../mixins.scss";

.doctorOnline_wrapper {
    .main_heading {
        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(28px);
            font-weight: 300;
            line-height: 34px;
            margin-bottom: 5px;

            span {
                font-weight: 600;
                display: block;
            }
        }

        p {
            font-family: "Roboto", sans-serif;
            @include fontSize(14px);
            font-weight: 300;
            line-height: 16px;
            margin-bottom: 0px;
            opacity: 0.5;
        }
    }

    .employee_list {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        .employee_list_inner {
            display: flex;
            flex-direction: row-reverse;
            margin-left: 8px;

            li {
                list-style-type: none;
                margin-left: -8px;
                @include border-radius(40px);
                border: 1px solid rgba(0, 0, 0, 0.20);
                background: $white;
                box-shadow: 0px 4px 16px 0px rgba(107, 98, 220, 0.30);
            }
        }

        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(14px);
            font-weight: 400;
            line-height: 17px;
            margin: 0 10px 0 10px;
        }

        .online {
            background: #91DB77;
            height: 10px;
            width: 10px;
            @include border-radius(100%);
        }

        .offline {
            background: #da1212;
            height: 10px;
            width: 10px;
            @include border-radius(100%);
        }

    }

    .doctor_list {
        .doctor_list_inner {
            display: flex;

            li {
                list-style-type: none;
                font-family: "Montserrat", sans-serif;
                @include fontSize(14px);
                font-weight: 400;
                line-height: 17px;
                margin-right: 16px;
                color: #30364D;
                padding-left: 29px;
                position: relative;

                $button-icons: verified,
                digital,
                followup;
                $k: 0;

                @each $icons in $button-icons {
                    $bacRightP: $k * 24;
                    $k: $k +1;

                    &.#{$icons} {
                        &:before {
                            @include position(absolute, $left: 0px, $top: calc(50% - 12px));
                            content: "";
                            height: 24px;
                            width: 24px;
                            background: url("../../../../../public/images/employee_doctor_img.svg") no-repeat -#{$bacRightP}px 0px /72px;
                        }
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .consult_specialities {
        padding: 16px 12px;
        @include border-radius(12px);
        background: #F9F9FF;
        margin-bottom: 40px;

        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(18px);
            font-weight: 600;
            line-height: 21px;
            margin-bottom: 24px;

            span {
                display: block;
                font-family: "Roboto", sans-serif;
                @include fontSize(14px);
                font-weight: 300;
                line-height: 16px;
                padding-top: 5px;
            }
        }

        .react-multiple-carousel__arrow {
            background: $white;
            min-width: 32px;
            min-height: 32px;
            box-shadow: 0px 8px 12px 0px rgba(107, 98, 220, 0.20);
            @include border-radius(40px);

            &:before {
                color: $black;
                @include fontSize(13px);
            }
        }

        .react-multi-carousel-dot-list {
            display: none;
        }

        .react-multiple-carousel__arrow--left {
            left: calc(4% - 29px);
        }

        .react-multiple-carousel__arrow--right {
            right: calc(4% - 29px);
        }

        .choose-sec {
            padding: 0px 4px 12px 4px;
            margin: 70px 12px 0px 12px;
            text-align: center;
            cursor: pointer;
            @include border-radius(0 0 16px 16px);
            border-right: 1px dashed rgba(107, 98, 220, 0.50);
            border-bottom: 1px dashed rgba(107, 98, 220, 0.50);
            border-left: 1px dashed rgba(107, 98, 220, 0.50);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
            box-shadow: 0px 10px 12px 0px rgba(107, 98, 220, 0.10);
            height: 103px;
            width: 138px;

            h5 {
                font-family: "Montserrat", sans-serif;
                @include fontSize(12px);
                font-weight: 500;
                line-height: 14px;
                margin-bottom: 0;
                position: relative;
                top: -60px;
            }

            img {
                height: 90px;
                position: relative;
                top: -60px;
            }

            p {
                font-family: "Montserrat", sans-serif;
                @include fontSize(12px);
                font-weight: 400;
                line-height: 14px;
                margin-bottom: 0;
                color: $primary;
                position: relative;
                top: -30px;
            }
        }
    }

    .benefits_consult {
        @include border-radius(0 0 20px 20px);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
        box-shadow: 0px 8px 10px 0px rgba(107, 98, 220, 0.10);
        padding: 0 16px;
        margin-bottom: 40px;

        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(24px);
            font-weight: 600;
            line-height: 29px;
            margin-bottom: 24px;

            span {
                font-weight: 300;
            }
        }

        .benefits_list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0;

            li {
                list-style-type: none;
                font-family: "Montserrat", sans-serif;
                @include fontSize(14px);
                font-weight: 500;
                line-height: 17px;
                width: 33%;
                margin: 12px 0;
                position: relative;
                padding-left: 26px;

                &:before {
                    content: '';
                    background: url("../../../../../public/images/doctor_benefits_img.svg") no-repeat;
                    height: 14px;
                    width: 14px;
                    @include position(absolute, $left: 0, $top: calc(50% - 7px));
                }
            }
        }
    }

    .faq {
        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(24px);
            font-weight: 600;
            line-height: 29px;
            margin-bottom: 12px;
        }
    }

    .our_doctors {
        margin-bottom: 32px;

        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(18px);
            font-weight: 600;
            line-height: 21px;
            margin-bottom: 16px;

            span {
                display: block;
                font-family: "Roboto", sans-serif;
                @include fontSize(14px);
                font-weight: 300;
                line-height: 16px;
                padding-top: 5px;
                color: #808080;
            }
        }

        .doctor_carousel {
            @include border-radius(12px);
            border: 1px dashed #ACD4FB;
            background: linear-gradient(90deg, #EDF1FE 0%, rgba(237, 241, 254, 0.00) 100%);
            padding: 12px;
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            img {
                height: 50px;
                margin-right: 8px;
            }

            h6 {
                font-family: "Montserrat", sans-serif;
                @include fontSize(10px);
                font-weight: 5600;
                line-height: 12px;
                color: $primary;
                width: 35px;
                margin: 0 12px 0 0;
            }

            h5 {
                font-family: "Montserrat", sans-serif;
                @include fontSize(12px);
                font-weight: 600;
                line-height: 14px;
                margin-bottom: 2px;

                span {
                    display: block;
                    padding-top: 2px;
                    font-weight: 400;
                    color: #30364D;
                }
            }
        }

        .react-multiple-carousel__arrow--left {
            display: none;
        }

        .react-multiple-carousel__arrow--right {
            display: none;
        }

        .react-multi-carousel-dot-list {
            .react-multi-carousel-dot {
                button {
                    border-color: #D9D9D9;
                    background: #D9D9D9;
                }
            }

            .react-multi-carousel-dot--active button {
                background: $primary;
                border-color: $primary;
            }
        }
    }

    .health_concern {
        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(18px);
            font-weight: 600;
            line-height: 21px;
            margin-bottom: 16px;

            span {
                display: block;
                font-weight: 400;
                color: #30364D;
                padding-top: 5px;
            }
        }

        .health_concernList {
            li {
                list-style-type: none;
                padding: 12px 14px;
                @include border-radius(8px);
                border: 1px solid #e5e5e5;
                font-family: "Montserrat", sans-serif;
                @include fontSize(14px);
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 24px;
                position: relative;

                &:before {
                    content: '';
                    background: url("../../../../../public/images/down_arrow.svg") no-repeat;
                    height: 10px;
                    width: 10px;
                    @include position(absolute, $right: 20px, $top: 21px);
                }

                &:last-child {
                    margin-bottom: 12px;
                }
            }
        }



    }
}