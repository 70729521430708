@import "../../mixins.scss";

.newLoader {
  background: rgba(0, 0, 0, 0.74);
  @include position(fixed, $top: 0px, $left: 0px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 9991;
  backdrop-filter: blur(4px);

  .innerWrapper {
    // background: $white;
    max-width: 1164px;
    width: 100%;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      font-size: 20px;
      line-height: 36px;
      font-weight: 400;
      color: white;
      text-align: center;
      margin: 0;

      span {
        display: block;
        font-weight: 700;
      }
    }

    .loader-box {
      max-width: 360px;
      width: 100%;
      // height: 150px;
      margin-top: 40px;
      display: flex;
      justify-content: center;

      .loading span {
        display: inline-block;
        vertical-align: middle;
        width: 0.5em;
        height: 2.5em;
        margin: 0 5px;
        background: rgba(234, 233, 255, 0.19);
        // animation: loading 1s infinite alternate;
        animation: loading 2s infinite ease-in-out;
      }

      .loading span:nth-of-type(2) {
        background: #fff;
        animation-delay: 0.2s;
        height: 3.5em
      }

      .loading span:nth-of-type(3) {
        background: #fff;
        animation-delay: 0.4s;
        height: 8em;
      }

      .loading span:nth-of-type(4) {
        background: #fff;
        animation-delay: 0.6s;
        height: 7em;
      }

      .loading span:nth-of-type(5) {
        background: #fff;
        animation-delay: 0.8s;
        height: 5em;
      }

      .loading span:nth-of-type(6) {
        background: #fff;
        animation-delay: 1.0s;
        height: 2.5em;
      }

      .loading span:nth-of-type(7) {
        background: #fff;
        animation-delay: 1.2s;
        height: 3.5em;
      }

      .loading span:nth-of-type(8) {
        background: #fff;
        animation-delay: 1.4s;
        height: 8em;
      }

      .loading span:nth-of-type(9) {
        background: #fff;
        animation-delay: 1.6s;
        height: 7em;
      }

      .loading span:nth-of-type(10) {
        background: #fff;
        animation-delay: 1.8s;
        height: 6em;
      }

      .loading span:nth-of-type(11) {
        background: #fff;
        animation-delay: 2.0s;
        height: 2.5em;
      }

      .loading span:nth-of-type(12) {
        background: #fff;
        animation-delay: 2.2s;
        height: 3.5em;
      }

      @keyframes loading {
        0% {
          background-color: rgba(234, 233, 255, 0.19);
        }

        33% {
          background-color: rgba(234, 233, 255, 0.19);
        }

        66% {
          background-color: #fff;
        }

        100% {
          background-color: #fff;
        }
      }
    }
  }
}

.mob_loader {
  background: rgba(0, 0, 0, 0.90);
  @include position(fixed, $top: 0px, $left: 0px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 9991;
  backdrop-filter: blur(1px);

  .spinner {
    position: absolute;
    width: 9px;
    height: 9px;

    div {
      position: absolute;
      width: 50%;
      height: 150%;
      background: #e4e1e1;
      transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
      animation: spinner-fzua35 1s calc(var(--delay) * 1s) infinite ease;

      &:nth-child(1) {
        --delay: 0.1;
        --rotation: 36;
        --translation: 150;
      }

      &:nth-child(2) {
        --delay: 0.2;
        --rotation: 72;
        --translation: 150;
      }

      &:nth-child(3) {
        --delay: 0.3;
        --rotation: 108;
        --translation: 150;
      }

      &:nth-child(4) {
        --delay: 0.4;
        --rotation: 144;
        --translation: 150;
      }

      &:nth-child(5) {
        --delay: 0.5;
        --rotation: 180;
        --translation: 150;
      }

      &:nth-child(6) {
        --delay: 0.6;
        --rotation: 216;
        --translation: 150;
      }

      &:nth-child(7) {
        --delay: 0.7;
        --rotation: 252;
        --translation: 150;
      }

      &:nth-child(8) {
        --delay: 0.8;
        --rotation: 288;
        --translation: 150;
      }

      &:nth-child(9) {
        --delay: 0.9;
        --rotation: 324;
        --translation: 150;
      }

      &:nth-child(10) {
        --delay: 1;
        --rotation: 360;
        --translation: 150;
      }
    }

    @keyframes spinner-fzua35 {

      0%,
      10%,
      20%,
      30%,
      50%,
      60%,
      70%,
      80%,
      90%,
      100% {
        transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1%));
      }

      50% {
        transform: rotate(calc(var(--rotation) * 1deg)) translate(0, calc(var(--translation) * 1.5%));
      }
    }
  }
}