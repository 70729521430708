:root,
.rs-theme-light {
  --rs-gray-50: #f7f7fa;
  --rs-gray-100: #f2f2f5;
  --rs-gray-200: #e5e5ea;
  --rs-gray-300: #d9d9d9;
  --rs-gray-400: #c5c6c7;
  --rs-gray-500: #a6a6a6;
  --rs-gray-600: #8e8e93;
  --rs-gray-700: #7a7a7a;
  --rs-gray-800: #575757;
  --rs-gray-900: #272c36;
  --rs-primary-50: #f2faff;
  --rs-primary-100: #cce9ff;
  --rs-primary-200: #a6d7ff;
  --rs-primary-300: #80c4ff;
  --rs-primary-400: #59afff;
  --rs-primary-500: #3498ff;
  --rs-primary-600: #2589f5;
  --rs-primary-700: #1675e0;
  --rs-primary-800: #0a5dc2;
  --rs-primary-900: #004299;
  --rs-red-50: #fff2f2;
  --rs-red-100: #fccfcf;
  --rs-red-200: #faa9a7;
  --rs-red-300: #fa8682;
  --rs-red-400: #f7635c;
  --rs-red-500: #f44336;
  --rs-red-600: #eb3626;
  --rs-red-700: #d62915;
  --rs-red-800: #b81c07;
  --rs-red-900: #8f1300;
  --rs-orange-50: #fff8f2;
  --rs-orange-100: #ffdfc2;
  --rs-orange-200: #fcc690;
  --rs-orange-300: #fcb160;
  --rs-orange-400: #fa9b2f;
  --rs-orange-500: #fa8900;
  --rs-orange-600: #f08800;
  --rs-orange-700: #db8000;
  --rs-orange-800: #bd7100;
  --rs-orange-900: #945b00;
  --rs-yellow-50: #fffaf2;
  --rs-yellow-100: #ffe9c2;
  --rs-yellow-200: #ffd991;
  --rs-yellow-300: #ffca61;
  --rs-yellow-400: #ffbe30;
  --rs-yellow-500: #ffb300;
  --rs-yellow-600: #f5af00;
  --rs-yellow-700: #e0a500;
  --rs-yellow-800: #c29100;
  --rs-yellow-900: #997500;
  --rs-green-50: #eeffed;
  --rs-green-100: #c8f0c7;
  --rs-green-200: #a5e0a4;
  --rs-green-300: #82cf82;
  --rs-green-400: #65bf67;
  --rs-green-500: #4caf50;
  --rs-green-600: #37ab3c;
  --rs-green-700: #22a12a;
  --rs-green-800: #0f9119;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #f0f9ff;
  --rs-blue-100: #c5e7fc;
  --rs-blue-200: #9bd4fa;
  --rs-blue-300: #72c0f7;
  --rs-blue-400: #49abf5;
  --rs-blue-500: #2196f3;
  --rs-blue-600: #1787e8;
  --rs-blue-700: #0d73d4;
  --rs-blue-800: #045cb5;
  --rs-blue-900: #00448c;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #4caf50;
  --rs-state-info: #2196f3;
  --rs-state-warning: #ffb300;
  --rs-state-error: #f44336;
  --rs-body: #fff;
  --rs-bg-success: #edfae1;
  --rs-bg-info: #e9f5fe;
  --rs-bg-warning: #fff9e6;
  --rs-bg-error: #fde9ef;
  --rs-text-link: #1675e0;
  --rs-text-link-hover: #0a5dc2;
  --rs-text-link-active: #004299;
  --rs-text-primary: #575757;
  --rs-text-secondary: #8e8e93;
  --rs-text-tertiary: #a6a6a6;
  --rs-text-heading: #272c36;
  --rs-text-inverse: #f7f7fa;
  --rs-text-heading-inverse: #fff;
  --rs-text-active: #1675e0;
  --rs-text-disabled: #c5c6c7;
  --rs-text-error: #f44336;
  --rs-border-primary: #e5e5ea;
  --rs-border-secondary: #f2f2f5;
  --rs-bg-card: #fff;
  --rs-bg-overlay: #fff;
  --rs-bg-well: #f7f7fa;
  --rs-bg-active: #3498ff;
  --rs-bg-backdrop: rgba(39, 44, 54, 0.3);
  --rs-state-hover-bg: #f2faff;
  --rs-color-focus-ring: rgba(52, 152, 255, 0.25);
  --rs-state-focus-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  --rs-state-focus-outline: 3px solid rgba(52, 152, 255, 0.25);
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #f7f7fa;
  --rs-btn-default-text: #575757;
  --rs-btn-default-hover-bg: #e5e5ea;
  --rs-btn-default-active-bg: #d9d9d9;
  --rs-btn-default-active-text: #272c36;
  --rs-btn-default-disabled-bg: #f7f7fa;
  --rs-btn-default-disabled-text: #c5c6c7;
  --rs-btn-primary-bg: #3498ff;
  --rs-btn-primary-text: #fff;
  --rs-btn-primary-hover-bg: #2589f5;
  --rs-btn-primary-active-bg: #1675e0;
  --rs-btn-subtle-text: #8e8e93;
  --rs-btn-subtle-hover-bg: #f7f7fa;
  --rs-btn-subtle-hover-text: #575757;
  --rs-btn-subtle-active-bg: #e5e5ea;
  --rs-btn-subtle-active-text: #272c36;
  --rs-btn-subtle-disabled-text: #c5c6c7;
  --rs-btn-ghost-border: #1675e0;
  --rs-btn-ghost-text: #1675e0;
  --rs-btn-ghost-hover-border: #0a5dc2;
  --rs-btn-ghost-hover-text: #0a5dc2;
  --rs-btn-ghost-active-border: #004299;
  --rs-btn-ghost-active-text: #004299;
  --rs-btn-link-text: #1675e0;
  --rs-btn-link-hover-text: #0a5dc2;
  --rs-btn-link-active-text: #004299;
  --rs-iconbtn-addon: #f2f2f5;
  --rs-iconbtn-activated-addon: #d9d9d9;
  --rs-iconbtn-pressed-addon: #c5c6c7;
  --rs-iconbtn-primary-addon: #2589f5;
  --rs-iconbtn-primary-activated-addon: #1675e0;
  --rs-iconbtn-primary-pressed-addon: #0a5dc2;
  --rs-divider-border: #e5e5ea;
  --rs-loader-ring: rgba(247, 247, 250, 0.8);
  --rs-loader-rotor: #a6a6a6;
  --rs-loader-backdrop: rgba(255, 255, 255, 0.9);
  --rs-loader-ring-inverse: rgba(247, 247, 250, 0.3);
  --rs-loader-rotor-inverse: #fff;
  --rs-loader-backdrop-inverse: rgba(39, 44, 54, 0.83);
  --rs-message-success-header: var(--rs-text-heading);
  --rs-message-success-text: var(--rs-text-primary);
  --rs-message-success-icon: #4caf50;
  --rs-message-success-bg: #eeffed;
  --rs-message-info-header: var(--rs-text-heading);
  --rs-message-info-text: var(--rs-text-primary);
  --rs-message-info-icon: #2196f3;
  --rs-message-info-bg: #f0f9ff;
  --rs-message-warning-header: var(--rs-text-heading);
  --rs-message-warning-text: var(--rs-text-primary);
  --rs-message-warning-icon: #ffb300;
  --rs-message-warning-bg: #fffaf2;
  --rs-message-error-header: var(--rs-text-heading);
  --rs-message-error-text: var(--rs-text-primary);
  --rs-message-error-icon: #f44336;
  --rs-message-error-bg: #fff2f2;
  --rs-tooltip-bg: #272c36;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #e5e5ea;
  --rs-progress-bar: #3498ff;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #f2f2f5;
  --rs-placeholder-active: #e5e5ea;
  --rs-dropdown-divider: #e5e5ea;
  --rs-dropdown-item-bg-hover: rgba(204, 233, 255, 0.5);
  --rs-dropdown-item-bg-active: #f2faff;
  --rs-dropdown-item-text-active: #1675e0;
  --rs-dropdown-header-text: #a6a6a6;
  --rs-dropdown-shadow: 0 0 10px rgba(0, 0, 0, 0.06),
    0 4px 4px rgba(0, 0, 0, 0.12);
  --rs-menuitem-active-bg: rgba(204, 233, 255, 0.5);
  --rs-menuitem-active-text: #1675e0;
  --rs-steps-border: #8e8e93;
  --rs-steps-state-finish: #3498ff;
  --rs-steps-border-state-finish: #3498ff;
  --rs-steps-state-wait: #8e8e93;
  --rs-steps-state-process: #3498ff;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #3498ff;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #8e8e93;
  --rs-navs-text-hover: #575757;
  --rs-navs-bg-hover: #e5e5ea;
  --rs-navs-text-active: #272c36;
  --rs-navs-bg-active: #e5e5ea;
  --rs-navs-tab-border: #d9d9d9;
  --rs-navs-subtle-border: #f7f7fa;
  --rs-navs-selected: #1675e0;
  --rs-navbar-default-bg: #f7f7fa;
  --rs-navbar-default-text: #575757;
  --rs-navbar-default-selected-text: #1675e0;
  --rs-navbar-default-hover-bg: #e5e5ea;
  --rs-navbar-default-hover-text: #575757;
  --rs-navbar-inverse-bg: #3498ff;
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: #1675e0;
  --rs-navbar-inverse-hover-bg: #2589f5;
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: #fff;
  --rs-navbar-subtle-text: #8e8e93;
  --rs-navbar-subtle-selected-text: #1675e0;
  --rs-navbar-subtle-hover-bg: #f7f7fa;
  --rs-navbar-subtle-hover-text: #575757;
  --rs-sidenav-default-bg: #f7f7fa;
  --rs-sidenav-default-text: #575757;
  --rs-sidenav-default-selected-text: #1675e0;
  --rs-sidenav-default-hover-bg: #e5e5ea;
  --rs-sidenav-default-hover-text: #575757;
  --rs-sidenav-default-footer-border: #e5e5ea;
  --rs-sidenav-inverse-bg: #3498ff;
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: #1675e0;
  --rs-sidenav-inverse-hover-bg: #2589f5;
  --rs-sidenav-inverse-footer-border: #2589f5;
  --rs-sidenav-subtle-bg: #fff;
  --rs-sidenav-subtle-text: #8e8e93;
  --rs-sidenav-subtle-selected-text: #1675e0;
  --rs-sidenav-subtle-hover-bg: #f7f7fa;
  --rs-sidenav-subtle-hover-text: #575757;
  --rs-sidenav-subtle-footer-border: #e5e5ea;
  --rs-input-bg: #fff;
  --rs-input-focus-border: #3498ff;
  --rs-input-disabled-bg: #f7f7fa;
  --rs-listbox-option-hover-bg: rgba(204, 233, 255, 0.5);
  --rs-listbox-option-hover-text: #1675e0;
  --rs-listbox-option-selected-text: #1675e0;
  --rs-listbox-option-selected-bg: #f2faff;
  --rs-listbox-option-disabled-text: #c5c6c7;
  --rs-listbox-option-disabled-selected-text: #a6d7ff;
  --rs-checkbox-icon: #fff;
  --rs-checkbox-border: #d9d9d9;
  --rs-checkbox-checked-bg: #3498ff;
  --rs-checkbox-disabled-bg: #f7f7fa;
  --rs-radio-marker: #fff;
  --rs-radio-border: #d9d9d9;
  --rs-radio-checked-bg: #3498ff;
  --rs-radio-disabled-bg: #f7f7fa;
  --rs-rate-symbol: #8e8e93;
  --rs-rate-symbol-checked: #ffb300;
  --rs-toggle-bg: #d9d9d9;
  --rs-toggle-thumb: #fff;
  --rs-toggle-loader-ring: rgba(247, 247, 250, 0.3);
  --rs-toggle-loader-rotor: #fff;
  --rs-toggle-hover-bg: #c5c6c7;
  --rs-toggle-disabled-bg: #f7f7fa;
  --rs-toggle-disabled-thumb: #fff;
  --rs-toggle-checked-bg: #3498ff;
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #2589f5;
  --rs-toggle-checked-disabled-bg: #cce9ff;
  --rs-toggle-checked-disabled-thumb: #fff;
  --rs-slider-bar: #f2f2f5;
  --rs-slider-hover-bar: #e5e5ea;
  --rs-slider-thumb-border: #3498ff;
  --rs-slider-thumb-bg: #fff;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
  --rs-slider-progress: #3498ff;
  --rs-uploader-item-bg: #d9d9d9;
  --rs-uploader-item-hover-bg: #f7f7fa;
  --rs-uploader-overlay-bg: rgba(255, 255, 255, 0.8);
  --rs-uploader-dnd-bg: #fff;
  --rs-uploader-dnd-border: #e5e5ea;
  --rs-uploader-dnd-hover-border: #3498ff;
  --rs-avatar-bg: #d9d9d9;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #f7f7fa;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #8e8e93;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #3498ff;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: #fff;
  --rs-list-border: #e5e5ea;
  --rs-list-hover-bg: #f2faff;
  --rs-list-placeholder-bg: rgba(242, 250, 255, 0.5);
  --rs-list-placeholder-border: #3498ff;
  --rs-timeline-indicator-bg: #d9d9d9;
  --rs-timeline-indicator-active-bg: #3498ff;
  --rs-table-shadow: rgba(9, 9, 9, 0.08);
  --rs-table-sort: #3498ff;
  --rs-table-resize: #3498ff;
  --rs-table-scrollbar-track: #e5e5ea;
  --rs-table-scrollbar-thumb: #575757;
  --rs-table-scrollbar-thumb-active: #272c36;
  --rs-table-scrollbar-vertical-track: rgba(229, 229, 234, 0.4);
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #f44336;
  --rs-form-errormessage-bg: #fff;
  --rs-form-errormessage-border: #e5e5ea;
  --rs-picker-value: #1675e0;
  --rs-picker-count-bg: #3498ff;
  --rs-picker-count-text: #fff;
  --rs-calendar-today-bg: #3498ff;
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: rgba(204, 233, 255, 0.5);
  --rs-calendar-time-unit-bg: #f7f7fa;
  --rs-calendar-date-selected-text: #fff;
  --rs-popover-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
}
/* stylelint-disable */

a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: bold;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button {
  border-radius: 0;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: not-allowed;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
textarea {
  overflow: auto;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
[tabindex="-1"] {
  outline: none;
}
input[type="button"],
input[type="submit"],
input[type="reset"],
button {
  border-width: 0;
}
input::-ms-clear {
  display: none;
}
input[type="file"]::-webkit-file-upload-button,
input[type="file"]::-ms-browse {
  border-width: 0;
  background: transparent;
  color: currentColor;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #1675e0;
  color: var(--rs-text-link);
  text-decoration: none;
}
a:hover,
a:focus {
  color: #0a5dc2;
  color: var(--rs-text-link-hover);
  text-decoration: underline;
}
a:active {
  color: #004299;
  color: var(--rs-text-link-active);
  text-decoration: underline;
}
img {
  vertical-align: middle;
}

[role="button"] {
  cursor: pointer;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
dl {
  margin-top: 0;
  margin-bottom: 10px;
}
dt,
dd {
  line-height: 1.42857143;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
  margin-bottom: 10px;
}
@font-face {
  font-family: Apple-System;
  src: local(-apple-system), local(BlinkMacSystemFont), local(system-ui);
}
@-webkit-keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes slideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes slideOutLeft {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@-webkit-keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes slideInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes slideOutRight {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@keyframes slideOutRight {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
@-webkit-keyframes slideInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes slideInTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes slideOutTop {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes slideOutTop {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@-webkit-keyframes slideInBottom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes slideInBottom {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@-webkit-keyframes slideOutBottom {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes slideOutBottom {
  from {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
.rs-anim-slide-out {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.rs-anim-slide-in {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.rs-anim-right.rs-anim-slide-in {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
.rs-anim-right.rs-anim-slide-out {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}
.rs-anim-left.rs-anim-slide-in {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
.rs-anim-left.rs-anim-slide-out {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}
.rs-anim-top.rs-anim-slide-in {
  -webkit-animation-name: slideInTop;
  animation-name: slideInTop;
}
.rs-anim-top.rs-anim-slide-out {
  -webkit-animation-name: slideOutTop;
  animation-name: slideOutTop;
}
.rs-anim-bottom.rs-anim-slide-in {
  -webkit-animation-name: slideInBottom;
  animation-name: slideInBottom;
}
.rs-anim-bottom.rs-anim-slide-out {
  -webkit-animation-name: slideOutBottom;
  animation-name: slideOutBottom;
}
.rs-anim-bounce-in {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes bounceIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bounceIn {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.rs-anim-bounce-out {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes bounceOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes bounceOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.rs-anim-fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  pointer-events: none;
}
.rs-anim-fade.rs-anim-in {
  opacity: 1;
  pointer-events: unset;
}
.rs-anim-collapse {
  display: none;
}
.rs-anim-collapse.rs-anim-in {
  display: block;
}
tr.rs-anim-collapse.rs-anim-in {
  display: table-row;
}
tbody.rs-anim-collapse.rs-anim-in {
  display: table-row-group;
}
.rs-anim-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease;
  transition: height 0.35s ease, width 0.35s ease, visibility 0.35s ease;
}
.rs-auto-complete-menu {
  margin-bottom: 6px;
  overflow-y: auto;
  margin-top: 6px;
}
.rs-auto-complete-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rs-auto-complete-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  /* stylelint-disable-next-line */
}
.ReactVirtualized__Grid .rs-auto-complete-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-auto-complete-item:hover,
.rs-auto-complete-item:focus,
.rs-auto-complete-item-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast .rs-auto-complete-item:hover,
.rs-theme-high-contrast .rs-auto-complete-item:focus,
.rs-theme-high-contrast .rs-auto-complete-item-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item:hover,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item:focus,
.rs-theme-high-contrast .rs-theme-high-contrast .rs-auto-complete-item-focus {
  outline-offset: 2px;
}
.rs-auto-complete .rs-input:focus::-webkit-textfield-decoration-container {
  visibility: hidden;
  pointer-events: none;
}
.rs-avatar {
  background-color: #d9d9d9;
  background-color: var(--rs-avatar-bg);
  color: #fff;
  color: var(--rs-avatar-text);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 40px;
  font-size: 18px;
}
.rs-avatar > .rs-icon {
  font-size: 29px;
  height: 29px;
}
.rs-avatar > .rs-avatar-image {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.rs-avatar > .rs-icon {
  vertical-align: middle;
}
.rs-avatar-image::before {
  content: attr(alt);
  position: absolute;
  width: 100%;
  height: inherit;
  top: 0;
  left: 0;
  background: #d9d9d9;
  background: var(--rs-avatar-bg);
  text-align: center;
  padding: 0 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-avatar-circle {
  border-radius: 50%;
}
.rs-avatar-lg {
  width: 60px;
  height: 60px;
  font-size: 26px;
}
.rs-avatar-lg > .rs-icon {
  font-size: 43px;
  height: 43px;
}
.rs-avatar-lg > .rs-avatar-image {
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.rs-avatar-sm {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.rs-avatar-sm > .rs-icon {
  font-size: 22px;
  height: 22px;
}
.rs-avatar-sm > .rs-avatar-image {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.rs-avatar-xs {
  width: 20px;
  height: 20px;
  font-size: 12px;
}
.rs-avatar-xs > .rs-icon {
  font-size: 16px;
  height: 16px;
}
.rs-avatar-xs > .rs-avatar-image {
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.rs-avatar-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.rs-avatar-group-stack .rs-avatar {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin-right: -10px;
  -webkit-transition: margin 0.15s;
  transition: margin 0.15s;
}
.rs-avatar-group-stack .rs-avatar:last-child {
  margin-right: 0;
}
.rs-avatar-group-stack .rs-avatar:hover {
  margin-right: 0;
}
.rs-badge-wrapper {
  position: relative;
}
.rs-badge {
  display: inline-block;
}
.rs-badge-independent,
.rs-badge-content {
  background-color: #f44336;
  background-color: var(--rs-badge-bg);
  color: #fff;
  color: var(--rs-badge-text);
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 5px;
}
.rs-badge-independent.rs-badge-dot,
.rs-badge-wrapper .rs-badge-content:empty {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  padding: 0;
}
.rs-badge-wrapper .rs-badge-content {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  z-index: 1;
}
.rs-badge-red.rs-badge-independent,
.rs-badge-red .rs-badge-content {
  background-color: #f44336;
  background-color: var(--rs-red-500);
}
.rs-badge-orange.rs-badge-independent,
.rs-badge-orange .rs-badge-content {
  background-color: #fa8900;
  background-color: var(--rs-orange-500);
}
.rs-badge-yellow.rs-badge-independent,
.rs-badge-yellow .rs-badge-content {
  background-color: #ffb300;
  background-color: var(--rs-yellow-500);
}
.rs-badge-green.rs-badge-independent,
.rs-badge-green .rs-badge-content {
  background-color: #4caf50;
  background-color: var(--rs-green-500);
}
.rs-badge-cyan.rs-badge-independent,
.rs-badge-cyan .rs-badge-content {
  background-color: #00bcd4;
  background-color: var(--rs-cyan-500);
}
.rs-badge-blue.rs-badge-independent,
.rs-badge-blue .rs-badge-content {
  background-color: #2196f3;
  background-color: var(--rs-blue-500);
}
.rs-badge-violet.rs-badge-independent,
.rs-badge-violet .rs-badge-content {
  background-color: #673ab7;
  background-color: var(--rs-violet-500);
}
.rs-breadcrumb {
  margin-bottom: 20px;
  font-size: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
}
.rs-breadcrumb-item {
  -webkit-transition: color 0.3s linear;
  transition: color 0.3s linear;
}
.rs-breadcrumb-item:focus {
  outline: 0;
}
.rs-breadcrumb-separator {
  margin: 0 4px;
}
.rs-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: color 0.2s linear, background-color 0.3s linear;
  transition: color 0.2s linear, background-color 0.3s linear;
  border: none;
  border: var(--rs-btn-default-border, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  color: #575757;
  color: var(--rs-btn-default-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative;
}
.rs-theme-high-contrast .rs-btn {
  -webkit-transition: none;
  transition: none;
}
.rs-btn-ghost.rs-btn {
  padding: 7px 11px;
}
.rs-btn-icon.rs-btn {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-btn > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-btn.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-btn:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-btn:focus-visible {
  outline-offset: 2px;
}
.rs-btn:hover,
.rs-btn:focus {
  color: var(--rs-btn-default-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-default-hover-bg);
  text-decoration: none;
}
.rs-btn:active,
.rs-btn.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-default-active-text);
  background-color: #d9d9d9;
  background-color: var(--rs-btn-default-active-bg);
}
.rs-btn:disabled,
.rs-btn.rs-btn-disabled {
  cursor: not-allowed;
  color: #c5c6c7;
  color: var(--rs-btn-default-disabled-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-disabled-bg);
}
.rs-theme-high-contrast .rs-btn:disabled,
.rs-theme-high-contrast .rs-btn.rs-btn-disabled {
  opacity: 0.5;
}
@media not all and (min-resolution: 0.001dpcm) {
  .rs-btn {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */
  }
}
.rs-btn-primary {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #3498ff;
  background-color: var(--rs-btn-primary-bg);
  border: none;
}
.rs-btn-primary:hover,
.rs-btn-primary:focus {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #2589f5;
  background-color: var(--rs-btn-primary-hover-bg);
}
.rs-btn-primary:active,
.rs-btn-primary.rs-btn-active {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #1675e0;
  background-color: var(--rs-btn-primary-active-bg);
}
.rs-btn-primary:disabled,
.rs-btn-primary.rs-btn-disabled {
  color: #fff;
  color: var(--rs-btn-primary-text);
  background-color: #3498ff;
  background-color: var(--rs-btn-primary-bg);
  opacity: 0.3;
}
.rs-theme-high-contrast .rs-btn-primary:disabled,
.rs-theme-high-contrast .rs-btn-primary.rs-btn-disabled {
  opacity: 0.5;
}
.rs-btn-subtle {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
}
.rs-btn-subtle:hover,
.rs-btn-subtle:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-btn-subtle:active,
.rs-btn-subtle.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-btn-subtle:disabled,
.rs-btn-subtle.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-btn-subtle:disabled,
.rs-theme-high-contrast .rs-btn-subtle.rs-btn-disabled {
  opacity: 0.5;
}
.rs-btn-link {
  color: #1675e0;
  color: var(--rs-btn-link-text);
  background-color: transparent;
  border: none;
}
.rs-btn-link:hover,
.rs-btn-link:focus {
  color: #0a5dc2;
  color: var(--rs-btn-link-hover-text);
  background-color: transparent;
  text-decoration: underline;
}
.rs-btn-link:active,
.rs-btn-link.rs-btn-active {
  color: #004299;
  color: var(--rs-btn-link-active-text);
  background-color: transparent;
}
.rs-btn-link:disabled,
.rs-btn-link.rs-btn-disabled {
  color: #0a5dc2;
  color: var(--rs-btn-link-hover-text);
  background-color: transparent;
  text-decoration: none;
  opacity: 0.3;
}
.rs-theme-high-contrast .rs-btn-link:disabled,
.rs-theme-high-contrast .rs-btn-link.rs-btn-disabled {
  opacity: 0.5;
}
.rs-btn-ghost {
  color: #1675e0;
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  border: 1px solid #1675e0;
  border: 1px solid var(--rs-btn-ghost-border);
}
.rs-btn-ghost:hover,
.rs-btn-ghost:focus {
  color: #0a5dc2;
  color: var(--rs-btn-ghost-hover-text);
  background-color: transparent;
  border-color: #0a5dc2;
  border-color: var(--rs-btn-ghost-hover-border);
}
.rs-btn-ghost:active,
.rs-btn-ghost.rs-btn-active {
  color: #004299;
  color: var(--rs-btn-ghost-active-text);
  background-color: transparent;
  border-color: #004299;
  border-color: var(--rs-btn-ghost-active-border);
}
.rs-btn-ghost:disabled,
.rs-btn-ghost.rs-btn-disabled {
  color: #1675e0;
  color: var(--rs-btn-ghost-text);
  background-color: transparent;
  opacity: 0.3;
}
.rs-theme-high-contrast .rs-btn-ghost:disabled,
.rs-theme-high-contrast .rs-btn-ghost.rs-btn-disabled {
  opacity: 0.5;
}
.rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-500);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-700);
  --rs-btn-subtle-hover-bg: var(--rs-red-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-red-700);
  --rs-btn-ghost-text: var(--rs-red-700);
  --rs-btn-ghost-hover-border: var(--rs-red-800);
  --rs-btn-ghost-hover-text: var(--rs-red-800);
  --rs-btn-ghost-active-border: var(--rs-red-900);
  --rs-btn-ghost-active-text: var(--rs-red-900);
  --rs-btn-link-text: var(--rs-red-700);
  --rs-btn-link-hover-text: var(--rs-red-800);
  --rs-btn-link-active-text: var(--rs-red-900);
  --rs-iconbtn-primary-addon: var(--rs-red-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-red-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-red-800);
}
.rs-theme-dark .rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-400);
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-red-500);
  --rs-btn-ghost-text: var(--rs-red-500);
  --rs-btn-ghost-hover-border: var(--rs-red-400);
  --rs-btn-ghost-hover-text: var(--rs-red-400);
  --rs-btn-ghost-active-border: var(--rs-red-200);
  --rs-btn-ghost-active-text: var(--rs-red-200);
  --rs-btn-link-text: var(--rs-red-500);
  --rs-btn-link-hover-text: var(--rs-red-400);
  --rs-btn-link-active-text: var(--rs-red-200);
  --rs-iconbtn-primary-addon: var(--rs-red-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-red-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-red-400);
}
.rs-theme-high-contrast .rs-btn-red {
  --rs-btn-primary-bg: var(--rs-red-700);
  --rs-btn-primary-hover-bg: var(--rs-red-600);
  --rs-btn-primary-active-bg: var(--rs-red-400);
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-red-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-red-500);
  --rs-btn-ghost-text: var(--rs-red-500);
  --rs-btn-ghost-hover-border: var(--rs-red-400);
  --rs-btn-ghost-hover-text: var(--rs-red-400);
  --rs-btn-ghost-active-border: var(--rs-red-200);
  --rs-btn-ghost-active-text: var(--rs-red-200);
  --rs-btn-link-text: var(--rs-red-500);
  --rs-btn-link-hover-text: var(--rs-red-400);
  --rs-btn-link-active-text: var(--rs-red-200);
}
.rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-500);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-700);
  --rs-btn-subtle-hover-bg: var(--rs-orange-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-orange-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-orange-700);
  --rs-btn-ghost-text: var(--rs-orange-700);
  --rs-btn-ghost-hover-border: var(--rs-orange-800);
  --rs-btn-ghost-hover-text: var(--rs-orange-800);
  --rs-btn-ghost-active-border: var(--rs-orange-900);
  --rs-btn-ghost-active-text: var(--rs-orange-900);
  --rs-btn-link-text: var(--rs-orange-700);
  --rs-btn-link-hover-text: var(--rs-orange-800);
  --rs-btn-link-active-text: var(--rs-orange-900);
  --rs-iconbtn-primary-addon: var(--rs-orange-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-orange-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-orange-800);
}
.rs-theme-dark .rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-700);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-hover-bg: var(--rs-orange-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-orange-500);
  --rs-btn-ghost-text: var(--rs-orange-500);
  --rs-btn-ghost-hover-border: var(--rs-orange-400);
  --rs-btn-ghost-hover-text: var(--rs-orange-400);
  --rs-btn-ghost-active-border: var(--rs-orange-200);
  --rs-btn-ghost-active-text: var(--rs-orange-200);
  --rs-btn-link-text: var(--rs-orange-500);
  --rs-btn-link-hover-text: var(--rs-orange-400);
  --rs-btn-link-active-text: var(--rs-orange-200);
  --rs-iconbtn-primary-addon: var(--rs-orange-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-orange-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-orange-400);
}
.rs-theme-high-contrast .rs-btn-orange {
  --rs-btn-primary-bg: var(--rs-orange-700);
  --rs-btn-primary-hover-bg: var(--rs-orange-600);
  --rs-btn-primary-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-hover-bg: var(--rs-orange-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-orange-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-orange-500);
  --rs-btn-ghost-text: var(--rs-orange-500);
  --rs-btn-ghost-hover-border: var(--rs-orange-400);
  --rs-btn-ghost-hover-text: var(--rs-orange-400);
  --rs-btn-ghost-active-border: var(--rs-orange-200);
  --rs-btn-ghost-active-text: var(--rs-orange-200);
  --rs-btn-link-text: var(--rs-orange-500);
  --rs-btn-link-hover-text: var(--rs-orange-400);
  --rs-btn-link-active-text: var(--rs-orange-200);
}
.rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-500);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-700);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-yellow-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-yellow-700);
  --rs-btn-ghost-text: var(--rs-yellow-700);
  --rs-btn-ghost-hover-border: var(--rs-yellow-800);
  --rs-btn-ghost-hover-text: var(--rs-yellow-800);
  --rs-btn-ghost-active-border: var(--rs-yellow-900);
  --rs-btn-ghost-active-text: var(--rs-yellow-900);
  --rs-btn-link-text: var(--rs-yellow-700);
  --rs-btn-link-hover-text: var(--rs-yellow-800);
  --rs-btn-link-active-text: var(--rs-yellow-900);
  --rs-iconbtn-primary-addon: var(--rs-yellow-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-yellow-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-800);
}
.rs-theme-dark .rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-700);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-yellow-500);
  --rs-btn-ghost-text: var(--rs-yellow-500);
  --rs-btn-ghost-hover-border: var(--rs-yellow-400);
  --rs-btn-ghost-hover-text: var(--rs-yellow-400);
  --rs-btn-ghost-active-border: var(--rs-yellow-200);
  --rs-btn-ghost-active-text: var(--rs-yellow-200);
  --rs-btn-link-text: var(--rs-yellow-500);
  --rs-btn-link-hover-text: var(--rs-yellow-400);
  --rs-btn-link-active-text: var(--rs-yellow-200);
  --rs-iconbtn-primary-addon: var(--rs-yellow-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-yellow-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-yellow-400);
}
.rs-theme-high-contrast .rs-btn-yellow {
  --rs-btn-primary-bg: var(--rs-yellow-700);
  --rs-btn-primary-hover-bg: var(--rs-yellow-600);
  --rs-btn-primary-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-hover-bg: var(--rs-yellow-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-yellow-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-yellow-500);
  --rs-btn-ghost-text: var(--rs-yellow-500);
  --rs-btn-ghost-hover-border: var(--rs-yellow-400);
  --rs-btn-ghost-hover-text: var(--rs-yellow-400);
  --rs-btn-ghost-active-border: var(--rs-yellow-200);
  --rs-btn-ghost-active-text: var(--rs-yellow-200);
  --rs-btn-link-text: var(--rs-yellow-500);
  --rs-btn-link-hover-text: var(--rs-yellow-400);
  --rs-btn-link-active-text: var(--rs-yellow-200);
}
.rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-500);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-700);
  --rs-btn-subtle-hover-bg: var(--rs-green-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-green-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-green-700);
  --rs-btn-ghost-text: var(--rs-green-700);
  --rs-btn-ghost-hover-border: var(--rs-green-800);
  --rs-btn-ghost-hover-text: var(--rs-green-800);
  --rs-btn-ghost-active-border: var(--rs-green-900);
  --rs-btn-ghost-active-text: var(--rs-green-900);
  --rs-btn-link-text: var(--rs-green-700);
  --rs-btn-link-hover-text: var(--rs-green-800);
  --rs-btn-link-active-text: var(--rs-green-900);
  --rs-iconbtn-primary-addon: var(--rs-green-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-green-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-green-800);
}
.rs-theme-dark .rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-700);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-400);
  --rs-btn-subtle-hover-bg: var(--rs-green-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-green-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-green-500);
  --rs-btn-ghost-text: var(--rs-green-500);
  --rs-btn-ghost-hover-border: var(--rs-green-400);
  --rs-btn-ghost-hover-text: var(--rs-green-400);
  --rs-btn-ghost-active-border: var(--rs-green-200);
  --rs-btn-ghost-active-text: var(--rs-green-200);
  --rs-btn-link-text: var(--rs-green-500);
  --rs-btn-link-hover-text: var(--rs-green-400);
  --rs-btn-link-active-text: var(--rs-green-200);
  --rs-iconbtn-primary-addon: var(--rs-green-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-green-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-green-400);
}
.rs-theme-high-contrast .rs-btn-green {
  --rs-btn-primary-bg: var(--rs-green-700);
  --rs-btn-primary-hover-bg: var(--rs-green-600);
  --rs-btn-primary-active-bg: var(--rs-green-400);
  --rs-btn-subtle-hover-bg: var(--rs-green-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-green-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-green-500);
  --rs-btn-ghost-text: var(--rs-green-500);
  --rs-btn-ghost-hover-border: var(--rs-green-400);
  --rs-btn-ghost-hover-text: var(--rs-green-400);
  --rs-btn-ghost-active-border: var(--rs-green-200);
  --rs-btn-ghost-active-text: var(--rs-green-200);
  --rs-btn-link-text: var(--rs-green-500);
  --rs-btn-link-hover-text: var(--rs-green-400);
  --rs-btn-link-active-text: var(--rs-green-200);
}
.rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-500);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-700);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-cyan-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-cyan-700);
  --rs-btn-ghost-text: var(--rs-cyan-700);
  --rs-btn-ghost-hover-border: var(--rs-cyan-800);
  --rs-btn-ghost-hover-text: var(--rs-cyan-800);
  --rs-btn-ghost-active-border: var(--rs-cyan-900);
  --rs-btn-ghost-active-text: var(--rs-cyan-900);
  --rs-btn-link-text: var(--rs-cyan-700);
  --rs-btn-link-hover-text: var(--rs-cyan-800);
  --rs-btn-link-active-text: var(--rs-cyan-900);
  --rs-iconbtn-primary-addon: var(--rs-cyan-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-cyan-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-800);
}
.rs-theme-dark .rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-700);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-cyan-500);
  --rs-btn-ghost-text: var(--rs-cyan-500);
  --rs-btn-ghost-hover-border: var(--rs-cyan-400);
  --rs-btn-ghost-hover-text: var(--rs-cyan-400);
  --rs-btn-ghost-active-border: var(--rs-cyan-200);
  --rs-btn-ghost-active-text: var(--rs-cyan-200);
  --rs-btn-link-text: var(--rs-cyan-500);
  --rs-btn-link-hover-text: var(--rs-cyan-400);
  --rs-btn-link-active-text: var(--rs-cyan-200);
  --rs-iconbtn-primary-addon: var(--rs-cyan-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-cyan-500);
  --rs-iconbtn-primary-pressed-addon: var(--rs-cyan-400);
}
.rs-theme-high-contrast .rs-btn-cyan {
  --rs-btn-primary-bg: var(--rs-cyan-700);
  --rs-btn-primary-hover-bg: var(--rs-cyan-600);
  --rs-btn-primary-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-hover-bg: var(--rs-cyan-600);
  --rs-btn-subtle-hover-text: var(--rs-gray-900);
  --rs-btn-subtle-active-bg: var(--rs-cyan-400);
  --rs-btn-subtle-active-text: var(--rs-gray-900);
  --rs-btn-ghost-border: var(--rs-cyan-500);
  --rs-btn-ghost-text: var(--rs-cyan-500);
  --rs-btn-ghost-hover-border: var(--rs-cyan-400);
  --rs-btn-ghost-hover-text: var(--rs-cyan-400);
  --rs-btn-ghost-active-border: var(--rs-cyan-200);
  --rs-btn-ghost-active-text: var(--rs-cyan-200);
  --rs-btn-link-text: var(--rs-cyan-500);
  --rs-btn-link-hover-text: var(--rs-cyan-400);
  --rs-btn-link-active-text: var(--rs-cyan-200);
}
.rs-btn-blue {
  --rs-btn-primary-bg: var(--rs-blue-500);
  --rs-btn-primary-hover-bg: var(--rs-blue-600);
  --rs-btn-primary-active-bg: var(--rs-blue-700);
  --rs-btn-subtle-hover-bg: var(--rs-blue-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-blue-600);
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-ghost-border: var(--rs-blue-700);
  --rs-btn-ghost-text: var(--rs-blue-700);
  --rs-btn-ghost-hover-border: var(--rs-blue-800);
  --rs-btn-ghost-hover-text: var(--rs-blue-800);
  --rs-btn-ghost-active-border: var(--rs-blue-900);
  --rs-btn-ghost-active-text: var(--rs-blue-900);
  --rs-btn-link-text: var(--rs-blue-700);
  --rs-btn-link-hover-text: var(--rs-blue-800);
  --rs-btn-link-active-text: var(--rs-blue-900);
  --rs-iconbtn-primary-addon: var(--rs-blue-600);
  --rs-iconbtn-primary-activated-addon: var(--rs-blue-700);
  --rs-iconbtn-primary-pressed-addon: var(--rs-blue-800);
}

.rs-btn-lg {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
}
.rs-btn-ghost.rs-btn-lg {
  padding: 9px 15px;
}
.rs-btn-icon.rs-btn-lg {
  padding: 11px 11px;
  line-height: 20px;
}
.rs-btn-icon.rs-btn-lg > .rs-icon {
  font-size: 20px;
}
.rs-btn-icon-with-text.rs-btn-lg {
  line-height: 22px;
}
.rs-btn-icon-with-text.rs-btn-lg > .rs-icon {
  padding: 11px 11px;
  width: 42px;
  height: 42px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left {
  padding: 10px 16px 10px 58px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right {
  padding: 10px 58px 10px 16px;
}
.rs-btn-icon-with-text.rs-btn-lg.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-btn-md {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
}
.rs-btn-ghost.rs-btn-md {
  padding: 7px 11px;
}
.rs-btn-icon.rs-btn-md {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-btn-md > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn-md {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-md > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-btn-md.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-btn-sm {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
}
.rs-btn-ghost.rs-btn-sm {
  padding: 4px 9px;
}
.rs-btn-icon.rs-btn-sm {
  padding: 7px 7px;
  line-height: 16px;
}
.rs-btn-icon.rs-btn-sm > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-btn-sm {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-sm > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-btn-sm.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-btn-xs {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
}
.rs-btn-ghost.rs-btn-xs {
  padding: 1px 7px;
}
.rs-btn-icon.rs-btn-xs {
  padding: 6px 6px;
  line-height: 12px;
}
.rs-btn-icon.rs-btn-xs > .rs-icon {
  font-size: 12px;
}
.rs-btn-icon-with-text.rs-btn-xs {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-btn-xs > .rs-icon {
  padding: 6px 6px;
  width: 24px;
  height: 24px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left {
  padding: 2px 8px 2px 32px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right {
  padding: 2px 32px 2px 8px;
}
.rs-btn-icon-with-text.rs-btn-xs.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-btn-block {
  display: block;
  width: 100%;
}
.rs-btn-block + .rs-btn-block {
  margin-top: 5px;
}
.rs-btn-loading {
  color: transparent !important;
  position: relative;
  cursor: default;
  pointer-events: none;
}
.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-loading > .rs-btn-spin::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  z-index: 1;
}
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::before,
.rs-btn-xs.rs-btn-loading > .rs-btn-spin::after {
  width: 16px;
  height: 16px;
}
.rs-btn-loading > .rs-btn-spin::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
  border: 3px solid var(--rs-loader-ring);
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
  border-color: rgba(248, 247, 250, 0.3);
}
.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
  border-color: rgba(247, 247, 250, 0.3);
  border-color: var(--rs-loader-ring-inverse);
}
.rs-btn-loading > .rs-btn-spin::after {
  border-width: 3px;
  border-color: #a6a6a6 transparent transparent;
  border-color: var(--rs-loader-rotor) transparent transparent;
  border-style: solid;
  -webkit-animation: buttonSpin 0.6s infinite linear;
  animation: buttonSpin 0.6s infinite linear;
}
.rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
  border-top-color: #fff;
}
.rs-theme-high-contrast .rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
  border-top-color: #fff;
  border-top-color: var(--rs-loader-rotor-inverse);
}
@-webkit-keyframes buttonSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes buttonSpin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rs-btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.rs-btn-group > .rs-btn {
  position: relative;
}
.rs-btn-group > .rs-btn:focus,
.rs-btn-group > .rs-btn:active {
  z-index: 2;
}
.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn {
  float: left;
}
.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn-ghost + .rs-btn-ghost {
  margin-left: -1px;
}
.rs-btn-group-vertical > .rs-btn {
  display: block;
  width: 100%;
  max-width: 100%;
}
.rs-btn-group-vertical > .rs-btn:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.rs-btn-group-vertical > .rs-btn:not(:first-child) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.rs-btn-group-vertical > .rs-btn-ghost + .rs-btn-ghost {
  margin-top: -1px;
}
.rs-btn-group-justified {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.rs-btn-group-justified > .rs-btn {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 1%;
  flex: 1 1 1%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-btn-toolbar {
  line-height: 0;
}
.rs-btn-toolbar > :not(:first-child):not(.rs-btn-block) {
  margin-left: 10px;
}
.rs-picker-subtle .picker-subtle-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
  cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active {
  background: none;
}
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active::after {
  display: none;
}
.rs-calendar {
  padding-top: 12px;
  position: relative;
  min-height: 266px;
  overflow: hidden;
}
.rs-calendar-bordered .rs-calendar-table {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;
}
.rs-calendar-bordered
  .rs-calendar-table-row:not(:last-child)
  .rs-calendar-table-cell,
.rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-cell {
  border-bottom: 1px solid #f2f2f5;
  border-bottom: 1px solid var(--rs-border-secondary);
}
.rs-calendar-bordered .rs-calendar-month-dropdown {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  border-radius: 6px;
}
.rs-calendar-panel .rs-calendar-header {
  width: 100%;
  padding-bottom: 6px;
}
.rs-calendar-panel .rs-calendar-header::before,
.rs-calendar-panel .rs-calendar-header::after {
  content: " ";
  display: table;
}
.rs-calendar-panel .rs-calendar-header::after {
  clear: both;
}
.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  float: right;
}
.rs-calendar-panel .rs-calendar-header-forward,
.rs-calendar-panel .rs-calendar-header-backward {
  float: none !important;
}
.rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-forward,
.rs-calendar-panel.rs-calendar-show-month-dropdown
  .rs-calendar-header-backward {
  display: inline-block;
  visibility: hidden;
  pointer-events: none;
}
.rs-calendar-panel .rs-calendar-header-month-toolbar {
  width: auto !important;
}
.rs-calendar-panel.rs-calendar-show-month-dropdown
  .rs-calendar-header-month-toolbar {
  padding-left: 0;
  padding-right: 0;
}
.rs-calendar-panel .rs-calendar-show-month-dropdown {
  padding-left: 0;
  padding-right: 0;
}
.rs-calendar-panel
  .rs-calendar-table-header-row
  .rs-calendar-table-cell-content {
  padding-top: 8px;
  padding-bottom: 8px;
}
.rs-calendar-panel
  .rs-calendar-table-cell-is-today
  .rs-calendar-table-cell-day {
  color: #fff;
  color: var(--rs-calendar-today-text);
  width: 20px;
  height: 20px;
  background-color: #3498ff;
  background-color: var(--rs-calendar-today-bg);
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.rs-calendar-panel
  .rs-calendar-table-cell-is-today
  .rs-calendar-table-cell-content {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs-calendar-panel
  .rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  background-color: inherit;
  color: inherit;
  -webkit-box-shadow: inset 0 0 0 1px #3498ff;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
  box-shadow: inset 0 0 0 1px #3498ff;
  box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
}
.rs-calendar-panel .rs-calendar-table-cell-day {
  margin-top: 3px;
  display: block;
  text-align: center;
}
.rs-calendar-panel .rs-calendar-month-dropdown {
  margin-top: -2px;
  margin-left: 12px;
  margin-right: 12px;
  top: 54px;
  width: calc(100% - 24px);
}
.rs-calendar-panel:not(.rs-calendar-compact)
  .rs-calendar-month-dropdown-scroll {
  height: 644px;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
  height: 344px;
}
.rs-calendar-panel:not(.rs-calendar-compact)
  .rs-calendar-table-row:not(.rs-calendar-table-header-row)
  .rs-calendar-table-cell-content {
  width: 100%;
  height: 100px;
  overflow: hidden;
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
  vertical-align: top;
}
.rs-calendar-panel .rs-calendar-table-cell {
  padding: 0 1px;
  line-height: 0;
}
.rs-calendar-panel .rs-calendar-table-cell-content {
  width: 100%;
  border-radius: 0;
}
.rs-calendar-panel
  .rs-calendar-table-row:last-child
  :first-child
  .rs-calendar-table-cell-content {
  border-bottom-left-radius: 6px;
}
.rs-calendar-panel
  .rs-calendar-table-row:last-child
  :last-child
  .rs-calendar-table-cell-content {
  border-bottom-right-radius: 6px;
}
.rs-calendar-panel.rs-calendar-compact
  .rs-calendar-table-row:not(.rs-calendar-table-header-row)
  .rs-calendar-table-cell-content {
  height: 50px;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-month-toolbar {
  padding-left: 24px;
  padding-right: 24px;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-backward,
.rs-calendar-show-month-dropdown .rs-calendar-header-forward {
  display: none;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
  color: #1675e0;
  color: var(--rs-text-active);
  background: transparent;
}
.rs-calendar-show-month-dropdown
  .rs-calendar-header-title-date.rs-calendar-header-error {
  color: #f44336;
  color: var(--rs-text-error);
}
.rs-calendar-show-month-dropdown
  .rs-calendar-header-title-date.rs-calendar-header-error:focus,
.rs-calendar-show-month-dropdown
  .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
  color: #fff !important;
}
.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
  color: #1675e0;
  color: var(--rs-text-active);
  background: transparent;
}
.rs-calendar-show-time-dropdown
  .rs-calendar-header-title-time.rs-calendar-header-error {
  color: #f44336;
  color: var(--rs-text-error);
}
.rs-calendar-show-time-dropdown
  .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
  color: #fff;
}
.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
  position: relative;
}
.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
  content: "";
  display: block;
  width: 100%;
  margin-top: 4px;
  height: 24px;
  position: absolute;
  z-index: 0;
  top: 0;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative;
}
.rs-calendar-table-cell-in-range::before {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-calendar-range-bg);
}
.rs-calendar-table-cell-in-range:hover::before {
  display: none;
}
.rs-calendar-month-dropdown-scroll div:focus {
  outline: none;
}
.rs-calendar-header {
  width: 280px;
  padding-left: 12px;
  padding-right: 12px;
}
.rs-calendar-header::before,
.rs-calendar-header::after {
  content: " ";
  display: table;
}
.rs-calendar-header::after {
  clear: both;
}
.rs-calendar-header-month-toolbar,
.rs-calendar-header-time-toolbar {
  display: inline-block;
}
.rs-calendar-header-month-toolbar {
  float: left;
}
.rs-calendar-header-time-toolbar {
  float: right;
}
.rs-calendar-header-title {
  margin: 0 4px;
}
.rs-calendar-header-meridian {
  font-size: 12px;
  margin-left: 4px;
}
.rs-calendar-header-error {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  --rs-btn-subtle-text: var(--rs-red-500);
  --rs-btn-subtle-hover-bg: var(--rs-red-500);
  --rs-btn-subtle-hover-text: #fff;
  --rs-btn-subtle-active-bg: var(--rs-red-600);
  --rs-btn-subtle-active-text: #fff;
}
.rs-calendar-header-error:hover,
.rs-calendar-header-error:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-calendar-header-error:active,
.rs-calendar-header-error.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-calendar-header-error:disabled,
.rs-calendar-header-error.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-calendar-header-error:disabled,
.rs-theme-high-contrast .rs-calendar-header-error.rs-btn-disabled {
  opacity: 0.5;
}
.rs-theme-dark .rs-calendar-header-error {
  --rs-btn-subtle-hover-bg: var(--rs-red-600);
  --rs-btn-subtle-active-bg: var(--rs-red-400);
}
.rs-calendar-header-btn-disabled,
.rs-calendar-header-btn-disabled:hover,
.rs-calendar-header-btn-disabled:hover:focus {
  cursor: not-allowed;
  opacity: 0.3;
  background: none;
}
.rs-calendar-header-btn-disabled::after {
  display: none !important;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar {
  display: block;
  text-align: center;
  width: 100%;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar::before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar::after {
  content: " ";
  display: table;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar::after {
  clear: both;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-backward {
  float: left;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-forward {
  float: right;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
  margin: 0 auto;
  text-align: center;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month)
  .rs-calendar-header-time-toolbar {
  float: none;
  display: inline-block;
}
.rs-calendar-header-has-month.rs-calendar-header-has-time {
  margin: 0 auto;
}
/* rtl:begin:ignore */
/* stylelint-disable-next-line */
[dir="rtl"] .rs-calendar-header-backward .rs-icon,
[dir="rtl"] .rs-calendar-header-forward .rs-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
/* rtl:end:ignore */
.rs-calendar-view {
  padding: 4px 12px 12px;
}
.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
  display: table-cell;
  width: 1%;
  padding: 1px 0;
  text-align: center;
  vertical-align: middle;
}
.rs-calendar-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.rs-calendar-table-row {
  display: table-row;
}
.rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.rs-calendar-table-cell {
  display: table-cell;
  width: 1%;
  padding: 1px 0;
  text-align: center;
  vertical-align: middle;
}
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
}
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content:hover {
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
  background-color: #3498ff;
  background-color: var(--rs-bg-active);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs-theme-high-contrast
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content:hover {
  text-decoration: underline;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  opacity: 0.3;
}
.rs-calendar-table-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}
.rs-calendar-table-cell-content:hover {
  background-color: #f2faff;
  background-color: var(--rs-state-hover-bg);
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  -webkit-box-shadow: inset 0 0 0 1px #3498ff;
  -webkit-box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
  box-shadow: inset 0 0 0 1px #3498ff;
  box-shadow: inset 0 0 0 1px var(--rs-input-focus-border);
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
  background-color: #3498ff;
  background-color: var(--rs-bg-active);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs-theme-high-contrast
  .rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  text-decoration: underline;
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: #8e8e93;
  color: var(--rs-text-secondary);
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:focus,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover:focus {
  background: none;
  cursor: auto;
}
.rs-calendar-table-cell-week-number {
  display: table-cell;
  width: 1%;
  padding: 1px;
  text-align: center;
  vertical-align: middle;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  background-color: #f7f7fa;
  background-color: var(--rs-bg-well);
  font-size: 12px;
}
.rs-calendar-month-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary);
  margin-top: 0;
  width: 100%;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  overflow: hidden;
  z-index: 1;
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown {
  display: block;
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
  animation: 0.3s linear slideDown;
}
.rs-calendar-month-dropdown-scroll {
  height: 230px;
}
.rs-calendar-month-dropdown-row {
  position: relative;
  padding-left: 52px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 1px dotted #e5e5ea;
  border-bottom: 1px dotted var(--rs-border-primary);
}
.rs-calendar-month-dropdown-year {
  position: absolute;
  top: calc(50% - 0.5em);
  left: 12px;
}
.rs-calendar-month-dropdown-year-active {
  color: #1675e0;
  color: var(--rs-text-active);
}
.rs-calendar-month-dropdown-list {
  display: block;
}
.rs-calendar-month-dropdown-list::before,
.rs-calendar-month-dropdown-list::after {
  content: " ";
  display: table;
}
.rs-calendar-month-dropdown-list::after {
  clear: both;
}
.rs-calendar-month-dropdown-cell {
  display: inline-block;
  float: left;
  width: calc((100% - 1px * 12) / 6);
  margin: 1px;
  text-align: center;
  vertical-align: middle;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6);
  }
}
@supports (-ms-ime-align: auto) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 1px * 12 - 1px) / 6);
  }
}
.rs-calendar-month-dropdown-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
}
.rs-calendar-month-dropdown-cell-content:hover {
  background-color: #f2faff;
  background-color: var(--rs-state-hover-bg);
}
.rs-calendar-month-dropdown-cell:not(
    .rs-calendar-month-dropdown-cell-active
  ).disabled
  .rs-calendar-month-dropdown-cell-content {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active.disabled
  .rs-calendar-month-dropdown-cell-content {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
  background-color: #3498ff;
  background-color: var(--rs-bg-active);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs-theme-high-contrast
  .rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  text-decoration: underline;
}
.rs-calendar-time-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  color: #575757;
  color: var(--rs-text-primary);
  overflow: hidden;
  z-index: 1;
}
.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown {
  display: block;
}
.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
  animation: 0.3s linear slideDown;
}
.rs-calendar-time-dropdown-row {
  display: table;
  width: 100%;
}
.rs-calendar-time-dropdown-column {
  display: table-cell;
  width: 1%;
}
.rs-calendar-time-dropdown-column > ul,
.rs-calendar-time-dropdown-column > ul > li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rs-calendar-time-dropdown-column > ul {
  height: 230px;
  overflow-y: auto;
  padding-bottom: 200px;
}
.rs-calendar-time-dropdown-column-title {
  width: 100%;
  background-color: #f7f7fa;
  background-color: var(--rs-calendar-time-unit-bg);
  color: #8e8e93;
  color: var(--rs-text-secondary);
  text-align: center;
  font-size: 12px;
  line-height: 1.66666667;
  padding-top: 2px;
  padding-bottom: 2px;
}
.rs-calendar-time-dropdown-cell {
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  padding: 5px;
  display: block;
  text-align: center;
  cursor: pointer;
}
.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell:hover,
.rs-calendar-time-dropdown-cell:focus {
  text-decoration: none;
}
.rs-calendar-time-dropdown-cell:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast .rs-calendar-time-dropdown-cell:hover {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-calendar-time-dropdown-cell:hover {
  outline-offset: 2px;
}
.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: #3498ff;
  background-color: var(--rs-bg-active);
  color: #fff;
  color: var(--rs-calendar-date-selected-text);
}
.rs-theme-high-contrast
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active:hover {
  outline-offset: 2px;
}
.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-disabled {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
  background: none;
  cursor: not-allowed;
  text-decoration: line-through;
}
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
  opacity: 0.3;
  cursor: not-allowed;
}
@-webkit-keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes moveLeftHalf {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@keyframes moveLeftHalf {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  100% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@-webkit-keyframes moveLeftHalf-vertical {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@keyframes moveLeftHalf-vertical {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  100% {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.rs-picker-cascader-menu-items > div::before,
.rs-picker-cascader-menu-items > div::after {
  content: " ";
  display: table;
}
.rs-picker-cascader-menu-items > div::after {
  clear: both;
}
.rs-picker-cascader-search-panel {
  max-height: 300px;
  overflow: auto;
}
.rs-picker-cascader-row {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  /* stylelint-disable-next-line */
}
.ReactVirtualized__Grid .rs-picker-cascader-row {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-picker-cascader-row:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast .rs-picker-cascader-row:hover {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast .rs-theme-high-contrast .rs-picker-cascader-row:hover {
  outline-offset: 2px;
}
.rs-picker-cascader-row.rs-picker-cascader-row-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast .rs-picker-cascader-row.rs-picker-cascader-row-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-picker-cascader-row.rs-picker-cascader-row-focus {
  outline-offset: 2px;
}
.rs-picker-cascader-row:disabled,
.rs-picker-cascader-row.rs-picker-cascader-row-disabled {
  color: #c5c6c7;
  color: var(--rs-listbox-option-disabled-text);
  background: none;
  cursor: not-allowed;
}
.rs-picker-cascader-row:disabled .rs-picker-cascader-search-match,
.rs-picker-cascader-row.rs-picker-cascader-row-disabled
  .rs-picker-cascader-search-match {
  opacity: 0.7;
  font-weight: normal;
}
.rs-picker-cascader-col {
  display: inline-block;
}
.rs-picker-cascader-col::after {
  content: "\00a0/\00a0";
}
.rs-picker-cascader-col:last-child::after {
  display: none;
}
.rs-picker-cascader-search-match {
  color: #3498ff;
  color: var(--rs-primary-500);
  font-weight: bold;
}
.rs-picker-cascader-menu-column {
  float: left;
  overflow-y: auto;
}
.rs-picker-cascader-menu-column-loading {
  padding: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.rs-picker-cascader-menu-column-loading .rs-icon {
  margin: 4px;
}
.rs-picker-cascader-menu-items {
  padding: 6px 0;
}
.rs-picker-cascader-menu-items ul,
.rs-picker-cascader-menu-items li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.rs-picker-cascader-menu-item {
  display: block;
  padding: 8px 12px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #575757;
  color: var(--rs-text-primary);
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  /* stylelint-disable-next-line */
  position: relative;
  padding: 8px 28px 8px 12px;
  word-break: break-word;
}
.ReactVirtualized__Grid .rs-picker-cascader-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-picker-cascader-menu-items-has-children .rs-picker-cascader-menu-item {
  padding-right: 32px;
}
.rs-picker-cascader-menu-item:hover,
.rs-picker-cascader-menu-item:focus,
.rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus,
.rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast .rs-picker-cascader-menu-item:hover,
.rs-theme-high-contrast .rs-picker-cascader-menu-item:focus,
.rs-theme-high-contrast
  .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus,
.rs-theme-high-contrast
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-picker-cascader-menu-item:hover,
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-picker-cascader-menu-item:focus,
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus,
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus {
  outline-offset: 2px;
}
.rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
  background-color: #f2faff;
  background-color: var(--rs-listbox-option-selected-bg);
  font-weight: bold;
  color: #1675e0;
  color: var(--rs-listbox-option-selected-text);
}
.rs-theme-high-contrast
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
  text-decoration: underline;
}
.rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active
  .rs-picker-cascader-menu-caret {
  color: #575757;
  color: var(--rs-text-primary);
}
.rs-picker-cascader-menu-item:disabled,
.rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-disabled {
  color: #c5c6c7;
  color: var(--rs-listbox-option-disabled-text);
  background: none;
  cursor: not-allowed;
}
.rs-picker-cascader-menu-caret {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
}
.rs-checkbox {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.rs-checkbox-disabled label {
  cursor: not-allowed;
}
.rs-checkbox label {
  line-height: 1.14285714;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}
.rs-checkbox-inner::before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg);
}
.rs-checkbox.rs-checkbox-disabled label {
  cursor: not-allowed;
}
.rs-checkbox-disabled > .rs-checkbox-checker > label {
  color: #c5c6c7;
  color: var(--rs-text-disabled);
}
.rs-checkbox-inline {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  margin-top: 0;
  margin-left: 10px;
}
.rs-plaintext .rs-checkbox-inline:first-child {
  margin-left: 0;
}
.rs-checkbox [type="checkbox"]:focus-visible ~ .rs-checkbox-inner::before {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: var(--rs-state-focus-shadow);
  box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  box-shadow: var(--rs-state-focus-shadow);
}
.rs-checkbox-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 36px;
  min-height: 36px;
  line-height: 1;
  position: relative;
}
/* rtl:begin:ignore */
.rs-checkbox-wrapper::before,
.rs-checkbox-wrapper::after,
.rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
}
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  opacity: 0;
  -webkit-transform: rotate(45deg) scale(0);
  transform: rotate(45deg) scale(0);
  -webkit-transition: opacity 0.2s ease-in,
    -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in,
    -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in,
    transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: opacity 0.2s ease-in,
    transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46),
    -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner::after {
  -webkit-transition: none;
  transition: none;
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border: solid #fff;
  border: solid var(--rs-checkbox-icon);
  width: 6px;
  height: 9px;
  margin-top: 2px;
  margin-left: 5px;
  opacity: 1;
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
}
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border-width: 0 0 2px;
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  width: 10px;
  margin-top: 0;
  margin-left: 3px;
}
/* rtl:end:ignore */
/* stylelint-disable-next-line */
.rs-checkbox-wrapper {
  position: absolute;
  width: 16px;
  height: 16px;
  display: inline-block;
  left: 10px;
  top: 10px;
}
.rs-checkbox-wrapper [type="checkbox"] {
  width: 0;
  height: 0;
  opacity: 0;
}
.rs-checkbox-wrapper::before,
.rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-wrapper .rs-checkbox-inner::after {
  width: 16px;
  height: 16px;
}
.rs-checkbox-wrapper::before {
  border: 1px solid #3498ff;
  border: 1px solid var(--rs-checkbox-checked-bg);
  background-color: transparent;
  border-radius: 3px;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0.7;
  visibility: hidden;
  -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
  transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
  transition: transform 0.2s linear, opacity 0.2s linear;
  transition: transform 0.2s linear, opacity 0.2s linear,
    -webkit-transform 0.2s linear;
}
.rs-theme-high-contrast .rs-checkbox-wrapper::before {
  -webkit-transition: none;
  transition: none;
}
.rs-checkbox-checked .rs-checkbox-wrapper::before {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0;
  visibility: visible;
}
.rs-checkbox-wrapper::after {
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
}
.rs-checkbox-wrapper .rs-checkbox-inner::before {
  border: 1px solid #d9d9d9;
  border: 1px solid var(--rs-checkbox-border);
  background-color: transparent;
  border-radius: 3px;
  -webkit-transition: background 0.2s linear, border 0.2s linear,
    -webkit-box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear,
    -webkit-box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
  transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear,
    -webkit-box-shadow 0.2s linear;
}
.rs-theme-high-contrast .rs-checkbox-wrapper .rs-checkbox-inner::before {
  -webkit-transition: none;
  transition: none;
}
label:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg);
}
.rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate)
  .rs-checkbox-wrapper
  .rs-checkbox-inner::before {
  border-color: #f7f7fa;
  border-color: var(--rs-checkbox-disabled-bg);
  background-color: #f7f7fa;
  background-color: var(--rs-checkbox-disabled-bg);
}
.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  border-color: #3498ff;
  border-color: var(--rs-checkbox-checked-bg);
  background-color: #3498ff;
  background-color: var(--rs-checkbox-checked-bg);
}
.rs-checkbox-disabled.rs-checkbox-checked
  .rs-checkbox-wrapper
  .rs-checkbox-inner::before,
.rs-checkbox-disabled.rs-checkbox-indeterminate
  .rs-checkbox-wrapper
  .rs-checkbox-inner::before {
  opacity: 0.3;
}
.rs-theme-high-contrast
  .rs-checkbox-disabled.rs-checkbox-checked
  .rs-checkbox-wrapper
  .rs-checkbox-inner::before,
.rs-theme-high-contrast
  .rs-checkbox-disabled.rs-checkbox-indeterminate
  .rs-checkbox-wrapper
  .rs-checkbox-inner::before {
  opacity: 0.5;
}
.rs-checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.rs-checkbox-group-inline {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-left: -10px;
}
.rs-picker-check-menu-items {
  margin-bottom: 6px;
  overflow-y: auto;
}
.rs-picker-check-menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rs-picker-check-menu-items .rs-picker-menu-group:not(:first-child) {
  margin-top: 6px;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary);
  padding-top: 6px;
}
.rs-picker-check-menu-items .rs-picker-menu-group-title {
  padding: 8px 12px;
  padding-right: 32px;
  position: relative;
  cursor: default;
  -webkit-transition: color 0.3s linear, background-color 0.3s linear;
  transition: color 0.3s linear, background-color 0.3s linear;
  color: #272c36;
  color: var(--rs-text-heading);
}
.rs-picker-check-menu-items .rs-picker-menu-group-title:hover {
  background-color: #f2faff;
  background-color: var(--rs-state-hover-bg);
}
.rs-picker-check-menu-items
  .rs-picker-menu-group-title
  .rs-picker-menu-group-caret {
  display: inline-block;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  -webkit-transition: -webkit-transform 0.3s linear;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  padding: 3px;
}
.rs-picker-check-menu-items
  .rs-picker-menu-group.folded
  .rs-picker-menu-group-caret {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rs-picker-check-menu-items
  .rs-check-item.rs-checkbox-checked
  .rs-checkbox-checker
  > label {
  font-weight: bold;
}
.rs-check-tree {
  max-height: 360px;
  height: 100%;
  overflow-y: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.rs-check-tree.rs-check-tree-virtualized {
  overflow: hidden;
  /* stylelint-disable-next-line */
}
.rs-check-tree.rs-check-tree-virtualized
  .ReactVirtualized__Grid.ReactVirtualized__List {
  padding: 0 12px 12px 0;
}
.rs-check-tree.rs-check-tree-virtualized
  .ReactVirtualized__Grid.ReactVirtualized__List:focus {
  outline: none;
}
.rs-check-tree .rs-check-item .rs-checkbox-checker > label {
  text-align: left;
  position: relative;
  margin: 0;
  padding: 8px 12px;
  padding-left: 50px;
}
.rs-check-tree .rs-check-item .rs-checkbox-checker > label::before {
  content: "";
  position: absolute;
  width: 46px;
  height: 100%;
  top: 0;
  margin-left: -52px;
}
.rs-check-tree .rs-check-item .rs-checkbox-checker .rs-checkbox-wrapper {
  left: 20px;
}
.rs-check-tree-without-children .rs-check-item .rs-checkbox-checker > label {
  padding-left: 32px;
}
.rs-check-tree-without-children
  .rs-check-item
  .rs-checkbox-checker
  > label::before {
  width: 28px;
  margin-left: -34px;
}
.rs-check-tree-without-children
  .rs-check-item
  .rs-checkbox-checker
  .rs-checkbox-wrapper {
  left: 0;
}
.rs-check-tree-node {
  position: relative;
  font-size: 14px;
  line-height: 22px;
}
.rs-check-tree-node .rs-check-item {
  display: inline-block;
}
.rs-picker-menu .rs-check-tree-node .rs-check-item {
  display: block;
}
.rs-check-tree-node:focus .rs-check-item .rs-checkbox-checker > label {
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #f2faff;
  background-color: var(--rs-state-hover-bg);
}
.rs-check-tree-node
  .rs-check-item.rs-checkbox-checked
  .rs-checkbox-checker
  > label {
  font-weight: bold;
}
.rs-check-tree-node-all-uncheckable
  .rs-check-item
  .rs-checkbox-checker
  > label {
  padding-left: 22px;
}
.rs-check-tree-node-all-uncheckable
  .rs-check-item
  .rs-checkbox-checker
  > label::before {
  width: 14px;
  margin-left: 0;
  left: 0;
}
.rs-check-tree-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1;
}
.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
  display: inline-block;
  padding: 8px 0;
  padding-right: 8px;
  height: 36px;
  font-size: 16px;
  line-height: 22px;
  -webkit-transform: rotate(-90deg)
    /* rtl: rotate(90deg) translateX(-13px) translateY(-15px) */;
  transform: rotate(-90deg)
    /* rtl: rotate(90deg) translateX(-13px) translateY(-15px) */;
  -webkit-transform-origin: 1.5px 14px;
  transform-origin: 1.5px 14px;
  margin-top: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rs-check-tree-open
  > .rs-check-tree-node
  .rs-check-tree-node-expand-icon-wrapper
  > .rs-check-tree-node-expand-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: 0;
  margin-top: 0;
}
.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  margin-left: 0;
  margin-top: 0;
}
.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-custom-icon,
.rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-loading-icon {
  width: 14px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
}
.rs-check-tree-node-children > .rs-check-tree-children {
  position: relative;
  display: none;
}
.rs-check-tree-open.rs-check-tree-node-children > .rs-check-tree-children {
  display: block;
}
.rs-check-tree-indent-line {
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  margin-left: -13px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.rs-picker-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.rs-picker-menu.rs-check-tree-menu {
  padding-top: 12px;
}
.rs-picker-menu.rs-check-tree-menu .rs-picker-search-bar {
  padding-top: 0;
}
.rs-picker-menu .rs-check-tree {
  padding: 0 12px 12px 0;
}
.rs-picker-menu .rs-check-tree.rs-check-tree-virtualized {
  padding: 0;
}
.rs-picker-menu
  .rs-check-tree-node
  > .rs-check-tree-node-label
  .rs-check-tree-node-text-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
/* rtl:begin:ignore */
[dir="rtl"] .rs-check-tree-node-expand-icon-wrapper {
  right: 0;
  padding-right: inherit;
}
/* stylelint-disable-next-line */
.rs-check-tree {
  /* stylelint-disable-next-line */
}
.rs-check-tree-node
  > .rs-check-tree-node-label
  .rs-check-item
  .rs-checkbox-checker
  > label::before {
  /* stylelint-disable-next-line */
}
[dir="rtl"]
  .ReactVirtualized__Grid__innerScrollContainer
  .rs-check-tree-node
  > .rs-check-tree-node-label
  .rs-check-item
  .rs-checkbox-checker
  > label::before {
  padding-right: inherit;
  right: 46px;
}
/* rtl:end:ignore */
.rs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}
.rs-container-has-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.rs-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.rs-form-control-label {
  margin-bottom: 4px;
}
.rs-form-plaintext .rs-form-control-label {
  color: #8e8e93;
  color: var(--rs-text-secondary);
}
.rs-picker-date.rs-picker-default .rs-picker-toggle-caret,
.rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret,
.rs-picker-date.rs-picker-default .rs-picker-toggle-clean,
.rs-picker-daterange.rs-picker-default .rs-picker-toggle-clean {
  top: 7px;
}
.rs-picker-toolbar {
  padding: 12px;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-border-primary);
}
.rs-picker-date-inline {
  height: 299px;
}
.rs-picker-date-inline .rs-calendar {
  height: 286px;
}
.rs-picker-menu .rs-picker-toolbar {
  max-width: 100%;
}
.rs-picker-menu .rs-picker-toolbar-ranges {
  max-width: 400px;
}
.rs-picker-menu .rs-calendar {
  min-width: 246px;
  display: block;
  margin: 0 auto;
}
.rs-picker-menu .rs-calendar-show-week-numbers {
  min-width: 278px;
}
.rs-picker-menu .rs-calendar-header {
  width: 100%;
}
.rs-picker-menu .rs-calendar-table {
  width: unset;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  width: 30px;
  height: 30px;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast
  .rs-picker-menu
  .rs-calendar
  .rs-calendar-month-dropdown-cell-content:hover {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-picker-menu
  .rs-calendar
  .rs-calendar-month-dropdown-cell-content:hover {
  outline-offset: 2px;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-header-row
  .rs-calendar-table-cell-content {
  height: 24px;
  padding-top: 0;
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
}
.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: rgba(204, 233, 255, 0.5);
  background-color: var(--rs-listbox-option-hover-bg);
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
}
.rs-theme-high-contrast
  .rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
  outline-offset: -3px;
  outline-width: 2px;
  color: #1675e0;
  color: var(--rs-listbox-option-hover-text);
  text-decoration: underline;
}
.rs-theme-high-contrast
  .rs-theme-high-contrast
  .rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  outline-offset: 2px;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
  height: 227px;
}
.rs-picker-menu .rs-calendar .rs-calendar-time-dropdown-column > ul {
  height: 214px;
  padding-bottom: 184px;
}
.rs-picker-daterange-menu .rs-calendar {
  display: inline-block;
  height: 276px;
  padding-bottom: 12px;
}
.rs-picker-daterange-menu .rs-calendar:first-child {
  border-right: 1px solid #e5e5ea;
  border-right: 1px solid var(--rs-border-primary);
}
.rs-picker-daterange-menu .rs-calendar-header {
  width: 100%;
  text-align: center;
}
.rs-picker-daterange-menu .rs-calendar-header-month-toolbar {
  float: none;
}
.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  z-index: 1;
}
.rs-picker-daterange-menu .rs-calendar-month-dropdown-list {
  width: 185px;
}
.rs-picker-daterange-menu
  .rs-picker-daterange-panel-show-one-calendar
  .rs-picker-toolbar {
  max-width: 255px;
}
.rs-picker-daterange-menu
  .rs-picker-daterange-panel-show-one-calendar
  .rs-picker-toolbar-ranges {
  width: 190px;
}
.rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {
  border: 0;
  display: block;
  margin: auto;
}
.rs-picker-daterange-header {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-bottom: 1px solid #e5e5ea;
  border-bottom: 1px solid var(--rs-border-primary);
}
.rs-picker-daterange-calendar-group {
  height: 274px;
  min-width: 492px;
}
.rs-picker-daterange-predefined {
  height: 366px;
  border-right: 1px solid #e5e5ea;
  border-right: 1px solid var(--rs-border-primary);
  padding: 4px 0;
}
.rs-picker-daterange-predefined .rs-btn {
  display: block;
}
.rs-divider {
  background-color: #e5e5ea;
  background-color: var(--rs-divider-border);
}
.rs-divider-vertical {
  display: inline-block;
  height: 1em;
  width: 1px;
  vertical-align: middle;
  margin: 0 12px;
}
.rs-divider-horizontal {
  height: 1px;
  margin: 24px 0;
}
.rs-divider-horizontal.rs-divider-with-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  margin: 30px 0;
}
.rs-divider-horizontal.rs-divider-with-text::before,
.rs-divider-horizontal.rs-divider-with-text::after {
  content: "";
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-top: 1px solid #e5e5ea;
  border-top: 1px solid var(--rs-divider-border);
}
.rs-divider-inner-text {
  padding: 0 12px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
@-webkit-keyframes shakeHead {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  25%,
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shakeHead {
  0%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  25%,
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
.rs-drawer-wrapper {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rs-drawer {
  display: none;
  overflow: hidden;
  position: fixed;
  z-index: 1050;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-drawer-shadow);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-drawer-shadow);
  outline: 0;
}
.rs-drawer-open.rs-drawer-has-backdrop {
  overflow: hidden;
}
.rs-drawer-left,
.rs-drawer-right {
  top: 0;
  height: 100%;
}
.rs-drawer-left.rs-drawer-lg,
.rs-drawer-right.rs-drawer-lg {
  width: 968px;
}
.rs-drawer-left.rs-drawer-md,
.rs-drawer-right.rs-drawer-md {
  width: 800px;
}
.rs-drawer-left.rs-drawer-sm,
.rs-drawer-right.rs-drawer-sm {
  width: 600px;
}
.rs-drawer-left.rs-drawer-xs,
.rs-drawer-right.rs-drawer-xs {
  width: 400px;
}
.rs-drawer-top,
.rs-drawer-bottom {
  width: 100%;
}
.rs-drawer-top.rs-drawer-lg,
.rs-drawer-bottom.rs-drawer-lg {
  height: 568px;
}
.rs-drawer-top.rs-drawer-md,
.rs-drawer-bottom.rs-drawer-md {
  height: 480px;
}
.rs-drawer-top.rs-drawer-sm,
.rs-drawer-bottom.rs-drawer-sm {
  height: 400px;
}
.rs-drawer-top.rs-drawer-xs,
.rs-drawer-bottom.rs-drawer-xs {
  height: 290px;
}
.rs-drawer-full.rs-drawer-top,
.rs-drawer-full.rs-drawer-bottom {
  height: calc(100% - 60px);
}
.rs-drawer-full.rs-drawer-top .rs-drawer-content,
.rs-drawer-full.rs-drawer-bottom .rs-drawer-content {
  height: 100%;
}
.rs-drawer-full.rs-drawer-left,
.rs-drawer-full.rs-drawer-right {
  width: calc(100% - 60px);
}
.rs-drawer-right {
  right: 0;
}
.rs-drawer-left {
  left: 0;
}
.rs-drawer-top {
  top: 0;
}
.rs-drawer-bottom {
  bottom: 0;
}
.rs-drawer-open .rs-drawer {
  overflow: visible;
}
.rs-drawer-dialog {
  position: relative;
  width: 100%;
  height: 100%;
}
.rs-drawer-shake .rs-drawer-dialog {
  -webkit-animation: 0.3s linear shakeHead;
  animation: 0.3s linear shakeHead;
}
.rs-drawer-content {
  position: absolute;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  outline: 0;
  width: 100%;
  height: 100%;
}
.rs-theme-high-contrast .rs-drawer-content {
  background-color: var(--rs-drawer-bg);
}
.rs-drawer-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: rgba(39, 44, 54, 0.3);
  background-color: var(--rs-bg-backdrop);
}
.rs-drawer-backdrop.rs-anim-fade {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}
.rs-drawer-backdrop.rs-anim-in {
  opacity: 1;
}
.rs-drawer-header {
  position: relative;
  padding: 20px 40px 20px 60px;
  border-bottom: 1px solid #e5e5ea;
  border-bottom: 1px solid var(--rs-border-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.rs-drawer-header::before,
.rs-drawer-header::after {
  content: " ";
  display: table;
}
.rs-drawer-header::after {
  clear: both;
}
.rs-drawer-header .rs-drawer-header-close {
  position: absolute;
  left: 15px;
  top: 23px;
}
.rs-drawer-title {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  margin: 0;
  color: #272c36;
  color: var(--rs-text-heading);
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rs-drawer-actions {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: right;
  border-top: none;
  margin-left: auto;
}
.rs-drawer-actions::before,
.rs-drawer-actions::after {
  content: " ";
  display: table;
}
.rs-drawer-actions::after {
  clear: both;
}
.rs-drawer-title ~ .rs-drawer-actions {
  margin-left: 10px;
}
.rs-drawer-actions .rs-btn + .rs-btn {
  margin-left: 10px;
  margin-bottom: 0;
}
.rs-drawer-actions .rs-btn-group .rs-btn + .rs-btn {
  margin-left: -1px;
}
.rs-drawer-actions .rs-btn-block + .rs-btn-block {
  margin-left: 0;
}
.rs-drawer-body {
  position: relative;
  padding: 30px 60px;
  height: 100%;
  overflow: auto;
}
.rs-drawer-header + .rs-drawer-body {
  height: calc(100% - 76px);
}
.rs-drawer-body-close {
  position: absolute;
  left: 15px;
  top: 25px;
}
.rs-drawer-header ~ .rs-drawer-body .rs-drawer-body-close {
  display: none;
}
.rs-drawer-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.rs-drawer-footer {
  text-align: right;
  border-top: none;
  margin: 0 20px 20px;
}
.rs-drawer-footer::before,
.rs-drawer-footer::after {
  content: " ";
  display: table;
}
.rs-drawer-footer::after {
  clear: both;
}
.rs-drawer-footer .rs-btn + .rs-btn {
  margin-left: 10px;
  margin-bottom: 0;
}
.rs-drawer-footer .rs-btn-group .rs-btn + .rs-btn {
  margin-left: -1px;
}
.rs-drawer-footer .rs-btn-block + .rs-btn-block {
  margin-left: 0;
}
.rs-dropdown-toggle-caret {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
}
@-webkit-keyframes slideUpIn {
  0% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }
  100% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes slideUpIn {
  0% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }
  100% {
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

.rs-picker-toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.rs-picker-toggle.rs-btn .rs-ripple-pond {
  display: none !important;
}
.rs-picker-block {
  display: block;
}
.rs-picker-disabled {
  opacity: 0.3;
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary);
  font-family: "Montserrat" !important;
  font-size: 13px;
  font-weight: 500;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #1675e0;
  color: var(--rs-picker-value);
}
.rs-picker-none {
  padding: 6px 12px 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  cursor: default;
}
.rs-picker-countable .rs-picker-toggle-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.rs-picker-value-list {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-value-list {
    max-width: 100%;
  }
}
.rs-picker-value-count {
  margin: 0 4px;
  background-color: #3498ff;
  background-color: var(--rs-picker-count-bg);
  color: #fff;
  color: var(--rs-picker-count-text);
  border-radius: 10px;
  padding: 0 8px;
  line-height: 20px;
}
.rs-picker-value-separator {
  margin: 0 4px 0 0;
}
.rs-picker:not(.rs-picker-disabled):hover,
.rs-picker.rs-picker-focused {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border);
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  -webkit-box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: var(--rs-state-focus-shadow);
  box-shadow: 0 0 0 3px rgba(52, 152, 255, 0.25);
  box-shadow: var(--rs-state-focus-shadow);
}
.rs-picker-toggle {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: color 0.2s linear, background-color 0.3s linear;
  transition: color 0.2s linear, background-color 0.3s linear;
  border: none;
  border: var(--rs-btn-default-border, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  color: #575757;
  color: var(--rs-btn-default-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-bg);
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  overflow: hidden;
  position: relative;
}
.rs-theme-high-contrast .rs-picker-toggle {
  -webkit-transition: none;
  transition: none;
}
.rs-btn-ghost.rs-picker-toggle {
  padding: 7px 11px;
}
.rs-btn-icon.rs-picker-toggle {
  padding: 10px 10px;
  line-height: 16px;
}
.rs-btn-icon.rs-picker-toggle > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle > .rs-icon {
  padding: 10px 10px;
  width: 36px;
  height: 36px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left {
  padding: 8px 12px 8px 48px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-left > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right {
  padding: 8px 48px 8px 12px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-icon-placement-right > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle:focus-visible {
  outline: 3px solid rgba(52, 152, 255, 0.25);
  outline: 3px solid var(--rs-color-focus-ring);
}
.rs-theme-high-contrast .rs-picker-toggle:focus-visible {
  outline-offset: 2px;
}
.rs-picker-toggle:hover,
.rs-picker-toggle:focus {
  color: var(--rs-btn-default-hover-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-default-hover-bg);
  text-decoration: none;
}
.rs-picker-toggle:active,
.rs-picker-toggle.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-default-active-text);
  background-color: #d9d9d9;
  background-color: var(--rs-btn-default-active-bg);
}
.rs-picker-toggle:disabled,
.rs-picker-toggle.rs-btn-disabled {
  cursor: not-allowed;
  color: #c5c6c7;
  color: var(--rs-btn-default-disabled-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-default-disabled-bg);
}
.rs-theme-high-contrast .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5;
}
@media not all and (min-resolution: 0.001dpcm) {
  .rs-picker-toggle {
    /* stylelint-disable */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-enable */
  }
}
.rs-picker-default .rs-picker-toggle {
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
}
.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
  -webkit-transition: none;
  transition: none;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-picker-toggle {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  -webkit-transition: none;
  transition: none;
}
.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-picker-toggle:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-picker-subtle .rs-picker-toggle:disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5;
}
.rs-picker-subtle .rs-picker-toggle-active {
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
}
.rs-picker-default .rs-picker-toggle {
  padding: 7px 11px;
}
.rs-picker-toggle-textbox {
  font-family: "Montserrat" !important;
  font-size: 13px;
  position: absolute;
  font-weight: 500;
  width: 100%;
  height: 100%;
  left: 0;
  top: -1px;
  border: 1px solid #0000;
  padding-left: 10px;
  padding-right: 32px;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-input-bg);
  outline: none;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-textbox {
  padding-left: 14px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-textbox {
  padding-left: 8px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-textbox {
  padding-left: 6px;
}
.rs-picker-toggle-read-only {
  opacity: 0;
  font-family: "Montserrat" !important;
}
.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle {
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
}
.rs-theme-high-contrast .rs-picker-default .rs-btn,
.rs-theme-high-contrast .rs-picker-default .rs-picker-toggle {
  -webkit-transition: none;
  transition: none;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-btn,
.rs-picker-subtle .rs-picker-toggle {
  color: #8e8e93;
  color: var(--rs-btn-subtle-text);
  background-color: transparent;
  border: none;
  -webkit-transition: none;
  transition: none;
}
.rs-picker-subtle .rs-btn:hover,
.rs-picker-subtle .rs-picker-toggle:hover,
.rs-picker-subtle .rs-btn:focus,
.rs-picker-subtle .rs-picker-toggle:focus {
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
}
.rs-picker-subtle .rs-btn:active,
.rs-picker-subtle .rs-picker-toggle:active,
.rs-picker-subtle .rs-btn.rs-btn-active,
.rs-picker-subtle .rs-picker-toggle.rs-btn-active {
  color: #272c36;
  color: var(--rs-btn-subtle-active-text);
  background-color: #e5e5ea;
  background-color: var(--rs-btn-subtle-active-bg);
}
.rs-picker-subtle .rs-btn:disabled,
.rs-picker-subtle .rs-picker-toggle:disabled,
.rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  color: #c5c6c7;
  color: var(--rs-btn-subtle-disabled-text);
  background: none;
}
.rs-theme-high-contrast .rs-picker-subtle .rs-btn:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle:disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-btn.rs-btn-disabled,
.rs-theme-high-contrast .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled {
  opacity: 0.5;
}
.rs-picker-subtle .rs-btn-active,
.rs-picker-subtle .rs-picker-toggle-active {
  background-color: #f7f7fa;
  background-color: var(--rs-btn-subtle-hover-bg);
  color: #575757;
  color: var(--rs-btn-subtle-hover-text);
}
/* stylelint-disable-next-line */
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 32px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  padding-right: 44px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 7px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 5px;
}
.rs-picker-tag
  .rs-picker-toggle.rs-btn
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-left: 12px;
  margin-top: 5px;
  font-size: 14px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 36px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 8px;
}
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-caret,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-caret {
  top: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-lg {
  font-size: 16px;
  line-height: 22px;
  padding: 10px 16px;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
  padding-right: 36px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
  padding-right: 50px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 10px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 9px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 16px;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 32px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-left: 15px;
}
.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn-lg
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 1.375;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  padding-bottom: 6px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 6px;
}
.rs-picker-tag
  .rs-picker-toggle.rs-btn-lg
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-left: 16px;
  margin-top: 6px;
  font-size: 16px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper input {
  height: 20px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg {
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
  padding-right: 42px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 10px;
}
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-caret,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-lg
  .rs-picker-toggle-caret {
  top: 9px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 16px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 36px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-top: 9px;
  padding-bottom: 9px;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-lg {
  padding: 9px 15px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-lg {
  padding: 11px 11px;
  line-height: 20px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-lg > .rs-icon {
  font-size: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg {
  line-height: 22px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg > .rs-icon {
  padding: 11px 11px;
  width: 42px;
  height: 42px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left {
  padding: 10px 16px 10px 58px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-left
  > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right {
  padding: 10px 58px 10px 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-lg.rs-btn-icon-placement-right
  > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
  line-height: 22px;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
  padding-right: 32px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-md {
  padding-right: 44px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 8px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 7px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-left: 11px;
}
.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn-md
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper {
  padding-bottom: 5px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 5px;
}
.rs-picker-tag
  .rs-picker-toggle.rs-btn-md
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-left: 12px;
  margin-top: 5px;
  font-size: 14px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
  padding-right: 36px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  top: 8px;
}
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-md
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-md
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-md
  .rs-picker-toggle-caret,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-md
  .rs-picker-toggle-caret {
  top: 7px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 12px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 30px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-picker-toggle.rs-btn-sm {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 10px;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-sm {
  padding: 4px 9px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-sm {
  padding: 7px 7px;
  line-height: 16px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-sm > .rs-icon {
  font-size: 16px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm > .rs-icon {
  padding: 7px 7px;
  width: 30px;
  height: 30px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left {
  padding: 5px 10px 5px 40px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-left
  > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right {
  padding: 5px 40px 5px 10px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-sm.rs-btn-icon-placement-right
  > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
  padding-right: 30px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm {
  padding-right: 42px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 5px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 4px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 10px;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 28px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-left: 9px;
}
.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn-sm
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 4px 10px;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
  padding-bottom: 2px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 2px;
}
.rs-picker-tag
  .rs-picker-toggle.rs-btn-sm
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-left: 10px;
  margin-top: 2px;
  font-size: 14px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  padding-right: 34px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 4px;
}
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-sm
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-sm
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-sm
  .rs-picker-toggle-caret,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-sm
  .rs-picker-toggle-caret {
  top: 3px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 10px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 28px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-top: 4px;
  padding-bottom: 4px;
}
.rs-picker-toggle.rs-btn-xs {
  font-size: 12px;
  line-height: 20px;
  padding: 2px 8px;
  /* stylelint-disable */
  /* stylelint-enable */
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-xs {
  padding: 1px 7px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-xs {
  padding: 6px 6px;
  line-height: 12px;
}
.rs-btn-icon.rs-picker-toggle.rs-btn-xs > .rs-icon {
  font-size: 12px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs {
  line-height: 20px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs > .rs-icon {
  padding: 6px 6px;
  width: 24px;
  height: 24px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left {
  padding: 2px 8px 2px 32px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-left
  > .rs-icon {
  left: 0;
  border-right: none;
  border-right: var(--rs-btn-default-border, none);
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right {
  padding: 2px 32px 2px 8px;
}
.rs-btn-icon-with-text.rs-picker-toggle.rs-btn-xs.rs-btn-icon-placement-right
  > .rs-icon {
  right: 0;
  border-left: none;
  border-left: var(--rs-btn-default-border, none);
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
  padding-right: 28px;
}
.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs {
  padding-right: 40px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 2px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 1px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 8px;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 26px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-left: 7px;
}
.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn-xs
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 1px 8px;
  font-size: 12px;
  line-height: 1.66666667;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: -1px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: -1px;
}
.rs-picker-tag
  .rs-picker-toggle.rs-btn-xs
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-left: 8px;
  margin-top: -1px;
  font-size: 12px;
}
.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper input {
  height: 18px;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rs-picker-input.rs-picker-tag
  .rs-picker-toggle.rs-btn-xs
  ~ .rs-picker-tag-wrapper {
  padding-bottom: 1px;
}
.rs-picker-input.rs-picker-tag
  .rs-picker-toggle.rs-btn-xs
  ~ .rs-picker-tag-wrapper
  .rs-tag {
  margin-top: 1px;
}
.rs-picker-input.rs-picker-tag
  .rs-picker-toggle.rs-btn-xs
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding-top: 1px;
  padding-bottom: 1px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
  padding-right: 30px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  top: 2px;
}
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-xs
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-xs
  .rs-picker-toggle-clean,
.rs-picker-subtle
  .rs-picker-date
  .rs-picker-toggle.rs-btn-xs
  .rs-picker-toggle-caret,
.rs-picker-subtle
  .rs-picker-daterange
  .rs-picker-toggle.rs-btn-xs
  .rs-picker-toggle-caret {
  top: 1px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 8px;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 24px;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
}
/* stylelint-disable-next-line */
.rs-picker-toggle {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;
  color: #575757;
  color: var(--rs-text-primary);
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
}
.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border);
}
.rs-picker-subtle .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 32px;
  display: inline-block;
  cursor: pointer;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active {
  background: none;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active::after {
  display: none;
}
.rs-picker-toggle-label {
  color: #575757;
  color: var(--rs-text-primary);
}
.rs-picker-toggle-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 44px;
}
.rs-picker-date.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle,
.rs-picker-daterange.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 52px;
}
.rs-picker-toggle-clean {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 30px;
  height: 20px;
  font-size: 12px;
  background: inherit;
  color: #8e8e93;
  color: var(--rs-text-secondary);
  -webkit-transition: 0.2s color linear;
  transition: 0.2s color linear;
  cursor: pointer;
}
.rs-picker-toggle-clean.rs-btn-close {
  padding: 4px 0;
}
.rs-picker-toggle-clean:hover {
  color: #f44336;
  color: var(--rs-state-error);
}
.rs-picker-date .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle-clean {
  right: 38px;
}
.rs-picker-toggle-caret {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 8px;
  right: 12px;
  height: 20px;
  font-size: 12px;
  color: #8e8e93;
  color: var(--rs-text-secondary);
}
.rs-picker-menu {
  position: absolute;
  text-align: left;
  z-index: 7;
  border-radius: 6px;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-shadow-overlay);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-shadow-overlay);
  overflow: hidden;
  -webkit-transition: none;
  transition: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* stylelint-disable-next-line */
}
.rs-theme-high-contrast .rs-picker-menu {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
}
.rs-modal-open .rs-picker-menu {
  z-index: 1055;
}
.rs-drawer-open .rs-picker-menu {
  z-index: 1055;
}
.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
  padding-top: 6px;
}
.rs-picker-menu .rs-picker-search-bar {
  position: relative;
  padding: 6px 12px 12px;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  display: block;
  width: 100%;
  color: #575757;
  color: var(--rs-text-primary);
  background-color: #fff;
  background-color: var(--rs-input-bg);
  background-image: none;
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
  /* stylelint-disable */
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  min-width: 200px;
  padding-right: 36px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    height: 36px;
  }
}
textarea.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  height: auto;
}
.rs-theme-high-contrast
  .rs-picker-menu
  .rs-picker-search-bar
  .rs-picker-search-bar-input {
  -webkit-transition: none;
  transition: none;
}
.rs-picker-menu
  .rs-picker-search-bar
  .rs-picker-search-bar-input::-webkit-input-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary);
}
.rs-picker-menu
  .rs-picker-search-bar
  .rs-picker-search-bar-input::-moz-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary);
}
.rs-picker-menu
  .rs-picker-search-bar
  .rs-picker-search-bar-input::-ms-input-placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary);
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::placeholder {
  color: #8e8e93;
  color: var(--rs-text-secondary);
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
.rs-picker-menu
  .rs-picker-search-bar
  .rs-picker-search-bar-input:hover:not(:disabled) {
  border-color: #3498ff;
  border-color: var(--rs-input-focus-border);
}
.rs-btn-close {
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 8px 0;
  font-size: 12px;
  line-height: 1;
  border: none;
  outline: none !important;
  cursor: pointer;
}
.rs-btn-close .rs-icon {
  vertical-align: bottom;
}
.rs-stack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
