@import "../../../../../mixins.scss";

.reset_wrapper {
  background: #edf1fe;
  height: 100vh;
  position: relative;

  &:before {
    content: "";
    background: url("../../../../../../public/images/enrollmentdrive_bg.svg")
      no-repeat top left/100%;
    @include position(absolute, $left: 40px, $top: 0px);
    height: 100%;
    width: 100%;
  }

  .row {
    max-width: 1424px;
    margin: 0 auto;
  }

  .backBtn {
    svg {
      cursor: pointer;
      position: relative;
      transition: all 0.5s ease;
      left: 0;
      margin-right: 20px;

      &:hover {
        left: -4px;
        color: #ec3f3f;
      }
    }

    span {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(34px);
      line-height: 41px;
    }
  }

  .reset_section {
    max-width: 1070px;
    margin: 0 auto;

    .reset_innerSection {
      border: 2px solid $primary;
      @include border-radius(30px);
      background: $white;
      min-height: 759px;
      @include box-shadow(17px 17px 0px rgba($primary, 0.7));
      padding-top: 160px;
      text-align: center;
      position: relative;

      h5 {
        font-family: "Montserrat";
        @include fontSize(34px);
        line-height: 41px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
      }

      p {
        font-family: "Roboto";
        @include fontSize(16px);
        line-height: 18px;
        font-weight: 300;
        margin-bottom: 0px;
        text-align: center;
      }

      .ctaBtn {
        margin-top: 30px;

        .submitBtn {
          padding: 10px 96px;
        }
      }

      &:before {
        content: "";
        background: url("../../../../../../public/images/reset_password_img.svg")
          no-repeat;
        @include position(absolute, $left: 80px, $top: 205px);
        height: 560px;
        width: 902px;
        max-width: 100%;
      }
    }
  }
}

.m_reset_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  .content_container {
    border: 2px solid $primary;

    padding: 24px 12px 24px 12px;
    gap: 20px;
    border-radius: 30px;

    h1 {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      line-height: 29.26px;
      text-align: center;
    }
    p {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 300;
      line-height: 16.41px;
      text-align: center;
    }
    .ctaBtn {
      //   margin-top: 30px;

      .submitBtn {
        padding: 10px 96px;
        background: $primary;
      }
    }
  }
}
