@import "../../../../mixins.scss";

.policyWallet_wrapper {
    .button {
        @include border-radius(20px);
        color: $white;
        @include fontSize(10px);
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        line-height: 21px;
        padding: 5px 12px;
        width: 56px;

        &.red {
            background: #EC3F3F;
        }

        &.green {
            background: #77CB70;
        }

        &.purple {
            background: $primary;
        }
    }

    .main_heading {
        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(28px);
            font-weight: 600;
            line-height: 34px;
            margin-bottom: 10px;

            span {
                font-weight: 300;
            }
        }

        p {
            font-family: "Roboto", sans-serif;
            @include fontSize(14px);
            font-weight: 300;
            line-height: 16px;
            margin-bottom: 0px;
            opacity: 0.5;
            @include themed{
                color: #ddd;
            }
        }
    }

    .corporate_section {
        margin-bottom: 40px;

        h6 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(20px);
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 8px;
            @include themed{
                color: #ddd;
            }
        }

        p {
            font-family: "Roboto", sans-serif;
            @include fontSize(14px);
            font-weight: 300;
            line-height: 16px;
            margin-bottom: 0px;
            opacity: 0.5;
            @include themed{
                color: #ddd;
            }
        }

        .insurance_box {
            @include border-radius(0 0 20px 20px);
            background: $white;
            padding-bottom: 20px;

            .inner_box {
                display: flex;
                justify-content: center;
                position: relative;
                margin-bottom: 20px;

                img {
                    height: 40px;
                    margin-right: 20px;
                }

                h5 {
                    font-family: 'Montserrat', sans-serif;
                    @include fontSize(14px);
                    font-weight: 600;
                    line-height: 17px;
                    margin-bottom: 0px;

                    span {
                        display: block;
                        font-weight: 400;
                    }
                }
            }

            .policy_box {
                padding: 30px 35px 17px 35px;
                margin-bottom: 12px;
                display: flex;
                justify-content: center;

                img {
                    height: 57px;
                    margin-right: 12px;
                }

                h5 {
                    font-family: 'Montserrat', sans-serif;
                    @include fontSize(12px);
                    font-weight: 400;
                    line-height: 20px;
                    margin-bottom: 0;

                    span {
                        display: block;
                        font-weight: 600;
                    }
                }
            }

            .coverage_ection {
                padding: 0 35px;
                display: flex;
                justify-content: center;

                p {
                    font-family: 'Montserrat', sans-serif;
                    @include fontSize(12px);
                    font-weight: 400;
                    line-height: 14px;
                    margin-bottom: 0;

                    span {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .retail_policy_box {
        display: flex;
        align-items: center;
        @include border-radius(20px);
        border: 1px dashed rgba(107, 98, 220, 0.50);
        padding: 12px;
        margin-bottom: 24px;

        .retail_img {
            height: 32px;
        }

        .retail_text_section {
            padding-left: 18px;

            h5 {
                font-family: "Montserrat", sans-serif;
                @include fontSize(16px);
                font-weight: 600;
                line-height: 20px;
                margin-bottom: 5px;
            }

            p {
                font-family: "Roboto", sans-serif;
                @include fontSize(14px);
                font-weight: 300;
                line-height: 16px;
                margin-bottom: 0px;
                opacity: 0.5;
            }
        }


    }
}