@import "../../mixins.scss";

.searchBox {
    height: 36px;
    font-family: "Montserrat", sans-serif;
    @include border-radius(24px !important);
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    max-width: 200px;
    position: relative;
    @include themed {
        background: $bg-grey;
      }
    svg {
        height: 18px;
        @include position(absolute, $left:10px, $top:9px);
        @include themed {
            color: white;
          }
    }
    .MuiInputBase-root {
        border: none;
    }
    input {
        border: none!important;
        @include fontSize(14px);
        height: 36px;
        width: 100%;
        padding: 0px 12px 0px 34px;
        @include themed {
            color: white;
          }
    }
    fieldset {
        border: none !important;
    }
}