@import "../../../../../../mixins.scss";

.features_box {
    .features_list {
        list-style-type: none;
        background: #ECFFEE;
        @include border-radius(10px);
        padding: 10px;
        min-height: 64px;
        margin-bottom: 12px;
        position: relative;
        display: flex;
        align-items: center;
        &:last-child{
            margin-bottom: 0;
        }

        span {
            display: inline-block;

            $button-icons: medical,
            emergency,
            emergency,
            waiting,
            maternity,
            ambulance,
            waiting,
            emergency;
            $k: 0;

            @each $icons in $button-icons {
                $bacRightP: $k * 50;
                $k: $k +1;

                &.#{$icons} {
                    position: absolute;
                    content: "";
                    height: 50px;
                    width: 50px;
                    top: calc(50% - 25px);
                    left: 12px;
                    background: url("../../../../../../../public/images/policy_features_icon.svg") no-repeat -#{$bacRightP}px 0px/400px;
                }
            }
        }

        .feature_inerlist {
            padding-left: 64px;

            .feature_content {
                text-align: left;

                h5 {
                    font-family: "Montserrat";
                    @include fontSize(16px);
                    line-height: 24px;
                    font-weight: 600;
                    margin-bottom: 4px;
                    text-transform: capitalize;
                }

                p {
                    font-family: "Montserrat";
                    @include fontSize(14px);
                    line-height: 20px;
                    font-weight: 400;
                    margin-bottom: 0px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 4px;

                    span {
                        text-align: end;
                    }
                }

                ul {
                    margin-bottom: 0;

                    li {
                        list-style-type: none;
                        font-family: "Montserrat";
                        @include fontSize(12px);
                        line-height: 20px;
                        font-weight: 600;
                        padding-left: 20px;
                        position: relative;
                        span{
                            display: block;
                            opacity: 0.7;
                        }

                        &:before {
                            content: '';
                            background: $black;
                            height: 5px;
                            width: 5px;
                            @include border-radius(100%);
                            @include position(absolute, $left: 8px, $top: 9px);
                        }
                    }
                }
            }
        }
    }
}