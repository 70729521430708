@import "../../../../mixins.scss";

@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";

[data-rbd-draggable-id] {
  left: auto !important;
  top: auto !important;
}

.custom_layout_header {
  display: flex;
  justify-content: space-between;

  .right_side {
    display: flex;
    align-items: center;
    width: calc(100% * 2 / var(--Grid-columns));
    justify-content: space-between;
  }

  .privew_button {
    background: $primary;
    @include border-radius(6px);
    border: 1px solid rgba($primary, 0.4);
    color: $white;
    @include fontSize(13px);
    font-weight: 400;
    padding: 4px 16px 4px 12px;
    text-transform: capitalize;
    height: 36px;

    svg {
      margin-right: 4px;
      height: 16px;
    }

    &:hover {
      color: $primary;
    }
  }
}

.width_100 {
  width: 100%;
}

.width_20 {
  width: 20%;
}

.module_tab {
  display: flex;
  align-items: center;

  .right_tab {
    flex-grow: 1;
  }
}

.customModuleWrapper {
  padding: 24px 12px 12px;
  min-height: 100vh;

  @include themed {
    background: $bg-dark;
  }

  .pageTitle {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    a {
      display: flex;
      justify-content: center;
      margin-right: 12px;
      color: $primary;

      svg {
        position: relative;
        transition: all 0.5s ease;
        left: 0px;
      }

      &:hover {
        cursor: pointer;
        color: $primary-red;

        svg {
          left: -4px;
        }
      }
    }

    .MuiSelect-select {
      min-width: 220px;
    }
  }

  .fieldsSection {
    height: calc(100vh - 100px);
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    max-width: 330px;
    min-width: 330px;
    padding-right: 8px;

    .MuiAccordion-root {
      @include box-shadow(0px 4px 12px rgba($black, 0.1));
      @include border-radius(12px);
      margin-bottom: 16px;
      &:before {
        display: none;
      }
      .MuiAccordionSummary-root {
        width: 100%;
        height: 48px;
        .MuiAccordionSummary-content {
          margin: 0px;
        }
        &.Mui-expanded {
          margin: 0px;
          min-height: 20px;
        }
      }
      .MuiAccordionDetails-root {
        padding: 0px 8px;
      }
      h5 {
        // border-bottom: 1px solid $text-light;
        color: $primary;
        @include fontSize(14px);
        padding: 12px 8px 4px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;

        svg {
          margin-right: 4px;
          height: 20px;
        }

        &.unusedTitle {
          &:hover {
            cursor: pointer;
          }
          .count {
            @include border-radius(20px);
            border: 1px solid $primary-light;
            @include fontSize(12px);
            @include position(absolute, $right: 8px, $top: 12px);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 22px;
            width: 34px;
          }
        }
      }
      ul {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        &.unusedFields {
          li {
            // justify-content: space-between;
            width: 100%;
            &:hover {
              .removeIcon {
                display: block;
              }
            }
            label {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 80px;
              &:hover {
                cursor: grabbing;
              }
            }
            span {
              border-right: 1px dashed rgba($black, 0.2);
              display: inline-block;
              margin: 0px 8px;
              width: 1px;
              height: 20px;
            }
            .removeIcon {
              background: $white;
              @include border-radius(0px 4px 4px 0px);
              // @include box-shadow(0px 0px 8px rgba($black, 0.1));
              @include position(absolute, $right: 0px, $top: 0px);
              color: $primary-red;
              display: none;
              padding: 4px 0px;
              height: 36px;
              svg {
                margin-top: 7px;
                height: 16px;
              }
            }
          }
        }

        li {
          background: $blue-light;
          border: 0.5px solid $primary-light;
          @include border-radius(4px);
          list-style-type: none;
          @include fontSize(12px);
          position: relative;
          display: flex;
          align-items: center;
          width: 48%;
          margin: 1%;
          min-height: 38px;
          padding: 8px 8px 8px 24px;

          @include themed {
            background: $grey-dark;
            border-color: $grey-dark;
            color: $text-grey;
          }

          &:hover {
            cursor: grabbing;
          }

          $othericons: singleline, multiline, phone, email, singleselect,
            multiselect, datepicker, number, currency, checkbox, url, radio,
            searchselect, address;
          $k: 0;

          @each $icons in $othericons {
            $bacRightP: $k * 15;
            $k: $k + 1;

            &.#{$icons} {
              &:before {
                @include position(absolute, $left: 4px, $top: 11px);
                content: "";
                background: url("../../../../../public/images/fields_icons.svg")
                  no-repeat -#{$bacRightP}px
                  0px/225px;
                height: 15px;
                width: 15px;
              }
            }
          }
        }
      }
    }

    .innerPart {
      @include box-shadow(0px 4px 12px rgba($black, 0.1));
      @include border-radius(12px);
      padding-bottom: 6px;

      @include themed {
        background: $black;
      }

      h5 {
        // border-bottom: 1px solid $text-light;
        color: $primary;
        @include fontSize(14px);
        padding: 12px 8px 4px;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        position: relative;

        svg {
          margin-right: 4px;
          height: 20px;
        }

        &.unusedTitle {
          &:hover {
            cursor: pointer;
          }
          .count {
            @include border-radius(20px);
            border: 1px solid $primary-light;
            @include fontSize(12px);
            @include position(absolute, $right: 8px, $top: 12px);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 22px;
            width: 34px;
          }
        }
      }
      &.notes {
        @include box-shadow(0px 4px 12px rgba($black, 0));
        border: 1px solid $primary-light;
        margin-bottom: 12px;
        h6 {
          color: $primary-orange;
          font-weight: 400;
        }
        ol {
          margin: 8px 0px 0px 12px;
          padding: 0px;
          li {
            background: transparent;
            border: none;
            color: rgba($black, 0.5);
            padding: 2px 4px;
            @include fontSize(12px);
            width: 100%;
            margin: 0px;
          }
        }
      }
      ul {
        margin: 4px 6px 0px 6px;
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        &.unusedFields {
          li {
            // justify-content: space-between;
            width: 100%;
            &:hover {
              .removeIcon {
                display: block;
              }
            }
            label {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 56%;
              &:hover {
                cursor: grabbing;
              }
            }
            span {
              border-right: 1px dashed rgba($black, 0.2);
              display: inline-block;
              margin: 0px 8px;
              width: 1px;
              height: 20px;
            }
            .removeIcon {
              background: $white;
              @include border-radius(0px 4px 4px 0px);
              // @include box-shadow(0px 0px 8px rgba($black, 0.1));
              @include position(absolute, $right: 0px, $top: 0px);
              color: $primary-red;
              display: none;
              padding: 4px 0px;
              height: 36px;
              svg {
                margin-top: 7px;
                height: 16px;
              }
            }
          }
        }

        li {
          background: $blue-light;
          border: 0.5px solid $primary-light;
          @include border-radius(4px);
          list-style-type: none;
          @include fontSize(12px);
          position: relative;
          display: flex;
          align-items: center;
          width: 48%;
          margin: 1%;
          min-height: 38px;
          padding: 8px 8px 8px 24px;

          @include themed {
            background: $grey-dark;
            border-color: $grey-dark;
            color: $text-grey;
          }

          &:hover {
            cursor: grabbing;
          }

          $othericons: singleline, multiline, phone, email, singleselect,
            multiselect, datepicker, number, currency, checkbox, url, radio,
            searchselect, address;
          $k: 0;

          @each $icons in $othericons {
            $bacRightP: $k * 15;
            $k: $k + 1;

            &.#{$icons} {
              &:before {
                @include position(absolute, $left: 4px, $top: 11px);
                content: "";
                background: url("../../../../../public/images/fields_icons.svg")
                  no-repeat -#{$bacRightP}px
                  0px/225px;
                height: 15px;
                width: 15px;
              }
            }
          }
        }
      }
    }
  }

  .layoutSection {
    min-height: 100vh;
    .innerPart {
      border: 1px solid $primary-light;
      @include border-radius(12px);
      padding-bottom: 4px;

      @include themed {
        background: $black;
        border-color: $bg-grey;
      }

      .sectionTitle {
        background: $blue-light;
        display: flex;
        justify-content: space-between;
        padding: 12px 12px;
        border-radius: 11px 11px 0 0;
        height: 46px;

        @include themed {
          background: $bg-grey;
        }

        h5 {
          color: $black;
          @include fontSize(14px);
          display: flex;
          align-items: center;
          margin-top: -4px;
          margin-bottom: 0px;
          line-height: 21px;
          padding: 4px;
          outline: none;
          &:focus-visible {
            outline: none;
            @include border-radius(4px);
            background-position: 0 0, 0 0, 100% 0, 0 100%;
            background-repeat: no-repeat;
            background-image: repeating-linear-gradient(
                0deg,
                #6b61dc,
                #6b61dc 5px,
                transparent 5px,
                transparent 15px,
                #6b61dc 15px
              ),
              repeating-linear-gradient(
                90deg,
                #6b61dc,
                #6b61dc 5px,
                transparent 5px,
                transparent 15px,
                #6b61dc 15px
              ),
              repeating-linear-gradient(
                180deg,
                #6b61dc,
                #6b61dc 5px,
                transparent 5px,
                transparent 15px,
                #6b61dc 15px
              ),
              repeating-linear-gradient(
                270deg,
                #6b61dc,
                #6b61dc 5px,
                transparent 5px,
                transparent 15px,
                #6b61dc 15px
              );
            background-size: 1px calc(100% + 15px), calc(100% + 15px) 1px,
              1px calc(100% + 15px), calc(100% + 15px) 1px;
            background-position: 0 0, 0 0, 100% 0, 0 100%;
            background-repeat: no-repeat;
            animation: borderAnimation 1.5s infinite linear;

            @keyframes borderAnimation {
              from {
                background-position: 0 0, -15px 0, 100% -15px, 0 100%;
              }
              to {
                background-position: 0 -15px, 0 0, 100% 0, -15px 100%;
              }
            }
          }

          @include themed {
            color: $text-light;
          }

          svg {
            margin-right: 8px;
          }
        }

        .actionBtns {
          display: none;

          %linkbtns {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(12px);
            line-height: 16px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
          }

          .edit_btn {
            @extend %linkbtns;

            .edit_btn_icon {
              position: relative;
              top: -2px;
              height: 12px;
              margin-right: 4px;
            }
          }

          .addPremium {
            @extend %linkbtns;
            margin-right: 10px;
            position: relative;
            padding: 2px 0 0 28px;

            &.addfile {
              &:before {
                position: absolute;
                left: 1px;
                top: 0px;
              }
            }
          }

          .delete_btn {
            @extend %linkbtns;
            color: $primary-red;
          }

          svg {
            height: 16px;
            position: relative;
            top: 3px;
            color: #eb5757;
          }
        }

        &:hover {
          .actionBtns {
            display: inherit;
          }
        }
      }

      .subTitle {
        cursor: pointer;
        @include fontSize(14px);
        font-weight: 600;
        padding: 12px 12px;
        margin-bottom: 0px;
        position: relative;
        text-transform: capitalize;
        align-items: center;
        display: flex;
        height: 40px;
        span {
          padding: 4px 16px 4px 4px;
          outline: none;
          &:focus-visible {
            outline: none;
            @include border-radius(4px);
            background-position: 0 0, 0 0, 100% 0, 0 100%;
            background-repeat: no-repeat;
            background-image: repeating-linear-gradient(
                0deg,
                #6b61dc,
                #6b61dc 5px,
                transparent 5px,
                transparent 15px,
                #6b61dc 15px
              ),
              repeating-linear-gradient(
                90deg,
                #6b61dc,
                #6b61dc 5px,
                transparent 5px,
                transparent 15px,
                #6b61dc 15px
              ),
              repeating-linear-gradient(
                180deg,
                #6b61dc,
                #6b61dc 5px,
                transparent 5px,
                transparent 15px,
                #6b61dc 15px
              ),
              repeating-linear-gradient(
                270deg,
                #6b61dc,
                #6b61dc 5px,
                transparent 5px,
                transparent 15px,
                #6b61dc 15px
              );
            background-size: 1px calc(100% + 15px), calc(100% + 15px) 1px,
              1px calc(100% + 15px), calc(100% + 15px) 1px;
            background-position: 0 0, 0 0, 100% 0, 0 100%;
            background-repeat: no-repeat;
            animation: borderAnimation 1.5s infinite linear;

            @keyframes borderAnimation {
              from {
                background-position: 0 0, -15px 0, 100% -15px, 0 100%;
              }
              to {
                background-position: 0 -15px, 0 0, 100% 0, -15px 100%;
              }
            }
          }
        }

        @include themed {
          color: $text-grey;
        }

        &:before {
          content: "";
          @include position(absolute, $left: 16px, $bottom: 0px);
          background: $primary;
          height: 1px;
          width: 240px;
        }

        .actionBtns {
          display: none;
          padding-left: 8px;

          %links {
            @include border-radius(4px);
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            height: 22px;
          }

          .edit_btn {
            @extend %links;

            &:hover {
              background: $primary;
              color: $white;
            }
          }

          .delete_btn {
            @extend %links;
            color: $primary-red;
            margin-left: 4px;

            &:hover {
              background: $primary-red;
              color: $white;
            }
          }

          svg {
            height: 16px;
            position: relative;
            top: 3px;
          }
        }

        &:hover {
          .actionBtns {
            display: inherit;
          }
        }
      }

      .fieldBox {
        // padding: 8px 4px 8px 0px;
        position: relative;
        display: inline-flex;
        align-items: center;
        width: 100%;

        &:hover,
        &:focus {
          background: #fffced;
          @include border-radius(8px);

          @include themed {
            background: $bg-grey;
          }

          .delete,
          .settings {
            opacity: 1;
          }

          .textField {
            label {
              background: #fffced;

              @include themed {
                background: $bg-grey;
              }
            }
          }
        }

        .textField {
          border: 1px solid rgba($text-light, 0.7);
          @include border-radius(8px);
          padding: 12px 10px;
          position: relative;
          margin-right: 2px;
          margin-bottom: 0px;
          text-transform: capitalize;
          height: 38px;
          width: 100%;

          @include themed {
            border-color: rgba($text-light, 0.4);
          }

          label {
            background: $white;
            @include position(absolute, $left: 7px, $top: -10px);
            @include fontSize(10px);
            color: $primary;
            padding: 0px 8px 0px 3px;
            line-height: 10px;
            height: 20px;
            display: flex;
            align-items: center;
            span {
              color: $primary-red;
              position: relative;
              min-width: auto;
              min-height: auto;
              right: auto;
              padding-left: 4px;
            }

            @include themed {
              background: $black;
            }
          }

          p {
            // padding-top: 6px;
            @include fontSize(12px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 90%;

            @include themed {
              color: $text-grey;
            }
          }
        }

        .selectField {
          @extend .textField;
          background: url("../../../../../public/images/down_arrow.svg")
            no-repeat right 12px center/10px;
        }

        .delete,
        .settings {
          opacity: 0;
          min-width: 20px;
          height: 20px;

          svg {
            height: 15px;
            width: 15px;
          }
        }

        .settings {
          padding: 1px;

          svg {
            color: $black;

            @include themed {
              color: $text-light;
            }
          }
        }

        .delete {
          svg {
            color: $primary-red;
          }
        }

        .delete_disable {
          svg {
            color: $disable-red;
          }
        }

        span {
          @include position(absolute, $right: 24px, $top: 0px);
          min-height: 36px;
          min-width: 26px;
          // margin-left: -22px;
          a {
            position: relative;
            top: 0px;
          }
        }
        a {
          @include position(absolute, $right: 2px, $top: 7px);
          @include border-radius(4px);
          color: $black;
          padding: 4px 2px;
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            top: 2px;
            right: 0px;
            min-height: 20px;
            min-width: 24px;
          }

          &:hover {
            background: rgba($text-light, 0.5);
          }
          button {
            &:hover {
              background: transparent;
            }
            .MuiTouchRipple-root {
              display: none;
            }
          }

          .closedLock {
            color: $text-grey;
            height: 15px;

            @include themed {
              color: $text-light;
            }
          }

          svg {
            height: 16px;
          }
        }
      }
    }
  }

  .fieldsBox {
    min-height: 100vh;

    .dataHeading {
      background: $blue-light;
      display: flex;
      flex-wrap: wrap;
      @include border-radius(12px 12px 0px 0px);
      padding: 16px 12px;
      margin-bottom: 0px;

      @include themed {
        background: $bg-grey;
        color: $text-light;
        border: 1px solid rgba($text-light, 0.5) !important;
      }

      li {
        list-style-type: none;
        @include fontSize(14px);
        font-weight: 500;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        justify-content: space-between;

        &:first-child {
          max-width: 100px;
        }

        &:last-child {
          max-width: 200px;
          text-align: left;
          justify-content: flex-start;
        }

        &:hover {
          .sorting-btn {
            opacity: 1;
          }
        }

        .sorting-btn {
          background: transparent;
          margin-left: auto;
          margin-right: 15px;
          position: relative;
          opacity: 0;
          // width: 50%;
          background: #fff;
          width: 21px;
          height: 21px;
          border-radius: 50%;

          @include themed {
            background-color: $text-grey;
          }

          &:before {
            position: absolute;
            content: "";
            background: url("./../../../../../public/images/sorting_btn.svg")
              no-repeat 0 0 /100%;
            top: 5px;
            right: 7px;
            width: 7px;
            height: 12px;

            @include themed {
              background: url("./../../../../../public/images/sorting_btn_white.svg")
                no-repeat 0 0 /100%;
            }
          }

          img {
            height: 11px;
          }

          &.last {
            margin-left: 10px;
            margin-right: 0;
          }
        }
      }
    }

    .dataLists {
      @extend .dataHeading;
      background: transparent;
      @include border-radius(0px);
      padding: 12px;
      margin-bottom: 0px;
      border: 1px solid rgba($text-light, 0.5) !important;

      @include themed {
        background: $black;
        color: $text-light;
        border: 1px solid $bg-grey;
      }

      li {
        font-weight: 400;
        align-self: center;

        &:first-child {
          max-width: 100px;
        }

        // &:nth-child(2),
        // &:nth-child(3),
        // &:nth-child(4) {
        //   max-width: 400px;
        // }

        &:last-child {
          max-width: 200px;
          text-align: left;
          justify-content: flex-start;
        }
      }
    }
  }

  .summaryBox {
    border: 1px dashed darken($blue-light, 12%);
    background: $blue-light;
    @include border-radius(16px);
    padding: 12px 24px;
    margin-bottom: 42px;
    margin-left: 15px;

    @include themed {
      background: $black;
      color: $text-light;
      border: 1px dashed $text-light;
    }

    label {
      background: darken($blue-light, 8%);
      @include border-radius(20px);
      @include fontSize(16px);
      padding: 4px 16px;
      position: relative;
      top: -22px;

      @include themed {
        background: $black;
        color: $text-light;
      }
    }

    ul {
      margin: 0px 18px;

      li {
        list-style-type: none;
        border-bottom: 1px solid rgba($text-light, 0.5);
        padding: 12px 0px;
        display: flex;

        &:last-child {
          border: none;
        }

        div {
          min-width: 260px;
          display: inline-block;

          &:last-child {
            font-weight: 600;
            padding-left: 12px;
          }
        }

        ul {
          margin: 0px;

          li {
            border: none;
            padding: 0px 0px 12px;

            span {
              font-weight: 400;
              padding-right: 8px;
              display: inline-block;

              &:last-child {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  .btn {
    display: inline-block;
    cursor: pointer;
    padding: 8px;
  }

  .stagessection {
    .tableHeading {
      background: $blue-light;
      display: flex;
      flex-wrap: wrap;
      @include border-radius(12px 12px 0px 0px);
      padding: 16px 12px;
      margin-bottom: 0px;

      @include themed {
        background: $bg-grey;
        color: $text-light;
        border: 1px solid $bg-grey;
      }

      li {
        list-style-type: none;
        @include fontSize(14px);
        font-weight: 500;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 200px;

        &:first-child {
          max-width: 95%;
        }

        &:last-child {
          text-align: right;
          margin-right: 0px;
        }
      }
    }

    .contentarea {
      .tableSubHeading {
        background: #f8f8f8;
        padding: 8px 12px;
        margin-bottom: 0px;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;

        @include themed {
          background: $black;
          color: $text-light;
          //border: 1px solid $text-light;
        }

        p {
          color: $black;
          max-width: 200px;
          font-size: 12px;
          font-weight: 400;
          align-items: center;

          &:last-child {
            text-align: right;
          }

          .sourcing {
            color: $black !important;
            font-family: "Montserrat";
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            text-decoration: none !important;

            &:focus,
            &:visited,
            &:link,
            &:active,
            &:hover {
              text-decoration: none !important;
            }
          }
        }
      }

      .subheading_data {
        background: #fff;
        padding: 4px 12px 8px;
        margin-bottom: 0px;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;

        li {
          color: $black;
          height: 30px;
          max-width: 200px;
          align-items: center;

          &:first-child {
            max-width: 80%;
            padding-left: 30px;
            position: relative;

            &:before {
              background: #2196537d;
              content: "";
              height: 18px;
              width: 18px;
              @include position(absolute, $top: 0px, $left: 0px);
              border-radius: 4px;
            }
          }

          &:last-child {
            text-align: right;
          }
        }
      }

      .innertable {
        background: #fff;
        padding: 4px 12px 4px 30px;
        margin-bottom: 0px;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;

        @include themed {
          background: $black;
        }

        li {
          color: $black;
          // height: 26px;
          padding: 8px 4px 6px;
          max-width: 200px;
          align-items: center;
          align-self: center;
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 400;

          @include themed {
            color: $text-light;
          }

          &:first-child {
            max-width: 80%;
            padding-left: 13px;
            position: relative;
          }

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .sliding-panel-container {
    .panel-content {
      padding: 24px;

      .pickListView {
        background: $blue-light;
        @include border-radius(12px);
        margin-bottom: 24px;
        .dragField {
          position: relative;
          &:before {
            background: url("../../../../../public/images/drag-icon.svg")
              no-repeat center center/10px;
            content: "";
            @include position(absolute, $left: 0px, $top: 0px);
            opacity: 0.3;
            height: 100%;
            width: 24px;
          }
          .inputField {
            width: calc(100% - 24px);
            margin-bottom: 0px;
            margin-left: 24px;
          }
        }
        .add,
        .remove {
          height: 24px;
          width: 24px;
          display: inline-flex;
          align-items: center;
          margin-top: 4px;
        }
        .add {
          color: $primary;
          margin-right: 12px;
        }
        .remove {
          color: $primary-red;
        }
      }
    }

    .warningMessage {
      @include border-radius(12px);
      background: lighten($primary-red, 32%);
      border: 1px solid rgba($primary-red, 0.3);
      color: $primary-red;
      text-align: center;
      @include fontSize(14px);
      line-height: 18px;
      @include position(absolute, $bottom: 64px, $left: 50%);
      transform: translateX(-50%);
      max-width: 300px;
      padding: 12px 24px;
      animation: slideup 1s ease forwards;
      z-index: 9991;

      h4 {
        margin-bottom: 12px;

        @include themed {
          color: $primary-red;
        }
      }
    }
    .successMessage {
      @extend .warningMessage;
      background: lighten(green, 32%);
      border: 1px solid rgba(green, 0.3);
      color: green;
    }

    @keyframes slideup {
      from {
        opacity: 0;
        bottom: -110px;
      }

      to {
        opacity: 1;
        bottom: 64px;
      }
    }
  }
}

// Form Bulder
/* .body {
  display: flex;
  flex-direction: row;
} */

/* .sideBar {
  flex: 0 0 1;
  width: 200px;
  padding: 10px;
  border-right: 1px solid #111;
  height: 100vh;
  background: #e1e1e1;
}

.sideBarItem {
  border: 1px solid #000;
  border-radius: 3px;
  height: 30px;
  padding: 10px;
  margin-top: 10px;
  background: #ccc;
}

.page {
  flex: 1 1 auto;
  padding: 0 20px;
  margin: 20px;
  border: 1px solid green;
} */

/* .dropZone:nth-of-type(2n) {
  display: none;
} */

// .false {
//   .fieldBox {
//     cursor: no-drop;
//   }
// }

.dropZone.horizontalDrag {
  width: 36px;
  height: auto;
  &:before {
    border-left: 1px dashed rgba($black, 0.15);
    border-right: 1px dashed rgba($black, 0.15);
    @include position(absolute, $left: 2px, $top: 3px);
    content: "";
    height: calc(100% - 8px);
    width: calc(100% - 8px);
  }
}

.dropZone.active.horizontalDrag {
  width: 100px !important;
  min-width: auto !important;
  max-width: 100px !important;
}

.dropZone:not(.horizontalDrag).isLast {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.dropZone {
  flex: 0 0 auto;
  min-height: 30px;
  width: 100%;
  transition: 0.3s all ease-in-out;
  position: relative;
}

.dropZone.active,
.trashDropZone.active {
  background: #e4f1ff;
  min-height: 60px;
  min-width: 100px;
  color: #fff;
  transition: 0.3s all ease-in-out;

  @include themed {
    background: $bg-grey;
  }
}

.component {
  height: 40px;
  padding: 0px !important;
  /* background: #aaa; */
  cursor: move;
  &.disabled {
    cursor: not-allowed !important;
  }
}

.column {
  // border: 1px solid transparent;
  flex-basis: 0;
  flex-grow: 1;
  -webkit-box-flex: 1;
  max-width: 100%;
  cursor: move;
  /* min-width: 300px; */
  // .dropZone {
  //   position: relative;
  //   top: 4px;
  // }
}

// .column:hover {
//   border: 1px solid #3569b6;
// }

.columns {
  align-items: center;
  display: flex;
  -webkit-box-flex: 1;
  flex-wrap: wrap;
  padding: 0px 4px;
  .column {
    position: relative;
    &.lastColumn {
      display: flex;
      flex-direction: column;
    }
    // &:last-child {
    //   display: flex;
    //   flex-direction: column;
    // }
    .actionBtns {
      @include position(absolute, $right: 0px, $top: 0px);
      a {
        cursor: pointer;
        &.delete_btn {
          color: $primary-red;
          opacity: 0;
        }
        svg {
          height: 18px;
        }
      }
    }
    &:hover {
      .actionBtns {
        a.delete_btn {
          opacity: 1;
        }
      }
    }
  }
}

.columnContainer {
  display: flex;
}

.row {
  /* border: 1px solid red; */
  padding: 0;
  cursor: move;
}

.row:hover {
  outline: 1px solid #eb5757;
}

.trashDropZone {
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border: 1px solid purple;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 100px;
}

// Popups
.swal2-modal {
  .swal2-title {
    @include fontSize(22px);
  }
  .swal2-html-container {
    @include fontSize(14px);
  }
  .swal2-confirm,
  .swal2-cancel {
    font-weight: 400;
    @include fontSize(14px);
    background-color: #2160aa !important;
  }
}
