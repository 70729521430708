@import "../../../../mixins.scss";

.notificationWrapper {
    padding: 0px 12px 12px 0px;
    .notifications {
        @include box-shadow(0px 4px 12px rgba($black, 0.1));
        @include border-radius(8px);
        padding: 12px 12px 4px;
        @include themed() {
            background: $bg-grey;
        }
        li {
            list-style-type: none;
            border-bottom: 1px solid rgba($black, 0.1);
            position: relative;
            padding-bottom: 12px;
            padding-left: 60px;
            padding-right: 44px;
            margin-bottom: 12px;
            @include themed() {
                border-color: $black;
            }
            &:before {
                content: "";
                position: absolute;
                left: 0px;
                top: -5px;
                height: 50px;
                width: 50px;
            }
            &:last-child {
                border: none;
                margin-bottom: 0px;
            }
            h4 {
                @include fontSize(14px);
                margin-bottom: 4px;
            }
            p {
                @include fontSize(13px);
                color: rgba($black, 0.5);
                @include themed() {
                    color: $text-grey;
                }
            }
            a {
                border: 1px solid $primary;
                @include border-radius(20px);
                @include position(absolute, $right:4px, $top:8px);
                color: $primary;
                height: 22px;
                width: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                    background: $primary;
                    color: $white;
                }
                svg {
                    height: 14px;
                    width: 14px;
                }
            }

            $othericons: dashboard, policywallet, riskmap, userprofile, mydocument,
            claimassistant, referafriend, trendingquestions, community, support,
            wellness, doctoronline, repairvehicle, settings, employe, report,
            endorsement, quote, policies, clients, contacts;
            $k: 0;
            @each $icons in $othericons {
            $bacRightP: $k * 50;
            $k: $k + 1;

                &.#{$icons} {
                    &:before {
                    background-image: url("../../../../../public/images/tablist_icons.svg");
                    background-position: -#{$bacRightP}px 0px;
                    background-size: 1100px;
                    background-repeat: no-repeat;
                    @include themed() {
                        background-image: url("../../../../../public/images/tablist-active-icons.svg");
                        opacity: 0.6;
                    }
                    }
                }
            }
        }
    }
    .quote {
        border: 2px dashed $text-light;
        @include border-radius(20px);
        max-width: 820px;
        margin: 12px auto;
        padding: 48px;
        @include themed(){
            border-color: $bg-grey;
        }
        span {
            color: $primary;
            @include fontSize(40px);
            line-height: 32px;
            position: relative;
            top: 12px;
        }
        p {
            color: $text-grey;
            margin-bottom: 4px;
        }
    }
}