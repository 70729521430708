@import "../../../../../mixins.scss";

.enrollmentConfirmation_wrapper {
  position: relative;

  &:before {
    content: "";
    background: url("../../../../../../public/images/enrollmentdrive_bg.svg")
      no-repeat top left/100%;
    @include position(absolute, $left: 40px, $top: 00px);
    height: 100%;
    width: 100%;
  }

  .row {
    max-width: 1424px;
    margin: 0 auto;
  }

  .backBtn {
    svg {
      cursor: pointer;
      position: relative;
      transition: all 0.5s ease;
      left: 0;
      margin-right: 20px;

      &:hover {
        left: -4px;
        color: #ec3f3f;
      }
    }

    span {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(34px);
      line-height: 41px;
    }
  }

  .confirmation_section {
    max-width: 1070px;
    margin: 0 auto;

    .confirmation_innerSection {
      border: 2px solid $primary;
      @include border-radius(30px);
      background: $white;
      @include box-shadow(17px 17px 0px rgba($primary, 0.7));
      padding: 50px 24px 49px;
      text-align: center;

      h5 {
        font-family: "Montserrat";
        @include fontSize(34px);
        line-height: 50px;
        font-weight: 600;
        margin-bottom: 0px;
      }

      hr {
        color: $black;
        opacity: 10%;
        margin: 24px 0;
      }

      .confirmation_content {
        text-align: left;

        p {
          font-family: "Montserrat";
          @include fontSize(14px);
          line-height: 17px;
          font-weight: 400;
          color: #808080;
          margin-bottom: 24px;

          span {
            color: #029d11;
            font-weight: 500;
          }
        }

        ul {
          margin-bottom: 24px;

          li {
            list-style-type: none;
            font-family: "Montserrat";
            @include fontSize(14px);
            line-height: 17px;
            font-weight: 400;
            color: #029d11;
            padding-left: 30px;
            margin-bottom: 14px;
            position: relative;

            &.success {
              position: relative;
              &:before {
                content: "";
                background: url("../../../../../../public/images/green_tick.svg")
                  no-repeat;
                @include position(absolute, $left: 0, $top: calc(50% - 10px));
                height: 20px;
                width: 22px;
              }
            }

            &.error {
              position: relative;
              color: red;
              &:before {
                content: "";
                background: url("../../../../../../public/images/crossup.svg")
                  no-repeat;
                @include position(absolute, $left: 0, $top: calc(50% - 10px));
                height: 20px;
                width: 22px;
              }
            }

            // &:before {
            //     content: '';
            //     background: url("../../../../../../public/images/green_tick.svg") no-repeat;
            //     @include position (absolute, $left: 0, $top: calc(50% - 10px));
            //     height: 20px;
            //     width: 22px;
            // }
          }
        }

        .ctaBtn {
          .submitBtn {
            padding: 10px 78px;
          }
        }
      }
    }
  }
}

.enrollmentConfirmation_wrapper {
  .password_container {
    // border: 2px solid #2160aa;
    display: flex;
    flex-direction: column;
    gap: 20px;

    border-radius: 30px;

    h5 {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      line-height: 29.26px;
      text-align: center;
      @media(max-width:599px){
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        color:$primary;
        text-transform: capitalize;
        margin: 0;
        padding: 0;
        margin-bottom: 12px;

      }
    }
    h4.label {
      @media(max-width:599px){
        font-family: Montserrat;
font-size: 18px;
font-weight: 400;
line-height: 21.94px;
text-align: center;
color: #000;

      }
    }
  }
}
