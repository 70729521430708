@import "../../../../mixins.scss";

.repairVehicle_wrapper {
    .main_heading {
        h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(28px);
            font-weight: 600;
            line-height: 34px;
            margin-bottom: 5px;

            span {
                font-weight: 300;
            }
        }

        p {
            font-family: "Roboto", sans-serif;
            @include fontSize(14px);
            font-weight: 300;
            line-height: 16px;
            margin-bottom: 0px;
            opacity: 0.5;
        }
    }

    .repair_list {
        .inner_repairlist {
            display: flex;

            li {
                list-style-type: none;

                .inner_content {
                    background: $primary;
                    opacity: 10%;
                    height: 60px;
                    width: 60px;
                    @include border-radius(100%);
                }
            }
        }
    }
}