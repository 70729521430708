.MNotAllowedContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  div {
    text-align: center;
    h1 {
      font-family: Hind;
      font-size: 28px;
      font-weight: 600;
      line-height: 44.83px;
      text-align: center;
      margin-bottom: 12px;
    }
    p {
      font-family: Hind;
      font-size: 16px;
      font-weight: 300;
      line-height: 25.62px;
      text-align: center;
    }
  }
}
