@import "../../../../../mixins.scss";

.thankyou_wrapper {
  background: #edf1fe;
  height: 100vh;
  position: relative;

  &:before {
    content: "";
    background: url("../../../../../../public/images/enrollmentdrive_bg.svg")
      no-repeat top left/100%;
    @include position(absolute, $left: 40px, $top: 00px);
    height: 100%;
    width: 100%;
  }

  .row {
    max-width: 1424px;
    margin: 0 auto;
  }

  .backBtn {
    svg {
      cursor: pointer;
      position: relative;
      transition: all 0.5s ease;
      left: 0;
      margin-right: 20px;

      &:hover {
        left: -4px;
        color: #ec3f3f;
      }
    }

    span {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(34px);
      line-height: 41px;
    }
  }

  .thankyou_section {
    max-width: 1070px;
    margin: 0 auto;

    .thankyou_innerSection {
      border: 2px solid $primary;
      @include border-radius(30px);
      background: $white;
      min-height: 450px;
      @include box-shadow(17px 17px 0px rgba($primary, 0.7));
      padding: 67px 92px;
      text-align: center;

      img {
        margin-bottom: 20px;
        max-width: 100%;
      }

      h5 {
        font-family: "Montserrat";
        @include fontSize(34px);
        line-height: 41px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
      }

      p {
        font-family: "Roboto";
        @include fontSize(16px);
        line-height: 18px;
        font-weight: 300;
        margin-bottom: 0px;
        text-align: center;
      }

      .ctaBtn {
        margin-top: 30px;

        .submitBtn {
          padding: 10px 96px;
        }
      }
    }
  }
}

.m_image_container {
  width: 100%;
  height: 100vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;

  .img_cont {
    // border: 2px solid $primary;
    // display: flex;
    // flex-direction: column;
    // gap: 20px;
    text-align: center;
    padding: 24px;
    border-radius: 30px;
    
    h5 {
      font-family: Montserrat;
      font-size: 24px;
      font-weight: 600;
      line-height: 29.26px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 16px;
    }
    p {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 300;
      line-height: 14.63px;
      text-align: center;
      
    }
    button {
      padding: 10px 40px;
      border-radius: 10px;
      background: $primary;
      color: #fff;
      margin-top: 20px;
      text-transform: capitalize;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
