@import "../../../mixins.scss";


.tableview {
    overflow-x: auto;
    overflow-y: hidden;
    animation: fadeIn 1s both;

    .tableinner {
        min-width: 100%;
        //min-width: 1600px;
        border: 1px solid $primary-light;
        @include border-radius(16px 16px 0px 0px);
        padding: 0;
        width: 100%;

        @include themed {
            border: 1px solid $bg-grey;
        }

        .tableHeader {
            background: #e4f1ff;
            @include border-radius(16px 16px 0px 0px);
            padding: 6px 12px;
            display: flex;
            margin-bottom: 0px;

            @include themed {
                background: $bg-grey;
            }

            li {
                list-style-type: none;
                font-family: 'Montserrat';
                font-weight: 500;
                @include fontSize(12px);
                line-height: 14px;
                margin-bottom: 0;
                height: 39px;
                color: $black;
                padding: 12px;
                display: flex;
                align-items: center;
                width: 300px;

                @include themed {
                    color: $text-grey !important;
                    background: $bg-grey !important;
                }

                &:nth-child(5) {
                    width: 600px;
                }

                &:nth-child(3) {
                    width: 400px;
                }

                // .sorting-btn {
                //     background: transparent;
                //     margin-left: auto;

                //     img {
                //         height: 10px;
                //     }
                // }
                .sorting-btn {
                    background: transparent;
                    margin-left: auto;
                    // margin-right: 15px;
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        background: url('../../../../public/images/sorting_btn.svg')no-repeat 0 0 /100%;
                        top: -5px;
                        right: 0px;
                        width: 7px;
                        height: 12px;

                        //build issue not using anywhere
                        // @include themed {
                        //     background: url('../../../../public/images/sorting_btn_white.svg')no-repeat 0 0 /100%;
                        // }
                    }
                }

            }
        }

        .tableHeadercashlesshospital {
            background: #e4f1ff;
            @include border-radius(16px 16px 0px 0px);
            padding: 6px 12px;
            display: flex;
            margin-bottom: 0px;

            @include themed {
                background: $bg-grey;
            }

            li {
                list-style-type: none;
                font-family: 'Montserrat';
                font-weight: 500;
                @include fontSize(12px);
                line-height: 14px;
                margin-bottom: 0;
                height: 39px;
                color: $black;
                padding: 12px;
                width: 300px;

                @include themed {
                    color: $text-grey !important;
                    background: $bg-grey !important;
                }


                // .sorting-btn {
                //     background: transparent;
                //     margin-left: auto;

                //     img {
                //         height: 10px;
                //     }
                // }
                .sorting-btn {
                    background: transparent;
                    margin-left: 20px;
                    // margin-right: 15px;
                    position: relative;
                    top: -2px;

                    &:before {
                        position: absolute;
                        content: '';
                        background: url('../../../../public/images/sorting_btn.svg')no-repeat 0 0 /100%;
                        top: -5px;
                        right: 0px;
                        width: 7px;
                        height: 12px;

                       // @include themed {
                        //    background: url('../../../../public/images/sorting_btn_white.svg')no-repeat 0 0 /100%;
                        //}
                    }
                }

            }
        }

        .tableData {
            display: flex;

            margin-bottom: 0px;
            border-bottom: 1px solid $blue-light;
            padding: 6px 12px;

            @include themed {
                border-bottom: 1px solid $bg-grey;
            }

            &:last-child {
                border-bottom: 0;
            }

            li {
            
                list-style-type: none;
                min-height: 50px;
                // border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
                padding: 13px 12px;
                font-family: 'Montserrat';
                font-weight: 700;
                width: 300px;
                @include fontSize(12px);
                line-height: 24px;
                margin-bottom: 0px;
                color: #000;

                &:nth-child(5) {
                    width: 600px;
                }

                &:nth-child(3) {
                    width: 400px;
                    color: $primary !important;
                }

                @include themed {
                    color: $text-light !important;
                }
div.liItem{
     font-weight: 500;
}

            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translate3d(0, -3%, 0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}