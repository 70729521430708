 @import "../../../mixins.scss";

 .notessection {
     background: $white;
     // @include border-radius(8px);
     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
     margin: 0 12px 12px;
     overflow: hidden;

     @include themed() {
         background: $bg-grey;
         @include border-radius(8px);
     }

     .username {
         font-weight: 600;
         @include fontSize(11px);
         line-height: 16px;
         margin-top: 4px;
         margin-bottom: 0px;
         font-family: 'Montserrat';

         @include themed() {
            color: $text-light;
         }
     }

     .creation_date {
         @include fontSize(10px);
         line-height: 16px;
         color: rgba(0, 0, 0, 0.5);
         margin-bottom: 6px;

         @include themed() {
             color: $text-grey;
         }
     }

     .usernotes {
         font-family: "Montserrat";
         font-weight: 500;
         @include fontSize(14px);
         line-height: 24px;
         text-align: justify;

         @include themed() {
             color: $text-light;
         }
     }
     &:last-child {
        margin-bottom: 0px;
     }
 }