@import "../../../../../../mixins.scss";

.box {
  @media (max-width: 599px) {
    width: 100%;
    min-height: 100vh;
    background: #fff;

    .header {
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 600;
      line-height: 24.38px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      color: #000000;
      align-items: center;
      margin-bottom: 16px;
      padding: 16px;

      h1 {
        margin-bottom: 0;
      }
      button {
        display: flex;
        align-items: center;
        .header {
          button {
            background: transparent !important;
          }
        }
      }
      .exclusion_box {
        padding: 10px !important;
        .exclusion_list {
          list-style-type: none;
          background: #fff3f3;
          @include border-radius(10px);
          padding: 10px;
          min-height: 64px;
          margin-bottom: 12px;
          position: relative;
          display: flex;
          align-items: center;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            display: inline-block;

            $button-icons: aids, pregnancy, ectopic, std, aids, pregnancy,
              ectopic1, std;
            $k: 0;

            @each $icons in $button-icons {
              $bacRightP: $k * 50;
              $k: $k + 1;

              &.#{$icons} {
                position: absolute;
                content: "";
                height: 50px;
                width: 50px;

                top: 10px;
                left: 12px;
                background: url("../../../../../../../public/images/policy_exclusion_icon.svg")
                  no-repeat -#{$bacRightP}px
                  0px/400px;
              }
            }
          }

          .exclusion_inerlist {
            padding-left: 64px;

            .exclusion_content {
              text-align: left;

              h5 {
                font-family: "Montserrat";
                @include fontSize(14px);
                line-height: 24px;
                font-weight: 600;
                margin-bottom: 4px;
              }

              p {
                font-family: "Montserrat";
                @include fontSize(12px);
                line-height: 16px;
                font-weight: 400;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}
