@import "../../../../mixins.scss";

.enrollmentdrive_wrapper {
  .row {
    max-width: 1070px;
    margin: 0 auto;
    &:hover {
      outline: none !important;
    }
  }

  .enrollment_bg {
    background: #edf1fe;
    min-height: 100vh;
    position: relative;
    padding: 58px 30px 48px 66px;

    &:before {
      content: "";
      background: url("../../../../../public/images/enrollmentdrive_bg.svg")
        no-repeat top left/100%;
      @include position(absolute, $left: 0, $top: 0px);
      height: 100%;
      width: 100%;
    }

    .backBtn {
      position: relative;
      top: -50px;
      left: -120px;

      svg {
        cursor: pointer;
        position: relative;
        transition: all 0.5s ease;
        left: 0;
        margin-right: 20px;

        &:hover {
          left: -4px;
          color: #ec3f3f;
        }
      }

      span {
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(34px);
        line-height: 41px;
      }
    }

    .enrollment_innersection {
      border: 2px solid $primary;
      @include border-radius(60px);
      background: $white;
      min-height: 740px;
      @include box-shadow(17px 17px 0px rgba($primary, 0.7));

      .inner_content {
        padding: 60px 0 0 60px;

        h5 {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(34px);
          line-height: 50px;
          margin: 20px 0 10px 0;

          span {
            font-weight: 600;
          }
        }

        p {
          font-family: "Roboto";
          font-weight: 300;
          @include fontSize(16px);
          line-height: 19px;
          margin-bottom: 20px;
        }

        .ctaBtn {
          .submitBtn {
            padding: 10px 94px;
          }
        }
      }

      .choose_policy {
        padding-top: 50px;

        h5 {
          font-family: "Montserrat";
          font-weight: 600;
          @include fontSize(34px);
          line-height: 41px;
          margin-bottom: 0px;
          text-align: center;
        }

        hr {
          color: rgba(0, 0, 0, 0.1);
          margin: 20px 0 20px !important;
        }

        .coverageAmt {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          margin: 0px;
          justify-content: center;

          li {
            list-style: none;
            min-width: 200px;

            .Checkbox {
              display: inline-block;
              position: relative;

              label {
                font-family: "Montserrat";
                display: inline-block;
                text-align: center;
                color: $black;
                cursor: pointer;
                position: relative;
                padding: 40px 10px 10px;
                @include border-radius(12px);
                border: 1px solid $primary-light;
                width: 209px;
                height: 216px;

                @include themed {
                  background: $bg-grey;
                  border-color: $bg-grey;
                }

                &:after {
                  position: absolute;
                  content: "";
                  height: 20px;
                  width: 20px;
                  border: 1px solid $primary;
                  @include border-radius(50%);
                  left: 10px;
                  top: 7px;
                }

                p {
                  // position: absolute;
                  // bottom: 12px;
                  left: 0;
                  width: 100%;
                  @include fontSize(14px);
                  line-height: 17px;
                  font-weight: 500;

                  @include themed {
                    color: $text-light;
                  }

                  span {
                    display: block;
                    padding-top: 8px;
                    @include fontSize(12px);
                    line-height: 17px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.5);
                  }
                }

                $button-icons: super, health, critical, accident;
                $k: 0;

                @each $icons in $button-icons {
                  $bacRightP: $k * 105;
                  $k: $k + 1;

                  &.#{$icons} {
                    &:before {
                      position: absolute;
                      content: "";
                      height: 100px;
                      width: 105px;
                      top: 30px;
                      left: calc(50% - 52px);
                      background: url("../../../../../public/images/enrollment_producttype_icons.svg")
                        no-repeat -#{$bacRightP}px
                        0px/420px;
                      z-index: 2;
                      border: none;
                    }
                  }
                }
              }

              input:checked ~ label {
                border: 1px solid $primary;
                @include box-shadow(0px -4px 12px rgba($black, 0.1));
                color: $black;

                &:after {
                  position: absolute;
                  content: "";
                  height: 22px;
                  width: 22px;
                  background: url("../../../../../public/images/checkbox_tick_icon.svg")
                    no-repeat center center/100%;
                  @include border-radius(50%);
                  left: 10px;
                  top: 7px;
                  border: none;
                }
              }

              hr {
                margin-bottom: 12px;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: none;
                border-left: none;
                border-right: none;
              }

              input {
                position: absolute;
                z-index: 999;
                width: 100%;
                height: 100%;
                opacity: 0;

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }

        .ctaBtn {
          .submitBtn {
            padding: 10px 78px;
          }
        }
      }
    }
  }
}
.m_enrollmentdrive {
  // position: fixed;
  // top: 0;
  // left:0;

  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: scroll;

  .inner_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
    padding: 40px 16px 0 16px;
  }
  .m_inner_section-heading {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: $primary;
    span {
      text-transform: capitalize;
    }
  }
  .welcome_text {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
  }
  .m_inner_section_desc {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    line-height: 14.06px;
    text-align: center;

    // text-align: left;/
  }
  .choose-policy-text {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 24.38px;
    text-align: left;
    margin-top: 26px;
  }
  .mpolicy_main_container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .mpolicy_container {
      //box-shadow: 0px 8px 20px 0px $primary;
      border: 1px solid transparent;
      border-radius: 20px;
      display: flex;
      align-items: center;
      gap: 24px;
      cursor: pointer;
      // Add padding if needed
      &.checked {
        border: 1px solid $primary;
      }
      hr {
        border: 1px solid #0000001a;
      }
      hr.active {
        border: 1px solid $primary;
      }

      .policy-top {
        padding: 12px 0 12px 12px;
        display: flex;

        // align-items: start;
        align-items: center;
        gap: 8px; // Adjust spacing as needed
        position: relative;
        // border:1px solid green;
        display: flex;
        // flex-direction: column;

        // This handles the checkbox interaction
        .checkbox-circle {
          width: 23px;
          height: 17px;
          border: 1px solid $primary; // Default border color for unchecked
          // border: 1px solid green;
          // border: 1px solid green;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff; // Background color for unchecked state
          position: relative;
          cursor: pointer;

          &.checked {
            // border: 1px solid $primary;
            // Border disappears or becomes transparent when checked
            // background-color: $primary;
            border: 1px solid $primary; // Background color for checked state
          }

          img {
            user-select: none;
            // Hide the tick icon by default

            &.checked {
              display: block; // Show the tick icon when checked
              .mpolicy_container {
                //border: 1px solid red;
              }
            }
          }
        }

        .w-100 {
          display: flex;
          justify-content: center;
          flex-grow: 1;
        }
      }

      .policy-bottom {
        // text-align: center;
        font-family: Montserrat;
        // margin-top: 12px;

        // margin-top: 12px;
        user-select: none;
        display: flex;
        align-items: center;

        width: 100%;

        h3 {
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 500;
          line-height: 14.63px;

          padding: 0;
          margin: 0;
          color: #000;
        }

        span {
          font-size: 12px;
          font-weight: 500;
          line-height: 14.63px;

          color: #00000080;
          // text-transform: capitalize;
          display: block;
          // margin: 5px 0;
        }
      }
    }
  }
}

.full-width {
  width: 100%;
}

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}

//   .mpolicy_container {
//     cursor: pointer;
//   }

//   .policy-top {
//     display: flex;
//     align-items: center;
//   }

//   .checkbox-circle {
//     width: 24px;
//     height: 24px;
//     border: 2px solid #333;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-right: 10px;
//     position: relative;
//     border-color: $primary;
//   }

//   .checkbox-circle.checked {
//     /* Example background color for checked state */
//     border-color: $primary; /* Remove border color when checked */
//   }

//   .checkbox-circle img {
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     /* Ensures the tick icon is centered in the circle */
//     display: none;
//   }

//   .checkbox-circle.checked img {
//     display: block;
//   }

//   .policy-top img {
//     margin-right: 10px;
//   }
// // }

// .add_family_save_btn {
//   background: $primary;
//   font-size: 1rem;
//   border-radius: 10px;
//   height: 52px;
//   color: #ffffff;
//   font-family: "Montserrat";
//   cursor: pointer;
//   padding: 16px 40px;
//   text-decoration: none;
//   margin-bottom: 0px;
// }

.add_family_save_btn {
  background: red;

  margin-top: 16px;
  font-size: 16px;
  border-radius: 10px;
  height: 52px;
  color: $white;
  font-family: "Montserrat";
  cursor: pointer;
  padding: 16px 40px;
  text-decoration: none;
  margin-bottom: 0px;
  &:hover {
    background: $primary;
  }
}

.MenrollmentStartBtn {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  /* left: 2.5%; */
  background: #fff;
  padding: 0px 10px 24px 10px;

  &.gradient_bg {
    background: rgba(107, 98, 220, 0.2);
  }

  background: #fff;

  .submitBtn {
    padding: 10px 94px;
    background: $primary;
    border-radius: 10px;
    color: #ffffff;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 10px 40px;
    box-shadow: none;
    position: relative;
    width: 100%;
    &:hover {
      background: $primary;
    }
  }
}

.MenrollmentBackBtn {
  position: fixed;

  top: 0;
  left: 0;
  // padding: 15px 16px;
  display: flex;
  align-items: center;
  gap: 18px;

  background: #fff;

  z-index: 10;
  width: 100%;
  // padding: 0 9px;
  padding: 0px 9px;
  height: 64px;

  box-sizing: border-box;
  h5 {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 24.38px;
    text-align: left;
    margin-top: 26px;
    padding: 0;
    margin: 0;
  }
}
