@import "../../../../../../mixins.scss";

.taxation_wrapper {
  padding: 120px 20px 0px;

  .detailSection {
    border: 1px solid $primary-light;
    @include border-radius(12px);
    // overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 12px;

    @include themed {
      background: $bg-dark;
      border: 1px solid $bg-grey;
    }

    .sectionTitle {
      background: $blue-light;
      display: flex;
      justify-content: space-between;
      padding: 12px 12px;
      margin-bottom: 15px;
      border-radius: 12px 12px 0 0;

      @include themed {
        background: $black;
      }

      h4 {
        color: $primary;
        @include fontSize(14px);
        font-weight: 500;
        margin-bottom: 0px;

        @include themed {
          color: $text-grey;
        }
      }
    }

    .tax_id {
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $black;
      padding-left: 27px;
      position: relative;

      @include themed {
        color: $text-light;
      }

      span {
        font-family: "Montserrat";
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }

      &:before {
        content: "";
        @include position(absolute, $left: 0px, $top: calc(50% - 10px));
        background: url("../../../../../../../public/images/tax_id_icon.svg")
          no-repeat 0px 0px/20px;
        width: 20px;
        height: 20px;
      }
    }

    .ctaBtn {
      text-align: right;

      .submitBtn {
        padding: 5px 40px;
      }
    }
  }

  .BasicInfoLabel {
    p {
      color: $primary;
      font-family: "Montserrat";
      font-weight: 400;
      @include fontSize(10px);
      line-height: 12px;
      margin-bottom: 8px;
    }

    h6 {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(14px);
      line-height: 21px;
      color: $black;
      margin-bottom: 10px;

      @include themed {
        color: $text-light;
      }
    }
  }

  .fieldsBox {
    .dataHeading {
      background: $blue-light;
      display: flex;
      flex-wrap: wrap;
      @include border-radius(12px 12px 0px 0px);
      padding: 16px 12px;
      margin-bottom: 0px;

      @include themed {
        background: $black;
        color: $text-light;
        border: 1px solid $bg-grey !important;
      }

      li {
        list-style-type: none;
        @include fontSize(12px);
        line-height: 15px;
        font-weight: 500;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        justify-content: space-between;

        &:last-child {
          justify-content: end;
        }

        .sorting-btn {
          background: transparent;
          // margin-left: auto;
          margin-right: 15px;

          img {
            height: 10px;
          }
        }
      }
    }

    .dataLists {
      @extend .dataHeading;
      background: transparent;
      @include border-radius(0px);
      padding: 12px;
      margin-bottom: 0px;
      border: 1px solid rgba($text-light, 0.5) !important;

      @include themed {
        background: $black;
        color: $text-light;
      }

      &:last-child {
        @include border-radius(0px 0px 12px 12px);
      }

      li {
        font-weight: 400;
      }
    }
  }
}
