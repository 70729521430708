@import "../../../../mixins.scss";

.totalClients {
  background: $blue-light !important;
  @include border-radius(20px);
  position: relative;
  min-height: 140px;

  h3 {
    @include fontSize(32px);
    color: $primary;
    margin-bottom: 4px;
  }

  p {
    font-weight: 300;
  }

  .charts {
    position: absolute;
    // top: -7px;
    top: 3px;
    right: 8px;
  }

  svg {
    transform: scale(1.2);
    transition: all 0.4s ease;

    &:hover {
      transform: scale(2);
    }

    rect {
      fill: none;
    }
  }
}

.activePolicies {
  background: $white;
  border: 1px solid #ffe1a7;
  @include border-radius(20px);
  min-height: 140px;

  h3 {
    @include fontSize(32px);
    color: #d8940f;
    margin-bottom: 4px;
    margin-top: 6px;
  }

  p {
    font-weight: 300;
    margin-bottom: 6px;
  }

  ul {
    margin-top: 12px;
    margin-bottom: 12px;

    li {
      @include fontSize(12px);
      text-align: center;
      list-style-type: none;
      position: relative;
      text-align: right;
      padding-right: 12px;
      margin-bottom: 6px;

      &:before {
        content: "";
        @include position(absolute, $right: 0px, $top: 4px);
        height: 6px;
        width: 6px;
      }

      $box-colors: ("health", $primary), ("term", #576cef), ("ci", #e45c8b),
        ("pa", #f3b03d), ("trvl", #4caf50), ("stp", #bfc507);
      $k: 0;

      @each $color in $box-colors {
        $k: $k + 1;

        &.#{nth($color,1)} {
          &:before {
            background: nth($color, 2);
          }
        }
      }
    }
  }
}

.noofLives {
  background: $primary;
  @include border-radius(20px);
  min-height: 140px;

  .totalLives {
    display: flex;
    justify-content: center;
    margin-top: 8px;

    h3 {
      color: $white;
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 24px;
    }

    img {
      height: 60px;
      margin-right: 16px;
      margin-left: 8px;
    }
  }

  h5 {
    color: $white;
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 20px;
  }

  p {
    color: $white;
    @include fontSize(14px);
    font-weight: 500;
  }
}

.alertMessage {
  border: 1px solid rgba($primary-orange, 0.5);
  @include border-radius(12px);
  @include box-shadow(0px 4px 12px rgba($primary-orange, 0.2));
  padding: 16px 12px 12px 12px;
  position: relative;

  // svg {
  //     color: $primary-orange;
  //     @include position(absolute, $top: 50%, $left: 16px);
  //     transform: translateY(-50%);
  // }

  h5 {
    margin-bottom: 8px;
    @include fontSize(14px);
    font-weight: 600;
    font-family: "Montserrat";
    display: flex;
    align-items: center;
    gap: 8px;

    @include themed() {
      color: $bg-dark;
    }

    svg {
      color: $primary-orange;
    }
  }

  p {
    @include fontSize(12px);
    color: rgba($black, 0.5);
    margin-bottom: 0px;

    @include themed() {
      color: rgba($black, 0.5) !important;
    }
  }

  a {
    display: block;
    @include position(absolute, $top: 50%, $right: 16px);
    transform: translateY(-50%);
    height: 20px;
    width: 20px;

    svg {
      color: $text-grey;
      position: relative;
      top: auto;
      left: auto;
      transform: translateY(0%);
    }

    &:hover {
      cursor: pointer;
    }
  }

  ul {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    padding-left: 10px;

    &::-webkit-scrollbar {
      width: 0px;
    }

    li {
      font-family: "Montserrat";
      @include fontSize(13px);
      color: rgba($black, 0.5);
      margin-bottom: 6px;
      position: relative;
      padding-left: 10px;

      &:before {
        content: "";
        @include position(absolute, $top: 7px, $left: 0);
        background: $primary;
        height: 5px;
        width: 5px;
        border-radius: 50%;
      }

      @media (max-width: 1450px) {
        display: flex;
        flex-direction: column;
        gap: 0;
      }
    }
  }
}

.policyRenewal {
  background: #def5e6;
  @include border-radius(20px);
  border: 1px solid $white;
  @include box-shadow(0px 4px 16px #def5e6);
  padding: 16px;
  position: relative;
  margin-bottom: 12px;

  .thisMonth {
    background: $white;
    @include border-radius(20px);
    @include box-shadow(0px 4px 12px rgba(#2e814a, 0.3));
    @include position(absolute, $top: 12px, $right: 12px);
    @include fontSize(12px);
    padding: 6px 12px;
  }

  h3 {
    color: #2e814a;
    @include fontSize(28px);
    margin-bottom: 4px;
  }

  ul {
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
    width: 100%;

    li {
      @include fontSize(10px);
      text-align: center;
      list-style-type: none;
      padding: 4px 8px;
      position: relative;

      &:before {
        content: "";
        @include position(absolute, $bottom: -6px, $left: 50%);
        transform: translateX(-50%);
        height: 4px;
        width: 24px;
      }

      span {
        font-weight: 600;
        display: block;
        margin-bottom: 4px;
      }

      $box-colors: ("health", $primary), ("term", #576cef), ("ci", #e45c8b),
        ("pa", #f3b03d);
      $k: 0;

      @each $color in $box-colors {
        $k: $k + 1;

        &.#{nth($color,1)} {
          &:before {
            background: nth($color, 2);
          }
        }
      }
    }
  }
}

.policytypeas {
  min-height: 400px;

  svg {
    g text {
      font-family: "Montserrat";
    }

    rect {
      fill: none;
    }

    .highcharts-exporting-group,
    .highcharts-title,
    .highcharts-credits {
      display: none;
    }
  }
}

.alertSummary {
  h3 {
    @include fontSize(18px);
    margin-bottom: 8px;
  }

  p {
    @include fontSize(12px);
    color: rgba($black, 0.5);
    margin-bottom: 0px;
  }

  ul {
    margin-top: 24px;

    li {
      list-style-type: none;
      border-bottom: 1px solid rgba($black, 0.1);
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      margin-bottom: 16px;

      p {
        @include fontSize(14px);
        color: $black;
        width: 100%;

        span {
          font-weight: 600;
          float: right;
        }
      }

      $box-colors: ("quoteicon", #def5e6), ("claimicon", #ffeee6),
        ("endoreseicon", $blue-light);
      $k: 0;

      @each $color in $box-colors {
        $bacRightP: $k * 40;
        $k: $k + 1;

        .#{nth($color,1)} {
          background: nth($color, 2);
          @include border-radius(8px);
          position: relative;
          margin-right: 12px;
          height: 40px;
          width: 40px;

          &:before {
            content: "";
            position: absolute;
            left: 0px;
            top: 0px;
            background: url("../../../../../public/images/alert_summary_icons.svg")
              no-repeat;
            background-position: -#{$bacRightP}px 0px;
            background-size: 120px;
            height: 40px;
            width: 40px;
          }
        }
      }
    }
  }
}

.loginTypeToggle {
  background: #fff;
  border: 1px solid rgba($black, 0.5);
  @include border-radius(20px !important);

  button {
    @include border-radius(20px !important);
    border: none;
    color: $grey-dark;
    text-transform: capitalize;
    font-weight: 500;
    @include fontSize(12px);
    padding: 6px 12px;

    @include themed() {
      color: $text-grey;
    }

    &.MuiToggleButtonGroup-grouped {
      @include border-radius(8px);
    }

    &.Mui-selected {
      background-color: $primary-color;
      color: #fff;
    }
  }
}

.employerPolicyWrapper {
  margin-bottom: 32px;

  $box-colors: ("bluePolicyCard", #c5d8ed), ("redPolicyCard", #ffd8e3),
    ("cyanPolicyCard", #acd4fb);
  $k: 0;

  @each $color in $box-colors {
    $bacRightP: $k * 70;
    $k: $k + 1;

    .#{nth($color,1)} {
      border-radius: 12px;
      padding: 12px 12px 0px;
      margin-bottom: 12px;
      position: relative;
      background: nth($color, 2);
      box-shadow: 16px 16px 28px nth($color, 2);

      img {
        width: 100%;
        max-width: 100px;
        max-height: 55px;
        height: 100%;
      }

      p {
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;

        &.small {
          font-weight: 500;
          font-size: 10px;
          line-height: 20px;
          color: rgba(#000, 0.7);
        }
      }

      h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        margin-top: 6px;
        margin-bottom: 4px;
      }

      h6 {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
      }

      .active {
        background: #ffffff;
        box-shadow: 0px 4px 4px darken($color: nth($color, 2), $amount: 8%);
        border-radius: 10px;
        color: #219653;
        display: inline-block;
        font-size: 11px;
        padding: 2px 6px;
      }

      .inactive {
        @extend .active;
        color: #ec3f3f;
      }

      .rIcon {
        background: url("../../../../../public/images/inr_icon.svg")
          no-repeat
          0px
          3px/7px;
        padding-left: 9px;
      }

      .whitepart {
        background-color: #fff;
        border-radius: 0px 0px 12px 12px;
        display: flex;
        flex-direction: row;
        margin: 12px -12px 0px;
        padding: 12px 24px;
        position: relative;

        .col {
          text-align: center;
          width: 100%;

          &:first-child {
            text-align: left;
          }

          &:last-child {
            text-align: right !important;
          }
        }

        .note {
          font-size: 10px;
          position: absolute;
          right: 0px;
          bottom: -20px;
        }
      }
    }
  }
}

.graphLib {
  text-align: center;

  .graphBox {
    padding: 24px;
    background: $blue-light !important;
    border-radius: 20px;

    @media (max-width: 1300px) {
      padding: 24px 0;
    }

    .total_count {
      text-align: center;
      color: #8e8e93;
      font-weight: 600;
    }
  }
}

.policyslider {
  .slick-dots {
    bottom: 0;

    li {
      width: 8px;
      height: 8px;
      padding: 0;
      margin: 0 4px;

      button {
        width: 8px;
        height: 8px;
        background: $primary;
        opacity: 0.4;
        border-radius: 200px;
        transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        width: 24px;

        button {
          opacity: 1;
          width: 24px;
        }
      }
    }
  }
}
