@import "./mixins.scss";

.layoutWrapper {
  padding-top: 70px;
  min-height: 100vh;
  //padding-bottom: 90px;

  @include themed {
    background: $bg-dark !important;
  }

  .testingforserver {
    color: #000000;
  }

  .pageHeader {
    background: $white;
    @include box-shadow(0px 2px 12px rgba($black, 0.1));
    @include position(fixed, $top: 0px, $left: 0px);
    margin: 0px;
    height: 70px;
    width: 100%;
    z-index: 991;

    @include themed {
      background: $bg-dark;
      box-shadow: 0 8px 6px -6px black;
    }

    .pageTitle {
      display: flex;
      align-items: center;

      .backStep {
        margin-right: 8px;
        color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          color: $primary-red;

          svg {
            left: -4px;
          }
        }

        svg {
          position: relative;
          transition: all 0.5s ease;
          left: 0px;
          height: 26px;
          width: 30px;
        }
      }

      .detailIcon {
        background: $primary-orange;
        @include border-radius(50px);
        color: $white;
        @include fontSize(20px);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        margin-right: 16px;
        height: 40px;
        width: 40px;
      }

      h3 {
        margin-bottom: 0px;
        @include fontSize(16px);
        line-height: 20px;
        text-transform: capitalize;

        @include themed {
          color: $text-grey;
        }
      }

      p {
        margin-bottom: 0px;
        margin-top: 6px;
        margin-bottom: 2px;
        color: rgba($black, 0.5);
        @include fontSize(11px);

        @include themed {
          color: $text-grey;
        }
      }
    }

    .updatePassword {
      text-transform: capitalize;
      background: rgba($primary, 0.1);
      color: $primary;
      height: 36px;
      @include fontSize(12px);
      font-family: "Montserrat";
      font-weight: 500;
      @include border-radius(50px);
      padding: 4px 16px;

      svg {
        height: 18px;
      }
    }

    .actionLinks {
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(16px);
      line-height: 20px;
      color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      text-decoration: none;
      cursor: pointer;
      background: $blue-light !important;
      @include border-radius(100px);
      height: 36px;
      width: 36px;
      margin-right: 12px;

      svg {
        height: 16px;
        width: 16px;
      }

      &:hover {
        background: $primary;
        color: $primary;
      }

      &.red_icon {
        color: $primary-red;
        background: #fcecdf;

        svg {
          height: 16px;
          width: 16px;
        }

        &:hover {
          background: $primary-red;
          color: $white;
        }
      }
    }

    h6 {
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(18px);
      line-height: 22px;
    }

    .status_dropdown {
      min-width: 140px;
      @include border-radius(20px);
      display: flex;
      align-items: center;
      padding: 0 10px;
      // width: 100%; ye comment saini ne krwaya
      border: 1px dashed;

      svg {
        height: 16px;
        width: 24px;
      }

      .inputFieldd {
        position: relative;
        margin-bottom: 0;
        width: 100%;

        .MuiOutlinedInput-input {
          font-family: "Montserrat";
          font-weight: 600;
          @include fontSize(12px);
          line-height: 15px;
          padding: 9px 20px 7px 1px;
        }

        label,
        input {
          font-family: "Montserrat";
          font-weight: 500;
          letter-spacing: 0px;
          @include fontSize(12px);

          @include themed() {
            color: $text-light;
          }
        }

        label {
          top: 2px;
        }

        input {
          height: 23px;
        }

        .MuiSelect-select {
          background: url("../public/images/down_arrow.svg") no-repeat right 5px
            center/10px !important;
          text-align: left;

          @include themed() {
            background: url("../public/images/down_arrow.svg") no-repeat right
              6px center/10px !important;
            color: $text-dark;
          }
        }

        .MuiInputLabel-shrink {
          color: $primary !important;
        }

        svg {
          display: none;
        }

        .Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: $primary !important;
          }
        }

        fieldset {
          border: none !important;
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(12px);
          line-height: 15px;

          @include themed() {
            border-color: $text-grey;
          }
        }
      }
    }

    div#Pending div {
      color: #ff9b44;
      border-color: #ff9b44;
      background: #fcecdf;
    }

    div#Active div {
      color: #219653;
      border-color: #219653;
      background: #d3fce5;
    }

    div#Approved div {
      color: #219653;
      border-color: #219653;
      background: #d3fce5;
    }

    div#Expired div {
      color: $primary-red;
      border-color: $primary-red;
      background: #ffe3e3;
    }

    div#Rejected div {
      color: $primary-red;
      border-color: $primary-red;
      background: #ffe3e3;
    }

    div#Inprogress div {
      color: #4293e4;
      border-color: #4293e4;
      background: #deeeff;
    }

    div#Sent_To_Insurer div {
      color: #4293e4;
      border-color: #4293e4;
      background: #deeeff;
    }

    div#Documents_Pending div {
      color: #ac25ac;
      border-color: #ac25ac;
      background: #c4b9c3;
    }

    div#Request_Raised div {
      color: #05daf7;
      border-color: #05daf7;
      background: #cee6e7;
    }
  }

  .request {
    color: #01663c !important;
    border-color: #5de442 !important;
    background: #b2ccac;
  }

  .leftSection {
    max-width: 260px;
    padding-top: 26px;
    height: calc(100vh - 130px);
    padding-left: 20px;

    .sectionLink {
      display: block;
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(12px);
      line-height: 17px;
      position: relative;
      text-decoration: none;
      cursor: pointer;
      padding-left: 30px;
      color: $black;
      margin-bottom: 15px;

      @include themed {
        color: $white;
      }

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0;
        top: -1px;
        background: #c5d8ed;
        @include border-radius(50%);

        @include themed {
          background: $bg-grey;
        }
      }

      &:after {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 4px;
        top: 5px;
        background: url("../public/images/white_tick_icon.svg") no-repeat 0px
          0px/100%;
      }

      &.active {
        color: $primary !important;

        &:before {
          background-color: $primary !important;
          @include border-radius(50%);
        }
      }
    }

    .accordion_list {
      list-style-type: none;
      margin-top: -12px;

      li {
        margin-left: 31px;

        .inner_link {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(12px);
          line-height: 14px;
          color: rgba(0, 0, 0, 0.3);
          text-decoration: none;
          position: relative;
          padding: 8px;
          display: inline-block;

          &:before {
            @include position(absolute, $top: 0px, $left: 0px);
            content: "";
            background: #c5d8ed;
            height: 30px;
            width: 1px;
          }

          &.active {
            color: $black;

            @include themed {
              color: $white;
            }

            &:before {
              background: $primary;
            }
          }

          @include themed {
            color: $text-grey;
          }
        }
      }
    }

    &.h-100 {
      min-height: calc(100vh - 82px);
    }
  }

  .rightSection {
    padding-top: 16px;
    overflow-y: auto;
    height: calc(100vh - 145px);

    &.h-100 {
      min-height: calc(100vh - 82px);
    }

    &.scroll {
      height: calc(100vh - 155px);
    }

    // .company_logo {
    //   min-height: 180px;
    //   background: #f9fbff;
    //   @include border-radius(16px);
    //   display: flex;
    //   justify-content: center;

    //   @include themed {
    //     background: $bg-grey;
    //   }

    //   .addnewlogo {
    //     @include border-radius(6px);
    //     height: 50px;
    //     width: 200px;
    //     margin-left: 0 !important;

    //     &.greenBtn {
    //       &.addfile {
    //         &:before {
    //           position: absolute;
    //           left: 30px;
    //           top: 12px;
    //         }

    //         &:after {
    //           position: absolute;
    //           left: 30px;
    //           top: 12px;
    //         }
    //       }
    //     }

    //     span {
    //       position: relative;
    //       left: 20px;
    //       text-transform: capitalize;
    //       @include fontSize(12px);
    //       line-height: 12px;
    //       font-family: "Montserrat";
    //     }
    //   }
    // }

    .lastupdate {
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(12px);
      line-height: 17px;
      color: $black;
      opacity: 0.5;
      margin: 0px;

      @include themed {
        color: $text-light;
      }

      @media (max-width: 1250px) {
        @include fontSize(10px);
      }
    }

    .small-text {
      @include fontSize(10px);
      line-height: 12px;
      margin: 10px 0px;
      color: rgba(0, 0, 0, 0.6);
    }

    .net_premium {
      font-family: "Montserrat";
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 0;

      @include themed {
        color: $text-light;
      }
    }

    .client_div {
      width: 300px;
    }

    .clientsID {
      display: inline-block !important;
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(10px);
      line-height: 12px;
      margin-right: 20px;

      span {
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(12px);
        line-height: 15px;
        padding-left: 5px;
      }

      @include themed {
        color: $text-light;
      }
    }

    .companyDetails {
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(10px);
      line-height: 12px;
      margin-right: 20px;

      @include themed {
        color: $text-light;
      }

      span {
        display: block;
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(12px);
        line-height: 15px;
        padding-top: 4px;
      }
    }

    .employee_icons {
      height: 55px;
      width: 115px;
      background: #f2f3fe;

      @include border-radius(10px);
    }

    .client_info_section {
      display: flex;

      .client_info_p {
        font-family: "Montserrat";
        border: 1px solid #c5d8ed;
        // font-style: italic;
        font-weight: 400;
        @include fontSize(14px);
        line-height: 18px;
        position: relative;
        padding: 15px 12px 15px 42px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        width: 280px;
        margin-right: 20px;

        @include themed {
          border: 1px solid $bg-grey;
        }

        &.md_width {
          width: 250px;

          @media (max-width: "1000px") {
            max-width: 280px;
          }
        }

        @media (max-width: "1300px") {
          width: 100%;
        }

        @include themed {
          color: $white;
        }

        span {
          font-weight: 600;
          display: contents;
        }

        $button-icons: freshPolicy, empID, dependents, totalEmployees, user;

        $k: 0;

        @each $icons in $button-icons {
          $bacRightP: $k * 20;

          $k: $k + 1;

          &.#{$icons} {
            &:before {
              @include position(absolute, $left: 10px, $top: calc(50% - 10px));

              content: "";

              height: 20px;

              width: 20px;

              background: url("../public/images/client_info_icons.svg")
                no-repeat -#{$bacRightP}px
                0px /100px;

              z-index: 2;

              border: none;
            }
          }
        }
      }
    }

    .upload-logo {
      display: flex;
      position: relative;

      .add_logo {
        z-index: 1 !important;
        position: relative;
      }

      .error {
        color: #eb5757 !important;
        font-size: 11px;
        position: absolute;
        right: 50%;
        bottom: 0px;
      }

      .upload_company_logo {
        min-height: 70px !important;
        max-height: 100px;
        // max-width: 100%;
        // width: 200px;
        padding: 8px;
        overflow: hidden;
        position: relative;
        border: 1px dashed #cfd2d3;
        @include border-radius(8px);
        outline: none;
      }

      .upload_img {
        cursor: pointer;
        margin-left: 20px;
        text-decoration: none;

        img {
          position: relative !important;
          top: 0px !important;
          right: auto !important;
          left: 0px;
        }

        span {
          color: #219653;
          @include fontSize(12px);
          line-height: 12px;
          font-weight: 500;
          padding-left: 4px;
          font-family: "Montserrat";
        }
      }
    }

    // @keyframes fadeIn {
    //     0% {
    //         opacity: 0;
    //         transform: translate3d(0, -3%, 0)
    //     }

    //     to {
    //         opacity: 1;
    //         transform: translateZ(0)
    //     }
    // }

    .detailSection {
      border: 1px solid $primary-light;
      @include border-radius(12px);
      // overflow: hidden;
      margin-bottom: 20px;
      padding-bottom: 12px;
      // opacity: 0;
      // animation: fadeIn 1s both;

      &.lastsection {
        margin-bottom: 80px;
      }

      @include themed {
        background: $bg-dark;
        border: 1px solid $bg-grey;
      }

      .policy_table {
        overflow-x: auto;

        .policy_table_inner {
          width: 100%;
          min-width: 2200px;

          .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $blue-light;
            background: #e4f1ff;
            @include border-radius(4px 4px 0px 0px);
            margin-bottom: 8px;
            width: 100%;

            @include themed {
              background: $bg-grey !important;
              border-bottom: 1px solid $bg-grey;
            }

            li {
              list-style-type: none;
              font-family: "Montserrat";
              font-weight: 600;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0;
              min-width: 310px;
              width: 310px;
              height: 40px;
              color: $black;
              padding: 10px 12px;
              // flex: 1;

              &:first-child {
                min-width: 200px;
                max-width: 200px;
              }

              @include themed {
                background: $bg-grey !important;
                color: $text-grey;
              }

              @include themed {
                background: $bg-grey !important;
                color: $text-grey;
              }
            }
          }

          .subheading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            border-bottom: 1px solid $blue-light;
            width: 100%;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            &:last-child {
              border-bottom: 0;
            }

            li {
              list-style-type: none;
              min-height: 50px;
              padding: 13px 12px;
              font-family: "Montserrat";
              font-weight: 500;
              min-width: 310px;
              width: 310px;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0px;
              color: $black;
              // flex: 1;

              @include themed {
                color: $text-light !important;
              }

              &:first-child {
                min-width: 200px;
                max-width: 200px;
              }

              // &:child(2) {
              //   min-width: 200px;
              //   width: 50%;
              // }

              .date {
                font-family: "Montserrat";
                font-weight: 500;
                @include fontSize(12px);
                line-height: 24px;
                max-width: 200px;
                color: rgba(0, 0, 0, 0.6) !important;

                @include themed {
                  color: $text-light !important;
                }
              }

              .time {
                font-family: "Montserrat";
                font-weight: 500;
                @include fontSize(12px);
                line-height: 24px;
                max-width: 200px;
                color: rgba(0, 0, 0, 0.6) !important;

                @include themed {
                  color: $text-light !important;
                }
              }
            }
          }
        }
      }

      .sectionTitle {
        background: $blue-light;
        display: flex;
        justify-content: space-between;
        padding: 12px 12px;
        margin-bottom: 15px;
        @include border-radius(12px 12px 0 0);

        @include themed {
          background: $black;
        }

        h4 {
          color: $primary;
          @include fontSize(14px);
          font-weight: 500;
          margin-bottom: 0px;

          @include themed {
            color: $text-grey;
          }
        }

        &.spoc_section_title {
          @extend .sectionTitle;
          background: transparent;
          padding: 0;
          margin-bottom: 0;
        }

        .actionBtns {
          .edit_btn {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;

            .edit_btn_icon {
              position: relative;
              top: -2px;
              height: 12px;
              margin-right: 4px;
            }
          }

          .addPremium {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            margin-right: 10px;
            position: relative;
            padding: 2px 0 0 28px;

            &.addfile {
              &:before {
                position: absolute;
                left: 1px;
                top: 0px;
              }
            }
          }
        }

        .total_lives {
          p {
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $primary;
            margin-bottom: 0;

            span {
              font-weight: 500;
              color: $black;

              @include themed {
                color: $text-light;
              }
            }
          }
        }
      }

      .plan_type {
        font-weight: 600;
        @include fontSize(14px);
        line-height: 17px;
        margin-bottom: 0;
        font-family: "Montserrat";

        @include themed {
          color: $text-light;
        }
      }

      .lives {
        display: inline-block;
        color: $black;
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(14px);
        line-height: 17px;

        span {
          color: #ec3f3f;
        }
      }

      .subHeading {
        @include fontSize(14px);
        font-weight: 600;
        line-height: 24px;
        margin: 0px 0px 12px 0px;
        position: relative;

        &.hidden {
          top: -31px;
          margin-bottom: -20px;
          opacity: 0;
          height: 0px;
        }

        @include themed {
          color: $text-grey;
        }

        &:before {
          content: "";
          @include position(absolute, $top: 26px, $left: 0px);
          width: 200px;
          height: 1px;
          background: $primary;
        }
      }

      // .subRow {
      //   .subColumn {
      //     // .inputField {
      //     //   margin-bottom: 24px;

      //     //   &:last-child {
      //     //     margin-bottom: 4px;
      //     //   }
      //     // }
      //   }
      // }

      .client_organization {
        min-width: 250px;

        h6 {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(10px);
          line-height: 12px;
          color: $black;
          margin-bottom: 16px;

          @include themed {
            color: $text-light;
          }

          span {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(12px);
            line-height: 15px;
            color: $black;
            display: block;
            margin-top: 8px;
            word-break: break-all;

            @include themed {
              color: $text-light;
            }
          }
        }
      }

      .MuiGrid2-container {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      &.switchFields {
        span {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(12px);
          line-height: 42.68px;
          padding-right: 24px;
          margin-right: 20px;

          @include themed {
            color: $text-light;
          }
        }

        &.switchheight {
          min-height: 80px;
        }
      }

      .switchFields {
        span {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(12px);
          line-height: 15px;
          // margin-right: 20px;

          @include themed {
            color: $text-light;
          }
        }
      }

      .org-info {
        display: flex;

        .logo-div {
          width: 350px;

          .existing_client_logo {
            padding: 12px;
            background: $white;
            @include border-radius(8px);
            max-width: 250px;
            border: 1px dashed #cfd2d3;
            overflow: hidden;

            @include themed {
              background: $bg-grey;
            }

            .company_logo {
              max-width: 100%;
              // height: 92px;
              margin: auto;
              display: block;
              margin: auto;
              border-radius: 8px;
            }
          }
        }

        .info-details {
          .inner-info-div {
            min-width: 300px;

            .company-name {
              font-family: "Montserrat";
              font-weight: 500;
              @include fontSize(16px);
              line-height: 20px;
              color: $black;
              margin-bottom: 0;

              @include themed {
                color: $text-light;
              }

              span {
                @include fontSize(11px);
                line-height: 20px;
                color: $text-grey;
                display: block;

                @include themed {
                  color: $text-light;
                }
              }
            }

            .info_p {
              font-family: "Montserrat";
              font-weight: 500;
              @include fontSize(12px);
              line-height: 20px;

              @include themed {
                color: $text-light;
              }

              span {
                font-family: "Montserrat";
                font-weight: 500;
                @include fontSize(16px);
                line-height: 20px;
                color: $primary;
                display: block;
                padding-top: 4px;

                .link {
                  text-decoration: none;
                  color: $primary;
                }
              }
            }
          }
        }
      }

      .brand_logo {
        width: 450px;
        display: flex;

        .company_logo {
          // height: 100%;
          cursor: pointer;
          // padding-right: 25px;
          width: 200px;
          margin-right: 15px;
        }
      }

      .ParentOrgSection {
        padding: 12px;

        .sm-font {
          font-family: "Montserrat";
          font-weight: 400;
          @include fontSize(10px);
          line-height: 12px;
          color: $black;
          padding-bottom: 10px;

          span {
            display: block;
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(12px);
            line-height: 15px;
          }
        }

        .mobile {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(12px);
          line-height: 15px;
          padding-left: 20px;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: url("../public/images/phone_icon.svg") 0 0 /15px;
            height: 15px;
            width: 15px;
          }
        }

        .email {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(12px);
          line-height: 15px;
          padding-left: 20px;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: url("../public/images/email_icon.svg") 0 0 / 15px;
            height: 15px;
            width: 15px;
          }
        }

        .address {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(12px);
          line-height: 15px;
          padding-left: 20px;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: url("../public/images/locations_icon.svg") 0 0 /15px;
            height: 14px;
            width: 14px;
          }
        }
      }

      .insurer_logo {
        max-height: 60px;
        max-width: 140px;
      }

      //contact Detail Start
      .insurers-table {
        overflow-x: auto;

        .heading {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $blue-light;
          background: #eef6fe;
          margin-bottom: 0px;
          @include border-radius(8px 8px 0px 0px);

          @include themed {
            background: $bg-grey;
            border-bottom: 1px solid $bg-grey;
          }

          li {
            list-style-type: none;
            font-family: "Montserrat";
            font-weight: 600;
            @include fontSize(12px);
            line-height: 20px;
            margin-bottom: 0;
            width: 400px;
            height: 40px;
            color: $black;
            display: flex;
            justify-content: space-between;
            padding: 10px 12px;

            @include themed {
              color: $text-light;
            }

            &:first-child {
              max-width: 100px;
            }

            &:last-child {
              max-width: 100px;
            }

            .sorting-btn {
              background: transparent;
              margin-left: auto;
              margin-right: 15px;
              position: relative;

              &:before {
                position: absolute;
                content: "";
                background: url("./../public/images/sorting_btn.svg") no-repeat
                  0 0 /100%;
                top: 5px;
                right: 0px;
                width: 7px;
                height: 12px;

                @include themed {
                  background: url("./../public/images/sorting_btn_white.svg")
                    no-repeat 0 0 /100%;
                }
              }
            }
          }

          .subheading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;
            border-bottom: 1px solid $blue-light;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            &:last-child {
              border-bottom: none;
            }

            li {
              list-style-type: none;
              min-height: 40px;
              padding: 13px 12px;
              font-family: "Montserrat";
              font-weight: 500;
              width: 400px;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0px;
              color: $black;

              @include themed {
                color: $text-light !important;
              }

              &:first-child {
                max-width: 100px;
              }

              &:last-child {
                max-width: 100px;
                text-align: right;
              }
            }
          }
        }

        .profile_section {
          background: #f9fbff;
          @include border-radius(16px);
          padding: 20px 17px;
          text-align: center;
          min-height: 190px;
          margin-bottom: 24px;

          @include themed {
            background: $bg-grey;
          }

          .profile_inner_section {
            border: 1px dashed rgba(0, 0, 0, 0.28);
            @include border-radius(20px);

            @include themed {
              border-color: $text-light;
            }
          }

          h6 {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(10px);
            line-height: 12px;
            text-align: center;
            margin-bottom: 16px;

            @include themed {
              color: $text-light;
            }
          }

          .profile_logo {
            height: 92px;
            border: 1px solid transparent;
            @include border-radius(100%);
            width: 92px;
            overflow: hidden;
            left: 19px;
            position: relative;
            padding: 5px;
          }

          .upload_img {
            cursor: pointer;

            &:hover {
              background-color: transparent;
            }

            img {
              position: relative;
              right: 3px;
              top: 18px;
            }
          }
        }
      }

      .notessection {
        @include themed() {
          background: $bg-grey;
        }

        .username {
          font-weight: 600;
          @include fontSize(11px);
          line-height: 16px;
          margin-bottom: 6px;
          font-family: "Montserrat";

          @include themed() {
            color: $text-light;
          }
        }

        .creation_date {
          @include fontSize(10px);
          line-height: 16px;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 6px;

          @include themed() {
            color: $text-grey;
          }
        }

        .usernotes {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(14px);
          line-height: 24px;
          text-align: justify;

          @include themed() {
            color: $text-light;
          }
        }
      }

      //contact Detail End

      // Quotes Start
      .radio_group {
        display: flex;
        border: 1px solid $primary-light;
        @include border-radius(12px);
        max-width: 400px;

        &.no-border {
          border: none !important;
          min-width: 658px;

          @include themed {
            border: none !important;
          }
        }

        @include themed {
          border: 1px solid $bg-grey;
        }

        .MuiFormGroup-row {
          .MuiFormControlLabel-root {
            .MuiRadio-root {
              color: $grey-light;

              &:hover {
                background-color: transparent;
                border: none;
              }
            }

            .MuiRadio-root.Mui-checked {
              color: $primary;
            }

            .MuiFormControlLabel-label {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: 500;
              @include fontSize(14px);
              line-height: 24px;

              @include themed {
                color: $text-light;
              }
            }
          }
        }
      }

      .productTypeView {
        background: $primary-light;
        @include border-radius(12px);
        position: relative;
        padding: 8px 12px;
        border: 1px solid #aea8eb;

        @include themed {
          border-color: $bg-grey;
        }

        .my {
          padding-left: 70px;
          min-height: 54px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;

          h5 {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 17px;
            margin-bottom: 8px;

            @include themed {
              color: $text-light;
            }
          }

          p {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(11px);
            line-height: 13px;
            color: $black;
            opacity: 0.5;

            @include themed {
              color: $text-light;
            }
          }
        }

        span {
          position: relative;
          display: block;

          $button-icons: grpTerm, grpHealth, grpTravel, grpPA, grpCI;
          $k: 0;

          @each $icons in $button-icons {
            $bacRightP: $k * 60;
            $k: $k + 1;

            &.#{$icons} {
              &:before {
                background: url("../public/images/producttype_icons.svg")
                  no-repeat -#{$bacRightP}px
                  0px /300px;
                content: "";
                @include position(absolute, $left: 0px, $top: -4px);
                height: 60px;
                width: 60px;
                z-index: 2;
              }
            }
          }
        }
      }

      .plantTypeView {
        background: #fef8ed;
        border: 1px solid #f5be4c;
        @include border-radius(12px);
        padding: 8px 20px;
        position: relative;

        &:before {
          position: absolute;
          content: "";
          background: url("../public/images/plantype_tickicon.svg") no-repeat
            0px 0px/100%;
          height: 26px;
          width: 26px;
          top: 50%;
          transform: translateY(-50%);
        }

        .select_plan_inner {
          padding-left: 40px;
          min-height: 54px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h5 {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 17px;
            margin-bottom: 8px;
          }

          p {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(11px);
            line-height: 13px;
            color: $black;
            opacity: 0.5;
          }
        }
      }

      .premium_payment_section,
      .planType_section {
        padding-left: 14px;

        h6 {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          @include fontSize(13px);
          line-height: 24px;
          margin-bottom: 0;
          opacity: 0.4;

          @include themed {
            color: $white;
          }
        }

        .payment_radio_group {
          display: flex;

          // @include themed {
          //   border: 1px solid $bg-grey;
          // }

          .MuiFormGroup-row {
            .MuiFormControlLabel-root {
              .MuiRadio-root {
                color: $grey-light;

                &:hover {
                  background-color: transparent;
                  border: none;
                }
              }

              .MuiRadio-root.Mui-checked {
                color: $primary;
              }

              .MuiFormControlLabel-label {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                @include fontSize(14px);
                line-height: 24px;

                @include themed {
                  color: $text-light;
                }
              }
            }
          }
        }

        .payment_inner_section {
          display: flex;

          .inputField {
            width: 150px;
            margin-right: 15px;

            .MuiInputBase-root {
              @include themed {
                background: $bg-grey;
                border-radius: 8px;
              }
            }

            .MuiInputAdornment-root {
              p {
                @include themed {
                  color: $white;
                }
              }
            }
          }
        }
      }

      .coverageTypeView {
        .coverage_type_section {
          margin: 0;
          display: flex;
          flex-wrap: wrap;

          li {
            list-style: none;
            margin-right: 24px;

            &:last-child {
              margin-right: 0px;
            }

            .coverage_box {
              font-family: "Montserrat";
              display: inline-block;
              text-align: center;
              color: $black;
              position: relative;
              @include fontSize(14px);
              line-height: 17px;
              padding: 12px;
              @include border-radius(12px);
              border: 1px solid $primary;
              font-weight: 500;
              width: 160px;
              height: 120px;
              @include box-shadow(0px 4px 12px rgba($black, 0.1));

              &:after {
                position: absolute;
                content: "";
                height: 22px;
                width: 22px;
                background: url("../public/images/checkbox_tick_icon.svg")
                  no-repeat 0px 0px/100%;
                @include border-radius(50%);
                left: 10px;
                border: none;
              }

              $button-icons: employee, spouse, kids, parents, parentsinlaw;
              $k: 0;

              @each $icons in $button-icons {
                $bacRightP: $k * 80;
                $k: $k + 1;

                &.#{$icons} {
                  &:before {
                    position: absolute;
                    content: "";
                    height: 80px;
                    width: 80px;
                    left: calc(50% - 40px);
                    background: url("../public/images/coveragetype_icons1.svg")
                      no-repeat -#{$bacRightP}px
                      0px/400px;
                    z-index: 2;
                    border: none;
                  }
                }
              }
            }

            p {
              @include position(absolute, $left: 0px, $bottom: 12px);
              width: 100%;
              @include fontSize(14px);
              line-height: 17px;
              font-weight: 500;

              @include themed {
                color: $text-light;
              }
            }
          }
        }
      }

      .employeeView {
        .livesCount {
          background: $blue-light;
          @include border-radius(40px);
          display: inline-block;

          @include themed {
            background: $bg-grey;
            @include border-radius(40px);
          }

          p {
            font-family: "Montserrat";
            font-weight: 600;
            @include fontSize(14px);
            line-height: 20px;
            padding: 12px 20px;

            @include themed {
              color: $text-light;
            }

            span {
              color: #ec3f3f;
            }
          }
        }

        .GradedSection {
          @include box-shadow(0px 4px 12px rgba($black, 0.1));
          @include border-radius(12px);
          padding: 8px;

          @include themed {
            border: 1px solid $bg-grey;
          }

          h5 {
            font-weight: 600;
            @include fontSize(14px);
            line-height: 17px;
            color: $primary;
            font-family: "Montserrat";
            margin-bottom: 0;
          }

          p {
            font-family: "Montserrat";
            font-weight: 600;
            @include fontSize(14px);
            line-height: 17px;

            @include themed {
              color: $text-light;
            }

            span {
              background: $primary;
              border: 1px solid $primary;
              @include border-radius(6px);
              color: $white;
              padding: 8px 16px 8px 30px;
              margin-left: 12px;
              position: relative;

              &::before {
                @include position(absolute, $left: 16px, $top: 12px);
                content: "";
                background: url("../public/images/rupee_white_icon.svg") 0px 0px/8px;
                height: 11px;
                width: 8px;
              }
            }
          }

          .feature_list {
            background: #ecffee;
            @include border-radius(8px);
            padding: 16px;

            ul {
              margin: 0;
              display: flex;

              li {
                list-style: none;
                font-family: "Montserrat";
                font-weight: 500;
                @include fontSize(14px);
                line-height: 17px;
                color: #029d11;
                margin-right: 20px;
                position: relative;
                padding-left: 28px;
                color: #000;

                &:before {
                  position: absolute;
                  content: "";
                  background: url("../public/images/feature_list_tickicon.svg")
                    no-repeat;
                  height: 20px;
                  width: 20px;
                  @include border-radius(50%);
                  left: 0px;
                  top: 2px;
                }

                span {
                  display: block;
                  font-family: "Montserrat";
                  font-weight: 500;
                  @include fontSize(12px);
                  line-height: 15px;
                  color: $grey-light;
                  margin-top: 4px;
                }
              }
            }
          }
        }
      }

      .productTypeSection {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 0px;

        .radioBox {
          margin-right: 12px;
          width: 140px;
          margin-bottom: 12px;
        }
      }

      .super_topup {
        display: flex;

        align-items: center;

        h6 {
          font-family: "Montserrat";

          color: $black;

          @include fontSize(13px);

          line-height: 14px;

          font-weight: 500;

          margin-bottom: 0;

          @include themed {
            color: $text-light;
          }
        }
      }

      .PolicyTypeSection {
        @extend .productTypeSection;

        .radioBox {
          width: 200px;
        }
      }

      .radioBox {
        display: inline-block;
        position: relative;

        label {
          font-family: "Montserrat";
          width: 100%;
          display: inline-block;
          text-align: center;
          color: $black;
          cursor: pointer;
          position: relative;
          @include fontSize(11px);
          line-height: 14px;
          padding: 12px;
          @include border-radius(12px);
          border: 1px solid rgba(0, 0, 0, 0.1);
          font-weight: 500;

          @include themed {
            background: $bg-grey;
            border: 1px solid $bg-grey;
            color: $white;
          }

          p {
            font-weight: 500;
            @include fontSize(11px);
            line-height: 13px;
            padding-top: 12px;
            opacity: 0.5;
          }

          &.policytype {
            position: relative;
            padding-top: 120px;
            width: 200px;
            height: 200px;

            &::after {
              position: absolute;
              content: "";
              background: url("../public/images/producttype_white_tick.svg")
                no-repeat 0px 0px/100%;
              height: 18px;
              width: 18px;
              top: 6px;
              right: 6px;
              // opacity: 0;
            }

            $button-icons: fresh, renew;
            $k: 0;

            @each $icons in $button-icons {
              $bacRightP: $k * 95;
              $k: $k + 1;

              &.#{$icons} {
                &:before {
                  border: none;
                  @include position(
                    absolute,
                    $left: calc(50% - 48px),
                    $top: 10px
                  );
                  content: "";
                  height: 102px;
                  width: 95px;
                  background: url("../public/images/policy-type.svg")
                    no-repeat -#{$bacRightP}px
                    0px/190px;
                  z-index: 2;
                }
              }
            }

            input:checked ~ label {
              background-color: $primary;
              @include box-shadow(0px 4px 12px rgba($black, 0.1));
              color: $white;

              &:after {
                @include position(absolute, $left: 10px, $top: 6px);
                content: "";
                height: 29px;
                width: 29px;
                background: url("../public/images/checkbox_tick_icon.svg")
                  no-repeat;

                @include border-radius(50%);
                border: none;
              }
            }

            hr {
              margin-bottom: 12px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              border-bottom: none;
              border-left: none;
              border-right: none;
            }
          }

          &.producttype {
            position: relative;
            padding-top: 76px;

            &::after {
              position: absolute;
              content: "";
              background: url("../public/images/producttype_white_tick.svg")
                no-repeat 0px 0px/100%;
              height: 18px;
              width: 18px;
              top: 6px;
              right: 6px;
            }

            $button-icons: grpTerm, grpHealth, grpTravel, grpPA, grpCI, grptopup;
            $k: 0;

            @each $icons in $button-icons {
              $bacRightP: $k * 60;
              $k: $k + 1;

              &.#{$icons} {
                &:before {
                  border: none;
                  @include position(
                    absolute,
                    $left: calc(50% - 30px),
                    $top: 10px
                  );
                  content: "";
                  height: 60px;
                  width: 60px;
                  background: url("../public/images/producttype_icons.svg")
                    no-repeat -#{$bacRightP}px
                    0px/360px;
                  z-index: 2;
                }
              }
            }

            input:checked ~ label {
              background-color: $primary;
              @include box-shadow(0px 4px 12px rgba($black, 0.1));
              color: $white;

              &:after {
                @include position(absolute, $left: 10px, $top: 6px);
                content: "";
                height: 29px;
                width: 29px;
                background: url("../public/images/checkbox_tick_icon.svg")
                  no-repeat;

                @include border-radius(50%);
                border: none;
              }
            }

            hr {
              margin-bottom: 12px;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              border-bottom: none;
              border-left: none;
              border-right: none;
            }
          }
        }

        input:checked ~ label {
          background-color: $primary;
          @include box-shadow(0px 4px 12px rgba($black, 0.1));
          color: $white;

          p {
            opacity: 0.5;
          }
        }

        input {
          position: absolute;
          z-index: 990;
          width: 100%;
          height: 100%;
          opacity: 0;

          &:hover {
            cursor: pointer;
          }
        }

        &.plantype {
          width: 100%;

          label {
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            text-align: left !important;
            position: relative;
            padding-left: 60px;
            padding-top: 12px;
            padding-bottom: 12px;

            span {
              font-family: "Montserrat";
              font-weight: 400;
              @include fontSize(11px);
              line-height: 14px;
              padding-top: 4px;
              display: block;
            }

            &:before {
              @include position(absolute, $left: 20px, $top: 50%);
              height: 26px;
              width: 26px;
              content: "";
              background: $text-light;
              @include border-radius(50%);
              transform: translateY(-50%);
            }
          }

          input:checked ~ label {
            background-color: #fef8ed;
            border: 1px solid #f5be4c;

            @include border-radius(12px);
            color: $black;
            box-shadow: none;

            p {
              opacity: 0.5;
            }

            &:before {
              background: url("../public/images/plantype_tickicon.svg")
                no-repeat center center/26px;
              border: none;
            }
          }
        }
      }

      .coverageDetail {
        .empsecHeading {
          font-weight: 600;
          @include fontSize(14px);
          line-height: 17px;
          margin-bottom: 0;
          font-family: "Montserrat";

          @include themed {
            color: $text-light;
          }
        }

        .coverageAmt {
          display: flex;
          flex-wrap: wrap;
          margin: 0px;

          li {
            list-style: none;
            margin-right: 12px;

            &:last-child {
              margin-right: 0;
            }

            @media (max-width: 1100px) {
              margin-right: 16px;
              margin-bottom: 16px;
            }

            @media (max-width: 1310px) {
              margin-bottom: 16px;
            }

            .radioBox {
              label {
                border: 1px solid $primary;
                @include border-radius(6px);
                @include fontSize(12px);
                font-weight: 500;
                line-height: 17px;
                position: relative;
                padding: 8px 10px 8px 26px;

                @media (max-width: 1100px) {
                  padding: 10px 10px 10px 28px;
                }

                &:before {
                  position: absolute;
                  content: "";
                  background: url("../public/images/rupee_black_icon.svg")
                    no-repeat 0px 0px/8px;
                  height: 12px;
                  width: 12px;
                  left: 13px;
                  top: 11px;
                  outline: none;
                  border: none;

                  @media (max-width: 1100px) {
                    top: 12px;
                  }

                  @include themed {
                    background: url("../public/images/rupee_white_icon.svg")
                      no-repeat;
                  }
                }
              }

              input:checked ~ label {
                position: relative;

                &:before {
                  position: absolute;
                  content: "";
                  background: url("../public/images/rupee_white_icon.svg")
                    no-repeat 0px 0px/7.5px;
                  height: 12px;
                  width: 12px;
                  left: 13px;
                  top: 11px;
                  outline: none;
                  border: none;

                  @media (max-width: 1100px) {
                    top: 12px;
                  }
                }
              }
            }
          }
        }

        .Checkbox {
          display: inline-block;
          position: relative;

          label {
            font-family: "Montserrat";
            display: inline-block;
            text-align: center;
            color: $black;
            cursor: pointer;
            position: relative;
            @include fontSize(12px);
            line-height: 17px;
            padding: 10px 12px;
            @include border-radius(12px);
            border: 1px solid $primary-light;
            font-weight: 500;
            width: 160px;
            height: 120px;

            @include themed {
              background: $bg-grey;
              border-color: $bg-grey;
            }

            &:after {
              position: absolute;
              content: "";
              height: 20px;
              width: 20px;
              border: 1px solid $primary;
              @include border-radius(50%);
              left: 10px;
            }

            p {
              position: absolute;
              bottom: 12px;
              left: 0;
              width: 100%;
              @include fontSize(12px);
              line-height: 17px;
              font-weight: 500;

              @include themed {
                color: $text-light;
              }
            }

            $button-icons: employee, spouse, kids, parents;
            $k: 0;

            @each $icons in $button-icons {
              $bacRightP: $k * 80;
              $k: $k + 1;

              &.#{$icons} {
                &:before {
                  position: absolute;
                  content: "";
                  height: 80px;
                  width: 80px;
                  top: 10px;
                  left: calc(50% - 40px);
                  background: url("../public/images/coveragetype_icons.svg")
                    no-repeat -#{$bacRightP}px
                    0px/320px;
                  z-index: 2;
                  border: none;
                }
              }
            }
          }

          input:checked ~ label {
            border: 1px solid $primary;
            @include box-shadow(0px -4px 12px rgba($black, 0.1));
            color: $black;

            &:after {
              position: absolute;
              content: "";
              height: 22px;
              width: 22px;
              background: url("../public/images/checkbox_tick_icon.svg")
                no-repeat center center/100%;
              @include border-radius(50%);
              left: 10px;
              border: none;
            }
          }

          input {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .Checkbox_two {
          display: inline-block;
          position: relative;

          &.checked {
            label {
              border: 1px solid $primary;
              @include box-shadow(0px -4px 12px rgba($black, 0.1));
              color: $black;

              &:after {
                position: absolute;
                content: "";
                height: 22px;
                width: 22px;
                background: url("../public/images/checkbox_tick_icon.svg")
                  no-repeat center center/100%;
                @include border-radius(50%);
                left: 10px;
                border: none;
              }
            }
          }

          label {
            font-family: "Montserrat";
            display: inline-block;
            text-align: center;
            color: $black;
            cursor: pointer;
            position: relative;
            @include fontSize(12px);
            line-height: 17px;
            padding: 10px 12px;
            @include border-radius(12px);
            border: 1px solid $primary-light;
            font-weight: 500;
            width: 160px;
            height: 120px;

            @include themed {
              background: $bg-grey;
              border-color: $bg-grey;
            }

            &:after {
              position: absolute;
              content: "";
              height: 20px;
              width: 20px;
              border: 1px solid $primary;
              @include border-radius(50%);
              left: 10px;
            }

            p {
              position: absolute;
              bottom: 12px;
              left: 0;
              width: 100%;
              @include fontSize(12px);
              line-height: 17px;
              font-weight: 500;

              @include themed {
                color: $text-light;
              }
            }

            $button-icons: employee, spouse, kids, parents;
            $k: 0;

            @each $icons in $button-icons {
              $bacRightP: $k * 80;
              $k: $k + 1;

              &.#{$icons} {
                &:before {
                  position: absolute;
                  content: "";
                  height: 80px;
                  width: 80px;
                  top: 10px;
                  left: calc(50% - 40px);
                  background: url("../public/images/coveragetype_icons.svg")
                    no-repeat -#{$bacRightP}px
                    0px/320px;
                  z-index: 2;
                  border: none;
                }
              }
            }
          }

          input {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:hover {
              cursor: pointer;
            }
          }
        }

        @extend .coverageTypeView;
        @extend .client_organization;
      }

      .employeeDetail {
        &.w-100 {
          width: 100%;
        }

        .featureBtn {
          padding: 10px 16px;
          text-decoration: None;
          @include border-radius(8px);
          border: 1px solid $primary;
          @include fontSize(14px);
          display: inline-block;
          color: $primary;
          cursor: pointer;

          &:hover {
            @include box-shadow(0px 4px 16px rgba($black, 0.1));
          }
        }

        .Accordion_section {
          background: $white;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
          @include border-radius(12px !important);
          margin-bottom: 10px;
          animation: fadeIn 1s both;

          @include themed {
            background-color: $bg-dark;
            border: 1px solid $bg-grey;
          }

          &:before {
            background: transparent;
          }

          &.Mui-expanded {
            margin: 0;
          }

          .MuiAccordionSummary-root {
            .MuiAccordionSummary-content {
              margin: 0;
            }

            &.Mui-expanded {
              max-height: 48px;
              min-height: 48px;
            }
          }
        }

        // graded section css

        .gradedSection {
          @include box-shadow(0px 4px 16px rgba($black, 0.1));
          @include border-radius(12px);
          padding: 12px;

          &.gradepadding {
            padding: 0px !important;
            margin: 0px 16px 20px;
          }

          h5 {
            font-weight: 600;
            @include fontSize(14px);
            line-height: 23px;
            color: $primary;
            font-family: "Montserrat";
            margin-bottom: 0;
            padding-top: 14px;
          }

          h4 {
            font-weight: 600;
            @include fontSize(14px);
            line-height: 47px;
            color: $black;
            font-family: "Montserrat";
            margin-bottom: 0;
          }

          p {
            font-weight: 600;
            @include fontSize(14px);
            line-height: 17px;
            margin-bottom: 0;
            font-family: "Montserrat";

            @include themed {
              color: $text-light;
            }
          }

          .featureBtn {
            padding: 10px 16px;
            text-decoration: None;
            @include box-shadow(0px 4px 16px rgba($black, 0.1));
            @include border-radius(12px);
            border: 1px solid $primary;
            display: inline-block;
            color: $primary;
            cursor: pointer;
          }
        }

        @extend .coverageDetail;
      }

      .band_lives {
        margin: 16px;
        // background: $white;
        // @include box-shadow(0px 4px 12px rgba($black, 0.1));
        // @include border-radius(12px);
        // padding: 16px;

        // @include themed {
        //   background: $bg-grey;
        // }

        h5 {
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $black;
          margin-bottom: 0;

          @include themed {
            color: $text-light;
          }
        }

        span {
          @include themed {
            color: $text-grey;
          }
        }

        .bandlives_inner {
          display: flex;
          align-items: baseline;

          .disabled_inp {
            .inputField {
              div.Mui-disabled {
                -webkit-text-fill-color: #000;
                // background: aliceblue;

                input.Mui-disabled {
                  border: 2px solid $primary;
                }
              }
            }
          }

          .inputField {
            min-width: 150px;
            margin-right: 15px;
            // border:1px solid red !important;
            // background: green !important;
          }
        }
      }

      .sectionsHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px;

        @include themed {
          background: $black;
          @include border-radius(8px);
        }

        h6 {
          color: $black;
          @include fontSize(14px);
          font-weight: 500;
          margin-bottom: 0px;

          @include themed {
            color: $text-grey;
          }
        }

        .actionBtns {
          .edit_btn {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;

            .edit_btn_icon {
              position: relative;
              top: -2px;
              height: 12px;
              margin-right: 4px;
            }
          }

          .addPremium {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            margin-right: 10px;
            position: relative;
            padding: 2px 0 0 28px;

            &.addfile {
              &:before {
                position: absolute;
                left: 1px;
                top: 0px;
              }
            }
          }

          .addnew_btn {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;

            .edit_btn_icon {
              position: relative;
              top: -2px;
              height: 12px;
              margin-right: 4px;
            }
          }
        }
      }

      .exclusionDetail {
        background: #fff0f0;
        @include border-radius(8px 8px);
        padding: 16px;
        margin: 0 15px;
        width: 100%;

        // @include themed {
        //   border-color: $black;
        //   background: $black;
        // }

        .header {
          background: $blue-light;
          @include border-radius(12px 12px 0px 0px);
          padding: 16px;

          @include themed {
            background: $bg-dark;
          }

          h3 {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            margin: 0px;
          }

          .addPremium {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            margin-right: 10px;
            position: relative;
            padding: 2px 0 0 28px;

            &.addfile {
              &:before {
                position: absolute;
                left: 1px;
                top: 0px;
              }
            }
          }
        }

        .edit_btn {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(14px);
          line-height: 20px;
          color: $primary;
          outline: none;
          text-decoration: none;
          display: inline-block;
          cursor: pointer;

          .edit_btn_icon {
            position: relative;
            top: -2px;
            height: 12px;
            margin-right: 4px;
          }
        }

        .features_list {
          ul {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            li {
              list-style: none;
              font-family: "Montserrat";
              font-weight: 500;
              @include fontSize(12px);
              line-height: 15px;
              color: $black;
              margin-bottom: 12px;
              position: relative;
              padding-left: 28px;
              width: 50%;
              padding-right: 15px;
              margin-bottom: 0px;

              // @include themed {
              //   color: $text-light;
              // }

              &:before {
                position: absolute;
                content: "";
                background: url("../public/images/featurelist_red_tick_icon.svg")
                  no-repeat 0px 0px/100%;
                height: 20px;
                width: 16px;
                @include border-radius(50%);
                left: 0px;
                top: calc(50% - 17px);
              }

              span {
                font-family: "Montserrat";
                font-weight: 400;
                @include fontSize(11px);
                line-height: 20px;
                color: $black;
                opacity: 0.5;
                display: block;
                margin-top: 4px;

                // @include themed {
                //   color: $text-light;
                // }
              }
            }
          }
        }
      }

      .voluntaryplans {
        background: #fef8ed;
        @include border-radius(8px 8px);
        padding: 16px;
        margin: 0 15px;
        width: 100%;

        @include themed {
          border-color: $black;
          background: $black;
        }

        .header {
          background: $blue-light;
          @include border-radius(12px 12px 0px 0px);
          padding: 16px;

          @include themed {
            background: $bg-dark;
          }

          h3 {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            margin: 0px;
          }

          .addPremium {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            margin-right: 10px;
            position: relative;
            padding: 2px 0 0 28px;

            &.addfile {
              &:before {
                position: absolute;
                left: 1px;
                top: 0px;
              }
            }
          }
        }

        .edit_btn {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(14px);
          line-height: 20px;
          color: $primary;
          outline: none;
          text-decoration: none;
          display: inline-block;
          cursor: pointer;

          .edit_btn_icon {
            position: relative;
            top: -2px;
            height: 12px;
            margin-right: 4px;
          }
        }

        .features_list {
          ul {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            li {
              list-style: none;
              font-family: "Montserrat";
              font-weight: 500;
              @include fontSize(12px);
              line-height: 15px;
              color: $black;
              margin-bottom: 12px;
              position: relative;
              padding-left: 28px;
              width: 50%;
              padding-right: 15px;
              margin-bottom: 0px;

              &:before {
                position: absolute;
                content: "";
                background: url("../public/images/featurelist_yellow_tick_icon.svg")
                  no-repeat 0px 0px/100%;
                height: 20px;
                width: 16px;
                @include border-radius(50%);
                left: 0px;
                top: calc(50% - 17px);
              }

              span {
                font-family: "Montserrat";
                font-weight: 400;
                @include fontSize(11px);
                line-height: 20px;
                color: $black;
                opacity: 0.5;
                display: block;
                margin-top: 4px;
              }
            }
          }
        }
      }

      .featuresDetail {
        background: #ecffee;
        @include border-radius(8px 8px);
        padding: 16px;
        margin: 0 15px;
        width: 100%;

        // @include themed {
        //   border-color: $black;
        //   background: $black;
        // }

        .header {
          background: $blue-light;
          @include border-radius(12px 12px 0px 0px);
          padding: 16px;

          @include themed {
            background: $bg-dark;
          }

          h3 {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            margin: 0px;
          }

          .addPremium {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            margin-right: 10px;
            position: relative;
            padding: 2px 0 0 28px;

            &.addfile {
              &:before {
                position: absolute;
                left: 1px;
                top: 0px;
              }
            }
          }
        }

        .edit_btn {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(14px);
          line-height: 20px;
          color: $primary;
          outline: none;
          text-decoration: none;
          display: inline-block;
          cursor: pointer;

          .edit_btn_icon {
            position: relative;
            top: -2px;
            height: 12px;
            margin-right: 4px;
          }
        }

        .features_list {
          ul {
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            li {
              list-style: none;
              font-family: "Montserrat";
              font-weight: 500;
              @include fontSize(12px);
              line-height: 15px;
              color: $black;
              margin-bottom: 12px;
              position: relative;
              padding-left: 28px;
              display: inline-block;
              padding-right: 15px;
              width: 50%;

              &:before {
                position: absolute;
                content: "";
                background: url("../public/images/featurelist_green_tick_icon.svg")
                  no-repeat 0px 0px/100%;
                height: 20px;
                width: 16px;
                border-radius: 50%;
                left: 0px;
                top: calc(50% - 17px);
              }

              span {
                font-family: "Montserrat";
                font-weight: 400;
                @include fontSize(11px);
                line-height: 20px;
                color: $black;
                opacity: 0.5;
                display: block;
                margin-top: 4px;
              }
            }
          }
        }
      }

      .company_details {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(14px);
        line-height: 20px;
        color: $black;
        margin-bottom: 0;

        @include themed {
          color: $text-light;
        }

        span {
          @include fontSize(11px);
          line-height: 20px;
          color: $text-grey;
          display: block;

          @include themed {
            color: $text-light;
          }
        }
      }

      .email_list {
        text-decoration: none;
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(12px);
        line-height: 20px;
        color: $primary;

        @include themed {
          color: $primary;
        }

        svg {
          height: 16px;
          width: 16px;
          position: relative;
          top: 4px;
          margin-right: 7px;
        }
      }

      .contactNumber {
        @extend .company_details;
        @include fontSize(12px);

        svg {
          height: 16px;
          width: 17px;
          position: relative;
          top: 3px;
          margin-right: 7px;
          color: $primary;
        }
      }

      // Quotes End

      // Add New Client Css Start
      .add_logo {
        background: #efefef;
        @include border-radius(6px);
        height: 50px;
        width: 150px;
        margin-left: 0 !important;

        @include themed {
          background: $bg-grey;
        }

        &.greenBtn {
          &.addfile {
            &:before {
              position: absolute;
              left: 30px;
              top: 12px;
            }

            &:after {
              position: absolute;
              left: 30px;
              top: 12px;
            }
          }
        }

        span {
          position: relative;
          left: 20px;
          text-transform: capitalize;
          @include fontSize(12px);
          line-height: 12px;
          font-family: "Montserrat";
        }
      }

      .emp_switchFields {
        span {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(12px);
          line-height: 15px;
          padding-right: 24px;

          @include themed {
            color: $text-light;
          }
        }
      }

      // Add New Client Css End

      // client details css start

      .planname {
        width: 100%;
        background: $primary;
        color: $white;
        height: 46px;
        padding: 14px 12px;
        border-radius: 8px;

        p {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(14px);
          line-height: 17px;
          position: relative;
          padding-left: 40px;
          $policy-products: group_term, group_health, group_travel,
            group_accident, group_critical;
          $k: 0;

          @each $icons in $policy-products {
            $bacRightP: $k * 30;
            $k: $k + 1;

            .#{$icons} {
              &:before {
                @include position(absolute, $left: 0px, $top: -4px);
                content: "";
                height: 30px;
                width: 30px;
                background-image: url("../public/images/policy_icons.svg");
                background-position: -#{$bacRightP}px 0px;
                background-size: 150px;
              }
            }
          }
        }
      }

      .company_details {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(14px);
        line-height: 20px;
        color: $black;
        margin-bottom: 0;

        @include themed {
          color: $text-light;
        }

        span {
          @include fontSize(11px);
          line-height: 20px;
          color: $text-grey;
          display: block;

          @include themed {
            color: $text-light;
          }
        }
      }

      .email_list {
        text-decoration: none;
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(12px);
        line-height: 20px;
        color: $primary;

        @include themed {
          color: $primary;
        }

        svg {
          height: 16px;
          width: 16px;
          position: relative;
          top: 4px;
          margin-right: 7px;
        }
      }

      .contactNumber {
        @extend .company_details;
        @include fontSize(12px);

        svg {
          height: 16px;
          width: 17px;
          position: relative;
          top: 3px;
          margin-right: 7px;
          color: $primary;
        }
      }

      // settings table
      .settingtableview {
        overflow-x: auto;
        overflow-y: hidden;

        padding: 12px;

        .Settingstableinner {
          min-width: 100%;
          border: 1px solid $primary-light;
          @include border-radius(16px 16px 0px 0px);
          padding: 0;
          width: 100%;
          min-width: 1600px;

          @include themed {
            border: 1px solid $bg-grey;
          }

          .SettingstableHeader {
            background: #e4f1ff;
            @include border-radius(16px 16px 0px 0px);
            padding: 6px 12px;
            display: flex;
            margin-bottom: 0px;

            @include themed {
              background: $black;
            }

            li {
              list-style-type: none;
              font-family: "Montserrat";
              font-weight: 500;
              @include fontSize(11px);
              line-height: 14px;
              margin-bottom: 0;
              height: 39px;
              color: $black;
              padding: 12px;
              display: flex;
              align-items: center;
              min-width: 300px;

              @include themed {
                color: $text-light !important;
                background: $bg-dark !important;
              }

              &:nth-child(2) {
                width: 400px;
              }

              &:last-child {
                justify-content: end;
                min-width: 200px;
              }

              .sorting-btn {
                background: transparent;
                margin-left: auto;

                img {
                  height: 10px;
                }
              }
            }
          }

          .SettingstableData {
            display: flex;

            margin-bottom: 0px;
            border-bottom: 1px solid $blue-light;
            padding: 6px 12px;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            &:last-child {
              border-bottom: 0;
            }

            li {
              list-style-type: none;
              min-height: 50px;
              padding: 13px 12px;
              font-family: "Montserrat";
              font-weight: 500;
              width: 300px;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0px;
              color: rgba($black, 0.5);

              &:nth-child(2) {
                width: 400px;
              }

              &:last-child {
                justify-content: end;
                min-width: 100px;
              }

              @include themed {
                color: $text-light !important;
              }
            }
          }
        }
      }

      .upload_img {
        cursor: pointer;
        position: relative;

        .add_logo {
          z-index: 1 !important;
          position: relative;
        }

        .upload_company_logo {
          height: 80px;
          border: 1px solid transparent;
          width: 150px;
          overflow: hidden;
          position: relative;
          border: 1px solid;
          border-radius: 8px;
          left: 0;
          top: 0;
          z-index: 10;
        }

        &:hover {
          background-color: transparent;
        }

        img {
          position: absolute;
          z-index: 3 !important;
          top: 24px;
          right: -10px;
        }
      }

      .edit_profile_logo {
        // @include border-radius(100% !important);
        // width: 200px;
        height: 70px;
        padding: 12px;
      }

      .editlogo {
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $primary;
        text-decoration: none;
        margin-left: 20px;
        padding-left: 20px;
        position: relative;

        &:before {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          background: url("../public/images/edit_icon.svg") 0px 0px /16px;
          height: 16px;
          width: 16px;
        }
      }

      .GradedLives {
        display: flex;
        justify-content: space-between;

        p {
          font-family: "Montserrat";
          @include fontSize(14px);
          font-weight: 600;
          line-height: 17px;
          color: $black;
          margin-bottom: 0;

          span {
            color: #ec3f3f;
          }
        }
      }

      //voluntary table
      .table {
        overflow-x: auto;

        .voluntary-table {
          width: 100%;
          min-width: 1500px;
          @include border-radius(12px);
          overflow-y: hidden;

          &.addnewplan {
            @include border-radius(0);
            border: none;
          }

          .heading {
            display: flex;
            flex-wrap: wrap;
            background: $blue-light;
            border-bottom: 1px solid $blue-light;
            margin-bottom: 8px;
            @include border-radius(12px 12px 0 0);

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            &.newplan {
              background: transparent;
            }

            li {
              list-style-type: none;
              font-family: "Montserrat";
              font-weight: 600;
              @include fontSize(12px);
              line-height: 20px;
              margin-bottom: 0;
              height: 40px;
              color: $black;
              padding: 10px 12px;
              display: flex;
              flex-basis: 0;
              flex-grow: 1;
              justify-content: space-between;

              &:first-child {
                max-width: 300px;
              }

              &:last-child {
                max-width: 100px;
              }

              .sorting-btn {
                background: transparent;
                margin-left: auto;
                margin-right: 15px;
                position: relative;

                &:before {
                  position: absolute;
                  content: "";
                  background: url("./../public/images/sorting_btn.svg")
                    no-repeat 0 0 /100%;
                  top: 5px;
                  right: 7px;
                  width: 7px;
                  height: 12px;

                  @include themed {
                    background: url("./../public/images/sorting_btn_white.svg")
                      no-repeat 0 0 /100%;
                  }
                }

                img {
                  height: 10px;
                }
              }

              @include themed {
                background: $bg-grey;
                color: $text-light;
                overflow-y: hidden;
              }
            }
          }

          .subheading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;
            border-bottom: 1px solid $blue-light;

            &:last-child {
              border-bottom: 0;
            }

            li {
              list-style-type: none;
              min-height: 40px;
              padding: 10px 12px;
              font-family: "Montserrat";
              font-weight: 400;
              width: 250px;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0px;
              color: $black;
              flex-basis: 0;
              flex-grow: 1;
              align-self: center;

              @include themed {
                color: $text-light !important;
              }

              &:first-child {
                max-width: 300px;
              }

              &:last-child {
                max-width: 100px;
                text-align: end;
              }
            }
          }
        }
      }

      //voluntaryfeature table
      .table {
        overflow-x: auto;

        .voluntaryfeaturetable {
          width: 100%;
          min-width: 1500px;

          @include themed {
            border: 1px soild $bg-grey;
          }

          &.addnewplan {
            @include border-radius();
            border: none;
          }

          .headingdata {
            display: flex;
            flex-wrap: wrap;
            background: $blue-light;
            border-bottom: 1px solid $blue-light;
            margin-bottom: 8px;
            overflow-y: hidden;
            border-radius: 12px 12px 0px 0px;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            &.newplan {
              background: transparent;
            }

            li {
              list-style-type: none;
              font-family: "Montserrat";
              font-weight: 600;
              @include fontSize(12px);
              line-height: 20px;
              margin-bottom: 0;
              height: 40px;
              color: $black;
              padding: 10px 12px;
              display: flex;
              flex-basis: 0;
              flex-grow: 1;

              justify-content: space-between;

              &:first-child {
                max-width: 250px;
                min-width: 250px;
              }

              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                max-width: 200px;
              }

              &:last-child {
                max-width: 100px;
                text-align: right;
              }

              .sorting-btn {
                background: transparent;
                margin-left: auto;
                margin-right: 15px;
                position: relative;

                &:before {
                  position: absolute;
                  content: "";
                  background: url("./../public/images/sorting_btn.svg")
                    no-repeat 0 0 /100%;
                  top: 5px;
                  right: 7px;
                  width: 7px;
                  height: 12px;

                  @include themed {
                    background: url("./../public/images/sorting_btn_white.svg")
                      no-repeat 0 0 /100%;
                  }
                }

                img {
                  height: 10px;
                }
              }

              @include themed {
                background: $bg-grey;
                color: $text-light;
              }
            }
          }

          .subheadingdata {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;
            border-bottom: 1px solid $blue-light;

            &:last-child {
              border-bottom: 0;
            }

            li {
              list-style-type: none;
              min-height: 40px;
              padding: 10px 12px;
              font-family: "Montserrat";
              font-weight: 400;
              width: 250px;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0px;
              color: $black;
              flex-basis: 0;
              flex-grow: 1;
              align-self: center;

              @include themed {
                color: $text-light !important;
              }

              &:first-child {
                max-width: 250px;
                min-width: 250px;
              }

              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                max-width: 200px;
              }

              &:last-child {
                max-width: 100px;
                text-align: right;
              }
            }
          }
        }
      }

      //Email table
      .table {
        .common-table {
          width: 100%;

          @media (max-width: 1200px) {
            width: 1200px;
          }

          .heading {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid $blue-light;
            margin-bottom: 8px;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            li {
              list-style-type: none;
              font-family: "Montserrat";
              font-weight: 600;
              @include fontSize(12px);
              line-height: 20px;
              margin-bottom: 0;
              height: 40px;
              color: $black;
              padding: 10px 12px;
              display: flex;
              flex-basis: 0;
              flex-grow: 1;
              justify-content: space-between;

              .sorting-btn {
                background: transparent;
                margin-left: auto;
                margin-right: 15px;
                position: relative;

                &:before {
                  position: absolute;
                  content: "";
                  background: url("./../public/images/sorting_btn.svg")
                    no-repeat 0 0 /100%;
                  top: 5px;
                  right: 7px;
                  width: 7px;
                  height: 12px;

                  @include themed {
                    background: url("./../public/images/sorting_btn_white.svg")
                      no-repeat 0 0 /100%;
                  }
                }

                img {
                  height: 10px;
                }
              }

              @include themed {
                background: $bg-grey;
                color: $text-light;
              }

              &:first-child {
                width: 50px;
                max-width: 100px;
              }

              &:nth-child(2) {
                max-width: 300px;
              }

              &:last-child {
                display: flex;
                justify-content: flex-end;
                max-width: 90px;
              }
            }
          }

          .subheading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;
            border-bottom: 1px solid $blue-light;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            &:last-child {
              border-bottom: 0;
            }

            li {
              list-style-type: none;
              min-height: 40px;
              padding: 10px 12px;
              font-family: "Montserrat";
              font-weight: 400;
              width: 250px;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0px;
              color: $black;
              flex-basis: 0;
              flex-grow: 1;
              align-self: center;

              @include themed {
                color: $text-light !important;
              }

              &:first-child {
                width: 50px;
                max-width: 100px;
              }

              &:nth-child(2) {
                max-width: 300px;
              }

              &:last-child {
                display: flex;
                justify-content: flex-end;
                max-width: 90px;
                cursor: pointer;
              }

              .Checkbox {
                label {
                  &:after {
                    position: absolute;
                    content: "";
                    height: 15px;
                    width: 15px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    @include border-radius(2px);
                    left: 2px;
                    top: 6px;

                    @include themed {
                      border-color: $text-light !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      //template table
      .table {
        .template-table {
          width: 100%;

          @media (max-width: 1000px) {
            width: 1000px;
          }

          .heading {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid $blue-light;
            margin-bottom: 8px;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            li {
              list-style-type: none;
              font-family: "Montserrat";
              font-weight: 600;
              @include fontSize(12px);
              line-height: 20px;
              margin-bottom: 0;
              height: 40px;
              color: $black;
              padding: 10px 12px;
              display: flex;
              flex-basis: 0;
              flex-grow: 1;
              justify-content: space-between;

              .sorting-btn {
                background: transparent;
                margin-left: auto;
                margin-right: 15px;
                position: relative;

                &:before {
                  position: absolute;
                  content: "";
                  background: url("./../public/images/sorting_btn.svg")
                    no-repeat 0 0 /100%;
                  top: 5px;
                  right: 7px;
                  width: 7px;
                  height: 12px;

                  @include themed {
                    background: url("./../public/images/sorting_btn_white.svg")
                      no-repeat 0 0 /100%;
                  }
                }

                img {
                  height: 10px;
                }
              }

              @include themed {
                background: $bg-grey;
                color: $text-light;
              }

              &:first-child {
                width: 50px;
                max-width: 100px;
              }

              &:last-child {
                display: flex;
                justify-content: flex-end;
                max-width: 90px;
              }
            }
          }

          .subheading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;
            border-bottom: 1px solid $blue-light;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            &:last-child {
              border-bottom: 0;
            }

            li {
              list-style-type: none;
              min-height: 40px;
              padding: 10px 12px;
              font-family: "Montserrat";
              font-weight: 400;
              width: 250px;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0px;
              color: $black;
              flex-basis: 0;
              flex-grow: 1;
              align-self: center;

              @include themed {
                color: $text-light !important;
              }

              &:first-child {
                width: 50px;
                max-width: 100px;
              }

              &:last-child {
                display: flex;
                justify-content: flex-end;
                max-width: 90px;
                cursor: pointer;
              }

              .Checkbox {
                label {
                  &:after {
                    position: absolute;
                    content: "";
                    height: 15px;
                    width: 15px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    @include border-radius(2px);
                    left: 2px;
                    top: 6px;

                    @include themed {
                      border-color: $text-light !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      //physical table
      .table {
        overflow-x: auto;

        .physical-table {
          width: 100%;
          min-width: 1500px;

          .heading {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid $blue-light;
            margin-bottom: 8px;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            li {
              list-style-type: none;
              font-family: "Montserrat";
              font-weight: 600;
              @include fontSize(12px);
              line-height: 20px;
              margin-bottom: 0;
              height: 40px;
              color: $black;
              padding: 10px 12px;
              display: flex;
              flex-basis: 0;
              flex-grow: 1;
              justify-content: space-between;

              .sorting-btn {
                background: transparent;
                margin-left: auto;
                margin-right: 15px;
                position: relative;

                &:before {
                  position: absolute;
                  content: "";
                  background: url("./../public/images/sorting_btn.svg")
                    no-repeat 0 0 /100%;
                  top: 5px;
                  right: 7px;
                  width: 7px;
                  height: 12px;

                  @include themed {
                    background: url("./../public/images/sorting_btn_white.svg")
                      no-repeat 0 0 /100%;
                  }
                }

                img {
                  height: 10px;
                }
              }

              @include themed {
                background: $bg-grey;
                color: $text-light;
              }

              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                max-width: 200px;
              }

              &:last-child {
                max-width: 120px;
              }
            }
          }

          .subheading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;
            border-bottom: 1px solid $blue-light;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            &:last-child {
              border-bottom: 0;
            }

            li {
              list-style-type: none;
              min-height: 40px;
              padding: 10px 12px;
              font-family: "Montserrat";
              font-weight: 400;
              width: 250px;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0px;
              color: $black;
              flex-basis: 0;
              flex-grow: 1;
              align-self: center;

              @include themed {
                color: $text-light !important;
              }

              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                max-width: 200px;
              }

              &:last-child {
                max-width: 120px;
              }

              .Checkbox {
                label {
                  &:after {
                    position: absolute;
                    content: "";
                    height: 15px;
                    width: 15px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    @include border-radius(2px);
                    left: 2px;
                    top: 6px;

                    @include themed {
                      border-color: $text-light !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // employee table

      .employee_table {
        overflow-x: auto;
        .content_table {
          min-width: unset !important;
          .heading {
            li {
              width: 30% !important;
              &.title {
                width: 70% !important;
              }
            }
          }
          .subheading {
            li {
              width: 30% !important;
              &.title {
                width: 70% !important;
              }
            }
          }
        }

        .employee_innerpart {
          width: 100%;
          min-width: 2000px;

          .heading {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $blue-light;
            background: #e4f1ff;
            @include border-radius(4px 4px 0px 0px);
            margin-bottom: 8px;
            width: 100%;

            @include themed {
              background: $bg-grey !important;
              border-bottom: 1px solid $bg-grey;
            }

            li {
              list-style-type: none;
              font-family: "Montserrat";
              font-weight: 600;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0;
              //  background: #e4f1ff;
              background: rgba(228, 241, 255, 0.3);
              min-width: 300px;
              width: 300px;
              height: auto;
              color: $black;
              padding: 10px 12px;
              // flex: 1;

              &:first-child {
                min-width: 300px;
                width: auto !important;
                // width: 50%;
              }

              @include themed {
                background: $bg-grey !important;
                color: $text-grey;
              }

              // &:last-child {
              //   width: 300px !important;
              // }

              // &:child(2) {
              //   min-width: 600px;
              //   width: 50%;
              // }
            }
          }

          .subheading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            border-bottom: 1px solid $blue-light;
            width: 100%;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            &:last-child {
              border-bottom: 0;
            }

            li {
              list-style-type: none;
              min-height: 50px;
              padding: 13px 12px;
              font-family: "Montserrat";
              font-weight: 500;
              min-width: 300px;
              width: 300px;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0px;
              color: $black;
              // flex: 1;

              @include themed {
                color: $text-light !important;
              }

              &:first-child {
                min-width: 300px;
                width: auto !important;
                // width: 50%;
              }

              &:last-child {
                width: 300px !important;
              }

              // &:child(2) {
              //   min-width: 600px;
              //   width: 50%;
              // }
            }
          }
        }

        &.tpa_table {
          .employee_innerpart {
            max-height: 200px;

            .heading,
            .subheading {
              li {
                flex: none;

                &:first-child {
                  width: 70% !important;
                }

                &:last-child {
                  width: 30% !important;
                }
              }
            }
          }
        }
      }

      .Checkbox {
        display: inline-block !important;
        position: relative;

        label {
          font-family: "Montserrat";
          display: inline-block;
          text-align: center;
          color: $black;
          cursor: pointer;
          position: relative;
          @include fontSize(12px);
          line-height: 17px;
          padding: 10px 12px;
          font-weight: 500;
          margin-right: 5px;

          @include themed {
            background: transparent !important;
          }

          &.contentbox {
            top: 6px !important;
          }

          &:after {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            border: 2px solid rgba(0, 0, 0, 0.1);
            @include border-radius(2px);
            left: 2px;
            top: 2px;

            @include themed {
              border-color: $text-light !important;
              background: transparent;
            }
          }
        }

        input:checked ~ label {
          &:after {
            position: absolute;
            content: "";
            height: 12px;
            width: 12px;
            background: $primary url("../public/images/transparent_tick.svg")
              no-repeat center center/10px;
          }
        }

        input {
          position: absolute;
          z-index: 999;
          width: 100%;
          height: 100%;
          opacity: 0;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .module_table {
        overflow-x: auto;
        overflow-y: auto;

        .module_permission_table {
          width: 100%;
          min-width: 1500px;

          .heading {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $blue-light;
            background: #e4f1ff;
            @include border-radius(4px 4px 0px 0px);
            margin-bottom: 5px;
            width: 100%;

            @include themed {
              background: $bg-grey !important;
              border-bottom: 1px solid $bg-grey;
            }

            li {
              list-style-type: none;
              font-family: "Montserrat";
              font-weight: 600;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0;
              // width: 200px;
              height: 40px;
              color: $black;
              padding: 10px 11px;
              flex: 1;
              min-width: 150px;

              &:first-child {
                min-width: 300px;
                width: 50%;
              }

              &:child(2) {
                min-width: 600px;
                width: 50%;
              }

              @include themed {
                background: $bg-grey !important;
                color: $text-grey;
              }

              svg {
                height: 12px;
                width: 12px;
                margin-right: 5px;
                position: relative;
                top: 2px;
              }
            }
          }

          .collapse_plus {
            position: relative;
            padding-right: 0px !important;

            &:before {
              position: absolute;
              content: "";
              background: url("../public/images/module_add_icon.svg") no-repeat
                0px 0px/15px;
              height: 15px;
              width: 15px;
              left: 5px;
              top: 14px;
            }

            &.setting_plus {
              // padding-left: 32px !important;

              &:before {
                left: 12px;
              }
            }

            p {
              position: relative;
              z-index: 99;
              padding-left: 25px !important;
            }
          }

          .collapse_minus {
            position: relative;
            padding-right: 0px !important;

            &:before {
              position: absolute;
              content: "";
              background: url("../public/images/module_subtract_icon.svg")
                no-repeat 0px 0px/15px;
              height: 15px;
              width: 15px;
              left: 5px;
              top: 14px;
            }

            &.setting_minus {
              // padding-left: 32px !important;

              &:before {
                left: 12px;
              }
            }

            p {
              position: relative;
              z-index: 99;
              padding-left: 25px !important;
            }

            @media (max-width: 2200px) {
              padding-right: 25px !important;
            }
          }

          .module_table_lines {
            position: relative;

            &:before {
              content: "";
              position: absolute;
              width: 1px;
              height: 46px;
              border-left: 1px solid $primary-light;
              top: 0px;
              // left: -20px;
              // left: 12px;
              left: -28px;

              @include themed {
                border-color: $bg-grey !important;
              }
            }

            &:after {
              content: "";
              position: absolute;
              width: 10px;
              height: 1px;
              border-bottom: 1px solid $primary-light;
              top: 21px;
              // left: -20px;
              // left: 13px;
              left: -27px;

              @include themed {
                border-color: $bg-grey !important;
              }
            }

            &.settings_lines {
              &:before {
                height: 80vh;
                top: 50px;
                left: -12px;
              }

              &:after {
                top: 50px;
                left: -12px;
              }
            }
          }

          .module_table_line_last {
            position: relative;

            &:before {
              content: "";
              position: absolute;
              width: 1px;
              height: 23px;
              border-left: 1px solid $primary-light;
              top: 0px;
              // left: -20px;
              // left: 12px;
              left: -28px;

              @include themed {
                border-color: $bg-grey !important;
              }
            }

            &:after {
              content: "";
              position: absolute;
              width: 10px;
              height: 1px;
              border-bottom: 1px solid $primary-light;
              top: 21px;
              // left: -20px;
              // left: 13px;
              left: -27px;

              @include themed {
                border-color: $bg-grey !important;
              }
            }
          }

          .module_table_inner {
            overflow: inherit;
            overflow-y: auto;
            max-height: 550px;

            .subheading {
              display: flex;
              justify-content: space-between;
              margin-bottom: 0px;
              border-bottom: 1px solid $blue-light;
              width: calc(100% - -17px);

              @media (max-width: 2200px) {
                width: calc(100% - 0px);
              }

              @include themed {
                border-bottom: 1px solid $bg-grey;
              }

              &:last-child {
                border-bottom: 0;
                position: relative;

                > li {
                  position: relative;

                  &:after {
                    content: "";
                    position: absolute;
                    background: $primary-light;
                    top: 45px;
                    left: 18px;
                    height: 100%;
                    width: 1px;

                    @include themed {
                      background: $bg-grey !important;
                    }
                  }
                }
              }

              &.parent {
                li {
                  &:last-child {
                    cursor: pointer;
                  }
                }
              }

              &.child {
                cursor: pointer;
                margin-left: 25px !important;

                li {
                  padding-right: 0px !important;
                  padding-left: 0 !important;
                  position: relative;

                  &:after {
                    display: none;
                  }

                  .setting_parent {
                    position: relative;
                    z-index: 99;
                    padding-left: 25px;
                  }
                }
              }

              &.childofchild {
                margin-left: 40px;
                border-bottom: 1px solid #e4f1ff;

                &:last-child {
                  border-bottom: none;
                }

                li {
                  &:after {
                    display: none;
                  }

                  &:first-child {
                    min-width: 235px;
                    width: 50%;
                    max-width: 235px;
                  }
                }
              }

              li {
                list-style-type: none;
                min-height: 45px;
                padding: 7px 12px;
                font-family: "Montserrat";
                font-weight: 500;
                // width: 200px;
                @include fontSize(12px);
                line-height: 28px;
                margin-bottom: 0px;
                color: $black;
                flex: 1;
                min-width: 171px;

                @include themed {
                  color: $text-light !important;
                }

                &:first-child {
                  min-width: 300px;
                  width: 50%;
                }

                &:child(2) {
                  min-width: 600px;
                  width: 50%;
                }

                .Checkbox {
                  display: inline-block !important;
                  position: relative;

                  label {
                    font-family: "Montserrat";
                    display: inline-block;
                    text-align: center;
                    color: $black;
                    cursor: pointer;
                    position: relative;
                    @include fontSize(12px);
                    line-height: 17px;
                    padding: 7px 12px;
                    font-weight: 500;
                    margin-right: 5px;
                    min-height: 12px;
                    min-width: 12px;
                  }
                }
              }
            }
          }
        }
      }

      // employee detail profile tab css start

      .profile_detail {
        @include border-radius(12px);
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include themed {
          background: $bg-grey;
        }

        .profileTypeSection {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 0px;
          max-width: 125px;

          .radioBox {
            display: inline-block;
            position: relative;
            margin: 0 46px 30px 0;

            label {
              font-family: "Montserrat";
              width: 100%;
              display: inline-block;
              text-align: center;
              color: $black;
              cursor: pointer;
              position: relative;
              @include fontSize(11px);
              line-height: 14px;
              padding: 12px;
              @include border-radius(12px);
              border: 1px solid rgba(0, 0, 0, 0.1);
              font-weight: 500;

              @include themed {
                background: $bg-grey;
                border: 1px solid $black;
                color: $white;
              }
            }

            h6 {
              font-family: "Montserrat";
              font-weight: 500;
              @include fontSize(14px);
              line-height: 17px;
              text-align: center;
              padding-top: 10px;
              word-break: break-all !important;
              word-wrap: break-word !important;

              @include themed {
                color: $text-light;
              }
            }

            p {
              font-weight: 400;
              @include fontSize(12px);
              line-height: 15px;
              padding-top: 2px;
              opacity: 0.5;
              text-align: center;

              @include themed {
                color: $text-light;
              }

              span {
                font-weight: 400;
                @include fontSize(12px);
                line-height: 15px;
                display: block;
                margin-top: 2px;
              }
            }

            input:checked ~ label {
              background-color: $white !important;
              @include box-shadow(0px 4px 12px rgba($black, 0.1));
              color: $white;
              border-color: $primary;

              p {
                opacity: 0.5;
              }

              @include themed {
                background-color: $black !important;
              }
            }

            input {
              position: absolute;
              z-index: 999;
              width: 100%;
              height: 100%;
              opacity: 0;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .radioBox_two {
            display: inline-block;
            position: relative;
            margin: 0 46px 30px 0;

            label {
              font-family: "Montserrat";
              width: 100%;
              display: inline-block;
              text-align: center;
              color: $black;
              cursor: pointer;
              position: relative;
              @include fontSize(11px);
              line-height: 14px;
              padding: 12px;
              @include border-radius(12px);
              border: 1px solid rgba(0, 0, 0, 0.1);
              font-weight: 500;
              max-width: 125px;

              @include themed {
                background: $bg-grey;
                border: 1px solid $black;
                color: $white;
              }
            }

            h6 {
              font-family: "Montserrat";
              font-weight: 500;
              @include fontSize(14px);
              line-height: 17px;
              text-align: center;
              padding-top: 10px;
              word-break: break-all !important;
              word-wrap: break-word !important;

              @include themed {
                color: $text-light;
              }
            }

            p {
              font-weight: 400;
              @include fontSize(12px);
              line-height: 15px;
              padding-top: 2px;
              opacity: 0.5;
              text-align: center;

              @include themed {
                color: $text-light;
              }

              span {
                font-weight: 400;
                @include fontSize(12px);
                line-height: 15px;
                display: block;
                margin-top: 2px;
              }
            }

            &.check {
              label {
                background-color: $white !important;
                @include box-shadow(0px 4px 12px rgba($black, 0.1));
                color: $white;
                border-color: $primary;

                p {
                  opacity: 0.5;
                }

                @include themed {
                  background-color: $black !important;
                }
              }
            }

            input {
              position: absolute;
              z-index: 999;
              width: 100%;
              height: 100%;
              opacity: 0;
              max-width: 125px;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        .vehicle_details {
          display: flex;

          li {
            list-style-type: none;
            width: 250px;

            p {
              font-family: "Montserrat";
              @include fontSize(10px);
              font-weight: 400;
              line-height: 12px;
              color: $primary;

              span {
                display: block;
                margin-top: 8px;
                color: $black;
                @include fontSize(12px);
                line-height: 15px;
                font-weight: 700;

                @include themed {
                  color: $text-light;
                }
              }
            }

            &:first-child {
              width: 180px;
            }

            &:nth-child(2) {
              width: 600px;
            }

            &:nth-child(4) {
              width: 300px;
            }

            &:nth-child(7) {
              width: 450px;
            }
          }
        }

        .property_details {
          display: flex;
          flex-wrap: wrap;

          li {
            list-style-type: none;
            min-width: 200px;
            flex: 1;
            padding-right: 12px;
            margin-bottom: 16px;

            p {
              font-family: "Montserrat";
              @include fontSize(10px);
              font-weight: 400;
              line-height: 12px;
              color: $primary;

              span {
                display: block;
                margin-top: 8px;
                color: $black;
                @include fontSize(12px);
                line-height: 15px;
                font-weight: 700;

                @include themed {
                  color: $text-light;
                }
              }
            }

            // &:nth-child(3) {
            //   width: 600px;
            // }

            // &:nth-child(4) {
            //   width: 600px;
            // }

            // &:nth-child(7) {
            //   width: 450px;
            // }
          }
        }
      }

      // custom multi search dropdown css start
      .custom_dropdown {
        border: 1px solid $text-light;
        min-height: 38.6px;
        @include border-radius(8px);
        padding: 0 39px 0 0px;
        cursor: pointer;
        position: relative;

        &:before {
          content: "";
          background: url("../public/images/down_arrow.svg") no-repeat;
          @include position(absolute, $right: 12px, $top: 15px);
          height: 10px;
          width: 12px;
        }

        &:hover {
          border-color: $black;
        }

        &:active {
          border-color: $primary;
        }

        .customDropdownList {
          @include position(absolute, $left: 0px, $top: 0px);
          padding-bottom: 16px;
          max-width: 400px;
          width: 100%;
          z-index: 9991;

          .innerContainer {
            background: $white;
            @include box-shadow(0px 4px 12px rgba($black, 0.1));
            @include border-radius(8px);
            padding: 8px;

            .cross {
              display: block;
              margin-left: auto;
              height: 24px;
              width: 24px;
              position: relative;

              &:hover {
                &:before,
                &:after {
                  transform: rotate(0deg);
                }
              }

              &:before,
              &:after {
                @include position(absolute, $left: 4px, $top: 10px);
                transform: rotate(-45deg);
                content: "";
                height: 1.5px;
                width: 16px;
                background-color: #000;
                transition: all 0.2s ease;
              }

              &:after {
                transform: rotate(45deg);
              }
            }

            ul {
              max-height: 200px;
              overflow-y: auto;

              li {
                border-bottom: 1px solid rgba($black, 0.1);
                display: flex;
                align-items: center;
                @include fontSize(14px);
              }
            }
          }
        }

        .custom_input {
          display: flex;
          flex-wrap: wrap;
          min-height: 38px;
          height: 100%;

          label {
            color: #666666;
            @include fontSize(13px);
            @include position(absolute, $left: 13px, $top: 10px);
            transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
            pointer-events: none;
            margin: 0px;
            font-family: "Montserrat";
            font-weight: 500;
          }

          %label-movement {
            // Move up, colorize and shrink
            @include fontSize(11px);
            font-weight: 400;
            color: $primary;
            top: -7px;
            left: 12px;
            transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
            background: $white;
          }

          input {
            display: none;

            &:focus {
              // Trigger the effects
              outline: 0;

              ~ label {
                // Move Label
                @extend %label-movement;
              }
            }

            &:valid ~ label {
              @extend %label-movement;
            }
          }

          .custom_field {
            span {
              @include fontSize(12px);
              background: #e1e1e1;
              display: inline-block;
              border-radius: 40px;
              padding: 4px 4px 4px 10px;
              color: $black;
              margin: 8px 4px;

              @include themed {
                color: $text-light;
                background: $bg-grey;
              }

              .MuiIconButton-root {
                padding: 0;

                svg {
                  padding: 0;
                  height: 10px;
                  color: $black;
                  z-index: 999;

                  @include themed {
                    color: $text-light;
                  }
                }
              }

              button {
                position: relative;
                top: -1px;
                height: 12px;

                span {
                  margin: 0px;
                }
              }
            }
          }
        }

        .Checkboxx {
          display: inline-block !important;
          position: relative;
          margin-right: 10px;

          label {
            font-family: "Montserrat";
            display: inline-block;
            text-align: center;
            color: $black;
            cursor: pointer;
            position: relative;
            @include fontSize(12px);
            line-height: 17px;
            padding: 10px 6px;
            font-weight: 500;
            margin-right: 5px;

            @include themed {
              background: transparent !important;
            }

            &:after {
              position: absolute;
              content: "";
              height: 12px;
              width: 12px;
              border: 2px solid rgba(0, 0, 0, 0.1);
              @include border-radius(2px);
              left: 3px !important;
              top: 3px;

              @include themed {
                border-color: $text-light !important;
                background: transparent;
              }
            }
          }

          input:checked ~ label {
            &:after {
              position: absolute;
              content: "";
              height: 12px;
              width: 12px;
              background: $primary url("../public/images/transparent_tick.svg")
                no-repeat center center/10px;
            }
          }

          input {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      // custom multi search dropdown css end
    }

    .networkhosp {
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(14px);
      line-height: 17px;
      color: $primary;
      text-decoration: none;
      text-align: right;
    }

    .networkhosplist {
      font-family: "Montserrat";
      font-weight: 500;
      @include fontSize(12px);
      line-height: 20px;
      color: $primary;
      padding-left: 18px;
      text-decoration: none;
      text-align: right;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 16px;
        width: 14px;
        background: url("../public/images/hospital_icon.svg") no-repeat;
      }
    }

    .policy_lives {
      .coverageAmt {
        display: flex;
        margin: 0px;

        li {
          list-style: none;
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }

          @media (max-width: 1100px) {
            margin-right: 16px;
            margin-bottom: 16px;
          }

          @media (max-width: 1310px) {
            margin-bottom: 16px;
          }

          .radioBox {
            display: inline-block;
            position: relative;

            label {
              font-family: "Montserrat";
              width: 100%;
              display: inline-block;
              text-align: center;
              color: $black;
              cursor: pointer;
              position: relative;
              @include fontSize(14px);
              line-height: 24px;
              padding: 8px 16px;
              @include border-radius(8px);
              border: 1px solid rgba(0, 0, 0, 0.1);
              font-weight: 500;

              @include themed {
                background: $bg-grey;
                border: 1px solid $bg-grey;
                color: $white;
              }

              p {
                font-weight: 500;
                @include fontSize(11px);
                line-height: 13px;
                padding-top: 12px;
                opacity: 0.5;
              }
            }

            input:checked ~ label {
              background-color: $primary;
              @include box-shadow(0px 4px 12px rgba($black, 0.1));
              color: $white;

              p {
                opacity: 0.5;
              }
            }

            input {
              position: absolute;
              z-index: 999;
              width: 100%;
              height: 100%;
              opacity: 0;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      .quotedetail_field {
        width: 100%;
        animation: fadeIn 1s both;
      }
    }

    // age table
    .table {
      overflow-x: auto;

      // @keyframes fadeIn {
      //     0% {
      //         opacity: 0;
      //         transform: translate3d(0, -3%, 0)
      //     }

      //     to {
      //         opacity: 1;
      //         transform: translateZ(0)
      //     }
      // }

      .age-table {
        width: 100%;
        min-width: 1500px;
        animation: fadeIn 1s both;

        .heading {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $blue-light;
          margin-bottom: 8px;
          @include border-radius(4px 4px 0px 0px);
          background: #e4f1ff;

          @include themed {
            border-bottom: 1px solid $bg-grey;
          }

          li {
            list-style-type: none;
            font-family: "Montserrat";
            font-weight: 600;
            @include fontSize(12px);
            line-height: 20px;
            margin-bottom: 0;
            height: 40px;
            color: $black;
            padding: 10px 12px;
            flex: 1;

            @include themed {
              background: $bg-grey;
              color: $text-grey;
            }

            @media (max-width: 1199px) {
              min-width: 100px;
            }
          }
        }

        .subheading {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0px;
          border-bottom: 1px solid $blue-light;

          @include themed {
            border-bottom: 1px solid $bg-grey;
          }

          &:last-child {
            border-bottom: 0;
          }

          li {
            list-style-type: none;
            min-height: 40px;
            padding: 13px 12px;
            font-family: "Montserrat";
            font-weight: 400;
            width: 250px;
            @include fontSize(12px);
            line-height: 24px;
            margin-bottom: 0px;
            color: $black;
            flex: 1;
            align-self: center;

            &:first-child {
              padding-left: 0px;
            }

            &:last-child {
              padding-right: 0px;
            }

            @include themed {
              color: $text-light !important;
            }

            @media (max-width: 1200px) {
              min-width: 100px;
            }

            @media (max-width: 991px) {
              &:first-child {
                min-width: 180px;
              }
            }
          }
        }
      }
    }

    // rate table
    .table {
      overflow-x: auto;

      .rate-table {
        @extend .age-table;
      }
    }

    // policy lives information table

    .table {
      // @keyframes fadeIn {
      //     0% {
      //         opacity: 0;
      //         transform: translate3d(0, -3%, 0)
      //     }

      //     to {
      //         opacity: 1;
      //         transform: translateZ(0)
      //     }
      // }

      .livesinformation-table {
        width: 100%;
        animation: fadeIn 1s both;
        min-width: 1800px;

        .heading {
          display: flex;
          // flex-wrap: wrap;
          border-bottom: 1px solid $blue-light;
          margin-bottom: 8px;
          background: #e4f1ff;
          @include border-radius(4px 4px 0 0);

          @include themed {
            border-bottom: 1px solid $bg-grey;
            background: $bg-grey;
          }

          li {
            list-style-type: none;
            font-family: "Montserrat";
            font-weight: 600;
            @include fontSize(12px);
            line-height: 20px;
            margin-bottom: 0;
            height: 60px;
            color: $black;
            padding: 10px 12px;
            display: flex;
            width: 220px;
            max-width: 250px;
            height: auto;

            @include themed {
              background: $bg-grey;
              color: $text-light;
            }
          }
        }

        .subheading {
          display: flex;
          margin-bottom: 0px;
          border-bottom: 1px solid $blue-light;

          @include themed {
            border-bottom: 1px solid $bg-grey;
          }

          &:last-child {
            border-bottom: 0;
          }

          li {
            list-style-type: none;
            min-height: 40px;
            padding: 10px 12px;
            font-family: "Montserrat";
            font-weight: 400;
            width: 220px;
            max-width: 250px;
            @include fontSize(12px);
            line-height: 24px;
            margin-bottom: 0px;
            color: $black;
            align-self: center;

            @include themed {
              color: $text-light !important;
            }
          }
        }
      }
    }

    // configuration voluntry plans section css

    .voluntry_plans_outer {
      .coverageAmt {
        display: flex;
        flex-wrap: wrap;
        margin: 0px;
        justify-content: center;

        li {
          list-style: none;
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }

          @media (max-width: 1100px) {
            margin-right: 16px;
            margin-bottom: 16px;
          }

          @media (max-width: 1310px) {
            margin-bottom: 16px;
          }

          .radioBox {
            display: inline-block;
            position: relative;

            label {
              font-family: "Montserrat";
              width: 100%;
              display: inline-block;
              text-align: center;
              color: $black;
              cursor: pointer;
              position: relative;
              @include fontSize(14px);
              line-height: 24px;
              padding: 8px 16px;
              @include border-radius(8px);
              border: 1px solid rgba(0, 0, 0, 0.1);
              font-weight: 500;

              @include themed {
                background: $bg-grey;
                border: 1px solid $bg-grey;
                color: $white;
              }

              p {
                font-weight: 500;
                @include fontSize(11px);
                line-height: 13px;
                padding-top: 12px;
                opacity: 0.5;
              }
            }

            input:checked ~ label {
              background-color: $primary;
              @include box-shadow(0px 4px 12px rgba($black, 0.1));
              color: $white;

              p {
                opacity: 0.5;
              }
            }

            input {
              position: absolute;
              z-index: 999;
              width: 100%;
              height: 100%;
              opacity: 0;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .rider_details_sec {
      .inputField {
        // width: 200px;
        margin-right: 15px;

        .MuiInputBase-root {
          @include themed {
            background: $bg-grey;
            border-radius: 8px;
          }
        }

        .MuiInputAdornment-root {
          p {
            @include themed {
              color: $white;
            }
          }
        }
      }
    }
  }

  .footerSection {
    background: $white;
    @include box-shadow(0px -4px 12px rgba($black, 0.1));
    padding: 4px;
    @include position(fixed, $bottom: 0px, $left: 0px);
    margin: 0px;
    width: 100%;
    z-index: 991;
    text-align: right;

    @include themed {
      background: $bg-grey;
    }

    button {
      @include fontSize(14px);
      margin-left: 12px;
      padding: 8px 30px;
    }
  }
}
