@import "../../../../mixins.scss";

.modalSideOpen {
  overflow-y: auto;
  overflow-x: hidden;
  backdrop-filter: blur(4px);
  background: rgba(#000, 0.2);

  .MuiBackdrop-root {
    background: transparent;
  }

  .modalInner {
    background: $white;
    @include box-shadow(0px 4px 16px rgba(#000, 0.2));
    padding: 24px;
    position: absolute;
    right: 0px;
    top: 0px;
    min-height: 100%;
    width: 582px;

    animation: out 1s both;
    transform: translateX(700px);

    &.show {
      animation: in 1s 1s both;
    }

    @keyframes in {
      0% {
        transform: translateX(700px);
      }

      100% {
        transform: translateX(0px);
      }
    }

    @keyframes out {
      0% {
        transform: translateX(0px);
      }

      100% {
        transform: translateX(700px);
      }
    }

    hr {
      border: none;
      border-top: 1px solid $primary-light;

      @include themed {
        border-color: #000;
      }
    }
  }
}

.productTabs {
  @include themed {
    border: 1px solid $bg-grey;
  }
}

.labelTitle {
  position: relative;
  padding-bottom: 12px;

  &:before {
    @include position(absolute, $left: 0px, $bottom: 0px);
    content: "";
    height: 14px;
    width: 100%;
  }

  @include themed {
    color: $text-light;
  }
}

.table {
  overflow-x: auto;

  .fieldBox {
    width: 100%;
    min-width: calc(1500px - 10%);

    .dataHeading {
      background: $blue-light;
      display: flex;
      flex-wrap: wrap;
      @include border-radius(12px 12px 0px 0px);
      padding: 16px 12px;
      margin-bottom: 0px;

      @include themed {
        background: $bg-grey;
        color: $text-light;
        border: 1px solid rgba($text-light, 0.5) !important;
      }

      li {
        list-style-type: none;
        @include fontSize(14px);
        font-weight: 500;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        display: flex;
        justify-content: space-between;

        &:first-child {
          max-width: 100px;
        }

        &:last-child {
          max-width: 150px;
          justify-content: end;
        }

        &:nth-child(2) {
          max-width: 220px;
        }

        &:nth-child(5) {
          max-width: 500px;
        }

        .sorting-btn {
          background: transparent;
          margin-left: auto;
          margin-right: 15px;

          img {
            height: 11px;
          }

          &.last {
            margin-left: 10px;
            margin-right: 0;
          }
        }
      }
    }

    .content {
      @extend .dataHeading;
      background: transparent;
      @include border-radius(0px);
      padding: 12px;
      margin-bottom: 0px;
      border: 1px solid rgba($text-light, 0.5) !important;

      @include themed {
        background: $black;
        color: $text-light;
      }

      li {
        @include fontSize(14px);
        font-weight: 400;
        align-self: center;

        .insurername {
          color: $black;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }

          @include themed {
            color: $text-light;
          }
        }
      }

      .insurer_img {
        height: 40px;
        background: $white;
      }

      .insurer_site {
        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
}

.table {
  min-width: 100%;
  overflow-x: auto;

  &.bulk_scroll {
    height: calc(100vh - 240px);
  }

  .fieldsBox {
    width: 100%;
    min-width: calc(1800px - 10%);

    .voluntry_table {
      width: 100%;

      .tableHeading {
        background: $blue-light;
        display: flex;
        flex-wrap: wrap;
        @include border-radius(12px 12px 0px 0px);
        padding: 16px 12px;
        margin-bottom: 0px;

        @include themed {
          background: $bg-grey;
          color: $text-light;
          border: 1px solid rgba($bg-grey, 1) !important;
          @include border-radius(12px 12px 0px 0px);
        }

        li {
          .Checkbox {
            display: inline-block !important;
            position: relative;

            label {
              font-family: "Montserrat";
              display: inline-block;
              text-align: center;
              color: $black;
              cursor: pointer;
              position: relative;
              @include fontSize(12px);
              line-height: 17px;
              padding: 10px 12px;
              font-weight: 500;
              margin-right: 5px;

              @include themed {
                background: transparent !important;
              }

              &:after {
                position: absolute;
                content: "";
                height: 12px;
                width: 12px;
                border: 2px solid rgba(0, 0, 0, 0.1);
                border-radius: 2px;
                left: 2px;
                top: 3px;

                @include themed {
                  border-color: #999 !important;
                  background: transparent;
                }
              }
            }

            input:checked ~ label {
              &:after {
                position: absolute;
                content: "";
                height: 12px;
                width: 12px;
                background: $primary
                  url("../../../../../public/images/transparent_tick.svg")
                  no-repeat center center/10px;
                border-color: $primary !important;
              }
            }

            input {
              // display: none;
              position: absolute;
              z-index: 999;
              width: 100%;
              height: 100%;
              opacity: 0;

              &:hover {
                cursor: pointer;
              }
            }
          }

          list-style-type: none;
          @include fontSize(14px);
          font-weight: 500;
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:hover {
            .sorting-btn {
              opacity: 1;
            }
          }

          &:first-child {
            max-width: 300px;
          }

          &:last-child {
            max-width: 150px;
            justify-content: end;
          }

          .sorting-btn {
            background: transparent;
            margin-left: auto;
            margin-right: 15px;
            position: relative;
            opacity: 0;
            // width: 50%;
            background: #fff;
            width: 21px;
            height: 21px;
            border-radius: 50%;

            @include themed {
              background-color: $text-grey;
            }

            &:before {
              position: absolute;
              content: "";
              background: url("./../../../../../public/images/sorting_btn.svg")
                no-repeat 0 0 /100%;
              top: 5px;
              right: 7px;
              width: 7px;
              height: 12px;

              @include themed {
                background: url("./../../../../../public/images/sorting_btn_white.svg")
                  no-repeat 0 0 /100%;
              }
            }

            img {
              height: 11px;
            }

            &.last {
              margin-left: 10px;
              margin-right: 0;
            }
          }
        }
      }

      .tablecontent {
        .Checkbox {
          display: inline-block !important;
          position: relative;

          label {
            font-family: "Montserrat";
            display: inline-block;
            text-align: center;
            color: $black;
            cursor: pointer;
            position: relative;
            @include fontSize(12px);
            line-height: 17px;
            padding: 10px 12px;
            font-weight: 500;
            margin-right: 5px;

            @include themed {
              background: transparent !important;
            }

            &:after {
              position: absolute;
              content: "";
              height: 15px;
              width: 15px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 2px;
              left: 2px;
              top: 7px;

              @include themed {
                border-color: $text-light !important;
                background: transparent;
              }
            }
          }

          input:checked ~ label {
            &:after {
              position: absolute;
              content: "";
              height: 15px;
              width: 15px;
              background: $primary
                url("../../../../../public/images/transparent_tick.svg")
                no-repeat center center/100%;
            }
          }

          input {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:hover {
              cursor: pointer;
            }
          }
        }

        @extend .tableHeading;
        background: transparent;
        @include border-radius(0px !important);
        padding: 12px;
        margin-bottom: 0px;
        border: 1px solid rgba($text-light, 0.5) !important;
        border-top: none !important;

        @include themed {
          background: $bg-dark;
          color: $text-light;
          border-top: none !important;
          border-bottom: 1px solid rgba($bg-grey, 1) !important;
        }

        li {
          @include fontSize(13px);
          font-weight: 400;
          align-self: center;

          .productname {
            color: $black;
            text-decoration: none;

            &:hover {
              text-decoration: none;
            }

            @include themed {
              color: $text-light;
            }
          }

          &:first-child {
            max-width: 300px;
            display: flex;
            justify-content: flex-start;
          }

          &:last-child {
            max-width: 150px;
            justify-content: end;
          }
        }
      }
    }
  }
}

.popup-subheading {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
  color: $black;
  position: relative;

  @include themed {
    color: $text-light;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: $primary;
    height: 1px;
    width: 200px;
  }
}

.sliding-panel-container {
  position: fixed;
  top: 0;
  left: 0;

  &.active {
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);

    body {
      overflow: hidden;
    }

    .panel {
      animation: inpanel 0.6s both;
    }

    @keyframes inpanel {
      0% {
        transform: translateX(1000px);
      }

      100% {
        transform: translateX(0px);
      }
    }
  }

  .glass {
    min-width: calc(100vw - 700px);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }

  .panel {
    height: 100%;
    width: 100%;
    max-width: 700px;
    background-color: white;
    animation: outpanel 0.3s both;

    @include themed() {
      background-color: #171b1e;
    }
  }

  @keyframes outpanel {
    0% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(1000px);
    }
  }

  .panel-content {
    padding: 0px 20px;
    position: relative !important;

    svg[data-testid="CloseIcon"] {
      cursor: pointer;
      transition: all 0.3s ease;

      @include themed {
        color: $white;
      }

      &:hover {
        transform: rotate(-90deg);
        color: $primary-red;
      }
    }

    hr {
      border: none;
      border-top: 1px solid $primary-light;

      @include themed {
        border-color: $bg-grey;
      }
    }

    p {
      font-family: "Montserrat";

      @include themed {
        color: $text-grey;
      }
    }

    .scrollable_area {
      padding-top: 5px;
      height: calc(100vh - 240px);
      overflow-y: auto;
      overflow-x: hidden;

      &.smScroll {
        height: calc(100vh - 500px);
      }
      &.mdScroll {
        height: calc(100vh - 200px);
      }

      &.lgScroll {
        height: calc(100vh - 230px);
      }

      .uploadFile{
        .inputField{
          textarea{
            height: 23px !important;
          }
        }
      }

      .browsebtn {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(14px);
        line-height: 24px;
        background: $primary;
        padding: 7.5px 15px;
        border-radius: 0 10px 10px 0;
        text-transform: capitalize;
        color: #fff;
        text-align: center;
        display: inline-block;
        box-shadow: none;
        position: relative;
        left: -4px;

        &:hover {
          background: $primary;
          color: $white;
        }
      }

      .fileUploadTypeDetails {
        font-family: "Montserrat";
        font-weight: 400;
        @include fontSize(10px);
        line-height: 10px;
        margin-top: 4px;

        @include themed {
          color: $text-light;
        }
      }

      .description {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(14px);
        line-height: 10px;
        margin-bottom: 10px;

        @include themed {
          color: $text-light;
        }
      }

      .cdbalance_text {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(12px);
        line-height: 24px;
        color: $black;
        opacity: 0.5;
        margin-bottom: 0;

        @include themed {
          color: $text-light;
        }
      }

      .note_text {
        @include fontSize(12px);
        line-height: 20px;
        font-weight: 500;
        font-family: "Montserrat";
        color: lighten($black, 60%);
      }
    }
  }

  .fileName_text {
    @include fontSize(12px);
    line-height: 20px;
    font-weight: 500;
    font-family: "Montserrat";
    color: $black;
  }

  .associate_tax {
    font-family: "Montserrat";
    font-weight: 600;
    @include fontSize(12px);
    line-height: 15px;
    color: $black;
    margin-bottom: 12px;

    @include themed {
      color: $text-light;
    }
  }

  .tax_list_table {
    .tax_list_heading {
      background: #eef6fe;
      border-radius: 16px 16px 0px 0px;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;

      @include themed {
        background: $black;
      }

      li {
        list-style-type: none;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $black;

        @include themed {
          color: $text-light;
        }
      }
    }

    .tax_list_subheading {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;

      li {
        list-style-type: none;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: $black;
        align-self: center;

        @include themed {
          color: $text-light;
        }

        .Checkbox {
          display: inline-block;
          position: relative;

          label {
            font-family: "Montserrat";
            display: inline-block;
            text-align: center;
            color: $black;
            cursor: pointer;
            position: relative;
            @include fontSize(12px);
            line-height: 17px;
            padding: 10px 17px 10px 12px;
            font-weight: 500;

            &:after {
              position: absolute;
              content: "";
              height: 15px;
              width: 15px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 2px;
              left: 4px;
              top: 6px;

              @include themed {
                border-color: $text-light;
              }
            }
          }

          input:checked ~ label {
            &:after {
              position: absolute;
              content: "";
              height: 15px;
              width: 15px;
              background: $primary
                url("../../../../../public/images/transparent_tick.svg")
                no-repeat center center/100%;
            }
          }

          input {
            // display: none;
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 100%;
            opacity: 0;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .group_note {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.6);

    @include themed {
      color: $text-light;
    }

    span {
      font-weight: 400;
    }
  }

  .health_ecard {
    font-family: "Montserrat";
    font-weight: 500;
    @include fontSize(11px);
    line-height: 13px;
    color: $primary-color;
    margin-bottom: 0;

    span {
      @include fontSize(13px);
      line-height: 20px;
      font-weight: 500;
      color: $black;
      display: block;

      @include themed {
        color: $text-light;
      }
    }
  }

  .addPolicy_info_section {
    display: flex;

    .addPolicy_info_p {
      font-family: "Montserrat";
      border: 1px solid #c5d8ed;
      font-weight: 500;
      @include fontSize(12px);
      line-height: 15px;
      position: relative;
      padding: 15px 12px 15px 42px;
      @include border-radius(48px);
      display: inline-block;
      width: 220px;

      @include themed {
        color: $white;
        border: 1px solid $bg-grey;
      }

      span {
        font-weight: 600;
      }

      &:before {
        @include position(absolute, $left: 15px, $top: calc(50% - 10px));
        content: "";
        height: 20px;
        width: 20px;
        background: url("../../../../../public/images/employeePolicy_icon.svg")
          no-repeat;
      }
    }
  }

  .policy_subheading {
    @include fontSize(14px);
    font-weight: 600;
    line-height: 24px;
    margin: 0px;
    position: relative;
    font-family: "Montserrat";

    @include themed {
      color: $text-grey;
    }

    &:before {
      content: "";
      @include position(absolute, $top: 32px, $left: 0px);
      width: 200px;
      height: 1px;
      background: $primary;
    }
  }

  .empl_detail_policy_subheading {
    @include fontSize(12px);
    font-weight: 300;
    line-height: 15px;
    margin: 0px;
    margin-bottom: 8px;
    position: relative;
    font-family: "Montserrat";

    @include themed {
      color: $text-grey;
    }

    &:before {
      content: "";
      @include position(absolute, $top: 18px, $left: 0px);
      width: 150px;
      height: 1px;
      background: $primary;
    }
  }

  .employee_detail_policy_subheading {
    @extend .empl_detail_policy_subheading;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    &:before {
      bottom: -5px;
      top: auto;
      width: 200px;
    }
  }

  .ppolicy_table {
    overflow-x: auto;

    .policy_table_inner {
      width: 100%;
      min-width: 1500px;
      max-height: 300px;
      overflow-y: scroll !important;

      .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $blue-light;
        background: #e4f1ff;
        @include border-radius(4px 4px 0px 0px);
        margin-bottom: 8px;
        width: 100%;

        @include themed {
          background: $bg-grey !important;
          border-bottom: 1px solid $bg-grey;
        }

        li {
          list-style-type: none;
          font-family: "Montserrat";
          font-weight: 600;
          @include fontSize(12px);
          line-height: 24px;
          margin-bottom: 0;
          min-height: 40px;
          color: $black;
          padding: 10px 12px;
          flex: 1;
          min-width: 150px;

          &:first-child {
            min-width: 120px;
            max-width: 120px;
          }

          &:last-child {
            min-width: 350px !important;
          }

          @include themed {
            background: $bg-grey !important;
            color: $text-grey;
          }

          // &:child(2) {
          //   min-width: 200px;
          //   width: 50%;
          // }

          @include themed {
            background: $bg-grey !important;
            color: $text-grey;
          }
        }
      }

      .subheading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        border-bottom: 1px solid $blue-light;
        width: 100%;

        @include themed {
          border-bottom: 1px solid $bg-grey;
        }

        &:last-child {
          border-bottom: 0;
        }

        li {
          list-style-type: none;
          // min-height: 50px;
          padding: 8px 12px;
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(12px);
          line-height: 24px;
          margin-bottom: 0px;
          color: $black;
          flex: 1;
          min-width: 150px;

          @include themed {
            color: $text-light !important;
          }

          &:first-child {
            max-width: 120px;
            min-width: 120px;
          }

          &:last-child {
            min-width: 350px !important;
          }

          .date {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(12px);
            line-height: 24px;
            max-width: 200px;
            color: rgba(0, 0, 0, 0.6) !important;

            @include themed {
              color: $text-light !important;
            }
          }

          .time {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(12px);
            line-height: 24px;
            max-width: 200px;
            color: rgba(0, 0, 0, 0.6) !important;

            @include themed {
              color: $text-light !important;
            }
          }
        }
      }
    }
  }

  .radio_button {
    display: flex;
    border: 1px solid $primary-light;
    @include border-radius(12px);
    max-width: 400px;

    &.no-border {
      border: none !important;
      min-width: 610px;

      @include themed {
        border: none !important;
      }
    }

    @include themed {
      border: 1px solid $bg-grey;
    }

    .MuiFormGroup-row {
      .MuiFormControlLabel-root {
        .MuiRadio-root {
          color: $grey-light;

          &:hover {
            background-color: transparent;
            border: none;
          }
        }

        .MuiRadio-root.Mui-checked {
          color: $primary;
        }

        .MuiFormControlLabel-label {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          @include fontSize(14px);
          line-height: 24px;

          @include themed {
            color: $text-light;
          }
        }
      }
    }
  }

  .status_data {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      max-width: 100%;
      width: 120px;
      position: relative;

      &:before {
        content: "";
        background-color: #e0e0e0;
        position: absolute;
        top: 50%;
        left: 0;
        height: 4px;
        width: 100%;
        transform: translateY(-50%);
        z-index: -1;
      }

      &:last-child {
        &:before {
          display: none;
        }
      }

      .circle {
        background-color: white;
        color: #999;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid #e0e0e0;
        transition: 0.4s ease;

        span {
          position: relative;
          top: 30px;
          @include fontSize(14px);
          line-height: 18px;
          font-weight: 400;

          &.active {
            color: #219653;
          }

          &.inprogress {
            color: #4293e4;
          }

          &.pending {
            color: #ff9b44;
          }

          &.rejected {
            color: $primary-red;
          }
        }
      }
    }
  }
}
.sliding-90 {
  .glass {
    min-width: unset !important;
  }
  .sliding-panel-container .panel {
    max-width: unset !important;
  }
}
.flter_none {
  .glass {
    backdrop-filter: unset;
  }
}
.MuiMenu-paper {
  .MuiMenu-list {
    max-height: 200px;
    overflow-y: auto;

    // scrollbar-width: thin;

    .MuiMenuItem-root {
      @include fontSize(13px);
      line-height: 18px;
      justify-content: stretch !important;
      padding: 8px 12px;
      width: 100%;
      white-space: wrap;
      // max-width: 300px;
    }
  }
}
