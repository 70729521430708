@import "./../../../../mixins.scss";

.task_headerSection {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    width: 100%;
    margin: 0 !important;
    position: fixed;
    z-index: 99;
    background: $white;

    @include themed {
        background: $bg-dark;
        box-shadow: 0 8px 6px -6px black;
    }

    .pageTitle {
        display: flex;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            margin-right: 12px;
            color: $primary;

            svg {
                position: relative;
                transition: all 0.5s ease;
                left: 0px;
            }

            &:hover {
                cursor: pointer;
                color: $primary-red;

                svg {
                    left: -4px;
                }
            }
        }

        .MuiSelect-select {
            min-width: 220px;
        }
    }
}

.task_management_section {
    height: 100vh;

    @include themed {
        background: $bg-dark;
    }

    .left_section {
        padding-top: 180px;
        padding-left: 20px;
        // padding-right: 20px;

        .folder_heading {
            margin-bottom: 12px;

            ul {
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;

                li {
                    list-style-type: none;
                    font-family: 'Montserrat';
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;

                    @include themed {
                        color: $text-light;
                    }

                    .edit {
                        font-family: 'Montserrat';
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        color: #000;
                        text-decoration: none !important;
                        cursor: pointer;
                        position: relative;

                        @include themed {
                            color: $text-light;
                        }

                        &:before {
                            content: "";
                            background: url("../../../../../public/images/edit_btn.svg") no-repeat;
                            position: absolute;
                            right: 28px;
                            top: -2px;
                            height: 18px;
                            width: 18px;
                        }
                    }
                }
            }

            hr {
                border: 1px solid rgba(107, 98, 220, 0.2);
            }
        }

        .folder_section {
            ul {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;
                border-radius: 4px;
                padding: 8px;

                &.black {
                    background-color: $black;
                }

                &.white {
                    background-color: $white;
                }

                .folder_name {
                    list-style-type: none;
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    position: relative;
                    padding-left: 19px;

                    &.black {
                        background-color: $black;
                        color: $white;

                        &:before {
                            content: "";
                            background: url("../../../../../public/images/folder_icon.svg") no-repeat;
                            position: absolute;
                            height: 16px;
                            width: 15px;
                            left: 0;
                            top: 1px;
                        }
                    }

                    &.white {
                        background-color: $white;
                        color: $black;

                        &:before {
                            content: "";
                            background: url("../../../../../public/images/folder_icon_black.svg") no-repeat;
                            position: absolute;
                            height: 16px;
                            width: 15px;
                            left: 0;
                            top: 1px;
                        }
                    }


                }

                li {
                    list-style-type: none;

                    .dotted_lines {
                        cursor: pointer;
                        // color: #fff;
                        text-decoration: none !important;
                        position: relative;
                        bottom: 5px;
                        list-style-type: none;

                        &.black {
                            background-color: $black;
                            color: $white;
                        }

                        &.white {
                            background-color: $white;
                            color: $black;
                        }
                    }
                }

            }

            .addnew {
                text-align: center;
            }

            .MuiMenu-paper {
                border-radius: 8px;
                box-shadow: none !important;

                @include themed() {
                    background-color: $text-grey;
                }

                .MuiMenu-list {
                    max-height: 220px;

                    overflow-y: scroll;

                    .MuiMenuItem-root {
                        font-family: 'Montserrat';
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        color: rgba(0, 0, 0, 0.7);
                    }
                }
            }
        }
    }

    .scrollsection {
        // max-height: 90vh;
        // overflow-y: auto;
        // overflow-x: hidden;
        padding-top: 100px;
        padding-left: 20px;
        padding-right: 20px;
        z-index: 10;
        // max-width: 1400px;
        // margin: auto;

        @include themed {
            background: $bg-dark;
        }

        .common_header {
            border-radius: 8px;
            padding: 12px;
            margin-bottom: 8px;

            // max-width: 360px;
            &.green {
                background: #DDFFE2;
                color: #219653;
            }

            &.orange {
                background: #FFECDB;
                color: #F2994A;
            }

            &.darkblue {
                background: #E8F1FD;
                color: #2F80ED;
            }

            &.lightblue {
                background: rgba(228, 242, 255, 0.6);
                color: #2D9CDB;
            }

            h6 {
                font-family: 'Montserrat';
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 0px;

                // color: black;
                @include themed {
                    color: $grey-dark;
                }
            }
        }

        .common_box {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            min-height: 139px;
            padding: 6px;

            // width: 360px;
            @include themed {
                background: $bg-grey !important;
            }

            .taskid {
                font-family: 'Montserrat';
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000;

                @include themed {
                    color: $white;
                }
            }

            .prioritys {
                border-radius: 40px;
                height: 20px;
                padding: 4px 12px;
                color: #fff;
                font-size: 10px;
                display: inline-block;
                line-height: 12px;
                font-weight: 600;

                &.red {
                    background: #FFE1E1;
                    color: #ec3f3f;
                }

                &.yellow {
                    background: #FFE8D8;
                    color: #F2994A;
                }

                &.blue {
                    background: #DFEDFF;
                    color: #2F80ED;
                }

            }

            .subject {
                font-family: 'Montserrat';
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000;
                margin-bottom: 12px;

                @include themed {
                    color: $white;
                }
            }

            .duedate {
                font-family: 'Montserrat';
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000;
                margin-bottom: 0px;

                // margin-bottom: 12px;
                @include themed {
                    color: $white;
                }
            }

            .desc {
                h6 {
                    font-family: 'Montserrat';
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    color: #000;
                    margin-bottom: 8px;

                    @include themed {
                        color: $white;
                    }
                }

                p {
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #000;
                    margin-bottom: 0px;

                    @include themed {
                        color: $white;
                    }
                }
            }

            .module_name {
                text-decoration: none;
                color: $primary;
                font-size: 12px;
                line-height: 12px;
                font-weight: 600;
                font-family: 'Montserrat';
            }

            .asignee_tag {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                padding: 4px 7px;
                color: #ffffff;
                cursor: pointer;

                &.orgBg {
                    background: #F2994A;

                    &:hover {
                        z-index: 99;
                        width: 100%;
                    }
                }

                &.violetBg {
                    background: $primary;
                    position: relative;
                    left: -5px;
                }
            }

        }

        .task_list {
            @include themed {
                background: $bg-grey !important;
            }

            .heading {
                display: flex;
                margin-bottom: 0px;

                @include themed {
                    background: $bg-grey !important;
                }


                li {
                    list-style-type: none;
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    margin-bottom: 0;
                    background: #fff;
                    height: 39px;
                    color: #000000;
                    padding: 12px 12px 5px;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    // min-width: 127px;
                    min-width: 97px;

                    @media(max-width:1200px) {
                        min-width: 50px;
                    }

                    @include themed {
                        color: $text-light !important;
                        background: $bg-grey !important;
                    }

                    @media(max-width:1300px) {
                        width: 100px;
                    }

                    .sorting-btn {
                        background: transparent;
                        margin-left: auto;

                        img {
                            height: 10px;
                        }
                    }

                    &:first-child {
                        min-width: 440px;
                        padding-left: 0px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        @media(max-width:1715px) {
                            min-width: 222px;
                        }
                    }

                    .started_heading {
                        display: inline-block !important;
                        padding: 10px 34px 8px;
                        margin: 0;
                        font-size: 12px;
                        line-height: 15px;
                        font-weight: 600;
                        border-radius: 8px 8px 0px 0px;

                        &.green {
                            background: #DDFFE2;
                            color: #219653;
                        }

                        &.orange {
                            background: #FFECDB;
                            color: #F2994A;
                        }

                        &.darkblue {
                            background: #E8F1FD;
                            color: #2F80ED;
                        }

                        &.lightblue {
                            background: rgba(228, 242, 255, 0.6);
                            color: #2D9CDB;
                        }
                    }
                }
            }

            .sub_heading {
                border: 1px solid rgba(0, 0, 0, 0.1);

                .sub_sub_heading {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 0px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding-left: 12px;

                    &:last-child {
                        border-bottom: none;
                    }

                    &.green {
                        background: #DDFFE2;
                        color: #219653;
                    }

                    li {
                        list-style-type: none;
                        font-family: 'Montserrat';
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        margin-bottom: 0;
                        background: #fff;
                        height: 39px;
                        color: $black;
                        padding: 12px 12px;
                        flex: 1;
                        display: flex;
                        align-items: center;
                        // min-width: 127px;
                        min-width: 97px;

                        @media(max-width:1200px) {
                            min-width: 50px;
                        }

                        @include themed {
                            color: $text-light !important;
                            background: $bg-grey !important;
                        }

                        .asignee_tag {
                            height: 20px;
                            width: 20px;
                            border-radius: 50%;
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 12px;
                            padding: 4px 7px;
                            color: #ffffff;
                            cursor: pointer;

                            &.orgBg {
                                background: #F2994A;
                            }

                            &.violetBg {
                                background: $primary;
                                position: relative;
                                left: -5px;
                            }

                        }

                        &:last-child {
                            padding-right: 0;
                        }

                        &:first-child {
                            min-width: 430px;
                            padding-left: 0px;
                            display: flex;
                            align-items: center;

                            @media(max-width:1715px) {
                                min-width: 212px;
                            }
                        }

                        select {
                            background: transparent;
                            border: 0;
                        }

                        span {
                            font-family: 'Montserrat';
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;

                            &.highest {
                                color: #ec3f3f;
                            }

                            &.normal {
                                color: #FFE87C;
                            }

                            &.lowest {
                                color: #2F80ED;
                            }

                            &.high {
                                color: #F2994A;
                            }

                            &.low {
                                color: #219653;
                            }
                        }

                        span {
                            cursor: pointer;
                        }
                    }

                    .table_right_arrow {
                        background: transparent;

                        img {
                            height: 8px;
                            margin-right: 10px;

                            &.rotate_icon {
                                transform: rotate(90deg);
                                transition: transform .2s cubic-bezier(.785, .135, .15, .86) 0s;
                            }
                        }
                    }

                    .status_dropdown {
                        width: 100%;

                        .inputFieldd {
                            position: relative;
                            margin-bottom: 0;

                            .MuiOutlinedInput-input {
                                font-family: 'Montserrat';
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 15px;
                                padding: 12px 0;
                            }

                            label,
                            input {
                                font-family: "Montserrat";
                                font-weight: 500;
                                letter-spacing: 0px;
                                font-size: 14px;

                                @include themed() {
                                    color: $text-light;
                                }
                            }

                            label {
                                // transform: translate(14px, 18px) scale(1);
                                top: 2px;
                            }

                            input {
                                height: 23px;
                            }


                            .MuiInputLabel-shrink {
                                color: $primary !important;
                            }

                            svg {
                                display: none;
                            }

                            .Mui-focused {

                                .MuiOutlinedInput-notchedOutline {
                                    border-color: $primary !important;

                                }
                            }

                            fieldset {
                                border: none !important;
                                font-family: "Montserrat";
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 15px;

                                @include themed() {
                                    border-color: $text-grey;
                                }
                            }
                        }


                    }

                    .sub_category {
                        display: flex;
                        margin-bottom: 0px;
                        padding-left: 12px;
                        width: 100%;

                        &:last-child {
                            border-bottom: none;
                        }

                        &.green {
                            background: #DDFFE2;
                            color: #219653;
                        }

                        li {
                            list-style-type: none;
                            font-family: 'Montserrat';
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            margin-bottom: 0;
                            background: #fff;
                            height: 39px;
                            color: #000000;
                            padding: 12px 12px;
                            flex: 1;
                            display: flex;
                            align-items: center;
                            // min-width: 127px;
                            min-width: 97px;

                            @media(max-width:1200px) {
                                min-width: 50px;
                            }

                            .asignee_tag {
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                font-weight: 600;
                                font-size: 10px;
                                line-height: 12px;
                                padding: 4px 7px;
                                color: #ffffff;
                                cursor: pointer;

                                &.orgBg {
                                    background: #F2994A;
                                }

                                &.violetBg {
                                    background: $primary;
                                    position: relative;
                                    left: -5px;
                                }
                            }


                            &:first-child {
                                min-width: 428px;
                                padding-left: 0px;
                                display: flex;
                                align-items: center;

                                @media(max-width:1715px) {
                                    min-width: 200px;
                                }
                            }

                            span {
                                font-family: 'Montserrat';
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 15px;

                                &.highest {
                                    color: #ec3f3f;
                                }

                                &.normal {
                                    color: #FFE87C;
                                }

                                &.lowest {
                                    color: #2F80ED;
                                }

                                &.high {
                                    color: #F2994A;
                                }

                                &.low {
                                    color: #219653;
                                }
                            }
                        }



                        .table_right_arrow {
                            background: transparent;
                            position: relative;

                            img {
                                height: 8px;
                                margin-right: 10px;

                                &:active {
                                    transform: rotate(90deg);
                                    transition: transform .2s cubic-bezier(.785, .135, .15, .86) 0s;
                                }
                            }

                            &:before {
                                content: "";
                                position: absolute;
                                width: 1px;
                                height: 38px;
                                border-left: 1px solid rgba(0, 0, 0, 0.1);
                                top: -29px;
                                left: -9px;

                                @include themed {
                                    border-color: $black !important;
                                }
                            }

                            &:after {
                                content: "";
                                position: absolute;
                                width: 10px;
                                height: 1px;
                                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                                top: 8px;
                                left: -9px;

                                @include themed {
                                    border-color: $black !important;
                                }
                            }
                        }

                        .status_dropdown {
                            width: 100%;

                            .inputFieldd {
                                position: relative;
                                margin-bottom: 0;

                                .MuiOutlinedInput-input {
                                    font-family: 'Montserrat';
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 15px;
                                    padding: 12px 0;
                                }

                                label,
                                input {
                                    font-family: "Montserrat";
                                    font-weight: 500;
                                    letter-spacing: 0px;
                                    font-size: 14px;

                                    @include themed() {
                                        color: $text-light;
                                    }
                                }

                                label {
                                    // transform: translate(14px, 18px) scale(1);
                                    top: 2px;
                                }

                                input {
                                    height: 23px;
                                }

                                .MuiInputLabel-shrink {
                                    color: $primary !important;
                                }

                                svg {
                                    display: none;
                                }

                                .Mui-focused {

                                    .MuiOutlinedInput-notchedOutline {
                                        border-color: $primary !important;
                                    }
                                }

                                fieldset {
                                    border: none !important;
                                    font-family: "Montserrat";
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 15px;

                                    @include themed() {
                                        border-color: $text-grey;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}


// .MuiMenu-paper {
//     border-radius: 8px;   

//     @include themed() {
//         background-color: $text-grey;
//     }

//     .MuiMenu-list {
//         max-height: 220px;

//         overflow-y: scroll;
//         .MuiMenuItem-root {
//             font-family: 'Montserrat';
//             font-weight: 500;
//             font-size: 12px;
//             line-height: 15px;
//             color: rgba(0, 0, 0, 0.7);
//         }
//     }
// }


.sliding-panel {
    .SubtaskHeading {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: $primary;
        margin-bottom: 0;
    }

    .browsebtn {
        padding: 7px 14px;
        width: 100%;
    }

    .scrollable_area {
        .newField {
            padding-left: 8px;

            span {
                font-family: 'Montserrat';
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;

                @include themed {
                    color: $text-light;
                }
            }

            .newDropdown {
                padding-left: 0px !important;
                margin-left: 6px;

                .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
                    padding: 10px 20px 6px 8px !important;
                    color: red;
                    border-color: white;
                    font-family: 'Montserrat';
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }

    }
}

li#started div {
    color: #219653;
}

#started {
    background: #DDFFE2;
}

li#inqueue div {
    color: #2F80ED;
}

#inqueue {
    background: #E8F1FD;
}

li#senttoinsurer div {
    color: #F2994A;
}

#senttoinsurer {
    background: #FFE8D8;
}

li#upcomingrenewal div {
    color: #ec3f3f;
}

#upcomingrenewal {
    background: #FFE1E1;
}

li#completed div {
    color: #FF1493;
}

#completed {
    background: #FFC0CB;
}

li#pendingwithinsurer div {
    color: #DDFFE2;
}

#pendingwithinsurer {
    background: #219653;
}

li#endorsementrequestsent div {
    color: #F27900;
}

#endorsementrequestsent {
    background: #FFBE7C;
}

li#claimrequestsent div {
    color: #FFCC00;
}

#claimrequestsent {
    background: #FFFFE0;
}