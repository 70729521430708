@import "../../../../../../mixins.scss";

.exclusion_box {
  .exclusion_list {
    list-style-type: none;
    
    border: 1px dotted #C73C3C8A;
    background: linear-gradient(270.54deg, rgba(255, 255, 255, 0) 0.41%, rgba(199, 60, 60, 0.07) 99.59%);

    @include border-radius(10px);
    padding: 10px;
    min-height: 64px;
    margin-bottom: 12px;
    position: relative;
    display: flex;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      display: inline-block;

      $button-icons: aids, pregnancy, ectopic, std, aids, pregnancy, ectopic1,
        std;
      $k: 0;

      @each $icons in $button-icons {
        $bacRightP: $k * 50;
        $k: $k + 1;

        &.#{$icons} {
          position: absolute;
          content: "";
          height: 50px;
          width: 50px;
          top: calc(50% - 25px);
          @media (max-width: 599px) {
            top: 12px;
          }
          left: 12px;
          background: url("../../../../../../../public/images/policy_exclusion_icon.svg")
            no-repeat -#{$bacRightP}px
            0px/400px;
        }
      }
    }

    .exclusion_inerlist {
      padding-left: 64px;

      .exclusion_content {
        text-align: left;

        h5 {
          font-family: "Montserrat";
          @include fontSize(14px);
          line-height: 24px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        p {
          font-family: "Montserrat";
          @include fontSize(12px);
          line-height: 16px;
          font-weight: 400;
          margin-bottom: 0px;
        }
      }
    }
  }
}
