@import "../../../../mixins.scss";

.profileWrapper {
  .personalInfo {
    .myimg {
      background: $blue-light;
      @include border-radius(16px 16px 0px 0px);
      overflow: hidden;
      position: relative;
      height: 190px;
      width: 180px;

      .icon {
        color: $primary;
        @include position(absolute, $top: 30px, $left: 50%);
        transform: translateX(-50%);
        opacity: 0.2;
        height: 180px;
        width: 180px;
      }

      img {
        width: 100%;
      }
    }

    .myDeails {
      background: $primary;
      @include border-radius(0px 16px 16px);
      border-right: 1px dashed $white;
      border-bottom: 1px dashed $white;
      @include box-shadow(4px 6px 12px rgba($primary, 0.3));
      color: $white;
      font-family: "Montserrat", sans-serif;
      position: relative;
      top: -10px;
      padding: 16px;
      width: 300px;

      .camera {
        background: #f5c651;
        @include box-shadow(4px 6px 12px rgba($black, 0.3));
        color: $black;
        cursor: pointer;
        @include border-radius(50px);
        @include position(absolute, $left: 165px, $top: -15px);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;

        input {
          @include position(absolute, $left: 0px, $top: 0px);
          cursor: pointer;
          opacity: 0;
          height: 100%;
          width: 100%;
        }

        svg {
          height: 18px;
        }
      }

      h4 {
        margin-top: 6px;
        margin-bottom: 6px;
        text-transform: uppercase;
      }

      p {
        @include fontSize(12px);
        opacity: 0.5;
        line-height: 18px;
      }
    }
  }

  .personalDetails {
    padding: 0px 20px;
    max-width: 360px;
    width: 100%;

    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      li {
        list-style-type: none;
        @include border-radius(8px);
        padding: 50px 20px 12px;
        @include fontSize(14px);
        line-height: 20px;
        text-align: center;
        position: relative;
        margin: 0px 8px 16px;
        @include themed{
          color: #ddd;
        }

        &:first-child {
          max-width: 100px;
        }

        $status-colors: (
          "dob",
          $primary-red),
          
          ("male", $primary),
          ("female", $primary),
          ("other", $primary),
          ("address", $primary-green),
          ("envelope", $blue-light),
          ("income", $blue-light),
          ("contact", $blue-light),
          ("marital", $primary-yellow
        );
      $k: 0;

      @each $color in $status-colors {
        $bacRightP: $k * 40;
        $k: $k + 1;

        &.#{nth($color,1)} {
          background: rgba(nth($color, 2), 0.15);

          &:before {
            @include position(absolute, $left: 50%, $top: 8px);
            transform: translateX(-50%);
            content: "";
            // background: url("../../../../../public/images/personal-details-icons.svg") no-repeat -#{$bacRightP}px 0px/280px;
            background: url("../../../../../public/images/personal-details-icons.svg") no-repeat -#{$bacRightP}px 0px/360px;
            height: 40px;
            width: 40px;
          }
        }
      }
    }
  }
}

.familyMembers {
  border: 1px dashed rgba($primary, 0.2);
  @include border-radius(16px);
  padding: 16px;
  width: 100%;

  ul {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    align-items: center;
    margin-left: 8px;
    margin-bottom: 24px;
    flex-wrap: wrap;

    li {
      background: $white;
      @include border-radius(80px);
      list-style-type: none;
      cursor: pointer;
      border: 1px solid rgba($black, 0.2);
      @include box-shadow(0px 4px 12px rgba($primary, 0.2));
      margin-left: -8px;
      position: relative;
      height: 70px;
      width: 70px;

      &.addMore {
        border: 1px dashed $primary;
        color: $primary;
        @include box-shadow(0px 4px 12px rgba($primary, 0));
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 24px;
        transition: all ease 0.3s;
        height: 40px;
        width: 40px;

        &:hover {
          transform: rotate(90deg);
        }
      }

      $button-icons: father, mother, husband, wife, brother, sister, son,
        daughter;
      $k: 0;

      @each $icons in $button-icons {
        $bacRightP: $k * 70;
        $k: $k + 1;

        &.#{$icons} {
          &.active {
            background: $primary url("../../../../../public/images/family-members.svg") no-repeat -#{$bacRightP}px 6px /630px;
          }

          background: $white url("../../../../../public/images/family-members.svg") no-repeat -#{$bacRightP}px 6px /630px;
        }
      }
    }
  }

  .memberDetails {
    background: linear-gradient(90deg,
        #edf1fe 0%,
        rgba(237, 241, 254, 0) 100%);
    @include border-radius(50px);
    padding: 12px 80px 12px 20px;
    @include fontSize(14px);
    position: relative;

    span {
      margin-right: 12px;
    }

    a:nth-of-type(1) {
      @include position(absolute, $right: 100px, $top: 8px);
    }
    a:nth-of-type(2) {
      // border: 2px solid crimson;

      @include position(absolute, $right: 10px, $top: 8px);
    }
  }
}

.email_data {
  background: #2160aa33;
  border-radius: 20px;
  padding: 24px;
  .grids{
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 12px;
    p{
      @include themed{
        color: #ddd;
      }
    }
  }
}

.salary_details {
  background: rgba(235, 87, 87, 0.15);
  padding: 15px 20px;
  @include border-radius(8px);
  max-width: 630px;
  @include themed{
    color: #ddd;
  }

  .headP {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    font-family: 'Montserrat','sans-serif';
    @include themed{
      color: #ddd;
    }
  }

  .valP {
    font-size: 14px;
    font-weight: 400;
    line-break: anywhere;
    @include themed{
      color: #ddd;
    }
  }

  .prof_detail {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  h6 {
    @include fontSize(16px);
    line-height: 20px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  // ul {
  //   display: flex;
  //   justify-content: space-between;
  //   flex-wrap: wrap;
  //   margin: 5px 0 0;

  //   li {
  //     list-style-type: none;
  //     @include fontSize(14px);
  //     line-height: 20px;
  //     font-weight: 400;
  //     margin-bottom: 0px;
  //   }
  // }
}


.propertyType {
  display: flex;

  li {
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    @include fontSize(14px);
    padding: 80px 12px 8px;
    min-width: 100px;

    $button-icons: rented, independent, parenthouse;
    $k: 0;

    @each $icons in $button-icons {
      $bacRightP: $k * 70;
      $k: $k + 1;

      &.#{$icons} {
        &.active {
          color: $primary;

          &:before {
            // background: $primary url("../../../../../public/images/property-type.svg") no-repeat -#{$bacRightP}px 0px /210px;
            outline: 2px solid $primary;
            offset: -2px;
          }
        }

        &:before {
          @include border-radius(8px);
          @include box-shadow(0px 4px 12px rgba($primary, 0.2));
          @include position(absolute, $top: 0px, $left: calc(50% - 35px));
          content: "";
          height: 70px;
          width: 70px;
          background: $white url("../../../../../public/images/property-type.svg") no-repeat -#{$bacRightP}px 0px /210px;
        }
      }
    }
  }
}
}

.property_details {
  display: flex;

  li {
    list-style-type: none;
    width: 300px;

    p {
      font-family: "Montserrat";
      font-size: 0.625rem;
      font-weight: 400;
      line-height: 12px;
      color: $primary;

      span {
        display: block;
        margin-top: 8px;
        color: #000;
        font-size: 0.75rem;
        line-height: 15px;
        font-weight: 700;
      }
    }
  }
}