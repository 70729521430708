@import "../../../../../mixins.scss";

.mainSettingWrapper {
    padding:90px 12px 24px; 

    @include themed() {
        background: $bg-dark;
        border-radius: 10px;
    }

    .pageTitle {
        display: flex;
        align-items: center;

        a {
            display: flex;
            justify-content: center;
            margin-right: 12px;
            color: $primary;

            svg {
                position: relative;
                transition: all 0.5s ease;
                left: 0px;
            }

            &:hover {
                cursor: pointer;
                color: $primary-red;

                svg {
                    left: -4px;
                }
            }
        }

        .MuiSelect-select {
            min-width: 220px;
        }
    }

    .accordian-section {
        width: 250px;
        min-height: 100vh;
        position: fixed;

        @include themed {
            background: $bg-dark;
        }

        .accordian {
            box-shadow: none;

            @include themed() {
                background: $bg-dark;
            }

            &::before {
                // background-color: $white;
                height: 0;
            }

            .accordion-header {
                position: relative;
                padding: 0;
                min-height: 48px;
                width: 100%;
                margin-bottom: 5px;


                .heading {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    padding-left: 52px;
                    color: rgba(0, 0, 0, 0.50) !important;
                    position: relative;

                    @include themed() {
                        color: $text-light !important;
                    }

                    $othericons: devspace,
                        portalspace,
                        customisation,
                        userimg,
                        dataadmin,
                        orgnaisation,
                        general;
                    $k: 0;

                    @each $icons in $othericons {
                        $bacRightP: $k * 40;
                        $k: $k +1;

                        &.#{$icons} {
                            &:before {
                                content: "";
                                @include position(absolute, $left: 0px, $top: calc(50% - 20px));
                                @include border-radius(12px);
                                height: 40px;
                                width: 40px;
                                background: url("../../../../../../public/images/setting-icons.svg") no-repeat -#{$bacRightP}px 0px/ 280px;

                                @include themed() {
                                    background: url("../../../../../../public/images/setting-icons-active.svg");
                                    opacity: 0.3;
                                }
                            }

                            &.active {
                                color: #333;

                                &:before {
                                    background: $black url("../../../../../../public/images/setting-icons-active.svg") no-repeat -#{$bacRightP}px 0px/ 280px;
                                    opacity: 1;

                                    @include themed() {
                                        //background-color: $white;
                                        @include box-shadow(0px 0px 16px rgba($black, 0.4));
                                    }
                                }
                            }
                        }


                    }


                    // @include themed() {
                    //     color: $white;
                    // }
                }

                // &::after {
                //     position: absolute;
                //     content: '';
                //     background: url('./../../../../../../public/images/accordion_dropdown_icon.svg') no-repeat 0 0/10px;
                //     width: 10px;
                //     height: 12px;
                //     right: 0px;
                // }
            }

            .accordian-detail-section {
                padding-left: 0px;
                padding-right: 0px;

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        list-style: none;
                        margin-bottom: 0;
                        padding-left: 18px;
                        position: relative;

                        @include themed() {
                            color: $text-light !important;
                        }

                        &:last-child {
                            padding-bottom: 0;
                        }

                        .tab-button {
                            color: rgba(0, 0, 0, 0.7) !important;
                            display: inline-block;

                            font-weight: 400;
                            font-size: 11px;
                            line-height: 12px;
                            padding: 6px 6px 6px 20px;
                            font-family: 'Montserrat';
                            position: relative;

                            @include themed() {
                                color: $text-light !important;
                            }

                            &:before {
                                content: '';
                                height: 26px;
                                width: 2px;
                                background: rgba($black, 0.2);
                                position: absolute;
                                left: 2px;
                                top: 0;
                            }

                            &.active {
                                color: #000 !important;
                                font-weight: 500;

                                @include themed() {
                                    color: $white !important;
                                }

                                &:before {
                                    background: $black;
                                }
                            }



                            @include themed() {
                                color: $white;
                            }

                            &:focus,
                            &:hover {
                                color: #000 !important;
                                font-weight: 500;
                                //transform: scale(1.2);
                                //transition: all 0.4s ease;
                                text-decoration: none;
                                @include themed{
                                    color: $white !important;
                                }

                            }
                        }
                    }
                }
            }
        }
    }

    .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0 !important;
        // border-bottom: 1px solid black;
    }

    .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        margin: 0 !important;
    }
}