 @import "../../../../../mixins.scss";
 .detailSection {
     border: 1px solid $primary-light;
     @include border-radius(12px);
     // overflow: hidden;
     margin-bottom: 20px;
     padding-bottom: 12px;

     @include themed {
         background: $bg-dark;
         border: none;
     }

     .sectionTitle {
         background: $blue-light;
         display: flex;
         justify-content: space-between;
         padding: 12px 12px;
         margin-bottom: 15px;
         border-radius: 12px 12px 0 0;

         @include themed {
             background: $black;
         }

         h4 {
             color: $primary;
             @include fontSize(14px);
             font-weight: 500;
             margin-bottom: 0px;

             @include themed {
                 color: $text-grey;
             }
         }

         .actionBtns {
             .edit_btn {
                 font-family: 'Montserrat';
                 font-weight: 500;
                 @include fontSize(14px);
                 line-height: 20px;
                 color: $primary;
                 outline: none;
                 text-decoration: none;
                 display: inline-block;
                 cursor: pointer;

                 .edit_btn_icon {
                     position: relative;
                     top: -2px;
                     height: 12px;
                     margin-right: 4px;
                 }
             }

             .addPremium {
                 font-family: 'Montserrat';
                 font-weight: 500;
                 @include fontSize(14px);
                 line-height: 20px;
                 color: $primary;
                 outline: none;
                 text-decoration: none;
                 display: inline-block;
                 cursor: pointer;
                 margin-right: 10px;
                 position: relative;
                 padding: 2px 0 0 28px;

                 &.addfile {
                     &:before {
                         position: absolute;
                         left: 1px;
                         top: 0px;
                     }
                 }
             }
         }
     }

     .subHeading {
         @include fontSize(14px);
         font-weight: 600;
         line-height: 24px;
         margin: 0px 0px 12px 0px;
         position: relative;

         @include themed {
             color: $text-grey;
         }

         &:before {
             content: '';
             @include position (absolute, $top: 26px, $left: 0px);
             width: 200px;
             height: 1px;
             background: $primary;
         }
     }

     .MuiGrid2-container {
         padding-top: 4px;
         padding-bottom: 4px;
     }


     .table {
         overflow-x: auto;

         .attatchment_table {
             width: 100%;
             min-width: 1500px;

             .heading {
                 display: flex;
                 align-items: center;
                 justify-content: space-between;
                 border-bottom: 1px solid $blue-light;
                background: #e4f1ff;
                @include border-radius(4px 4px 0px 0px);
                 margin-bottom: 8px;
                //  @include border-radius(8px 8px 0px 0px);

                 @include themed {
                     background: $bg-grey !important;
                     border-bottom: 1px solid $bg-grey;
                 }

                 li {
                     list-style-type: none;
                     font-family: 'Montserrat';
                     font-weight: 600;
                     @include fontSize(12px);
                     line-height: 24px;
                     margin-bottom: 0;
                     width: 200px;
                     background: $white;
                     height: 40px;
                     color: $black;
                     padding: 10px 12px;
                     flex: 1;
                    //  @include border-radius(8px 8px 0px 0px);

                     &:first-child {
                         // min-width: 65%;
                         min-width: 500px;
                         width: 50%;
                     }

                     @include themed {
                         background: $bg-grey !important;
                         color: $text-grey;
                     }

                 }
             }

             .subheading {
                 display: flex;
                 justify-content: space-between;
                 margin-bottom: 8px;
                 border-bottom: 1px solid $blue-light;

                 @include themed {
                     border-bottom: 1px solid $bg-grey;
                 }

                 &:last-child {
                     border-bottom: 0;
                 }

                 li {
                     list-style-type: none;
                     min-height: 50px;
                     // border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
                     padding: 13px 6px 13px 12px;
                     font-family: 'Montserrat';
                     font-weight: 500;
                     // max-width: 20 0px;
                     width: 200px;
                     @include fontSize(12px);
                     line-height: 24px;
                     margin-bottom: 0px;
                     color: $black;
                     flex: 1;

                     @include themed {
                         color: $text-light !important;
                     }

                     &:first-child {
                         // min-width: 65%;
                         min-width: 500px;
                         width: 50%;

                     }

                     .date {
                         font-family: 'Montserrat';
                         font-weight: 500;
                         @include fontSize(12px);
                         line-height: 24px;
                         max-width: 200px;
                         color: rgba(0, 0, 0, 0.6) !important;

                         @include themed {
                             color: $text-light !important;
                         }
                     }

                     .time {
                         font-family: 'Montserrat';
                         font-weight: 500;
                         @include fontSize(12px);
                         line-height: 24px;
                         max-width: 200px;
                         color: rgba(0, 0, 0, 0.6) !important;

                         @include themed {
                             color: $text-light !important;
                         }
                     }


                 }

             }
         }
     }
 }