@import "../../../mixins.scss";

.voluntry_plans {
    animation: fadeIn 1s both;

    .voluntry_plans_fea {
        display: flex;
        justify-content: center;

        .voluntry_description {
            h6 {
                font-family: 'Montserrat';
                font-weight: 500;
                @include fontSize(11px);
                line-height: 24px;
                color: $primary;
                margin-bottom: 0;
            }

            p {
                font-family: 'Montserrat';
                font-weight: 400;
                @include fontSize(14px);
                line-height: 24px;
                color: $black;
                margin-bottom: 0;

                @include themed {
                    color: $text-light;
                }
            }
        }

        .voluntry_features {
            overflow-x: hidden;
            overflow-y: auto;
            max-height: calc(100vh - 300px);
            background: #FEF8ED;
            border: 1px solid #F5BE4C;
            box-shadow: 0px 10px 36px rgba(245, 190, 76, 0.25);
            @include border-radius(12px);
            padding: 22px;
            position: relative;

            &::-webkit-scrollbar {
                width: 0px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #F5BE4C;
                // border-radius: 12px;
            }

            &::-webkit-scrollbar-track {
                background-color: #fffbf55e;
                // border-radius: 12px;
            }

            .feature_img {
                text-align: center;

                .voluntry_feature_img {
                    height: 100px;
                    margin-bottom: 24px;
                }

                h6 {
                    font-family: 'Montserrat';
                    font-weight: 600;
                    @include fontSize(18px);
                    line-height: 24px;
                    text-align: center;
                    color: $black;
                    margin-bottom: 24px;
                }

                .action_edit {
                    position: absolute;
                    top: 30px;
                    right: 20px;

                    .action_btn {
                        background: $blue-light !important;
                        @include border-radius(100px);
                        color: $primary;
                        text-decoration: none;
                        height: 36px;
                        width: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            height: 16px;
                            width: 16px;
                        }

                        &:hover {
                            background: $primary;
                            color: $white;
                        }
                    }
                }
            }

            .medical_cover {
                h5 {
                    background: rgba(255, 255, 255, 0.7);
                    border-bottom: 1px solid #F5BE4C;
                    @include border-radius(20px);
                    font-family: 'Montserrat';
                    font-weight: 600;
                    @include fontSize(14px);
                    line-height: 24px;
                    color: #F5BE4C;
                    padding: 4px 0 4px 16px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'Montserrat';
                    font-weight: 400;
                    @include fontSize(12px);
                    line-height: 20px;
                    margin-bottom: 0px;
                }

                ul {
                    padding-left: 20px;
                    margin-bottom: 0;

                    li {
                        font-family: 'Montserrat';
                        font-weight: 600;
                        @include fontSize(12px);
                        margin-bottom: 12px;
                        line-height: 17px;
                    }
                }

                h4 {
                    font-family: 'Montserrat';
                    font-weight: 600;
                    @include fontSize(12px);
                    margin-bottom: 12px;
                    line-height: 17px;
                }

                h6 {
                    font-family: 'Montserrat';
                    font-weight: 600;
                    @include fontSize(12px);
                    line-height: 20px;
                    margin-bottom: 0;
                    // text-align: right;
                }

                .word_break {
                    word-break: break-all
                }
            }
        }

        .voluntry_exclusion {
            overflow-x: hidden;
            overflow-y: auto;
            max-height: calc(100vh - 300px);
            background: #555555;
            @include border-radius(20px);
            padding: 22px;
            position: relative;

            &::-webkit-scrollbar {
                width: 0px;
            }

            .exclusion_img {
                text-align: center;

                img {
                    height: 100px;
                    margin-bottom: 16px;
                }

                h6 {
                    font-family: 'Montserrat';
                    font-weight: 600;
                    @include fontSize(18px);
                    line-height: 24px;
                    text-align: center;
                    color: $white;
                    margin-bottom: 16px;

                    @include themed {
                        color: $text-light;
                    }
                }

                .action_edit {
                    position: absolute;
                    top: 30px;
                    right: 20px;

                    .action_btn {
                        background: $blue-light !important;
                        @include border-radius(100px);
                        color: $white;
                        text-decoration: none;
                        height: 36px;
                        width: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            height: 16px;
                            width: 16px;
                        }

                        &:hover {
                            background: $primary;
                            color: $white;
                        }
                    }
                }
            }

            .exclusion_para {
                h6 {
                    font-family: 'Montserrat';
                    font-weight: 600;
                    @include fontSize(14px);
                    line-height: 24px;
                    color: $white;
                    margin-bottom: 6px;
                }

                p {
                    font-family: 'Montserrat';
                    font-weight: 400;
                    @include fontSize(12px);
                    line-height: 16px;
                    color: $white;
                    margin-bottom: 16px;
                }

                hr {
                    opacity: 0.2;
                    border: 1px solid $white;
                    max-width: 349px;
                    margin-bottom: 12px;
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translate3d(0, -3%, 0)
    }

    to {
        opacity: 1;
        transform: translateZ(0)
    }
}