@import "../../../../mixins.scss";

.supportWrapper {
    .uploadFile {
        .inputField fieldset {
            @include border-radius(8px 0px 0px 8px);
        }
        .browsebtn {
            @include box-shadow(0px 0px 0px transparent);
            @include border-radius(0px 8px 8px 0px);
            height: 38px;
            width: 100%;
        }
    }
    .requestSection {
        // @include box-shadow(inset 24px 0px 24px -24px rgba($primary, 0.2));
        position: relative;
        overflow: hidden;
        padding-right: 0px;
        min-height: 80vh;
        &:before {
            background: rgba($primary, 0.25);
            @include position(absolute, $left:-30px, $top:5%);
            border-radius: 50px / 100px;
            filter: blur(20px);
            content: '';
            height: 90%;
            width: 20px;
        }
        .requestList {
            margin-top: 24px;
            padding-left: 16px;
            li {
                list-style-type: none;
                color: $text-dark;
                position: relative;
                margin-bottom: 24px;
                h6 {
                    font-weight: 400;
                    @include fontSize(13px);
                    margin-bottom: 8px;
                    display: inline-flex;
                }
                h5 {
                    @include fontSize(14px);
                    font-weight: 600;
                    margin-bottom: 4px;
                }
                p {
                    @include fontSize(12px);
                }
                .viewMore {
                    background: $white;
                    @include border-radius(20px);
                    @include box-shadow(0px 4px 8px rgba($black, 0.15));
                    @include position(absolute, $right:16px, $top:26px);
                    color: $text-dark;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 26px;
                    width: 26px;
                    svg {
                        transition: all ease 0.2s;
                        height: 20px;
                    }
                }
                .cancelled {
                    background: linear-gradient(180deg, rgba($primary-red, 0.23) 0%, rgba(251, 69, 29, 0) 100%);
                    @include box-shadow(inset 0px 4px 4px rgba($primary-red, 0.3));
                    @include border-radius(12px);
                    position: relative;
                    &.MuiCollapse-entered {
                        .viewMore {
                            svg {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    .MuiCollapse-wrapperInner {
                        padding: 16px 60px 16px 16px;
                    }
                    .status {
                        background: $primary-red;
                        display: inline-flex;
                        color: $white;
                        @include fontSize(10px);
                        padding: 3px 8px;
                        @include border-radius(20px);
                        margin-left: 8px;
                    }
                }
                .inprogress {
                    @extend .cancelled;
                    background: linear-gradient(180deg, rgba($primary-yellow, 0.25) 0%, rgba(91, 202, 88, 0) 100%);
                    @include box-shadow(inset 0px 4px 4px rgba($primary-yellow, 0.3));
                    .status {
                        background: $primary-yellow;
                    }
                }
                .approved {
                    @extend .cancelled;
                    background: linear-gradient(180deg, rgba($primary-green, 0.25) 0%, rgba(91, 202, 88, 0) 100%);
                    @include box-shadow(inset 0px 4px 4px rgba($primary-green, 0.3));
                    .status {
                        background: $primary-green;
                    }
                }
            }
        }
    }
}