@import "../../../../../mixins.scss";

.addFeaturesWrapper {
  hr {
    border: none;
    border-top: 1px solid $primary-light;
  }
  .backbtn {
    color: $primary;
    text-decoration: none;
    @include fontSize(16px);
    display: flex;
    margin-right: 32px;
    padding: 3px 0px;
    height: 30px;
    &:hover {
      cursor: pointer;
      svg {
        left: -3px;
      }
    }
    svg {
      position: relative;
      top: -2px;
      left: 0px;
      transition: all 0.5s ease;
    }
  }
}
.text_area {
  width: 100%;
  height: 150px !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  @include border-radius(10px);
  padding: 12px;
  resize: none;
  overflow-x: auto;
  overflow-y: hidden;
  background: $white;

  &:focus-visible {
    outline: none;
  }
  @include themed {
    background: $bg-grey;
    color: $text-light;
    border-color: $black;
  }
}
