@import "../../../mixins.scss";

//spoc table
.table {
  overflow-x: auto;

  .spoc-table {
    width: 100%;
    min-width: 2500px;
    max-height: 300px;

    .heading {
      display: flex;
      // flex-wrap: wrap;
      border-bottom: 1px solid $blue-light;
      margin-bottom: 8px;
      background: #E4F1FF;
      @include border-radius(12px 12px 0px 0px);

      @include themed {
        border-bottom: 1px solid $bg-grey;
      }

      li {
        list-style-type: none;
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(12px);
        line-height: 20px;
        margin-bottom: 0;
        height: 40px;
        color: $black;
        padding: 10px 12px;
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        justify-content: space-between;
        width: 250px;
        min-width: 250px;

        .sorting-btn {
          background: transparent;
          margin-left: auto;
          margin-right: 15px;
          position: relative;

          &:before {
            position: absolute;
            content: "";
            background: url("../../../../public/images/sorting_btn.svg")
              no-repeat 0 0 /100%;
            top: 5px;
            right: 7px;
            width: 7px;
            height: 12px;

            @include themed {
              background: url("../../../../public/images/sorting_btn_white.svg")
                no-repeat 0 0 /100%;
            }
          }

          img {
            height: 10px;
          }
        }

        &:last-child {
          max-width: 100px;
          justify-content: end;
        }

        @include themed {
          background: $bg-grey;
          color: $text-light;
        }
      }
    }

    .subheading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0px;
      border-bottom: 1px solid $blue-light;

      @include themed {
        border-bottom: 1px solid $bg-grey;
      }

      &:last-child {
        border-bottom: 0;
      }

      li {
        list-style-type: none;
        min-height: 40px;
        padding: 10px 12px;
        font-family: "Montserrat";
        font-weight: 400;
        // width: 250px;
        // min-width: 250px;
        width: 290px;
        min-width: 282px;
        @include fontSize(12px);
        line-height: 24px;
        margin-bottom: 0px;
        color: $black;
        flex-basis: 0;
        flex-grow: 1;
        align-self: center;

        &:last-child {
          max-width: 100px;
          justify-content: end;
        }

        @include themed {
          color: $text-light !important;
        }

        .Checkbox {
          label {
            &:after {
              position: absolute;
              content: "";
              height: 15px;
              width: 15px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              @include border-radius(2px);
              left: 2px;
              top: 7px;

              @include themed {
                border-color: $text-light !important;
              }
            }
          }
        }
      }
    }
  }
}
