@import "./../../mixins.scss";

.BulklayoutWrapper {
  // padding-top: 70px;
  padding-top: 90px;
  // height: 100%;
  min-height: 100vh;

  @include themed {
    background: $bg-dark;
  }

  .pageHeader {
    background: $white;
    @include box-shadow(0px 2px 12px rgba($black, 0.1));
    @include position(fixed, $top: 0px, $left: 0px);
    margin: 0px;
    height: 70px;
    width: 100%;
    z-index: 991;

    @include themed {
      background: $bg-dark;
      box-shadow: 0 8px 6px -6px black;
    }

    .pageTitle {
      display: flex;
      align-items: center;

      .backStep {
        margin-right: 8px;
        color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          color: $primary-red;

          svg {
            left: -4px;
          }
        }

        svg {
          position: relative;
          transition: all 0.5s ease;
          left: 0px;
          height: 26px;
          width: 30px;
        }
      }

      .detailIcon {
        background: $primary-orange;
        @include border-radius(50px);
        color: $white;
        @include fontSize(20px);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        margin-right: 16px;
        height: 40px;
        width: 40px;
      }

      h3 {
        margin-bottom: 0px;
        @include fontSize(16px);
        line-height: 20px;

        @include themed {
          color: $text-grey;
        }
      }

      p {
        margin-bottom: 0px;
        margin-top: 6px;
        margin-bottom: 2px;
        color: rgba($black, 0.5);
        @include fontSize(11px);

        @include themed {
          color: $text-grey;
        }
      }
    }
  }

  .innerSection {
    .leftPart {
      p {
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(14px);
        line-height: 24px;
        margin-bottom: 8px;

        @include themed {
          color: $white;
        }
      }

      .browsebtn {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(14px);
        line-height: 24px;
        background: $primary;
        padding: 7.5px 14px;
        border-radius: 0 10px 10px 0;
        text-transform: capitalize;
        color: #fff;
        text-align: center;
        display: inline-block;
        box-shadow: none;
        position: relative;
        left: -4px;

        &:hover {
          background: $primary;
          color: $white;
        }
      }

      .MuiFormControlLabel-root {
        min-width: 50px;
        max-width: 100%;

        .MuiRadio-root {
          color: $grey-light;

          &:hover {
            background-color: transparent;
          }
        }

        .MuiRadio-root.Mui-checked {
          color: $primary;
        }

        .MuiSvgIcon-root {
          font-size: 16px;
        }

        .MuiFormControlLabel-label {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 500;
          @include fontSize(12px);
          line-height: 24px;

          @include themed {
            color: $text-light;
          }
        }
      }

      .fileUploadTypeDetails {
        font-family: "Montserrat";
        font-weight: 400;
        @include fontSize(10px);
        line-height: 10px;
      }

      .advanced_option_link {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(12px);
        line-height: 12px;
        color: $primary;
        text-decoration: none;
        position: relative;
        max-width: 130px;
        cursor: pointer;

        &::before {
          position: absolute;
          content: "";
          background: url("./../../../public/images/purple_downArrow.svg") no-repeat;
          width: 10px;
          height: 6px;
          right: -15px;
          top: 5px;
        }
      }
    }

    .rightPart {
      .innerPart {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 30px;

        @include themed {
          border: 1px solid $bg-grey;
        }

        p {
          font-family: "Montserrat";
          font-weight: 700;
          @include fontSize(14px);
          line-height: 24px;
          margin-bottom: 8px;

          @include themed {
            color: $text-grey;
          }
        }

        ul {
          li {
            font-family: "Montserrat";
            font-weight: 400;
            @include fontSize(12px);
            line-height: 24px;
            margin-bottom: 4px;
            margin-left: 24px;

            @include themed {
              color: $text-grey;
            }

            .sublist {
              li {
                font-family: "Montserrat";
                font-weight: 400;
                @include fontSize(11px);
                line-height: 24px;
                margin-bottom: 4px;
                margin-left: 24px;
              }
            }
          }
        }
      }
    }

    .mapfields_instructions {
      padding: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      @include themed {
        border: 1px solid $bg-grey;
      }

      p {
        font-family: "Montserrat";
        font-weight: 700;
        @include fontSize(14px);
        line-height: 24px;
        margin-bottom: 4px;

        @include themed {
          color: $text-grey;
        }
      }

      ul {
        li {
          font-family: "Montserrat";
          font-weight: 400;
          @include fontSize(14px);
          line-height: 24px;
          margin-left: 24px;

          @include themed {
            color: $text-grey;
          }
        }
      }

      .mapcolumnList {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(14px);
          line-height: 24px;
          color: rgba(0, 0, 0, 0.8);
          margin-left: 0;

          @include themed {
            color: $text-grey;
          }
        }
      }

      .recordViewList {
        margin: 0;
        padding: 0;
        display: flex;

        li {
          font-family: "Montserrat";
          font-weight: 500;
          @include fontSize(14px);
          line-height: 24px;
          list-style: none;
          margin-right: 30px;
          margin-left: 0;
          color: $primary;

          span {
            font-weight: 700;
          }

          &.black_li {
            color: #000000;

            @include themed {
              color: $text-grey;
            }
          }
        }
      }
    }

    .detailSection {
      border: 1px solid $primary-light;
      @include border-radius(12px);
      // overflow: hidden;
      margin-bottom: 20px;
      padding-bottom: 12px;

      @include themed {
        background: $bg-dark;
        border: 1px solid $bg-grey;
      }

      .sectionTitle {
        background: $blue-light;
        display: flex;
        justify-content: space-between;
        padding: 12px 12px;
        margin-bottom: 15px;
        border-radius: 12px 12px 0 0;

        @include themed {
          background: $black;
        }

        h4 {
          color: $primary;
          @include fontSize(14px);
          font-weight: 500;
          margin-bottom: 0px;

          @include themed {
            color: $text-grey;
          }
        }

        .actionBtns {
          .edit_btn {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;

            .edit_btn_icon {
              position: relative;
              top: -2px;
              height: 12px;
              margin-right: 4px;
            }
          }

          .addPremium {
            font-family: "Montserrat";
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            color: $primary;
            outline: none;
            text-decoration: none;
            display: inline-block;
            cursor: pointer;
            margin-right: 10px;
            position: relative;
            padding: 2px 0 0 28px;

            &.addfile {
              &:before {
                position: absolute;
                left: 1px;
                top: 0px;
              }
            }
          }
        }
      }

      .subHeading {
        @include fontSize(14px);
        font-weight: 600;
        line-height: 24px;
        margin: 0px 0px 12px 0px;
        position: relative;

        @include themed {
          color: $text-grey;
        }

        &:before {
          content: "";
          @include position(absolute, $top: 26px, $left: 0px);
          width: 200px;
          height: 1px;
          background: $primary;
        }
      }

      .form_p {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(12px);
        line-height: 12px;
        color: rgba(0, 0, 0, 0.7);

        @include themed {
          color: $text-grey;
        }
      }

      hr {
        border: 0.5px solid rgba(107, 98, 220, 0.2);
      }

      .fieldTitle {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(14px);
        line-height: 24px;
        color: $primary;
        margin-bottom: 0;
      }

      .inputField {
        max-width: 60%;

        .MuiSelect-select {
          @include fontSize(13px);
        }
      }
    }

    .table {
      //   overflow-x: auto;

      .renewal-table2 {
        width: 100%;
        // min-width: 1700px;
        min-width: 3000px;

        .heading {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $blue-light;
          background: #e4f1ff;
          margin-bottom: 8px;
          @include border-radius(8px 8px 0px 0px);

          @include themed {
            background: black;
            @include border-radius(8px 8px 0px 0px);
            border: none;
          }

          li {
            list-style-type: none;
            font-family: "Montserrat";
            font-weight: 600;
            @include fontSize(12px);
            line-height: 20px;
            margin-bottom: 0;
            width: 400px;

            // height: 40px;
            color: $black;
            //border-radius: 4px;
            padding: 10px 12px;
            flex: 1;

            @include themed {
              color: $text-grey;
              background: black;
              @include border-radius(8px 8px 0px 0px);
            }

            &:nth-child(1) {
              max-width: 350px;
            }

            &:nth-child(2) {
              max-width: 250px;
            }

            &:nth-child(3) {
              max-width: 100px;
            }

            &:nth-child(4) {
              max-width: 200px;
            }

            &:nth-child(5) {
              max-width: 100px;
            }

            &:nth-child(6) {
              max-width: 300px;
            }

            &:nth-child(7) {
              max-width: 300px;
            }

            &:nth-child(8) {
              max-width: 200px;
            }

            &:nth-child(9) {
              max-width: 200px;
            }

            &:nth-child(10) {
              width: 300px;
            }
          }
        }

        .subheading {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0px;
          border-bottom: 1px solid $blue-light;

          &:last-child {
            border-bottom: 0;
          }

          li {
            list-style-type: none;
            min-height: 40px;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
            padding: 10px 12px;
            font-family: "Montserrat";
            font-weight: 500;
            width: 400px;
            @include fontSize(12px);
            line-height: 24px;
            margin-bottom: 0px;
            color: $black;
            flex: 1;

            @include themed {
              color: $text-light !important;
            }

            &:nth-child(1) {
              max-width: 350px;
            }

            &:nth-child(2) {
              max-width: 250px;
            }

            &:nth-child(3) {
              max-width: 100px;
            }

            &:nth-child(4) {
              max-width: 200px;
            }

            &:nth-child(5) {
              max-width: 100px;
            }

            &:nth-child(6) {
              max-width: 300px;
            }

            &:nth-child(7) {
              max-width: 300px;
            }

            &:nth-child(8) {
              max-width: 200px;
            }

            &:nth-child(9) {
              max-width: 200px;
            }

            &:nth-child(10) {
              width: 300px;
            }
          }
        }
      }
    }
  }

  .footerSection {
    background: $white;
    @include box-shadow(0px -4px 12px rgba($black, 0.1));
    padding: 4px;
    @include position(fixed, $bottom: 0px, $left: 0px);
    margin: 0px;
    width: 100%;
    z-index: 991;
    text-align: right;

    @include themed {
      background: $bg-dark;
      box-shadow: 0 8px 6px 8px black;
    }

    button {
      @include fontSize(14px);
      margin-left: 12px;
      padding: 8px 30px;
    }
  }
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); // Modal backdrop

  .modal-root {
    width: 90%;
    height: 90%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    padding-top: 80px;

    >button>img {
      position: absolute;
      top: 20px;
      left: 20px;
    }

    .modal-image {
      width: 350px; // Adjust the size of your image
      margin-bottom: 20px;
    }


    .modal-message {
      // margin-bottom: 20px;
      // color: #5f6368;
      // font-size: 14px;
      margin-bottom: 0px;
      color: #5f6368;
      font-size: 14px;
      // border: 1px solid #00000029;
      opacity: 10 !important;
      padding: 12px 16px 12px 16px;
      gap: 10px;
      /* border-radius: 8px 0px 0px 0px; */
   
      opacity: 0px;
      border-radius: 8px;
      width: 80%;
      text-align: center;
      margin: 0 auto;
    }

    .modal-done-button {
      background-color: $primary;
      color: #fff;


      padding: 10px 40px 10px 40px;
      ;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.5px;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      text-transform: capitalize;





      &:hover {
        background-color: #5748b5;
      }
    }
  }
}