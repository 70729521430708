 @import "../../../mixins.scss";

 .detailSection {
     border: 1px solid $primary-light;
     @include border-radius(12px);
     // overflow: hidden;
     margin-bottom: 20px;
     padding-bottom: 12px;

     @include themed {
         background: $bg-dark;
         border: none;
     }

     .sectionTitle {
         background: $blue-light;
         display: flex;
         justify-content: space-between;
         padding: 12px 12px;
         margin-bottom: 15px;
         border-radius: 12px 12px 0 0;

         @include themed {
             background: $black;
         }

         h4 {
             color: $primary;
             @include fontSize(14px);
             font-weight: 500;
             margin-bottom: 0px;

             @include themed {
                 color: $text-grey;
             }
         }

         .actionBtns {
             .edit_btn {
                 font-family: 'Montserrat';
                 font-weight: 500;
                 @include fontSize(14px);
                 line-height: 20px;
                 color: $primary;
                 outline: none;
                 text-decoration: none;
                 display: inline-block;
                 cursor: pointer;

                 .edit_btn_icon {
                     position: relative;
                     top: -2px;
                     height: 12px;
                     margin-right: 4px;
                 }
             }

             .addPremium {
                 font-family: 'Montserrat';
                 font-weight: 500;
                 @include fontSize(14px);
                 line-height: 20px;
                 color: $primary;
                 outline: none;
                 text-decoration: none;
                 display: inline-block;
                 cursor: pointer;
                 margin-right: 10px;
                 position: relative;
                 padding: 2px 0 0 28px;

                 &.addfile {
                     &:before {
                         position: absolute;
                         left: 1px;
                         top: 0px;
                     }
                 }
             }
         }
     }

     .MuiGrid2-container {
         padding-top: 4px;
         padding-bottom: 4px;
     }

     .policyDetail {
         .Accordion_section {
             background: $white;
             box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
             @include border-radius(12px !important);
             margin-bottom: 10px;
             animation: fadeIn 1s both;

             @include themed {
                 background-color: $bg-dark;
                 border: 1px solid $bg-grey;
             }

             &:before {
                 background: transparent;
             }

             &.Mui-expanded {
                 margin: 0;
             }

             .MuiAccordionSummary-root {
                 .MuiAccordionSummary-content {
                     margin: 0;
                 }

                 &.Mui-expanded {
                     max-height: 48px;
                     min-height: 48px;
                 }
             }

             .emp_switchFields {
                 span {
                     font-family: "Montserrat";
                     font-weight: 500;
                     @include fontSize(12px);
                     line-height: 15px;
                     padding-right: 24px;

                     @include themed {
                         color: $text-light;
                     }
                 }
             }

             .heading {
                 font-family: 'Montserrat';
                 font-weight: 600;
                 @include fontSize(14px);
                 line-height: 17px;
                 margin-top: 14px;

                 @include themed {
                     color: $text-light;
                 }
             }

             .coverageAmt {
                 display: flex;
                 flex-wrap: wrap;
                 margin: 0px;

                 li {
                     list-style: none;
                     margin-right: 12px;

                     &:last-child {
                         margin-right: 0;
                     }

                     @media (max-width: 1100px) {
                         margin-right: 16px;
                         margin-bottom: 16px;
                     }

                     @media (max-width: 1310px) {
                         margin-bottom: 16px;
                     }

                     .Checkboxx {
                         display: inline-block;
                         position: relative;

                         label {
                             font-family: "Montserrat";
                             display: inline-block;
                             text-align: center;
                             color: $black;
                             cursor: pointer;
                             position: relative;
                             @include fontSize(12px);
                             line-height: 17px;
                             padding: 10px 12px;
                             @include border-radius(20px);
                             border: 1px solid $primary-light;
                             font-weight: 500;
                             width: 177px;
                             height: 57px;

                             @include themed {
                                 background: $bg-grey;
                                 border-color: $bg-grey;
                             }

                             &:after {
                                 position: absolute;
                                 content: "";
                                 height: 25px;
                                 width: 25px;
                                 border: 1px solid $primary;
                                 @include border-radius(50%);
                                 left: 10px;
                                 top: 14px
                             }

                             p {
                                 position: absolute;
                                 bottom: 19px;
                                 left: 0;
                                 width: 100%;
                                 @include fontSize(12px);
                                 line-height: 17px;
                                 font-weight: 500;

                                 @include themed {
                                     color: $text-light;
                                 }
                             }
                         }

                         input:checked~label {
                             border: 1px solid $primary;
                             @include box-shadow(0px -4px 12px rgba($black, 0.1));
                             color: $black;

                             &:after {
                                 position: absolute;
                                 content: "";
                                 height: 26px;
                                 width: 26px;
                                 background: url("../../../../public/images/checkbox_tick_icon.svg") no-repeat center center/100%;
                                 @include border-radius(50%);
                                 left: 10px;
                                 border: none;
                             }
                         }

                         input {
                             position: absolute;
                             z-index: 999;
                             width: 100%;
                             height: 100%;
                             opacity: 0;

                             &:hover {
                                 cursor: pointer;
                             }
                         }
                     }
                 }
             }

             .planTypeSection {
                 .radio_box {
                     display: inline-block;
                     position: relative;

                     label {
                         font-family: "Montserrat";
                         width: 100%;
                         display: inline-block;
                         text-align: center;
                         color: $black;
                         cursor: pointer;
                         position: relative;
                         @include fontSize(11px);
                         line-height: 14px;
                         padding: 12px;
                         @include border-radius(20px);
                         border: 1px solid rgba(0, 0, 0, 0.1);
                         font-weight: 500;

                         @include themed {
                             background: $bg-grey;
                             border: 1px solid $bg-grey;
                             color: $white;
                         }

                         p {
                             font-weight: 500;
                             @include fontSize(11px);
                             line-height: 13px;
                             padding-top: 12px;
                             opacity: 0.5;
                         }
                     }

                     input:checked~label {
                         @include box-shadow(0px 4px 12px rgba($black, 0.1));
                         color: $white;

                         p {
                             opacity: 0.5;
                         }
                     }

                     input {
                         position: absolute;
                         z-index: 999;
                         width: 100%;
                         height: 100%;
                         opacity: 0;

                         &:hover {
                             cursor: pointer;
                         }
                     }

                     &.plantypes {

                         label {
                             font-weight: 500;
                             @include fontSize(14px);
                             line-height: 20px;
                             text-align: left !important;
                             position: relative;
                             padding-left: 60px;
                             padding-top: 17px;
                             padding-bottom: 17px;

                             span {
                                 font-family: "Montserrat";
                                 font-weight: 400;
                                 @include fontSize(11px);
                                 line-height: 14px;
                                 padding-top: 4px;
                                 display: block;
                             }

                             &:before {
                                 @include position(absolute, $left: 11px, $top: 50%);
                                 height: 26px;
                                 width: 26px;
                                 content: "";
                                 border: 1px solid $primary;
                                 @include border-radius(50%);
                                 transform: translateY(-50%);
                             }
                         }

                         input:checked~label {
                             border: 1px solid $primary;
                             @include border-radius(20px);
                             color: $black;
                             box-shadow: none;

                             @include themed {
                                 color: $white;
                             }

                             p {
                                 opacity: 0.5;
                             }

                             &:before {
                                 background: url("../../../../public/images/checkbox_tick_icon.svg") no-repeat center center/26px;
                                 border: none;
                             }
                         }
                     }
                 }
             }
         }

         // graded section css

         .policyyearSection {
             @include box-shadow(0px 4px 16px rgba($black, 0.1));
             @include border-radius(12px);
             padding: 12px;

             &.gradepadding {
                 padding: 0px !important;
                 margin: 0px 16px 20px;
             }

             h5 {
                 font-weight: 600;
                 @include fontSize(14px);
                 line-height: 23px;
                 color: $primary;
                 font-family: "Montserrat";
                 margin-bottom: 0;
                 padding-top: 8px;
                 max-width: 70%;
                 white-space: nowrap;
                 text-overflow: ellipsis;
                 overflow: hidden;
             }

             h4 {
                 font-weight: 600;
                 @include fontSize(14px);
                 line-height: 23px;
                 color: $primary;
                 font-family: "Montserrat";
                 margin-bottom: 0;
                 padding-top: 8px;
             }

             h6 {
                 font-weight: 500;
                 @include fontSize(12px);
                 line-height: 15px;
                 color: $black;
                 font-family: "Montserrat";
                 margin-bottom: 0;
                 padding-top: 8px;

                 @include themed {
                     color: $text-light;
                 }
             }
         }
     }

     .MuiAccordionSummary-expandIconWrapper {
         @include themed {
             color: $white;
         }
     }
 }