@import "../../../../../../mixins.scss";

.currunciesWrapper {
    padding: 120px 20px 0px !important;

    .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-self: center;

        .switchDetails {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: 10px;
            text-decoration: none;

            p {
                font-family: 'Montserrat';
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #000;
                padding-left: 5px;

                @include themed() {
                    color: $white;
                }
            }

        }

        p {
            @include themed() {
                color: $text-light;
            }
        }
    }



    .example {
        font-family: 'Montserrat';
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        color: $black;
        margin-bottom: 0;
        text-align: left;
    }
}