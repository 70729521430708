@import "../../../../../../../mixins.scss";

.confirmation_content {
    text-align: left;

    p {
        font-family: "Montserrat";
        @include fontSize(14px);
        line-height: 17px;
        font-weight: 400;
        color: #808080;
        margin-bottom: 24px;

        span {
            color: #029d11;
            font-weight: 500;
        }
    }

    ul {
        margin-bottom: 24px;

        li {
            list-style-type: none;
            font-family: "Montserrat";
            @include fontSize(14px);
            line-height: 17px;
            font-weight: 400;
            color: #029d11;
            padding-left: 30px;
            margin-bottom: 14px;
            position: relative;

            &.success {
                position: relative;

                &:before {
                    content: "";
                    background: url("../../../../../../../../public/images/green_tick.svg") // background: url("../../../../../../public/images/green_tick.svg")
                        no-repeat;
                    @include position(absolute, $left: 0, $top: calc(50% - 10px));
                    height: 20px;
                    width: 22px;
                }
            }

            &.error {
                position: relative;
                color: red;

                &:before {
                    content: "";
                    background: url("../../../../../../../../public/images/crossup.svg") no-repeat;
                    @include position(absolute, $left: 0, $top: calc(50% - 10px));
                    height: 20px;
                    width: 22px;
                }
            }

            // &:before {
            //     content: '';
            //     background: url("../../../../../../public/images/green_tick.svg") no-repeat;
            //     @include position (absolute, $left: 0, $top: calc(50% - 10px));
            //     height: 20px;
            //     width: 22px;
            // }
        }
    }

    .ctaBtn {
        .submitBtn {
            padding: 10px 78px;
        }
    }
}