@import "../../../../mixins.scss";

.employeeWrapper {
  padding: 0px 12px;
  min-height: 100vh;

  @include themed {
    background: $bg-dark;
    position: relative;
    z-index: 10;
  }

  .logoCol {
    height: 50px;
    max-width: 270px !important;
    margin-bottom: 12px;
    display: flex;
  }

  a {
    text-decoration: none;
  }

  .menuCol {
    width: 112px;
    max-width: 100px !important;
    z-index: 991;

    .menuList {
      background: $white;
      @include border-radius(12px);
      @include box-shadow(0px 8px 16px rgba($text-dark, 0.2));
      position: relative;
      transition: all ease 0.3s;
      padding-top: 4px;
      padding-bottom: 12px;
      margin-top: 20px;
      width: 64px;

      .expandView {
        @include position(absolute, $right: -10px, $top: 10px);
        background: $primary;
        @include border-radius(12px);
        color: $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 14px;
        width: 14px;
        z-index: 9;

        svg {
          height: 12px;
        }
      }

      &.expand {
        width: 156px;

        button {
          width: 100%;
          overflow: visible;
        }
      }

      button {
        border: none;
        @include fontSize(10px);
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        line-height: 14px;
        color: rgba($color: $text-dark, $alpha: 0.5);
        background-color: transparent;
        cursor: pointer;
        text-align: left;
        position: relative;
        display: block;
        padding: 0px 0px 0px 66px;
        text-transform: capitalize;
        letter-spacing: 0px;
        margin-top: 10px;
        min-height: 50px;
        width: 50px;
        overflow-x: hidden;
        transition: all ease 0.3s;

        @include themed() {
          color: $text-grey;
        }

        &:before {
          content: "";
          position: absolute;
          border-radius: 16px;
          left: 8px;
          top: 2px;
          height: 48px;
          width: 48px;
        }

        $othericons: dashboard, policywallet, riskmap, userprofile, mydocument,
          claimassistant, referafriend, trendingquestions, community, support,
          wellness, doctoronline, repairvehicle, settings, employe, report,
          endorsement, quote, policies, clients, contacts, taskmanagement;
        $k: 0;

        @each $icons in $othericons {
          $bacRightP: $k * 48;
          $k: $k + 1;

          &.#{$icons} {
            &:before {
              background-image: url("../../../../../public/images/tablist_icons.svg");
              background-position: -#{$bacRightP}px 0px;
              background-size: 1056px;
              background-repeat: no-repeat;
            }

            &.active {
              color: $text-dark;

              &:before {
                background-color: #333;
                background-image: url("../../../../../public/images/tablist-active-icons.svg");
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
                opacity: 1;

                @include themed() {
                  @include box-shadow(0px 0px 16px rgba($black, 0.4));
                }
              }
            }
          }
        }
      }
    }
  }

  .navLinks {
    display: flex;
    align-items: center;
    justify-content: end;

    a {
      color: $text-dark;
      margin: 0px 8px;

      svg {
        height: 22px;
      }
    }

    .themeToggle {
      background: rgba($primary, 0.15);
      @include border-radius(20px);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;

      @include themed() {
        background: $black;
      }

      svg {
        height: 18px;
        color: $primary;
      }
    }

    .userDropdown {
      margin-left: 8px;

      button {
        background: #b9c4e1;
        @include border-radius(8px);
        color: $text-dark;
        padding: 0px;
        height: 36px;
        width: 36px;
        overflow: hidden;

        img {
          height: 100%;
        }
      }
    }

    .notificationDropdown {
      margin-left: 8px;
      margin-right: 8px;

      button {
        color: $text-dark;
        padding: 0px;
        height: 36px;
        width: 30px;

        svg {
          height: 30px;
        }
      }
    }
  }

  .pageTitle {
    h2 {
      @include fontSize(36px);
      font-weight: 300;
      margin-bottom: 8px;

      span {
        font-weight: 600;
      }
    }

    p {
      color: lighten($text-dark, 30%);
      font-weight: 300;
    }
  }

  .subHeading {
    h3 {
      @include fontSize(24px);
      font-weight: 300;
      margin-bottom: 8px;

      span {
        font-weight: 600;
      }
    }

    p {
      color: lighten($text-dark, 30%);
      font-weight: 300;
    }
  }

  // Sliding Popup
  .sliding-panel-container {
    position: fixed;
    top: 0;
    left: 0;

    &.active {
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.3);

      // will-change: transform;
      body {
        overflow: hidden;
      }

      .panel {
        // transition: transform 1s ;
        animation: inpanel 0.6s both;
      }

      @keyframes inpanel {
        0% {
          transform: translateX(1000px);
        }

        100% {
          transform: translateX(0px);
        }
      }
    }

    .headingTitle {
      h3 {
        @include fontSize(20px);
        font-weight: 300;
        margin-bottom: 8px;

        span {
          font-weight: 600;
        }
      }

      p {
        @include fontSize(14px);
        color: lighten($text-dark, 30%);
        font-weight: 300;
      }
    }

    .closeIcon {
      color: $black;
      height: 30px;
      width: 30px;
      transition: all ease 0.3s;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: $primary-red;
        transform: rotate(90deg);
        transform-origin: center;
      }
    }

    .glass {
      min-width: calc(100vw - 700px);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
    }

    .panel {
      height: 100%;
      width: 100%;
      max-width: 700px;
      background-color: white;
      animation: outpanel 0.3s both;

      @include themed() {
        background-color: #171b1e;
      }
    }

    @keyframes outpanel {
      0% {
        transform: translateX(0px);
      }

      100% {
        transform: translateX(1000px);
      }
    }

    .panel-content {
      padding: 0px 20px;

      hr {
        border: none;
        border-top: 1px solid $primary-light;
      }

      .scrollable_area {
        padding-top: 5px;
        height: calc(100vh - 160px);
        overflow-y: auto;
        overflow-x: hidden;

        &.lgScroll {
          height: calc(100vh - 230px);
        }

        .productTypeSection {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-bottom: 0px;

          .radioBox {
            width: 74px;
          }
        }

        .cashlessClaim {
          display: flex;
          align-items: center;

          .img_section {
            position: relative;

            $product-colors: ("healthInsurance", #e7384c),
              ("carInsurance", #66ccdb), ("bikeInsurance", #066ac8),
              ("termInsurance", $primary), ("investmentPlan", #ffa412),
              ("travelInsurance", #66b27b);
            $k: 0;

            @each $color in $product-colors {
              $bacRightP: $k * 58;
              $k: $k + 1;

              &.#{nth($color,1)} {
                background: linear-gradient(
                  90deg,
                  rgba(129, 130, 230, 0.2) 0%,
                  rgba(107, 98, 220, 0.2) 100%
                );

                &:before {
                  @include position(
                    absolute,
                    $left: calc(50% - 25px),
                    $top: 4px
                  );
                  content: "";
                  height: 50px;
                  width: 50px;
                  background: url("../../../../../public/images/insurance_product_icons.svg")
                    no-repeat -#{$bacRightP}px
                    0px /340px;
                  z-index: 2;
                }
              }
            }

            &.redbg {
              background: #ffe8ea;
            }

            &.bluebg {
              background: #dbeeff;
            }

            &.accepted {
              background: #e2ffcb;
            }

            &.pending {
              background: #fcecdf;
            }

            @include border-radius(12px);

            padding: 10px;
            width: 60px;
            height: 60px;
            margin-right: 25px;

            img {
              height: 40px;
            }
          }

          .request_heading {
            display: flex;
            align-items: center;

            h5 {
              font-family: "Montserrat", sans-serif;
              @include fontSize(16px);
              font-weight: 500;
              line-height: 20px;
              margin: 0 12px 0 0px;

              @include themed {
                color: $text-light;
              }
            }

            span {
              @include border-radius(20px);
              padding: 6px 16px;
              font-family: "Montserrat", sans-serif;
              @include fontSize(12px);
              font-weight: 500;
              line-height: 14px;

              &.inprogress {
                background: #e4e2ff;
                color: $primary;
              }

              &.accepted {
                background: #e2ffcb;
                color: #529f15;
              }

              &.rejected {
                color: $primary-red;
                background: #ffe3e3;
              }

              &.pending {
                color: #ff9b44;
                background: #fcecdf;
              }
            }

            .view_btn {
              text-transform: capitalize;
              color: $primary;
            }
          }
        }

        .healthretail_policy {
          .healthretail_innersection {
            border: 1px dashed #acd4fb;
            @include border-radius(12px);
            padding: 24px;
            background: linear-gradient(
              90deg,
              #edf1fe 0%,
              rgba(237, 241, 254, 0) 100%
            );
            margin-bottom: 16px;

            ul {
              margin-bottom: 0;
              display: flex;
              justify-content: space-between;

              li {
                list-style-type: none;
                font-family: "Montserrat", sans-serif;
                @include fontSize(12px);
                font-weight: 400;
                line-height: 14px;

                span {
                  display: block;
                  font-weight: 600;
                  padding-top: 5px;
                }
              }
            }
          }
        }

        .policy_number {
          h6 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(12px);
            font-weight: 400;
            line-height: 14px;
            margin-bottom: 0px;

            span {
              font-weight: 600;
            }
          }
        }

        .premium {
          h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(12px);
            font-weight: 400;
            line-height: 14px;
            margin-bottom: 0px;

            span {
              @include fontSize(8px);
              line-height: 9px;
            }

            b {
              color: #ec3f3f;
            }
          }
        }

        .policy_expiry {
          display: flex;

          img {
            margin-right: 8px;
          }

          h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(10px);
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 0;

            span {
              display: block;
              padding-top: 4px;
              @include fontSize(12px);
              font-weight: 600;
            }
          }
        }

        .policy_cover {
          h5 {
            font-family: "Montserrat", sans-serif;
            @include fontSize(12px);
            font-weight: 600;
            line-height: 14px;
            margin-bottom: 5px;
          }

          ul {
            margin-bottom: 0;

            li {
              list-style-type: none;
              font-family: "Montserrat", sans-serif;
              @include fontSize(12px);
              font-weight: 400;
              line-height: 18px;
              padding-left: 20px;
              position: relative;

              &:before {
                content: "";
                background: $black;
                height: 2px;
                width: 2px;
                @include border-radius(100%);
                @include position(absolute, $left: 8px, $top: 7px);
              }
            }
          }
        }

        .retailPolicy_button {
          display: flex;
          justify-content: center;
          margin-bottom: 0;

          li {
            list-style-type: none;
            background: $primary;
            padding: 8px 12px;
            @include border-radius(8px);
            margin-right: 16px;

            .policyBtn {
              font-family: "Montserrat", sans-serif;
              @include fontSize(10px);
              font-weight: 500;
              line-height: 12px;
              color: $white;
              text-transform: capitalize;
              padding-left: 25px;
              position: relative;
              $button-icons: endorsement, claim, download;
              $k: 0;

              @each $icons in $button-icons {
                $bacRightP: $k * 20;
                $k: $k + 1;

                &.#{$icons} {
                  &:before {
                    @include position(
                      absolute,
                      $left: 0px,
                      $top: calc(50% - 10px)
                    );
                    content: "";
                    height: 20px;
                    width: 20px;
                    background: url("../../../../../public/images/policybtn_icon.svg")
                      no-repeat -#{$bacRightP}px
                      0px /60px;
                  }
                }
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .radioBox {
          display: inline-block;
          position: relative;

          span {
            width: 100%;
            display: inline-block;
            cursor: pointer;
            position: relative;
            background: $white;
            @include border-radius(20px);
            box-shadow: 0px 10px 28px 0px rgba(0, 0, 0, 0.1);

            @include themed {
              background: $bg-grey;
              border: 1px solid $bg-grey;
              color: $white;
            }

            &.producttype {
              position: relative;
              padding-top: 76px;

              $button-icons: health, car, bike, term, nonterm, travel;
              $k: 0;

              @each $icons in $button-icons {
                $bacRightP: $k * 50;
                $k: $k + 1;

                &.#{$icons} {
                  &:before {
                    border: none;
                    @include position(
                      absolute,
                      $left: calc(50% - 25px),
                      $top: 10px
                    );
                    content: "";
                    height: 50px;
                    width: 50px;
                    background: url("../../../../../public/images/employee_product_icon.svg")
                      no-repeat -#{$bacRightP}px
                      0px/300px;
                    z-index: 2;
                  }
                }
              }

              &.active {
                background: $primary;
              }
            }
          }

          h6 {
            font-family: "Montserrat";
            text-align: center;
            color: $black;
            cursor: pointer;
            @include fontSize(12px);
            line-height: 14px;
            font-weight: 600;
            margin: 10px 0 0;
          }
        }
      }
      .tpa_exp{
        .tpa_exp_table{
            height: 175px;
            overflow-x: hidden;
        }
      }

    }
  }
}

.notificationMenu {
  .MuiMenu-list {
    padding-top: 0px !important;
    max-height: 300px;
    overflow: hidden;

    .listMenu {
      padding: 40px 12px 4px;
      // position: relative;
      overflow-y: auto;
      max-height: 300px;

      .title {
        @include position(absolute, $left: 0px, $top: 0px);
        background: #edf1fe;
        @include border-radius(8px 10px 0px 0px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        padding: 8px 12px;
        height: 36px;
        width: 100%;

        a {
          @include fontSize(12px);
          text-decoration: none;
          color: $primary;

          &:hover {
            color: $primary-yellow;
          }
        }
      }

      li {
        list-style-type: none;
        border-bottom: 1px solid rgba($black, 0.1);
        padding: 8px 0px;

        &:last-child {
          border-bottom: none;
        }

        h5 {
          @include fontSize(12px);
          font-weight: 600;
          margin-bottom: 2px;
        }

        p {
          @include fontSize(12px);
          color: rgba($black, 0.5);
        }
      }
    }
  }
}
