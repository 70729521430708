@import "../../../../mixins.scss";

.logoCol {
    max-width: 270px !important;
    margin-bottom: 12px;
    display: flex;

    img {
        object-fit: contain;
    }
}

.topNavbar {
    width: calc(100% - 183px);
    margin-bottom: 12px;

    .searchBox {
        position: relative;
        background: rgba(172, 212, 251, 0.1) url("../../../../../public/images/search_icon.svg") no-repeat 17px center/18px;
        border: 1px solid rgba(172, 212, 251, 0.2);
        border-radius: 16px;
        color: $grey-dark;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 14px;
        text-transform: capitalize;
        padding-left: 42px;
        justify-content: start;
        height: 46px;
        width: 320px;
    }

    .searchInput {
        position: relative;
        background: url("../../../../../public/images/search_icon.svg") no-repeat 17px center/18px;
        max-width: 360px;

        input {
            width: 360px;
            height: 46px;
            background: rgba(172, 212, 251, 0.1);
            border: 1px solid rgba(172, 212, 251, 0.2);
            border-radius: 16px;
            font-family: "Montserrat";
            font-weight: 400;
            font-size: 14px;
            padding-left: 39px;

            &:focus-visible {
                // border: 1px solid #ACD4FB;
                border: none;
                outline: none;
            }
        }
    }

    .notificationMenu {
        color: #000;
        display: inline-block;
        margin-right: 20px;
        height: 35px;
        width: 35px;

        .bellIcon {
            background: #fff url("../../../../../public/images/bell_icon.svg") no-repeat center 6px/19px;
            border-radius: 50%;
            position: relative;
            height: 31px;
            width: 31px;

            @include themed() {
                background-color: $black;
                background: $black url("../../../../../public/images/bell_icon_white.svg") no-repeat center 6px/18px;
            }

            // &:hover {
            //   background-color: #eee;
            // }

            .number {
                background-color: #ec3f3f;
                color: #fff;
                border-radius: 50%;
                font-size: 10px;
                position: absolute;
                padding-top: 0px;
                top: 5px;
                right: 4px;
                height: 13px;
                width: 13px;
                z-index: 9;
            }
        }
    }

    .signoutMenu {
        background: url("../../../../../public/images/logout_icon.svg") no-repeat center center/36px;
        @include border-radius(8px);
        color: #000;
        display: inline-block;
        height: 38px;
        width: 50px;

        @include themed() {
            // background-color: $text-grey;
            background: $black url("../../../../../public/images/logout_icon_white.svg") no-repeat center center/36px;

            &:hover {
                background-color: $text-grey;
            }
        }

        &:hover {
            background-color: $white;
        }

        button {
            height: 38px;
            width: 50px;

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.notificationList {
    .MuiMenu-list {
        overflow: hidden;
        max-height: 310px;
        padding-bottom: 0px;
    }

    h5 {
        margin-bottom: 8px;
    }

    .menuWrapper {
        max-height: 226px;
        overflow-y: auto;
        padding-top: 30px;

        h5 {
            background: $white;
            @include position(absolute, $left: 0px, $top: 0px);
            padding: 8px 12px;
            width: 100%;
            z-index: 91;

            @include themed() {
                background: $bg-grey;
                color: $text-light;
            }
        }
    }

    .footer {
        background: $blue-light;
        padding: 5px 12px;

        @include themed() {
            background: $bg-grey;
        }

        .viewall {
            @include fontSize(14px);
            display: inline-block;

            @include themed() {
                color: $text-light;
            }
        }
    }

    .listItem {
        display: block;
        border-top: 1px solid rgba(#000, 0.1);

        &:hover {
            background-color: #F0EFFC;
        }

        h6 {
            font-weight: 500;
            font-size: 12px;
            max-width: 360px;
        }

        p {
            color: rgba(0, 0, 0, 0.7);
            font-weight: 400;
            font-size: 12px;
            max-width: 360px;
        }
    }
}