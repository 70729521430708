@import "../../../mixins.scss";

.cdledger {
  overflow-x: auto;

  .cdledger-table {
    width: 100%;
    min-width: 2420px;

    .heading {
      display: flex;
      justify-content: space-between;
      background: #e4f1ff;
      margin-bottom: 0px;
      @include border-radius(4px 4px 0px 0px);

      @include themed {
        background: $bg-grey;
      }

      li {
        list-style-type: none;
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(12px);
        line-height: 20px;
        margin-bottom: 0;
        width: 250px;
        min-width: 220px;
        min-height: 40px;
        color: $black;
        display: flex;
        justify-content: space-between;
        padding: 15px 12px;
        flex: 1;
        line-break: anywhere;

        @include themed {
          background: $bg-grey;
          color: $text-grey;
        }

        .sorting-btn {
          background: transparent;
          margin-left: auto;
          position: relative;

          width: 21px;
          height: 21px;
          border-radius: 50%;

          &:before {
            position: absolute;
            content: "";
            background: url("../../../../public/images/sorting_btn.svg")
              no-repeat 0 0 /100%;
            top: 5px;
            right: 7px;
            width: 7px;
            height: 12px;

            @include themed {
              background: url("../../../../public/images/sorting_btn_white.svg")
                no-repeat 0 0 /100%;
            }
          }

          img {
            height: 11px;
          }
        }
      }
    }

    .subheading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0px;
      border-bottom: 1px solid $blue-light;

      @include themed {
        border-bottom: 1px solid $bg-grey;
      }

      li {
        list-style-type: none;
        min-height: 40px;
        padding: 13px 12px;
        font-family: "Montserrat";
        font-weight: 600;
        width: 250px;
        min-width: 220px;
        @include fontSize(12px);
        line-height: 24px;
        margin-bottom: 0px;
        color: rgba($black, 0.5);
        flex: 1;

        @include themed {
          color: $text-light !important;
        }
      }
    }
  }
}
