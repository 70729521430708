@import "../../../../mixins.scss";

.riskmapWrapper {
    .riskCover {
        background: $white;
        @include border-radius(0px 0px 12px 12px);
        @include box-shadow(0px 10px 12px rgba($primary, 0.1));
        padding: 16px ;
        display: flex;
        align-items: center;
        margin-top: 24px;
        font-family: "Montserrat", sans-serif;
        height: 210px;
        .svg-pi-wrapper {
            // transform: scale(1.4);
            margin-left: 24px;
            .meterbox {
                .niddle {
                    left:0px;
                }
            }
        }
        .scoreMeter {
            width: 280px;
            .App {
                margin: 0px auto;
                width: 180px;
            }
            p {
                text-align: center;
                color: $primary-orange;
                font-weight: 600;
            }
        }
        .riskScore {
            background: linear-gradient(90deg, #EDF1FE 0%, rgba(237, 241, 254, 0) 100%);
            border: 1px dashed #ACD4FB;
            @include border-radius(12px);
            margin-left: 20px;
            padding: 12px 24px;
            position: relative;
            width: 100%;
            h3 {
                @include fontSize(24px);
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                button {
                    color: $primary-green;
                    svg {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
            p {
                font-family: "Roboto", sans-serif;
                line-height: 24px;
                width: 60%;
            }
            .analyzer {
                @include position(absolute, $right:12px, $bottom:0px);
                width: 260px;
            }
        }
    }
    .insuranceAdvisory {
        margin-top: 24px;
        margin-bottom: 24px;
        .recommendation {
            @include border-radius(12px 12px 0px 0px);
            background: linear-gradient(180deg, rgba($primary-yellow, 0.27) 0%, rgba($white, 0) 100%);
            padding: 20px;
            text-align: center;
            width: 260px;
            margin: 0px auto 32px;
            ul {
                display: flex;
                font-family: "Montserrat", sans-serif;
                margin-bottom: 36px;
                li {
                    @include fontSize(12px);
                    list-style-type: none;
                    line-height: 20px;
                    text-align: left;
                    width: 50%;
                    span {
                        font-weight: 600;
                    }
                }
            }
            h4 {
                .morderate {
                    color:$primary-orange;
                }
                .high {
                    color:$primary-red;
                }
            }
            .buyPolicy {
                background: $primary;
                @include border-radius(50px);
                @include box-shadow(0px 4px 12px rgba($primary, 0.3));
                color: $white;
                cursor: pointer;
                font-family: "Montserrat", sans-serif;
                padding: 8px 20px;
                text-transform: capitalize;
                &:hover {
                    @include box-shadow(0px 0px 0px rgba($primary, 0));
                }
            }
        }
        h3 {
            @include fontSize(20px);
            display: flex;
            align-items: center;
            .tip {
                background: url("../../../../../public/images/ev-orange-icon.svg") no-repeat 10px 6px/11px;
                border: 1px dashed $primary-yellow;
                @include border-radius(4px);
                color: $primary-yellow;
                cursor: pointer;
                padding: 4px 10px 4px 26px;
                position: relative;
                @include fontSize(14px);
                margin-left: 12px;
                &:hover {
                    .tipInfo {
                        display: inherit;
                    }
                }
                .tipInfo {
                    @include position(absolute, $right:0px, $top:40px);
                    background: $text-dark;
                    @include border-radius(12px);
                    @include box-shadow(0px 4px 16px rgba($text-dark, 0.3));
                    color: $white;
                    display: none;
                    @include fontSize(12px);
                    font-family: "Roboto";
                    font-weight: 400;
                    text-align: justify;
                    line-height: 16px;
                    padding: 12px 16px;
                    width: 360px;
                    &:before {
                        @include position(absolute, $right: 20px, $top: -8px);
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: 8px solid $text-dark;
                    }
                }
            }
        }
        p {
            color: rgba($text-dark, 0.6);
        }
        .productImg {
            display: block;
            height: 204px;
            margin: 0px auto 24px;
        }
        .protectionShield {
            background: $blue-light url("../../../../../public/images/shield-icon.svg") no-repeat 20px 12px/16px;
            @include border-radius(100px);
            font-family: "Montserrat", sans-serif;
            @include fontSize(14px);
            padding: 13px 24px 13px 48px;
            margin-bottom: 20px;
            span {
                font-weight: 600;
            }
        }
    }
}