@import "../../../mixins.scss";

.detailSection {
  border: 1px solid $primary-light;
  @include border-radius(12px);
  // overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 12px;

  @include themed {
    background: $bg-dark;
    border: none;
  }

  .sectionTitle {
    background: $blue-light;
    display: flex;
    justify-content: space-between;
    padding: 12px 12px;
    margin-bottom: 15px;
    border-radius: 12px 12px 0 0;

    @include themed {
      background: $black;
    }

    h4 {
      color: $primary;
      @include fontSize(14px);
      font-weight: 500;
      margin-bottom: 0px;

      @include themed {
        color: $text-grey;
      }
    }

    .actionBtns {
      .edit_btn {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(14px);
        line-height: 20px;
        color: $primary;
        outline: none;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;

        .edit_btn_icon {
          position: relative;
          top: -2px;
          height: 12px;
          margin-right: 4px;
        }
      }

      .addPremium {
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(14px);
        line-height: 20px;
        color: $primary;
        outline: none;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
        position: relative;
        padding: 2px 0 0 28px;

        &.addfile {
          &:before {
            position: absolute;
            left: 1px;
            top: 0px;
          }
        }
      }
    }
  }

  .MuiGrid2-container {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .employee_detailpolicy {
    .Accordion_section {
      background: $white;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      @include border-radius(12px !important);
      margin-bottom: 10px;
      animation: fadeIn 1s both;

      @include themed {
        background-color: $bg-dark;
        border: 1px solid $bg-grey;
      }

      &:before {
        background: transparent;
      }

      &.Mui-expanded {
        margin: 0;
      }

      .MuiAccordionSummary-root {
        .MuiAccordionSummary-content {
          margin: 0;
        }

        &.Mui-expanded {
          //  max-height: 48px;
          min-height: 48px;
        }
      }

      .policies_detail {
        display: flex;
        justify-content: space-between;

        h4 {
          font-family: "Montserrat";
          font-weight: 400;
          @include fontSize(10px);
          line-height: 12px;
          color: $primary;

          span {
            display: block;
            color: $black;
            padding-top: 8px;
            font-weight: 700;
            @include fontSize(12px);
            line-height: 15px;

            @include themed {
              color: $text-light;
            }
          }
        }

        .subHeading {
          @include fontSize(14px);
          font-weight: 600;
          line-height: 14px;
          margin: 0px 0px 12px 0px;
          position: relative;

          @include themed {
            color: $text-grey;
          }

          &:before {
            content: "";
            @include position(absolute, $top: 30px, $left: 0px);
            width: 200px;
            height: 1px;
            background: $primary;
          }
        }
      }

      .employeeDetailtable {
        overflow-x: auto;

        .employeePolicy_table {
          width: 100%;
          min-width: 2200px;

          .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $blue-light;
            background: rgba(228, 241, 255, 0.4);

            @include border-radius(4px 4px 0px 0px);
            margin-bottom: 8px;
            width: 100%;

            @include themed {
              background: $bg-grey !important;
              border-bottom: 1px solid $bg-grey;
            }

            li {
              list-style-type: none;
              font-family: "Montserrat";
              font-weight: 600;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0;
              min-width: 200px;
              height: auto;
              color: $black;
              padding: 10px 12px;
              flex: 1;

              @include themed {
                background: $bg-grey !important;
                color: $text-grey;
              }

              @include themed {
                background: $bg-grey !important;
                color: $text-grey;
              }
            }
          }

          .subheading {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            border-bottom: 1px solid $blue-light;
            width: 100%;

            @include themed {
              border-bottom: 1px solid $bg-grey;
            }

            &:last-child {
              border-bottom: 0;
            }

            li {
              list-style-type: none;
              min-height: 50px;
              padding: 13px 12px;
              font-family: "Montserrat";
              font-weight: 500;
              min-width: 200px;
              @include fontSize(12px);
              line-height: 24px;
              margin-bottom: 0px;
              color: $black;
              flex: 1;

              @include themed {
                color: $text-light !important;
              }
            }
          }
        }
      }
    }

    .employee_policiesSection {
      @include box-shadow(0px 4px 16px rgba($black, 0.1));
      @include border-radius(12px);
      padding: 12px;

      &.accordion_padding {
        padding: 0px !important;
        margin: 0px 16px 20px;
      }

      h5 {
        font-weight: 600;
        @include fontSize(14px);
        line-height: 23px;
        color: $primary;
        font-family: "Montserrat";
        margin-bottom: 0;
        padding-top: 8px;
        max-width: 70%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      h6 {
        font-weight: 500;
        @include fontSize(12px);
        line-height: 15px;
        color: $black;
        font-family: "Montserrat";
        margin-bottom: 0;
        padding-top: 8px;

        @include themed {
          color: $text-light;
        }
      }
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    @include themed {
      color: $white;
    }
  }
}
