@import "../../../../mixins.scss";
$blue: #2160aa;
$bg: #2160aa1a;
$white: #fff;

.loginWrapper {
  max-height: 100vh;
  min-height: 100vh;
  background-color: $white;

  .leftcol {
    background: #2160aa1a;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;

    img {
      height: 100%;
      max-height: 560px;
    }
  }

  .formsection {
    display: flex;
    align-items: center;
    justify-content: center;

    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translate3d(0, -12%, 0);
      }

      to {
        opacity: 1;
        transform: translateZ(0);
      }
    }

    .innerWrapper {
      text-align: center;
      width: 470px;
      opacity: 0;
      animation: fadeIn 1s both;

      h2 {
        font-size: 27px;
        font-weight: 600;
        margin-bottom: 16px;
      }

      p {
        color: #6f6f6f;
        line-height: 24px;
        font-size: 14px;
      }

      h6 {
        font-size: 16px;
        font-weight: 400;
        color: #000;
      }

      .error_display_div {
        display: flex;
        justify-content: center;

        .error_display {
          width: fit-content;
          display: flex;
          align-items: center;
          color: $primary-red;
          @include fontSize(12px);
          font-weight: 400;
          line-height: 15px;
          border: 1px solid $primary-red;
          padding: 8px 10px;
          @include border-radius(6px);
          background: #ffdddd;
        }
      }

      .formFields {
        margin: 20px auto;
        max-width: 320px;
      }

      .forgotpassword_link {
        text-align: right;
        button {
          color: #2160aa !important;
        }
      }
    }
  }
}

.m_login {
  .header {
    background: #fff3f9;
    display: flex;
    justify-content: center;
    padding: 40px 0 20px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &:before {
      content: "";
      @include position(absolute, $top: 68%, $left: 0);
      background: $white;
      height: 70%;
      width: 100%;
      border-radius: 50% 50% 0 0;
      z-index: 2;
    }

    > img {
      z-index: 2;
    }
  }

  .head {
    font-family: Hind;
    font-size: 24px;
    font-weight: 600;
    line-height: 38.42px;
  }

  .para {
    font-family: Hind;
    font-size: 14px;
    font-weight: 300;
    line-height: 22.41px;
    color: #5b5b5b;
  }

  .error_display_div {
    display: flex;
    justify-content: center;

    .error_display {
      text-align: center;
      width: fit-content;
      display: flex;
      align-items: center;
      color: $primary-red;
      @include fontSize(12px);
      font-weight: 400;
      line-height: 15px;
      border: 1px solid $primary-red;
      padding: 8px 10px;
      @include border-radius(6px);
      background: #ffdddd;
    }
  }

  .fw_link {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    color: $blue;
    text-decoration: none;
  }

  .mSubmit {
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    background: linear-gradient(270deg, #ce5d9a 3.77%, #e88a81 90.8%);
    color: $white;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
  }

  .mcaptcha {
    display: flex;
    justify-content: center;

    .mcaptcha_div {
      max-width: 300px;

      > iframe {
        max-width: 250px;
      }
    }
  }

  .resetPw {
    margin-top: 100px;
  }

  .confirmation_content {
    > p {
      margin-bottom: 16px;
      font-size: 14px;
    }

    > ul {
      li {
        font-size: 12px;
      }
    }
  }
}
