@import "../../../../../mixins.scss";

.choosePolicy_wrapper {
  .row {
    max-width: 1424px;
    margin: 0 auto;
  }

  .backBtn {
    svg {
      cursor: pointer;
      position: relative;
      transition: all 0.5s ease;
      left: 0;
      margin-right: 20px;

      &:hover {
        left: -4px;
        color: #ec3f3f;
      }
    }

    span {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(34px);
      line-height: 41px;
    }
  }
  .pay_container {
    border: "1px solid green";
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    // background: red;
    box-shadow: 0px 8px 20px 0px rgba(107, 98, 220, 0.2);
    border-radius: 20px;
    .heading {
      font-family: Montserrat;
      font-size: 20px !important;
      font-weight: 600 !important;
      line-height: 24.38px;
      text-align: left;
      border: "1px solid green";
      padding: 0;
      margin: 0;
    }
    hr {
      border: 1px solid #0000001a;
    }
    .payment_container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        gap: 16px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: Montserrat;
          text-transform: capitalize;

          .name {
            font-size: 14px;
            font-weight: 400;
            line-height: 17.07px;
          }
          .value {
            font-size: 16px;
            font-weight: 500;
            line-height: 19.5px;
          }
        }
      }
      ul.total_payment {
        padding: 12px;
        gap: 12px;
        border-radius: 10px;
        background: #ebeaff;
      }
    }
  }

  .paysection-outer {
    position: sticky;
    top: 12px;
    .pay_section {
      @include border-radius(20px);
      background: $white;
      box-shadow: 0px 8px 20px 0px rgba(107, 98, 220, 0.2);
      padding: 24px;
      margin-bottom: 20px;
      @media (max-width: 599px) {
        padding: 10px;
      }

      > h5 {
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(20px);
        line-height: 29px;
        margin-bottom: 0;
        @media (max-width: 599px) {
          font-size: 16px;
          font-weight: 600;
          line-height: 19.5px;
          text-align: left;
          margin-bottom: 15px;
          margin-left: 9px;
        }
      }

      hr {
        color: $black;
        opacity: 10%;
        margin: 24px 0;
      }

      .pay_list {
        margin-bottom: 0;

        li {
          list-style-type: none;
          display: flex;
          justify-content: space-between;
          font-family: "Montserrat";
          font-weight: 400;
          @include fontSize(14px);
          line-height: 17px;
          margin-bottom: 16px;
          gap: 12px;

          .name {
            width: 60%;
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
            // text-transform: capitalize;
          }
          .price {
            width: 40%;
            font-weight: 500;
            @include fontSize(14px);
            line-height: 20px;
          }
        }

        .total_payment {
          background: #ebeaff;
          @include border-radius(10px);
          padding: 15px 14px;
          font-weight: 600;
          @include fontSize(14px);
          line-height: 17px;

          span {
            @include fontSize(16px);
            line-height: 20px;
          }
        }
      }

      img {
        height: 243px;
      }
    }
  }

  .ctaBtn {
    .submitBtn {
      width: 100%;
    }
  }

  .Accordion_section {
    background: $white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    @include border-radius(20px !important);
    margin-bottom: 24px !important;
    padding: 0 12px;

    @include themed {
      background-color: $bg-dark;
      border: 1px solid $bg-grey;
    }

    &:before {
      background: transparent;
    }

    &.Mui-expanded {
      margin: 0;
    }

    .MuiAccordionSummary-root {
      .MuiAccordionSummary-content {
        margin: 0;
      }

      &.Mui-expanded {
        // max-height: 48px;
        min-height: 48px;
      }
    }

    h5 {
      font-family: "Montserrat";
      font-weight: 600;
      @include fontSize(20px);
      line-height: 29px;
      margin-bottom: 0;
      // padding-top: 10px;

      @include themed {
        color: $text-light;
      }
    }

    hr {
      color: $black;
      opacity: 10%;
      margin: 0;
    }

    .policy_calender {
      margin-bottom: 0;
      display: flex;
      flex-wrap: wrap;

      li {
        list-style-type: none;
        font-family: "Montserrat";
        font-weight: 400;
        @include fontSize(10px);
        line-height: 18px;
        color: #30364d;
        padding-left: 48px;
        min-width: 160px;
        position: relative;

        span {
          display: block;
          padding-top: 4px;
          font-weight: 600;
          @include fontSize(12px);
          line-height: 14px;
        }

        $button-icons: lastdate, startdate;
        $k: 0;

        @each $icons in $button-icons {
          $bacRightP: $k * 40;
          $k: $k + 1;

          &.#{$icons} {
            &:before {
              position: absolute;
              content: "";
              height: 40px;
              width: 40px;
              top: 0px;
              left: 0;
              background: url("../../../../../../public/images/policy_calendar_icons.svg")
                no-repeat -#{$bacRightP}px
                0px/80px;
            }
          }
        }
      }
    }

    .coverage_section {
      border: 1px dashed #acd4fb;
      @include border-radius(12px);
      padding: 12px 24px;
      background: #f4f6fe;
      @media (max-width: 599px) {
        padding: 12px;
      }
    }

    .sum_insured {
      h6 {
        font-family: "Montserrat";
        font-weight: 400;
        @include fontSize(12px);
        line-height: 14px;
        margin-bottom: 0;

        span {
          display: block;
          padding-top: 5px;
          font-weight: 600;
         
        }
      }
    }

    .payment_config {
      display: flex;
      align-items: center;

      h5 {
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(12px);
        line-height: 14px;
        margin-bottom: 0;
      }

      img {
        height: 42px;
        margin-right: 8px;
      }
    }

    .choose_dependents {
      h5 {
        font-family: "Montserrat";
        font-weight: 600;
        @include fontSize(14px);
        line-height: 17px;
        margin-bottom: 0;
      }
      @media (max-width: 599px) {
        padding: 0 12px;
      }

      .dependent_list {
        display: flex;
        flex-wrap: wrap;
        margin: 0px;
        padding: 15px 0 0 0;
        @media (max-width: 599px) {
          // justify-content: space-between;
          column-gap: 20px;
          row-gap: 16px;
          padding: 12px 0 0 0;
        }

        li {
          list-style: none;
         margin-right: 16px;
         margin-bottom: 16px;
         @media(max-width:599px){
          margin-right: 0 !important;
          width: calc(50% - 10px);
          margin-bottom: 0 !important;
         }

          &:last-child {
            //margin-right: 0;
            margin-bottom: 20px !important;

          }
          
          @media (max-width: 1100px) {
            margin-right: 16px;
            margin-bottom: 16px;
          }

          @media (max-width: 1310px) {
           // margin-bottom: 16px;
          }

          .Checkbox {
            display: inline-block;
            position: relative;
            @media(max-width:599px){
              width: 100%;

            }
            label {
              font-family: "Montserrat";
              display: inline-block;
              text-align: center;
              color: $black;
              cursor: pointer;
              position: relative;
              @include border-radius(12px);
              border: 1px solid $primary-light;
              width: 153px;
              height: 151px;
              @media (max-width: 599px) {
                // width: 160px;
                width: 100%;
                height: 144px;
              }

              &.add_member {
                border: 1px solid $primary;
                padding: 10px;
                // @media(max-width:599px){

                // }

                &:after {
                  display: none;
                }

                p {
                  position: absolute;
                  bottom: 45px;
                  @media(max-width:599px){
                    bottom: 20px;
                  }
                }
              }

              @include themed {
                background: $bg-grey;
                border-color: $bg-grey;
              }

              &:after {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                border: 1px solid $primary;
                @include border-radius(50%);
                left: 10px;
                top: 7px;
              }

              p {
                position: absolute;
                bottom: 12px;
                left: 0;
                width: 100%;
                @include fontSize(14px);
                line-height: 17px;
                font-weight: 500;

                @include themed {
                  color: $text-light;
                }

                span {
                  display: block;
                  padding-top: 2px;
                  @include fontSize(12px);
                  line-height: 14px;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.5);
                }
              }

              // $button-icons: super, health, critical, accident;
              $button-icons:father-in-law,mother-in-law,husband,wife,brother,sister,son,daughter;
              $k: 0;

              @each $icons in $button-icons {
                $bacRightP: $k * 70;
                $k: $k + 1;

                &.#{$icons} {
                  &:before {
                    position: absolute;
                    // border:2px solid red;
                    content: "";
                    height: 70px;
                    // overflow: hidden;
                    width: 70px;
                    top: 10px;
                    left: calc(50% - 35px);
                    border-radius: 50%;
                    background: url("../../../../../../public/images/family-members.svg")
                      no-repeat -#{$bacRightP}px
                      0px/280px;
                      background-size: cover;
                  }
                }
              }

              // jjjjjj
              $button-icons2: father-in-law,mother-in-law,husband,wife,brother,sister,son,daughter;
              $k: 0;

              @each $icons2 in $button-icons2 {
                $bacRightP: $k * 70;
                $k: $k + 1;

                &.#{$icons2} {
                  &:before {
                    position: absolute;
                    content: "";
                    height: 70px;
                    width: 70px;
                    top: 10px;
                    left: calc(50% - 35px);
                    border-radius: 50%;
                    background: url("../../../../../../public/images/family-members.svg")
                      no-repeat -#{$bacRightP}px
                      0px/280px;
                      background-size: cover;
                  }
                }
              }
            }

            input:checked ~ label {
              border: 1px solid $primary;
              @include box-shadow(0px -4px 12px rgba($black, 0.1));
              color: $black;

              &:after {
                position: absolute;
                content: "";
                height: 22px;
                width: 22px;
                background: url("../../../../../../public/images/checkbox_tick_icon.svg")
                  no-repeat center center/100%;
                @include border-radius(50%);
                left: 10px;
                top: 10px;
                border: none;
              }
            }

            input {
              position: absolute;
              z-index: 999;
              width: 100%;
              height: 100%;
              opacity: 0;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      // @media (max-width: 599px) {
      //   .dependent_list {
      //     .Checkbox,
      //     li {
      //       width: 160px;
      //       height: 144px;
      //     }
      //   }
      // }
    }

    .feature_exclusion_btn {
      @media (max-width: 599px) {
        display: flex;
        justify-content: space-between;
        
      }
      .featureBtn {
        border: 1px solid;
        background: $white;
        @include border-radius(10px);
        color: $primary;
        border: 1px solid $primary;
        font-family: "Montserrat";
        font-weight: 500;
        @include fontSize(16px);
        line-height: 19px;
        padding: 13px 24px;
        box-shadow: none;
        text-transform: capitalize;
        position: relative;
      @media(max-width:599px){
        width: 48%;
      }
        &:hover {
          background: $primary;
          color: $white;
        }

        img {
          margin-right: 10px;
          width: 25px;
          height: 30px;
          @media(max-width:599px){
            width: 30px;
            height: 30px;
          }
        }
        // @media (max-width: 599px) {
        //   text-align: left;
        //   font-size: 10px;
        //   padding: 13px 15px;
        //   margin: 0 !important;
        // }
      }
      @media (max-width: 599px) {
        display: flex;
        // gap: 10px;
        font-size: 10px;
        text-align: left;

        //padding: 13px 15px;
        margin: 0 !important;
        // justify-content: space-between;
        .featureBtn {
          // padding: ;
          font-size: 12px;
          padding: 10px 12px;
          margin: 0;
          
          //padding: 10px;
          img {
            padding: 0;
          }
        }
      }
    }
  }
}

.m_policy_calender {
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    list-style-type: none;
    font-family: "Montserrat";
    font-weight: 400;
    @include fontSize(10px);
    line-height: 18px;
    color: #717174;
    padding-left: 48px;

    position: relative;

    width: 50%;
    @media (max-width: 599px) {
      padding-left: 48px;
    }

    span {
      display: block;
      padding-top: 4px;
      font-weight: 600;
      @include fontSize(12px);
      line-height: 14px;
    }

    $button-icons: lastdate, startdate;
    $k: 0;

    @each $icons in $button-icons {
      $bacRightP: $k * 40;
      $k: $k + 1;

      &.#{$icons} {
        &:before {
          position: absolute;
          content: "";
          height: 40px;
          width: 40px;
          top: 0px;
          left: 0;
          background: url("../../../../../../public/images/policy_calendar_icons.svg")
            no-repeat -#{$bacRightP}px
            0px/80px;
        }
      }
    }
  }
}

.m_payment_config {
  display: flex;
  flex-direction: column !important;
  align-items: start;

  h5 {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 10px !important;
    line-height: 14px;
    margin-bottom: 0;
  }

  img {
    height: 42px;
    margin-right: 8px;
  }
}

.riders_cont {
  position: relative;
  .switch {
    position: absolute;
    top: 6px;
    right: 12px;
  }
  h6 {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: left;
    margin-bottom: 12px;
  }
  .rider_sa {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-bottom: 4px;
  }
  .rider_amount {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: left;
    margin-bottom: 16px;
  }
  .description-heading {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
  }
  .description-text {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.63px;
    text-align: left;
  }
}

.dotted_border {
  border: 1px dashed #acd4fb;
  border-radius: 12px;
}

.rider-text-light {
  font-weight: 400;
  color: #7b7c7e;
}

.policy_pay_text {
  font-family: Montserrat;
font-size: 12px;
font-weight: 700;
line-height: 18px;
text-align: left;
padding-top: 12px !important;
padding-bottom: 0 !important;


}

.calculate_policy_amount {
  .Accordion_section {
    // border: 1px solid #0000001A;
    box-shadow: none;
  }
}