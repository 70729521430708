@import "../mixins.scss";


.inputField2 {
  input {
    border: 1px solid #0000001A;
    background: #fff !important;
  }

  .MuiInputLabel-shrink {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 4px;
  }
}

.inputField {
  position: relative;
  margin-bottom: 4px;

  label,
  input {
    font-family: "Montserrat";
    font-weight: 500;
    letter-spacing: 0px;
    @include fontSize(13px);

    @include themed() {
      color: $text-light;
    }
  }

  &.datepicker {

    // &:before {
    //   @include position(absolute, $top: calc(50% - 6px), $right:12px);
    //   content: "";
    //   background: url("./../../public/images/calender_icon.svg") no-repeat 0 0 /13px;
    //   width: 13px;
    //   height: 13px;
    //   z-index: 9;
    // }
    .MuiInputBase-root {
      @include themed {
        background: #333;
        border-radius: 8px;
      }
    }

    input {
      @include themed {
        color: $text-light !important;
      }
    }
  }

  label {
    transform: translate(14px, 8px) scale(1);
    top: 2px;
    text-transform: capitalize;

    span {
      color: $primary-red;
    }
  }

  .infoMessage {
    background: $white;
    @include border-radius(20px);
    @include position(absolute, $left: -7px, $top: 9px);
    height: 16px;
    width: 16px;
    z-index: 9;

    svg {
      color: $grey-light;
      display: inherit;
      @include fontSize(14px);
      margin-top: 1px;
      margin-left: 1px;
    }
  }

  input {
    // height: 20px;
    @include border-radius(8px);
    background: transparent;
    padding: 11px 14px 9px;

    @include themed() {
      background: $bg-grey;
    }

    &.Mui-disabled {
      @include themed {
        color: white !important;
        -webkit-text-fill-color: #888686;
      }
    }
  }

  .MuiChip-root {
    @include themed {
      border: 1px solid $white;
    }

    .MuiChip-label {
      @include themed {
        color: $white;
      }
    }

    svg {
      @include themed {
        color: $white !important;
      }
    }
  }

  .MuiInputBase-adornedStart {
    .MuiInputBase-inputAdornedStart {
      padding-left: 0px !important;
    }
  }

  .MuiAutocomplete-root {
    .MuiAutocomplete-inputRoot {
      @include border-radius(8px);
      padding: 0px 0px 0px 8px;
      min-height: 38.6px;

      &.Mui-disabled {
        input {
          @include themed {
            color: white !important;
            -webkit-text-fill-color: #888686;
          }
        }
      }
    }

    // .MuiInputBase-root {
    //   @include themed {
    //     background: $bg-grey;
    //   }
    // }

    .MuiAutocomplete-clearIndicator {
      background: $white;
      height: 18px;
      width: 18px;
      border: 1px solid $text-grey;

      @include themed() {
        background: $bg-dark;
        border: none;
      }

      svg {
        display: block;
        height: 12px;
        color: $primary-red;
      }
    }
  }

  .MuiAutocomplete-popper {
    // @include box-shadow(0px 4px 12px rgba(0, 0, 0, 0.2) !important);
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
    @include border-radius(0px 0px 8px 8px);
    position: absolute;
    background: white;
    // z-index: 99999!important;

    .MuiAutocomplete-paper {
      @include box-shadow(0px 4px 12px rgba(0, 0, 0, 0));

      @include themed {
        background-color: $text-grey;
      }
    }

    ul {
      // @include box-shadow(0px 4px 12px rgba(0,0,0,0.2));
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      max-height: 244px;
      overflow-y: auto;
      // z-index: 999;
    }

    li {
      @include fontSize(14px);
      line-height: 16px;
    }
  }

  .MuiInputLabel-shrink {
    color: $primary !important;
    transform: translate(14px, -9px) scale(0.75);
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $primary !important;
    }

    .MuiSelect-select {
      &:before {
        border: solid $primary-color;
        border-width: 0px 0px 1.4px 1.4px;
        transform: rotate(136deg);
        top: 22px;
      }

      &.Mui-disabled {
        @include themed {
          color: white !important;
          -webkit-text-fill-color: #888686;
        }
      }
    }
  }

  .MuiSelect-select {
    &.Mui-disabled {
      @include themed {
        color: white !important;
        -webkit-text-fill-color: #888686;
      }
    }
  }

  .MuiAutocomplete-hasClearIcon {
    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        height: auto;
        @include border-radius(8px);
        padding: 4px 0px 1px 8px;

        .MuiButtonBase-root {
          height: 20px;

          &.MuiAutocomplete-clearIndicator {
            display: none;
          }

          .MuiChip-label {
            @include fontSize(10px);
          }

          &#ArrowDropDownIcon {
            display: none !important;
          }

          svg {
            display: none;
          }

          .MuiTouchRipple-root {
            display: none;
          }

          .MuiChip-deleteIcon {
            color: rgba(0, 0, 0, 0.26);
            @include fontSize(18px);
            cursor: pointer;
            margin: 0px 2px 0 -6px;
            display: block;
          }
        }

        .MuiAutocomplete-input {
          min-width: 0px !important;
        }
      }
    }
  }

  &.react-multiemail-box {
    position: relative;

    &.not_allowed {
      position: relative;

      &:before {
        position: absolute;
        width: 100%;
        content: '';
        height: 100%;
        background: transparent;
        z-index: 99;
        cursor: not-allowed;
      }
    }

    .react-multi-email {
      border-radius: 8px;
      background: transparent;
      padding: 5px 14px;

      @include themed {
        background: $bg-grey;
      }

      &.focused {
        border-color: $primary !important;
      }

      &:active {
        border-color: $primary !important;
      }

      &:hover {
        border-color: rgba(0, 0, 0, 0.87) !important;
      }

      &.empty {
        span[data-placeholder] {
          color: rgba(0, 0, 0, 0.6);

          @include themed {
            color: $text-light;
          }
        }
      }

      .ug_email {
        margin: 0;
        font-family: "Montserrat";
        font-weight: 500;
        letter-spacing: 0px;
        @include fontSize(13px);
        padding: 5px;
        margin-right: 5px;
        color: $black;

        @include themed() {
          color: $text-light;
          background: $bg-dark;
        }
      }

    }

    .label {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      transition: top 0.3s ease, transform 0.3s ease;
      pointer-events: none;
      font-family: "Montserrat";
      font-weight: 500;
      letter-spacing: 0px;
      @include fontSize(13px);
      color: #666666;

      @include themed() {
        color: $text-light;
      }
    }

    &.focused .label {
      top: -1px;
      transform: translate(-8px, -8px) scale(0.80);
      color: $primary;
      background: white;
      padding: 0 4px 0 4px;
    }

  }

  &.multiline {
    .MuiInputBase-root {
      @include themed {
        background: $bg-grey;
        @include border-radius(8px);
      }
    }

    .MuiInputBase-input {
      height: 100px !important;
      @include fontSize(14px);

      @include themed {
        color: $text-light;
        ;
      }
    }
  }

  // only for Datepicker
  .MuiInputBase-readOnly .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.1) !important;
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.6) !important;
  }

}

.inputFieldMultipleline {
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-multiline{
    padding: 8px 14px !important;
    height: 39px;
  }
}

.textArea {
  .MuiInputBase-root {
    padding: 8px 14px !important;
  }
}

// Checkbox
.checkBox {
  display: block;

  input {
    display: none;
  }

  label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    padding-left: 28px;
    line-height: 16px;
    font-size: 14px;
    font-family: "Montserrat";

    @include themed {
      color: $white;
    }

    &:before {
      content: "";
      @include border-radius(4px);
      background-color: transparent;
      border: 1px solid $grey-light;
      @include abs-position(-0.5px, null, null, 0px);
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
  }

  input:checked+label {
    color: $sky-blue;

    &:before {
      border: 1px solid $sky-blue;
    }
  }

  input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 3px;
    height: 7px;
    border: solid $sky-blue;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.inputGroup {
  width: 100%;
  position: relative;
  font-family: "Montserrat";
  font-weight: 500;

  label {
    color: $black;
    padding: 18px 10px;
    @include fontSize(16px);
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
    pointer-events: none;
    margin: 0px;
  }

  %label-movement {
    // Move up, colorize and shrink
    @include fontSize(12px);
    color: $primary;
    top: -25px;
    left: 5px;
    background: $white;
    font-weight: 500;
    transition: all 0.125s cubic-bezier(0.2, 0, 0.03, 1);
  }

  input {
    background: transparent;
    border: 0;
    height: 54px;
    width: 100%;
    @include fontSize(16px);
    font-weight: 600;
    color: rgba($black, 0.87);
    padding: 15px 14px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
      border: 1px solid $black;
    }

    &:focus {
      // Trigger the effects
      outline: 0;
      border: 1px solid $primary;

      ~label {
        // Move Label
        @extend %label-movement;
      }
    }

    &:valid~label

    /*, &.not-empty ~ label*/
      {
      @extend %label-movement;
    }
  }

  &.textareafield {
    margin-top: 25px;

    label {
      top: -45px;
      padding-left: 0px;
    }
  }

  textarea {
    background: transparent;
    border: 0;
    width: 100%;
    @include fontSize(16px);
    font-weight: 500;
    padding: 10px;
    resize: none;
    @include box-shadow(0px 0px 0px transparent);
  }

  // &.inputRead {
  //   input {
  //     ~label {
  //       @extend %label-movement;
  //     }

  //     &:read-only,
  //     &:disabled {
  //       ~label {
  //         @extend %label-movement;
  //       }
  //     }
  //   }
  // }
}

.individualEdit {
  position: relative;

  &:hover {
    .editUpdate {
      opacity: 1;
    }
  }

  .infoMessage {
    background: $white;
    @include border-radius(20px);
    @include position(absolute, $left: -7px, $top: 9px);
    height: 16px;
    width: 16px;
    z-index: 9;

    svg {
      color: $grey-light;
      display: inherit;
      @include fontSize(14px);
      margin-top: 1px;
      margin-left: 1px;
    }
  }

  .staticField {
    border: 1px solid rgba($black, 0.1);
    @include border-radius(8px);
    padding: 0px 13px;
    margin-bottom: 4px;
    min-height: 38.68px;

    &.nullValue {
      label {
        @include fontSize(13px);
        font-weight: 500;
        top: 8px;
        color: $black;

        @include themed {
          color: #ddd;
        }
      }
    }

    @include themed {
      background: $bg-grey;
    }

    &:hover {
      border-color: $black;
    }

    label {
      background: $white;
      color: $primary;
      @include fontSize(9.7px);
      font-family: "Montserrat";
      font-weight: 500;
      padding: 1px 18px 1px 4px;
      @include position(relative, $top: -12.3px, $left: -4px);

      @include themed {
        background: transparent;
      }
    }

    p {
      @include fontSize(13px);
      font-family: "Montserrat";
      font-weight: 500;
      @include position(relative, $top: -7.5px);

      @include themed {
        color: $white;
      }
    }

    .infoMessage {
      background: $white;
      @include border-radius(20px);
      @include position(absolute, $left: -7px, $top: 9px);
      height: 16px;
      width: 16px;
      z-index: 9;

      svg {
        color: $grey-light;
        display: inherit;
        @include fontSize(14px);
        margin-top: 1px;
        margin-left: 1px;
      }
    }
  }

  .editUpdate {
    background: $blue-light;
    @include border-radius(40px);
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    @include position(absolute, $top: 5px, $right: 6px);
    height: 26px;
    width: 26px;
    z-index: 9;
    opacity: 0;

    &:hover {
      background: $primary;
      color: $white;
    }

    svg {
      height: 16px;
    }

    &.visible {
      background: #d0ffd8;
      color: #45b958;
      opacity: 1;

      &:hover {
        background: #45b958;
        color: $white;
      }
    }
  }
}

.structure_box {
  .MuiChip-root {
    @include themed {
      border: 1px solid #333;
    }

    .MuiChip-label {
      font-family: "Montserrat";
      font-weight: 500;
      letter-spacing: 0px;
      @include fontSize(13px);

      @include themed {
        color: $white;
      }
    }

    svg {
      @include themed {
        color: $white !important;
      }
    }
  }
}