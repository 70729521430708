@import '../../../../../../mixins.scss';

.retail_section {
    background: #2160aa12;
    padding: 24px;
    border-radius: 12px;

    .proContainer {
        align-items: center;
        min-height: 50px;
        padding: 0;
        margin: 0;

        .heading {
            font-family: Montserrat;
            font-size: 10px;
            font-weight: 600;
            line-height: 30px;
        }

        .product_icon {
            position: relative;
            $products: HEALTH, CAR, BIKE, TERM, INVESTMENT, TRAVEL;
            $k: 0;

            @each $pro in $products {
                $bacRightP: $k * 33;
                $k: $k +1;

                &.#{$pro} {
                    &:before {
                        @include position(absolute, $left: 0px, $top: 0px);
                        content: "";
                        height: 30px;
                        width: 33px;
                        background: url("../../../../../../../public/eproducts.svg");
                        background-position: -#{$bacRightP}px 0px;
                        background-size: 198px;
                        z-index: 2;
                    }
                }
            }
        }
    }
}